
  import Vue from 'vue';
  import TaskCard from '@/components/TaskCard.vue';
  import TaskDetail from '@/components/TaskDetail/TaskDetail.vue';
  import Component from 'vue-class-component';
  import { BoardModule, BoardStore } from '@/store/board';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import PageHeader from '@/components/PageHeader.vue';
  import AddBoard from '@/components/AddBoard.vue';
  import AddSpecialOccurrence from '@/components/AddSpecialOccurrence.vue';
  import RouteNames from '@/types/routeNames';
  import { UserModule, UserStore } from '@/store/user';
  import UserTimeZone from '@/components/User/UserTimeZone.vue';
  import { SpecialOccurrenceDto, SpecialOccurrenceListDto } from "@/apiclient/apiclient_generated";
  import { specialOccurrenceClient } from '@/apiclient/opsboardapi';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import SpecialOccurrenceService from '@/services/specialOccurrence.service';
  import PublicConstants from '@/types/publicConstants';

  @Component({
    components: {
      LoadingSpinner,
      UserTimeZone,
      AddBoard,
      AddSpecialOccurrence,
      PageHeader,
      TaskCard,
      TaskDetail,
    },
  })
  export default class BoardsComponent extends Vue {
    routes = RouteNames;
    private boardStore: BoardModule = BoardStore;
    private occurrenceStore: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private users: UserModule = UserStore;
    occurrences: SpecialOccurrenceListDto[] = [];
    occurrencesLoading = true;
    publicConstants = PublicConstants;

    async mounted() {
      await this.boardStore.loadAllBoards();
      await this.boardStore.loadAvailableBoards();
      await this.occurrenceStore.getAll();

      this.occurrences = await specialOccurrenceClient.getAllGrouped();
      this.occurrencesLoading = false;
    }

    get isAdmin() {
      return this.users.user && this.users.user.isAdmin;
    }

    get isMaterialRequestAdmin() {
      return this.users.user && this.users.user.isMaterialRequestAdmin;
    }

    get isSuperAdmin() {
      return this.users.user && this.users.user.isSuperAdmin;
    }

    get availableBoards() {
      return this.lodash.orderBy(this.boardStore.availableBoards, (t) => t.title);
    }

    get specialOccurrences() {
      return this.lodash.orderBy(this.occurrenceStore.allOccurrences, 'createdDate', 'desc');
    }

    navigateToOccurrence(so: SpecialOccurrenceListDto, id) {
      const route = {
        name: SpecialOccurrenceService.getTechnicalOccurrenceRouteName(so),
        params: { occId: id },
      };

      this.$router.push(route);
    }

    navigateToMaterialRequest(id) {
      const route = {
        name: RouteNames.specialOccurrence.root,
        params: { occId: id },
      };

      this.$router.push(route);
    }

    async createBoard() {
      const modal: any = this.$refs['newBoard'];
      await modal.show();
    }

    async createSpecialOccurrence() {
      const modal: any = this.$refs['newSpecialOccurrence'];
      await modal.show();
    }
  }
