import { DateTime } from 'luxon';

export default function formatTime(value) {
  if (!value) {
    return value;
  }

  let date;

  switch (typeof value) {
    case 'string':
      date = DateTime.fromISO(value);
      break;

    case 'object':
      date = DateTime.fromISO(value.toISOString());
      break;
  }
  return date.toFormat('HH:mm');
}
