import Vue from 'vue';
import App from './App.vue';
import router from './router/router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Notifications from 'vue-notification';

import opstaskHub from '@/signalr/opstaskhub';
Vue.use(opstaskHub);

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

import VueLodash from 'vue-lodash';
import lodash from 'lodash';
Vue.use(VueLodash, { lodash: lodash });

import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Notifications);

import TextareaAutosize from 'vue-textarea-autosize';
Vue.use(TextareaAutosize);

import VueLuxon from 'vue-luxon';
Vue.use(VueLuxon);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

import VJsonEditor from 'v-jsoneditor';
Vue.use(VJsonEditor);

import { setupAxios } from '@/axios-setup';

import { Grid, GridToolbar } from '@progress/kendo-vue-grid';
import { Input } from '@progress/kendo-vue-inputs';
Vue.component('toolbar', GridToolbar);
Vue.component('Grid', Grid);
Vue.component('k-input', Input);

import CheckboxWithLabel from '@/components/Misc/CheckboxWithLabel.vue';
Vue.component('checkbox-with-label', CheckboxWithLabel);

import Viewer from 'v-viewer';
Vue.use(Viewer);

import filters from './filters/index';
import { BoardStore } from '@/store/board';
Vue.use(filters);

import AppInfo from '@/components/AppInfo/AppInfo.vue';
Vue.component('AppInfo', AppInfo);

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import linkifyDirective from '@/directives/linkify-directive';
Vue.directive('linkifyDirective', linkifyDirective);

import simplifyHtmlToString from '@/directives/simplifyHtmlToString';
Vue.directive('simplify-html', simplifyHtmlToString);

import vueDebounce from 'vue-debounce';
Vue.use(vueDebounce, { defaultTime: '1000ms', listenTo: ['input', 'keyup'] });

import ElementUI from 'element-ui';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-tiptap/lib/index.css';

//@ts-ignore
Vue.use(ElementTiptapPlugin, {
  /* plugin options */
});

// use ElementUI's plugin
Vue.use(ElementUI);

// install rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend('hasitems', (value) => {
  return value && value.length > 0;
});

import VCalendar from 'v-calendar';
Vue.use(VCalendar);

import BoardItemDatePicker from '@/components/BoardItem/BoardItemDatePicker.vue';
Vue.component('board-item-date-picker', BoardItemDatePicker);

import disableComponent from '@/directives/disable-component';
Vue.directive('disable-component', disableComponent);

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

import JSZip from 'jszip'
Vue.use(JSZip)

import '@progress/kendo-licensing';
import '@progress/kendo-ui';
import '../kendo-ui-license.js';
import { SpreadsheetInstaller } from '@progress/kendo-spreadsheet-vue-wrapper';

Vue.use(SpreadsheetInstaller);

async function init() {
  console.log(`Using ${process.env.VUE_APP_APIURL} as base API url`);

  //temp for Condor Demo
  // if (window.location.href.toLowerCase().indexOf('azurewebsites') !== -1) {
  //   document.documentElement.style.setProperty('--bg-image', "url('/background_lufthansa.jpg')");
  //   document.documentElement.style.setProperty('--bg-image-opacity', '0.32');
  // }

  //start interval on BoardStore to make sure we refresh time dependent data
  setInterval(() => BoardStore.tickTime(), 1000);

  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
}

setupAxios();
init();
