
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { AvailabilityEntityType, UserDto } from '@/apiclient/apiclient_generated';
  import { UserModule, UserStore } from '@/store/user';
  import { BoardModule, BoardStore } from '@/store/board';
  import { orderBy } from 'lodash';

  @Component
  export default class BoardItemAssignedUser extends Vue {
    private userStore: UserModule = UserStore;
    private boardStore: BoardModule = BoardStore;
    @Prop() value: UserDto;
    @Prop() disabled;

    private filter = '';

    get filteredUsers() {
      let users = this.userStore.availableUsers;

      if (this.value) {
        users = users.filter((_) => _.id != this.value.id);
      }

      return orderBy(
        users.filter((item) => item.displayName.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0),
        ['firstName', 'lastName']
      );
    }

    mounted() {
      const userParams = {
        entityType: AvailabilityEntityType.Board,
        entityId: this.boardStore.board.id,
      };

      this.userStore.getAvailableUsers(userParams);
    }

    get user(): UserDto {
      return this.value;
    }

    async assignUser(user: UserDto) {
      const popover: any = this.$refs.assignUser;
      if (popover) {
        this.filter = '';
        popover.doClose();
      }

      this.$emit('boardItemAssignedUserUpdated', user);
    }
  }
