import { DirectiveOptions } from 'vue';
import * as linkifyStr from 'linkifyjs/html';
import { LookupStore } from '@/store/lookup';


const formatAmosStr = function (value: string) {
  const regEx = /#(?<key>wo|tq|mocid|pn|pr|p|aal)(?<id>[^\s]+)?/gi;
  const matches = value.matchAll(regEx);

  for (const match of matches) {
    let url = 'amos://';
    switch (match.groups.key.toLowerCase()) {
      case 'wo':
        url += 'WO.' + match.groups.id;
        break;
      case 'tq':
        url += 'TQ.' + match.groups.id;
        break;
      case 'mocid':
        url += 'AET.' + match.groups.id;
        break;
      case 'pn':
        url += 'PA.' + match.groups.id;
        break;
      case 'pr':
        url += 'ORDER.PR' + match.groups.id;
        break;
      case 'aal':
        url += 'AC_INT.' + (match.groups.id.toLowerCase().charCodeAt(0) - 96).toString();
    }

    if (window.location.href.indexOf('azurewebsite') === -1) {
      value = (value as any).replaceAll(match[0], `<a href="${url.trim()}" class="linkified amosbadge" title="Open AMOS" target="_blank"><i class="far fa-xs fa-external-link"></i> ${match[0].toUpperCase()}</a>`);
    } else {
      value = (value as any).replaceAll(match[0], `<a href="https://opsboarddemo.azurewebsites.net/amos.png" class="linkified badge badge-info text-xl" style="font-size:85%;" target="_blank"><i class="far fa-xs fa-external-link"></i> ${match[0].toUpperCase()}</a>`);
    }
  }

  return value;
};

const formatAirportLink = function (value: string) {
  const regEx = /#(?<iata>[a-zA-Z]{3})_SIS/gi;
  const matches = value.matchAll(regEx);

  for (const match of matches) {
    if (match.groups.iata) {
      const airports = LookupStore.airports;
      const airport = airports.find((_) => _.iataCode.toLowerCase() == match.groups.iata.toLowerCase());

      if (airport) {
        value = (value as any).replaceAll(
          match[0],
          `<a href="${airport.sisPdfUrl.trim()}" class="linkified text-nowrap" target="_blank"><i class="far fa-xs fa-external-link"></i> ${airport.iataCode.toUpperCase()} SIS</a>`
        );
      }
    }
  }
  return value;
};

const options = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  format: function (value, type) {
    if (value.indexOf('/infobox') > -1 || value.indexOf('infobox.aerologic.aero') != -1 || value.indexOf('infobox.al.network') != -1) {
      return 'Infobox Link';
    } else {
      return value;
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formatHref: function (value, type) {
    return value;
  },
};

const linkifyDirective: DirectiveOptions = {
  inserted(el, node) {
    if (node.value) {
      let html = linkifyStr(node.value, options);
      html = formatAmosStr(html);
      html = formatAirportLink(html);

      el.innerHTML = html.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
  },

  update(el, node) {
    if (node.value) {
      let html = linkifyStr(node.value, options);
      html = formatAmosStr(html);
      html = formatAirportLink(html);

      el.innerHTML = html.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
  },
};
export function linkify(value: string)
{
  if (!value)
    return '';

  let html = linkifyStr(value, options);
  html = formatAmosStr(html);
  html = formatAirportLink(html);
  return html;
}

export default linkifyDirective;
