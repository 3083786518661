import DateRangeDto from '@/types/dateRangeDto';
import { DateTime } from 'luxon';

export default function formatRangeDate(value: DateRangeDto) {
  if (!value) {
    return value;
  }

  const dateFrom = DateTime.fromISO(value.start).toFormat('dd.MM.yyyy');
  const dateTo = DateTime.fromISO(value.end).toFormat('dd.MM.yyyy');

  return `${dateFrom} - ${dateTo}`;
}
