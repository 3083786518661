var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{},[_c('button',{staticClass:"mr-2 d-flex justify-content-between align-items-center",style:({
      color: '#888',
      padding: '3px 5px',
      'border-radius': '3px',
      'font-size': '.9rem',
      border: 'dashed 1px #888',
    }),attrs:{"id":"addLabelFilter","type":"button"}},[_c('i',{staticClass:"far fa-plus mr-1 ml-1"}),_vm._v(" Add Label ")]),_c('b-popover',{ref:"addLabelFilter",attrs:{"target":"addLabelFilter","triggers":"click blur"}},[_c('div',{staticClass:"label mt-1 mb-1 d-flex align-items-center justify-content-center"},[_vm._v("Add Label")]),_c('hr',{staticClass:"mt-2 mb-2"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form-control mb-2 mt-1",attrs:{"type":"text","placeholder":"Filter..."},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing)return;_vm.filter=$event.target.value}}}),_c('div',{staticClass:"bi-label-container"},[_vm._l((_vm.filteredLabels),function(label){return _c('div',{key:label.id},[_c('div',{staticClass:"bi-label mb-2 cursor-pointer",style:({
            'background-color': label.color,
          }),on:{"click":function($event){return _vm.addLabel(label)}}},[(label.icon)?_c('i',{staticClass:"mr-1 ml-1",class:label.icon}):_vm._e(),_vm._v(" "+_vm._s(label.title)+" ")])])}),(!_vm.filteredLabels.length)?_c('div',[_c('div',{staticClass:"bi-label no-available-labels mb-2"},[_vm._v("No labels available")])]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }