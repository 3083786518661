import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { AUTH_MODE, WS_URL } from '@/config';
import { BoardStore } from '@/store/board';
import { NotificationStore } from '@/store/notification';
import { SettingsStore } from '@/store/settings';
import { UserStore } from '@/store/user';
import { GlobalHelper } from '@/helpers/global-helper';
import {
  BoardItemDto,
  BoardItemLabelsDto,
  OpsHubEvents,
  SpecialOccurrenceBoardDto,
  SpecialOccurrenceUpdateDto,
  BoardLabelDto,
  SpecialOccurrenceDto,
  ManagementReportInfoDto,
  IServiceResultOfManagementReportInfoDto,
} from '@/apiclient/apiclient_generated';
import { SpecialOccurrenceStore } from '@/store/specialOccurrence';
import BuildInfo from '@/build-info';
import { LabelStore } from '@/store/label';
import Mapper from '@/helpers/mapper';
import { MaterialRequestStore } from '@/store/materialRequest';

export enum MessageType {
  Error,
  Success,
}

const opstaskHub = {
  install(Vue) {
    const options: any = {};

    if (AUTH_MODE == 'azuread') {
      options.accessTokenFactory = () => sessionStorage.getItem('msal.idtoken');
    }

    const connection = new HubConnectionBuilder()
      .withUrl(WS_URL + '/opstaskhub', options)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    const opstaskhub = new Vue();
    Vue.prototype.$opstaskhub = opstaskhub;

    opstaskhub.subscribeToBoard = async function (guid: string) {
      await connection.invoke('SubscribeToBoard', guid);
    };

    opstaskhub.leaveBoard = async function (guid: string) {
      await connection.invoke('LeaveBoard', guid);
    };

    opstaskhub.sendForceReload = async function (connectionId: string) {
      await connection.invoke('forceReload', connectionId);
    };

    async function start() {
      try {
        await connection.start();
        await connection.send('sendVersion', BuildInfo.buildNumber);
        await BoardStore.loadAllBoards();
        await BoardStore.loadAvailableBoards();

        BoardStore.setHubStatus(true);
      } catch (err) {
        setTimeout(() => start(), 5000);
      }
    }

    async function notifyCurrentUser(messageType: MessageType, userId, message, title = null) {
      if (UserStore.user.id === userId) {
        switch (messageType) {
          case MessageType.Success:
            GlobalHelper.NotifySuccess(message);
            break;

          case MessageType.Error:
            GlobalHelper.NotifyError(message, title);
        }
      }
    }

    connection.onclose(() => {
      BoardStore.setHubStatus(false);
      start();
    });

    connection.on('shiftHandover', async (boardId) => {
      if (BoardStore.board.id == boardId) {
        await BoardStore.loadData(boardId);
      }
    });

    connection.on('forceReload', async () => {
      window.location.reload();
    });

    connection.on(OpsHubEvents.UpdateTaskLabels, (item: BoardItemLabelsDto) => {
      // Update Board
      const store = BoardStore;
      const index = store.boardItems.findIndex((_) => _.id === item.boardItemId);
      if (index >= 0) {
        store.boardItems[index].labels = item.labels;
      }

      if (store.currentBoardItem.id === item.boardItemId) {
        store.currentBoardItem.labels = item.labels;
      }

      const delegatedBoardItemIndex = store.delegatedBoardItems.findIndex((_) => _.id === item.boardItemId);
      if (delegatedBoardItemIndex >= 0) {
        store.delegatedBoardItems[delegatedBoardItemIndex].labels = item.labels;
      }

      // Update SpecialOccurrence Board
      const occStore = SpecialOccurrenceStore;
      occStore.soBoards.forEach((board) => {
        const soItemIndex = board.items.findIndex((_) => _.id === item.boardItemId);

        if (soItemIndex >= 0) {
          board.items[soItemIndex].labels = item.labels;
        }
      });
    });

    connection.on('updateTask', (item: BoardItemDto) => {
      const store = BoardStore;
      store.updateTask(item);

      const delegatedIndex = store.delegatedBoardItems.findIndex((_) => _.id === item.id);
      if (delegatedIndex !== -1) {
        store.delegatedBoardItems.splice(delegatedIndex, 1);
        store.delegatedBoardItems.push(item);
      }

      const sharedIndex = store.sharedBoardItems.findIndex((_) => _.id === item.id);
      if (sharedIndex !== -1) {
        store.sharedBoardItems.splice(sharedIndex, 1);
        store.sharedBoardItems.push(item);
      } else if (!item.isTemplate && item.isShared && item.sharedBoards && item.sharedBoards.some((t) => t.boardId === store.board.id)) {
        store.sharedBoardItems.push(item);
      }

      const board = store.allBoards?.find((_) => _.id === item.initialOwnerBoardId);
      const occStore = SpecialOccurrenceStore;
      occStore.processBoardItemUpdate({ boardItem: item, boardTitle: board?.title });
    });

    connection.on('processShiftPreparationResult', (updates) => {
      if (BoardStore.board.id === updates.boardId) {
        BoardStore.addBoardItems(updates.boardItems);
        BoardStore.updateCurrentShift(updates.isHandoverPrepared);
      }
    });

    connection.on(OpsHubEvents.AttachmentUpdated, (updates) => {
      BoardStore.updateBoardItemAttachment(updates);
    });

    connection.on('checkUpdate', (maxRowNumber) => {
      BoardStore.checkUpdate(maxRowNumber);
    });

    connection.on(OpsHubEvents.LabelReactivated, (label) => {
      LabelStore.reactivateLabel(label);
    });

    connection.on(OpsHubEvents.AddNotification, (notification) => {
      NotificationStore.addNotification(notification);
    });

    connection.on(OpsHubEvents.RemoveNotification, (notification) => {
      BoardStore.updateBoardItemViews({ boardItemId: notification.entityId, boardItemViews: notification.params });

      NotificationStore.removeNotification(notification);
    });

    connection.on('updateBoardInitialSettings', (updates) => {
      if (BoardStore.board.id === updates.board.id) {
        SettingsStore.setBoardInitialSettings(updates.settings);
        BoardStore.setBoardTitle(updates.settings);
        BoardStore.setShiftTypes(updates.board.shiftTypes);
      }
    });

    connection.on('updateBoardItemAction', (data) => {
      if (BoardStore.board.id === data.boardId) {
        BoardStore.setActionUpdates(data);

        if (!data.updates.error) {
          GlobalHelper.NotifySuccess('Action Updated.');
        }
      }
    });

    connection.on('BoardItemActionError', (data) => {
      notifyCurrentUser(MessageType.Error, data.userId, data.message);
    });

    connection.on('BoardItemActionUpdate', (data) => {
      notifyCurrentUser(MessageType.Success, data.userId, data.message);
    });

    connection.on('ActionParamError', (data) => {
      notifyCurrentUser(MessageType.Error, data.userId, data.message, 'Action Param Error');
    });

    connection.on('ItemWasMoved', (data) => {
      const store = BoardStore;
      const currentBoardId = store.board.id;

      const doneBoardItem: BoardItemDto = data.doneBoardItem;
      const movedBoardItem: BoardItemDto = data.movedBoardItem;

      if (currentBoardId === doneBoardItem.ownerBoardId) {
        // remove moved item from current board
        let index = -1;

        while ((index = store.boardItems.findIndex((_) => _.id === movedBoardItem.id)) !== -1) {
          store.boardItems.splice(index, 1);
        }

        store.boardItems.push(doneBoardItem);
      }

      if (currentBoardId === movedBoardItem.ownerBoardId) {
        store.boardItems.push(movedBoardItem);
      }

      // Special Occurrence board update
      const occurrenceStore = SpecialOccurrenceStore;
      if (occurrenceStore.currentOccurrence && occurrenceStore.currentOccurrence.id === movedBoardItem.specialOccurrenceId) {
        // Process Moved BoardItem
        let movedToBoardIndex = occurrenceStore.soBoards.findIndex((_) => _.boardId === movedBoardItem.ownerBoardId);

        if (movedToBoardIndex === -1) {
          const newLength = occurrenceStore.soBoards.push({
            boardId: movedBoardItem.ownerBoardId,
            boardTitle: store.allBoards.find((_) => _.id == movedBoardItem.ownerBoardId)?.title || 'Board',
            items: [],
          } as SpecialOccurrenceBoardDto);

          movedToBoardIndex = newLength - 1;
        }

        if (!occurrenceStore.soBoards[movedToBoardIndex].items) {
          occurrenceStore.soBoards[movedToBoardIndex].items = [];
        }
        occurrenceStore.soBoards[movedToBoardIndex].items.push(movedBoardItem);

        // Process Done BoardItem
        const doneBoardIndex = occurrenceStore.soBoards.findIndex((_) => _.boardId === doneBoardItem.ownerBoardId);

        const doneItemIndex = occurrenceStore.soBoards[doneBoardIndex].items.findIndex((_) => _.id === movedBoardItem.id);

        occurrenceStore.soBoards[doneBoardIndex].items.splice(doneItemIndex, 1, doneBoardItem);
      }
    });

    connection.on('updateSharedTasks', async () => {
      await BoardStore.loadSharedBoardItems();
    });

    connection.on('ItemWasShared', (item: BoardItemDto) => {
      const store = BoardStore;
      const currentBoardId = store.board.id;

      // remove shared item from current board
      let indexToRemove = -1;
      while ((indexToRemove = store.boardItems.findIndex((_) => _.id === item.id)) !== -1) {
        store.boardItems.splice(indexToRemove, 1);
      }

      const sharedIndex = store.sharedBoardItems.findIndex((_) => _.id === item.id);

      if (sharedIndex !== -1) {
        store.sharedBoardItems.splice(sharedIndex, 1, item);
      }

      if (sharedIndex === -1 && item.sharedBoards.find((_) => _.boardId == currentBoardId)) {
        store.sharedBoardItems.push(item);
      }
    });

    connection.on('ItemWasUnShared', (item: BoardItemDto) => {
      const store = BoardStore;
      const currentBoardId = store.board.id;

      //currently shared ids
      const sharedIds = item.sharedBoards.map((_) => _.boardId);
      if (sharedIds.indexOf(currentBoardId) === -1) {
        const sharedIndex = store.sharedBoardItems.findIndex((_) => _.id === item.id);

        if (sharedIndex !== -1) {
          store.sharedBoardItems.splice(sharedIndex, 1);
        }
      }
    });

    connection.on('ItemWasDelegated', (item: BoardItemDto) => {
      const store = BoardStore;
      const currentBoardId = store.board.id;

      if (currentBoardId === item.ownerBoardId) {
        // remove moved item from current board
        let indexToRemove = -1;
        while ((indexToRemove = store.boardItems.findIndex((_) => _.id === item.id)) !== -1) {
          store.boardItems.splice(indexToRemove, 1);
        }

        const delegatedIndex = store.delegatedBoardItems.findIndex((_) => _.id === item.id);
        if (delegatedIndex === -1) {
          store.delegatedBoardItems.push(item);
        }
      }

      if (store.board && store.board.lists && store.board.lists.some((_) => _.id === item.boardItemListId)) {
        store.boardItems.push(item);
      }

      // Special Occurrence board update
      const occurrenceStore = SpecialOccurrenceStore;
      if (occurrenceStore.currentOccurrence && occurrenceStore.currentOccurrence.id === item.specialOccurrenceId) {
        const newBoard = store.allBoards.find((_) => _.lists.some((l) => l.id == item.boardItemListId));
        if (newBoard) {
          // remove delegated item from owner board
          const oldBoardIndex = occurrenceStore.soBoards.findIndex((_) => _.boardId === item.ownerBoardId);
          const oldItemIndex = occurrenceStore.soBoards[oldBoardIndex].items.findIndex((_) => _.id === item.id);
          occurrenceStore.soBoards[oldBoardIndex].items.splice(oldItemIndex, 1);

          // Add delegated item to new board
          const boardIndex = occurrenceStore.soBoards.findIndex((_) => _.boardId == newBoard.id);
          if (!occurrenceStore.soBoards[boardIndex].items || !occurrenceStore.soBoards[boardIndex].items.length) {
            occurrenceStore.soBoards[boardIndex].items = [];
          }
          occurrenceStore.soBoards[boardIndex].items.push(item);
        }
      }
    });

    connection.on('MoveBackDelegatedItem', (item: BoardItemDto) => {
      const store = BoardStore;
      const currentBoardId = store.board.id;

      const delegatedIndexToRemove = store.delegatedBoardItems.findIndex((_) => _.id === item.id);
      if (delegatedIndexToRemove !== -1) {
        store.delegatedBoardItems.splice(delegatedIndexToRemove, 1);
      }

      const indexToRemove = store.boardItems.findIndex((_) => _.id === item.id);
      if (indexToRemove !== -1) {
        store.boardItems.splice(indexToRemove, 1);
      }

      if (item.ownerBoardId === currentBoardId) {
        store.boardItems.push(item);
      }

      const occurrenceStore = SpecialOccurrenceStore;
      if (occurrenceStore.currentOccurrence && occurrenceStore.currentOccurrence.id === item.specialOccurrenceId) {
        // Remove BoardItem from delegated board
        for (let index = 0; index < occurrenceStore.soBoards.length; index++) {
          const board = occurrenceStore.soBoards[index];

          const obsoleteItemIndex = board.items.findIndex((_) => _.id === item.id);

          if (obsoleteItemIndex >= 0) {
            board.items.splice(obsoleteItemIndex, 1);
            break;
          }
        }

        // Add BoardItem to owner board
        const newBoardIndex = occurrenceStore.soBoards.findIndex((_) => _.boardId === item.ownerBoardId);

        if (newBoardIndex >= 0) {
          const newBoard = occurrenceStore.soBoards[newBoardIndex];
          if (!newBoard.items || !newBoard.items.length) {
            newBoard.items = [];
          }
          newBoard.items.push(item);
        } else {
          occurrenceStore.soBoards.push({
            boardId: item.ownerBoardId,
            boardTitle: store.allBoards.find((_) => _.id == item.ownerBoardId)?.title || 'Board',
            items: [item],
          } as SpecialOccurrenceBoardDto);
        }
      }
    });

    // Special Occurrence section
    connection.on(OpsHubEvents.SpecialOccurrenceCreated, async (result) => {
      const store = SpecialOccurrenceStore;
      await store.getAll();

      const occurrence = result?.occurrence as SpecialOccurrenceDto;

      if (!occurrence || !occurrence.id) {
        return;
      }

      const soListDto = Mapper.mapSpecialOccurrence(occurrence);

      store.addGroupedOccurrence(soListDto);
    });

    connection.on(OpsHubEvents.SpecialOccurrenceUpdated, async (result) => {
      const store = SpecialOccurrenceStore;

      const occurrence = result?.occurrence as SpecialOccurrenceDto;
      if (!occurrence || !occurrence.id) {
        return;
      }

      const soListDto = Mapper.mapSpecialOccurrence(occurrence);

      store.updateGroupedOccurrence(soListDto);
    });

    // Special Occurrence section
    connection.on('soBoardItemCreated', (data) => {
      const store = SpecialOccurrenceStore;
      store.setSOBoardItem(data);

      const boardStore = BoardStore;

      if (boardStore.board && boardStore.board.id === data.boardId) {
        data.items.forEach((_) => boardStore.updateTask(_));
      }
    });

    connection.on('addSpecialOccurrenceUpdate', (data: SpecialOccurrenceUpdateDto) => {
      const store = SpecialOccurrenceStore;
      store.addSpecialOccurrenceUpdate(data);
    });

    connection.on(OpsHubEvents.GeneralLabelWasDeleted, (labelId: string) => {
      const store = LabelStore;
      store.deleteLabelById(labelId);
    });

    connection.on(OpsHubEvents.SelectedBoardLabelsWereUpdated, (selectedLabels: BoardLabelDto[]) => {
      const store = LabelStore;

      store.setSelectedBoardLabels(selectedLabels);
    });

    connection.on(OpsHubEvents.BoardLabelsUpdated, (update) => {
      const store = LabelStore;

      store.setBoardLabels(update);
    });

    connection.on(OpsHubEvents.ManagementReportPostponed, async (updates: IServiceResultOfManagementReportInfoDto) => {
      const store = MaterialRequestStore;

      store.processMaterialRequestChanges(updates);
    });

    start();
  },
};

export default opstaskHub;
