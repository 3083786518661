
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { AttachmentsModule, AttachmentsStore } from '@/store/attachments';
  import FileSaver from 'file-saver';
  import { API_URL } from '@/config';
  import { AttachmentDestination, AttachmentDto, DeleteAttachmentDto, UpdateAttachmentDto } from '@/apiclient/apiclient_generated';
  import { attachmentClient } from '@/apiclient/opsboardapi';

  @Component
  export default class AttachmentView extends Vue {
    @Prop() destination: AttachmentDestination;
    @Prop() attachments: AttachmentDto[];
    @Prop() readOnly: boolean;
    @Prop() destinationId: string;
    @Prop({ default: 'Attachments' }) header: string;
    @Prop({ default: true }) canDelete: boolean;
    @Prop({ default: '*' }) fileExtensions: string;
    @Prop({ default: '' }) ignoreFileExtensions: string;
    @Prop() disableDownloadAll: boolean;

    private apiUrl = API_URL;

    private vvieweroptions = {
      inline: false,
      button: false,
      navbar: false,
      title: false,
      toolbar: false,
      tooltip: false,
      movable: true,
      zoomable: true,
      rotatable: true,
      scalable: true,
      transition: true,
      fullscreen: true,
      keyboard: true,
      url: 'data-src',
    };

    canBeDisplayedInline(attachment: AttachmentDto) {
      return this.imageFileExtensions.some((t) => t == attachment.fileExtension);
    }

    async moveAttachmentToInline(attachment: AttachmentDto) {
      await attachmentClient.updateFile({
        id: attachment.id,
        boardItemId: this.destinationId,
        isWideBoardItemAttachment: true,
      } as UpdateAttachmentDto);
    }

    private imageFileExtensions = ['.jpg', '.jpeg', '.png', '.bmp'];

    private attachmentsStore: AttachmentsModule = AttachmentsStore;

    get showAttachmentsView() {
      switch (this.destination) {
        case AttachmentDestination.BoardItem:
        case AttachmentDestination.SpecialOccurrence:
        case AttachmentDestination.MaterialPart:
          return this.attachments?.length;
        default:
          return false;
      }
    }

    async downloadFile(attachment) {
      if (this.imageFileExtensions.indexOf(attachment.fileExtension) != -1) return;

      const fileData = await this.attachmentsStore.downloadAttachment(attachment.id);
      FileSaver.saveAs(fileData.data, fileData.fileName);
    }

    async downloadAll() {
      const fileData = await attachmentClient.downloadAllAttachments(this.destinationId, this.destination);
      FileSaver.saveAs(fileData.data, fileData.fileName);
    }

    get filteredAttachments(): AttachmentDto[] {
      if (this.fileExtensions == '*' && this.ignoreFileExtensions == '') return this.attachments;

      const fileExtArr = this.fileExtensions.toUpperCase().split('|');
      const fileIgnoreExtArr = this.ignoreFileExtensions.toUpperCase().split('|');

      if (this.fileExtensions != '*') {
        return this.attachments.filter((_) => fileExtArr.indexOf(_.fileExtension.toUpperCase().replace('.', '')) !== -1);
      }

      if (this.ignoreFileExtensions != '') {
        return this.attachments.filter((_) => fileIgnoreExtArr.indexOf(_.fileExtension.toUpperCase().replace('.', '')) === -1);
      }

      return this.attachments;
    }

    confirmDelete(attachment) {
      this.$bvModal
        .msgBoxConfirm('Do you really want to delete the attachment ' + attachment.fileName, {
          title: 'Confirm deletion',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete attachment',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then((value) => {
          if (value) {
            const params = {
              ownerId: '00000000-0000-0000-0000-000000000000',
              attachmentDestination: this.destination,
            } as DeleteAttachmentDto;

            switch (this.destination) {
              case AttachmentDestination.BoardItem:
                params.ownerId = this.destinationId;
                break;
            }

            this.attachmentsStore.deleteAttachment({ id: attachment.id, body: params, destination: this.destination }).then(() => {
              this.$emit('deleted');
            });
          }
        })
        .catch(() => {
          // An error occurred
        });
    }

    get canComment(): boolean {
      return this.destination.toLowerCase() === 'boarditem';
    }

    commentOn(attachment) {
      this.$root.$emit('commentonattachment', attachment);
    }
  }
