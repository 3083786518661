
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { FlightInfoDto } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';

  @Component({})
  export default class FlightInfo extends Vue {
    @Prop() flight: FlightInfoDto;

    openUrl(url) {
      // if (event) {
      //   event.stopPropagation();
      // }
      window.open(url, '_blank');
    }
  }
