
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SendAsEmailConfigDto } from '@/apiclient/apiclient_generated';
  import { settingsClient } from '@/apiclient/opsboardapi';
  import { GlobalHelper } from '@/helpers/global-helper';
  import WysiwigEditor from '@/components/Misc/WysiwigEditor.vue';

  @Component({
    components: { WysiwigEditor },
  })
  export default class SendAsEmailSettings extends Vue {
    private isDirty = false;
    private sendAsEmaiLConfig: SendAsEmailConfigDto = SendAsEmailConfigDto.fromJS({});
    private boardId: string;

    created() {
      this.boardId = this.$route.params.id;
    }

    async mounted() {
      this.sendAsEmaiLConfig = await settingsClient.getSendAsEmailSettings(this.boardId);
    }

    async save() {
      await settingsClient.storeSendAsEmailSettings(this.boardId, this.sendAsEmaiLConfig);
      GlobalHelper.NotifySuccess('Saved successfully');
    }
  }
