import { IBoardItemDto } from "@/apiclient/apiclient_generated";

export class BoardItemNotificationHelper {
  static NotifyUser(boardItem: IBoardItemDto) {
    if (boardItem.notificationSound == 'null' || boardItem.notificationSound == null || window.location.href.indexOf('board') == -1)
      return;

    const defaultNotification = new Audio("/notifications/acars.wav");
    const sound = new Audio("/notifications/" + boardItem.notificationSound);

    //note: if two notifications are triggered at the same time
    //currently the sound overlap each other - let's see how this works ;-)
    if (boardItem.notificationSound)
    {
      if (boardItem.notificationSound == 'default')
      {
        defaultNotification.play();
      } else {
        defaultNotification.onended = () => {
          sound.play();
        }

        defaultNotification.play();
      }
    }
  }
}
