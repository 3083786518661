
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import RouteNames from '@/types/routeNames';
  import { userClient } from '@/apiclient/opsboardapi';
  import {  UserDto } from '@/apiclient/apiclient_generated';

  @Component({
    components: {
      PageHeader,
    },
  })
  export default class UserSelect extends Vue {
    routes = RouteNames;
    masterUser: UserDto = UserDto.fromJS({});

    async mounted() {
      this.masterUser = await userClient.getCurrentUser();
    }

    navigateToBoards() {
      this.$router.push({name: RouteNames.boards});
    }
  }
