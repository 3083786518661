
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { ChecklistModule, ChecklistStore } from '@/store/checklist';
  import { IBoardItemDto } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';

  @Component({})
  export default class AddChecklist extends Vue {
    private checklistStore: ChecklistModule = ChecklistStore;
    private newChecklistTitle = 'Subtasks';

    @Prop() item: IBoardItemDto;

    async addChecklist(title) {
      const boardItemId = this.item.id;
      const data = {
        title,
        boardItemId,
      };
      await this.checklistStore.addChecklist(data);
      this.newChecklistTitle = 'Subtasks';
      const popover: any = this.$refs.createChecklist;
      if (popover) {
        popover.doClose();
      }
    }
  }
