
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { IBoardItemDto, BoardItemStatusDto, VarianceDto } from '@/apiclient/apiclient_generated';
  import { UserModule, UserStore } from '@/store/user';
  import { DateTime } from 'luxon';
  import { BoardStore, BoardModule } from '@/store/board';

  @Component({})
  export default class BoardItemHistory extends Vue {
    @Prop()
    boardItem: IBoardItemDto;
    statusType = BoardItemStatusDto;
    private userStore: UserModule = UserStore;
    private boardStore: BoardModule = BoardStore;

    toggleBoardItemHistory = false;

    changeTypes = {
      Title: 'Title',
      Description: 'Description',
      BoardItemStatus: 'BoardItemStatus',
      AssignedTo: 'AssignedTo',
      DueDate: 'DueDate',
      PlannedDate: 'PlannedDate',
      ValidFromDate: 'ValidFromDate',
      ValidToDate: 'ValidToDate',
      BoardItemTypeGuid: 'BoardItemTypeGuid',
    };

    boardItemTypes: any = {};

    private getUserName(userId) {
      const user = this.userStore.users.find((_) => _.id === userId);
      return user ? user.firstName : 'Unassigned';
    }

    private shouldDisplayChange(change: VarianceDto) {
      const condition =
        change.name === this.changeTypes.Title ||
        change.name === this.changeTypes.Description ||
        //
        change.name === this.changeTypes.BoardItemStatus ||
        //
        change.name === this.changeTypes.AssignedTo ||
        //
        change.name === this.changeTypes.PlannedDate ||
        change.name === this.changeTypes.DueDate ||
        change.name === this.changeTypes.ValidFromDate ||
        change.name === this.changeTypes.ValidToDate ||
        //
        change.name === this.changeTypes.BoardItemTypeGuid;

      return condition;
    }

    private getBoardItemType(typeId) {
      if (this.boardItemTypes[typeId]) {
        return this.boardItemTypes[typeId];
      }

      this.boardItemTypes[typeId] = this.boardStore.boardItemTypes.find((_) => _.id === typeId);

      return this.boardItemTypes[typeId];
    }

    private getFormattedDate(source) {
      if (!source) {
        return 'No date';
      }

      const date = DateTime.fromISO(source);
      return date.toFormat('dd.MM.yyyy HH:mm');
    }
  }
