
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { materialRequestClient } from '@/apiclient/opsboardapi';
  import { OrderStatusDto, ShipmentStatusDto, ShipperDto, VendorRequestPartOrderEditDto } from '@/apiclient/apiclient_generated';
  import { GlobalHelper } from '@/helpers/global-helper';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { UserModule, UserStore } from "@/store/user";

  @Component({
    components: { ModalWindow },
  })
  export default class EditVendorRequestPartOrder extends Vue {
    materialRequestId: string;
    vendorRequestPartId: string;
    vendorRequestPart: VendorRequestPartOrderEditDto = VendorRequestPartOrderEditDto.fromJS({ part: { pn: '' } });
    shipper: ShipperDto[] = [];
    shipperPopupText = '';
    orderStatus: OrderStatusDto[] = [];
    shipmentStatus: ShipmentStatusDto[] = [];
    private userStore: UserModule = UserStore;

    shipperHasChanged = false;

    created() {
      this.materialRequestId = this.$router.currentRoute.params.materialRequestId;
      this.vendorRequestPartId = this.$router.currentRoute.params.vendorRequestPartId;
    }

    async mounted() {
      await this.loadData();
    }

    async loadData() {
      this.shipper = await materialRequestClient.getShipper();
      this.vendorRequestPart = await materialRequestClient.getVendorRequestPartOrderEditDto(this.vendorRequestPartId);
      this.orderStatus = await materialRequestClient.getOrderStatus();
      this.shipmentStatus = await materialRequestClient.getShipmentStatus();

      await this.$nextTick();
      (this.$refs.form as any).validate();
    }

    get shipperIsOther(): boolean {
      const selectedShipper = this.shipper.find((_) => _.id == this.vendorRequestPart.shipperId);
      return selectedShipper && selectedShipper.isOther;
    }

    async savePart() {
      if (this.shipperHasChanged) {
        const selectedShipper = this.shipper.find((_) => _.id == this.vendorRequestPart.shipperId);

        if (selectedShipper && selectedShipper.popupText) {
          this.shipperPopupText = selectedShipper.popupText;
          this.$bvModal.show('shipperModal');
        } else {
          this.shipperHasChanged = false;
          await this.savePart();
        }
      } else {
        try {
          await materialRequestClient.editVendorRequestPartOrder(this.vendorRequestPart);
          GlobalHelper.NotifySuccess('Part saved');
          this.$emit('saved');
        } catch {
          GlobalHelper.NotifyError('Error occured');
        }
      }
    }

    async confirmShipperPopup() {
      this.shipperHasChanged = false;
      await this.savePart();
    }

    get userTlc(): string {
      return this.userStore.user.tlc;
    }

    changeShipper() {
      this.shipperHasChanged = true;
    }

    close() {
      this.$emit('close');
    }
  }
