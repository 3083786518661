import { AttachmentClient, AttachmentDestination, FileResponse, ImageDataDto, UpdateAttachmentDto } from '@/apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import { GlobalHelper } from '@/helpers/global-helper';

@Module({ dynamic: true, namespaced: true, store, name: 'attachments' })
export class AttachmentsModule extends VuexModule {
  private attachmentClient = new AttachmentClient(ApiUrl, axios);
  templateAttachments = [];
  occurrenceAttachments = [];

  @Action
  async loadOccurrenceAttachments(occId) {
    this.setOccurrenceAttachments([]);

    const attachments = await this.attachmentClient.getAllAttachments(occId, AttachmentDestination.SpecialOccurrence);
    this.setOccurrenceAttachments(attachments);
  }

  @Action
  async updateAttachment(dto: UpdateAttachmentDto) {
    await this.attachmentClient.updateFile(dto);
  }

  @Action
  async addAttachment(params: any) {
    const attachment = await this.attachmentClient.addFile(params.destination, params.id, {
      data: params.fileData,
      fileName: params.fileData.name,
    });

    switch (params.destination) {
      case AttachmentDestination.SpecialOccurrence:
        this.addOccurrenceAttachment(attachment);
        break;
    }

    return attachment;
  }

  @Action
  async addAttachmentFromBase64(params: any) {
    const dto = new ImageDataDto();
    dto.image = params.imageData;
    await this.attachmentClient.addImage(params.id, dto);
  }

  @Action
  async downloadAttachment(id: string): Promise<FileResponse> {
    return await this.attachmentClient.downloadAttachment(id);
  }

  @Action
  async deleteAttachment(params): Promise<any> {
    await this.attachmentClient.deleteAttachment(params.id, params.body);
    GlobalHelper.NotifySuccess('Attachment deleted.');

    this.removeAttachment(params);
  }

  @Mutation
  setTemplateAttachments(attachments) {
    this.templateAttachments = attachments;
  }

  @Mutation
  removeAttachment(params) {
    const { id, destination } = params;

    let attachmentsList = [];

    switch (destination) {
      case AttachmentDestination.SpecialOccurrence:
        attachmentsList = this.occurrenceAttachments;
        break;
    }

    const index = attachmentsList.findIndex((_) => _.id == id);

    if (index !== -1) {
      attachmentsList.splice(index, 1);
    }
  }

  @Mutation
  addOccurrenceAttachment(attachment) {
    if (!this.occurrenceAttachments) {
      this.occurrenceAttachments = [];
    }
    this.occurrenceAttachments.push(attachment);
  }

  @Mutation
  setOccurrenceAttachments(attachments) {
    this.occurrenceAttachments = attachments;
  }
}

export const AttachmentsStore = getModule(AttachmentsModule);
