
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import {
    AircraftDto,
    AirportDto,
    DiversionDto,
    FlightInfoDto,
    SpecialOccurrenceAccessLevel,
    SpecialOccurrenceFlightInsertDto,
  } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';
  import { GlobalHelper } from '@/helpers/global-helper';
  import TechnicalOccurrenceFlightsSelector from '@/components/SpecialOccurrence/Data/Flights/TechnicalOccurrenceFlightsSelector.vue';

  @Component({
    components: { TechnicalOccurrenceFlightsSelector },
  })
  export default class DiversionData extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    @Prop() occurrence: DiversionDto;
    showChooseFlightModal = false;
    addFlightsLoading = false;

    async mounted() {
      await this.store.loadDiversionReasons();
    }

    get divertedFlight(): FlightInfoDto {
      if (!this.occurrence.flights || !this.occurrence.flights[0]) {
        return null;
      }

      return this.occurrence.flights[0];
    }

    get stationInfo(): AirportDto {
      if (!this.divertedFlight) return AirportDto.fromJS({});

      return (
        this.store.airports.find((_) => _.iataCode.toLowerCase() === this.divertedFlight.departureAirport.toLowerCase()) || new AirportDto()
      );
    }

    get stationInfoDiv(): AirportDto {
      if (!this.divertedFlight || !this.divertedFlight.diversionAirport) return AirportDto.fromJS({});

      return (
        this.store.airports.find((_) => _.iataCode.toLowerCase() === this.divertedFlight.diversionAirport.toLowerCase()) || new AirportDto()
      );
    }

    get aircraftInfo(): AircraftDto {
      return this.store.aircrafts.find((_) => _.id == this.occurrence.aircraft) || new AircraftDto();
    }

    get airports() {
      return this.store.airports;
    }

    get aircrafts() {
      return this.store.aircrafts;
    }

    get diversionReasons() {
      return this.store.diversionReasons;
    }

    get userCanEdit(): boolean {
      return (
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin ||
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor
      );
    }

    get displayDiversionReasonRemarks(): boolean {
      const reason = this.diversionReasons.find(_=>_.id == this.occurrence.diversionReason);

      return reason?.isOther;
    }

    async chooseDivertedFlight() {
      this.addFlightsLoading = true;

      await this.store.saveOccurrenceDataChanges(this.occurrence);
      await this.store.getDiversionFlights();

      this.addFlightsLoading = false;

      const modal: any = this.$refs['addFlights'];
      modal.show();
    }

    closeModal() {
      const modal: any = this.$refs['addFlights'];
      modal.hide();
    }

    async saveChanges() {
      await this.store.saveOccurrenceDataChanges(this.occurrence);
      GlobalHelper.NotifySuccess('Special Occurrence updated');
      await this.store.loadData();
    }

    async flightSelected(flight) {
      this.store.currentOccurrence.flights = [];
      this.store.currentOccurrence.flights = [flight];

      const dto = {
        occurrenceId: this.occurrence.id,
        flights: this.store.currentOccurrence.flights,
      } as SpecialOccurrenceFlightInsertDto;

      await this.store.saveOccurrenceFlights(dto);

      GlobalHelper.NotifySuccess('Diverted Flight selected');
    }

    async removeFlight() {
      this.store.currentOccurrence.flights = [];
      const dto = {
        occurrenceId: this.occurrence.id,
        flights: this.store.currentOccurrence.flights,
      } as SpecialOccurrenceFlightInsertDto;

      await this.store.saveOccurrenceFlights(dto);
    }
  }
