var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"label mt-3 mb-1"},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"btn-group d-flex align-items-center",attrs:{"role":"group"}},[_c('button',{staticClass:"btn btn-xs",class:{
        'btn-outline-secondary': _vm.value,
        'btn-secondary': !_vm.value,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.updateState(false)}}},[_vm._v(" "+_vm._s(_vm.negativeText)+" ")]),_c('button',{staticClass:"btn btn-xs",class:{
        'btn-outline-primary': !_vm.value,
        'btn-primary': _vm.value,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.updateState(true)}}},[_vm._v(" "+_vm._s(_vm.positiveText)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }