
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { AttachmentsModule, AttachmentsStore } from '@/store/attachments';
  import { GlobalHelper } from '@/helpers/global-helper';
  import {
    AttachmentDestination,
    AttachmentDto,
    BoardItemDto,
    DeleteAttachmentDto,
    UpdateAttachmentDto,
  } from '@/apiclient/apiclient_generated';
  import { API_URL } from '@/config';
  import lodash from 'lodash';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';

  @Component({ components: { LoadingSpinner } })
  export default class WideAttachments extends Vue {
    @Prop() boardItem: BoardItemDto;

    apiUrl = API_URL;
    imageFileExtensions = ['.jpg', '.jpeg', '.png', '.bmp'];
    attachmentsStore: AttachmentsModule = AttachmentsStore;

    hiddenId = 'hiddenId';
    blobAttachmentUrl = '';
    window_ = window;

    isLoading = false;

    get wideItemAttachments() {
      const filtered = this.boardItem.attachments?.filter((t) => t.isWideBoardItemAttachment);

      return lodash.orderBy(filtered, 'createdDate');
    }

    async openAttachment(attachment) {
      this.isLoading = true;

      const fileData = await this.attachmentsStore.downloadAttachment(attachment.id);

      const file = new Blob([fileData.data], { type: 'image/jpg;base64' });
      const url = URL.createObjectURL(file);
      this.blobAttachmentUrl = url;

      this.isLoading = false;

      document.getElementById(this.hiddenId).click();
    }

    async moveAttachmentToList(attachment: AttachmentDto) {
      const dto = {
        id: attachment.id,
        boardItemId: this.boardItem.id,
        isWideBoardItemAttachment: false,
      } as UpdateAttachmentDto;

      await this.attachmentsStore.updateAttachment(dto);
    }

    confirmDelete(attachment: AttachmentDto) {
      this.$bvModal
        .msgBoxConfirm('Do you really want to delete the attachment ' + attachment.fileName, {
          title: 'Confirm deletion',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Delete attachment',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then((value) => {
          if (value) {
            const params = {
              ownerId: this.boardItem.id,
              attachmentDestination: AttachmentDestination.WideBoardItem,
            } as DeleteAttachmentDto;

            this.isLoading = true;

            this.attachmentsStore
              .deleteAttachment({ id: attachment.id, body: params, destination: AttachmentDestination.WideBoardItem })
              .then(() => {
                const index = this.boardItem.attachments.findIndex((t) => t.id === attachment.id);

                if (index >= 0) {
                  this.boardItem.attachments.splice(index, 1);
                }
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
