
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import { SettingsStore, SettingsModule } from '@/store/settings';
  import BoardSettings from '@/components/Board/BoardSettings.vue';

  @Component({
    components: {
      PageHeader,
      BoardSettings,
    },
  })
  export default class BoardSettingsComponent extends Vue {
    private settings: SettingsModule = SettingsStore;

    get boardTitle() {
      return this.settings.initialBoardSettings.title;
    }

    async mounted() {
      await this.settings.loadBoardInitialSettings(this.$route.params.id);
    }
  }
