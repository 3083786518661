import { DateTime } from 'luxon';

export default class DateRangeDto {
  start: string;
  end: string;

  constructor(start: string = null, end: string = null) {
    this.start = start || DateTime.utc().plus({ days: -2 }).toString();
    this.end = end || new Date().toISOString();
  }
}
