import { DirectiveOptions } from 'vue';

const disableComponent: DirectiveOptions = {
  //TODO: remove!
  componentUpdated(el, binding) {
    const tags = ['input', 'button', 'textarea', 'select', 'option', 'select'];

    tags.forEach((tagName) => {
      const nodes: any = el.getElementsByTagName(tagName);
      for (let i = 0; i < nodes.length; i++) {
        if (binding.value) {
          nodes[i].disabled = true;
          nodes[i].tabIndex = -1;
        } else {
          nodes[i].disabled = false;
        }
      }
    });
  },
};

export default disableComponent;
