import Vue from 'vue';

export class GlobalHelper {
  static NotifySuccess(message: any = '', duration = 2000) {
    if (!message) message = 'Success.';

    (Vue as any).notify({
      group: 'foo',
      text: message,
      type: 'success',
      position: 'bottom right',
      duration: duration,
    });
  }

  static NotifyError(message: string,  title = 'Error', duration = -1,) {
    (Vue as any).notify({
      group: 'foo',
      title: title,
      text: message,
      type: 'error',
      duration,
    });
  }
}
