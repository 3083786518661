var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container-fluid p-3"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"settingsmenu bg-white rounded-left shadow-sm mt-2 mb-2"},[_c('router-link',{staticClass:"menuitem",class:{ active: _vm.$route.name === _vm.routes.settings },attrs:{"tag":"div","to":{
          name: _vm.routes.settings,
          params: { id: _vm.$route.params.id },
        }}},[_c('i',{staticClass:"far fa-cog"}),_c('div',{staticClass:"nav-item-title"},[_vm._v("Basic Settings")])]),_c('router-link',{staticClass:"menuitem",class:{ active: _vm.$route.name === _vm.routes.shiftValidationSettings },attrs:{"tag":"div","to":{
          name: _vm.routes.shiftValidationSettings,
          params: { id: _vm.$route.params.id },
        }}},[_c('i',{staticClass:"far fa-check-square"}),_c('div',{staticClass:"nav-item-title"},[_vm._v("Categories/ Types")])]),_c('router-link',{staticClass:"menuitem",class:{ active: _vm.$route.name === _vm.routes.labels.boardLabels.name },attrs:{"tag":"div","to":{
          name: _vm.routes.labels.boardLabels.name,
          params: { id: _vm.$route.params.id },
        }}},[_c('i',{staticClass:"far fa-tags"}),_c('div',{staticClass:"nav-item-title"},[_vm._v("Board Labels")])]),_c('router-link',{staticClass:"menuitem",class:{ active: _vm.$route.name === _vm.routes.biTemplates },attrs:{"tag":"div","to":{
          name: _vm.routes.biTemplates,
          params: { id: _vm.$route.params.id },
        }}},[_c('i',{staticClass:"far fa-file-invoice"}),_c('div',{staticClass:"nav-item-title"},[_vm._v("Templates")])]),_c('router-link',{staticClass:"menuitem",class:{ active: _vm.$route.name === _vm.routes.emailToBoard },attrs:{"tag":"div","to":{
          name: _vm.routes.emailToBoard,
          params: { id: _vm.$route.params.id },
        }}},[_c('i',{staticClass:"far fa-mail-bulk"}),_c('div',{staticClass:"nav-item-title"},[_vm._v("E-Mail2Board")])]),_c('router-link',{staticClass:"menuitem",class:{ active: _vm.$route.name === _vm.routes.sendAsEmailSettings },attrs:{"tag":"div","to":{
          name: _vm.routes.sendAsEmailSettings,
          params: { id: _vm.$route.params.id },
        }}},[_c('i',{staticClass:"far fa-mail-bulk"}),_c('div',{staticClass:"nav-item-title"},[_vm._v("Send as Email")])])],1),_c('div',{staticClass:"bg-lightgray rounded-sm flex-grow-1 p-4 pl-5 pt-4 shadow-sm settingscontainer shadow-sm"},[_c('div',{staticStyle:{"min-width":"800px","max-width":"1550px"}},[_c('router-view')],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }