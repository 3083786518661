
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop, Watch } from 'vue-property-decorator';
  import { IBoardItemDto, BoardItemStatusDto } from '@/apiclient/apiclient_generated';
  import DueBadge from '@/components/DueBadge.vue';
  import IBoardItemDates from '@/types/boardItemDates';

  @Component({
    components: { DueBadge },
  })
  export default class BoardItemDate extends Vue {
    @Prop()
    boardItem: IBoardItemDto;
    @Prop()
    displayPlannedDate: boolean;

    @Prop()
    disabled: boolean;

    statusType = BoardItemStatusDto;

    private selectedDate = '';

    private dueDate = null;
    private plannedDate = null;
    private fromDate = null;
    private toDate = null;

    private enableWatch = false;

    get dueSelected() {
      return this.selectedDate === 'due';
    }

    @Watch('dueDate')
    changeDueDate() {
      this.pushUpdates();
    }

    @Watch('plannedDate')
    changePlannedDate() {
      this.pushUpdates();
    }

    @Watch('fromDate')
    changeFromDate() {
      this.pushUpdates();
    }

    @Watch('toDate')
    changeToDate() {
      this.pushUpdates();
    }

    get plannedSelected() {
      return this.selectedDate === 'planned' && this.displayPlannedDate;
    }

    async selectDate(selection) {
      if (selection === null) {
        await this.pushUpdates();
      }
    }

    async mounted() {
      this.fromDate = this.boardItem.validFromDate;
      this.toDate = this.boardItem.validToDate;
      this.dueDate = this.boardItem.dueDate;
      this.plannedDate = this.boardItem.plannedDate;
      this.selectedDate = 'due';

      await this.$nextTick();
      this.enableWatch = true;
    }

    private async pushUpdates() {
      if (this.enableWatch) {
        await this.$emit('boardItemDateUpdated', this.getBoardItemDates());
      }
    }

    private getBoardItemDates(): IBoardItemDates {
      return {
        validFromDate: this.fromDate,
        validToDate: this.toDate,
        dueDate: this.dueDate,
        plannedDate: this.plannedDate,
      } as IBoardItemDates;
    }
  }
