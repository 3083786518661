
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { BoardModule, BoardStore } from '@/store/board';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { DateTime } from 'luxon';
  import _, { sortBy } from 'lodash';
  import {
    AvailabilityEntityType,
    BoardItemCategoryDto,
    BoardItemPriorityDto,
    LabelDto,
    ShiftDto,
    ShiftHandoverDto,
    ShiftTypeDto,
  } from '@/apiclient/apiclient_generated';
  import { GlobalHelper } from '@/helpers/global-helper';
  import { UserModule, UserStore } from '@/store/user';
  import BoardItemStatus from '@/components/BoardItem/BoardItemStatus.vue';
  import BoardItemLabels from '@/components/Labels/BoardItemLabels.vue';
  import { SettingsModule, SettingsStore } from '@/store/settings';

  @Component({
    components: { BoardItemLabels, BoardItemStatus, ModalWindow },
  })
  export default class ShiftHandover extends Vue {
    private settingsStore: SettingsModule = SettingsStore;
    private boardStore: BoardModule = BoardStore;
    private userStore: UserModule = UserStore;

    private shiftStart = '';
    private shiftEnd = '';
    private nextShiftStart = '';
    private nextShiftEnd = '';
    private shiftTypeId = '';
    private supervisorId = '';
    private displayBaseData = true;
    private comment = '';

    private shiftHandover: ShiftHandoverDto = new ShiftHandoverDto();
    private relevantItemIds: string[] = [];

    private grouping = this.boardStore.board.shiftHandoverGrouping ? this.boardStore.board.shiftHandoverGrouping : 'category';

    async mounted() {
      const userParams = {
        entityType: AvailabilityEntityType.Board,
        entityId: this.boardStore.board.id,
      };

      await this.userStore.getAvailableUsers(userParams);
      await this.settingsStore.loadShiftValidationSettings(this.boardStore.currentBoardId);

      //leave the following line intact => used for testing
      //Settings.now = () => new Date(2020, 8, 4, 6, 30).valueOf();

      this.shiftStart = DateTime.fromISO(
        this.boardStore.board.currentShift ? this.boardStore.board.currentShift.dateFrom : '2020-01-01 00:00'
      ).toFormat('yyyy-MM-dd HH:mm');

      this.shiftEnd = DateTime.utc().toLocal().toFormat('yyyy-MM-dd HH:mm');
      this.nextShiftStart = DateTime.utc().toLocal().plus({ minutes: 1 }).toFormat('yyyy-MM-dd HH:mm');

      //find entry for next ShiftType
      const baseDate = DateTime.utc(); // Date to compare to
      const shiftTypesWithDistance = [];

      //calculate the distance from NOW() to ShiftType Start for each shift type and choose the shift type with
      //the smallest distance with an overlap for one hour
      //e.g. Shift starts at 06:00, now it's 06:30 => choose the shift which starts at 06:00, because it's in the 1hr window
      this.boardStore.board.shiftTypes.forEach((type) => {
        let dateShiftType = baseDate.set({
          hour: parseInt(type.defaultTimeFrom.substr(0, 2)),
          minute: parseInt(type.defaultTimeFrom.substr(3, 2)),
        });

        if (DateTime.local().isInDST) {
          dateShiftType = baseDate.set({
            hour: parseInt(type.timeFromDst.substr(0, 2)),
            minute: parseInt(type.timeFromDst.substr(3, 2)),
          });
        }

        if (dateShiftType < baseDate && dateShiftType.diff(baseDate, 'seconds').seconds < -3600) {
          dateShiftType = dateShiftType.plus({ days: 1 });
        }

        const distance = dateShiftType.diff(baseDate, 'seconds').seconds;

        shiftTypesWithDistance.push({ distance: distance, timeTo: dateShiftType, shiftType: type });
      });

      //set the shiftType to the shiftType with the smallest distance and the
      //shiftEnd to the end of this shiftType
      const chosenShiftType = sortBy(shiftTypesWithDistance, 'distance')[0];

      this.shiftTypeId = chosenShiftType.shiftType.id;
      this.changeNextShiftType(this.shiftTypeId);

      const relevantItems = [];

      const relevantForShiftReportTypeIds = this.settingsStore.shiftValidationSettings.types
        .filter((_) => _.isRelevantForShiftReport)
        .map((_) => _.typeId);

      const reportItems = [...this.boardStore.boardItems, ...this.boardStore.delegatedBoardItems, ...this.boardStore.sharedBoardItems];

      reportItems.forEach((_) => {
        if (
          relevantForShiftReportTypeIds.indexOf(_.boardItemTypeId) !== -1 ||
          _.priority == BoardItemPriorityDto.High ||
          _.isRelevantForReport
        ) {
          relevantItems.push(_.id);
        }
      });

      this.relevantItemIds = relevantItems;
    }

    changeNextShiftType(id: string) {
      const shiftType = this.boardStore.board.shiftTypes.find((_) => _.id == id);
      const baseDate = DateTime.utc(); // Date to compare to

      let dateShiftType = baseDate.set({
        hour: parseInt(shiftType.defaultTimeTo.substr(0, 2)),
        minute: parseInt(shiftType.defaultTimeTo.substr(3, 2)),
      });

      if (DateTime.local().isInDST) {
        dateShiftType = baseDate.set({
          hour: parseInt(shiftType.timeToDst.substr(0, 2)),
          minute: parseInt(shiftType.timeToDst.substr(3, 2)),
        });
      }

      if (dateShiftType < baseDate) {
        dateShiftType = dateShiftType.plus({ days: 1 });
      }

      this.nextShiftEnd = dateShiftType.toLocal().toFormat('yyyy-MM-dd HH:mm');
      this.shiftTypeId = id;
    }

    confirmHandover() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to handover to the next shift?', {
          title: 'Confirm shift handover',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Yes, handover shift',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.doHandover();
          }
        })
        .catch(() => {
          // An error occurred
        });
    }

    async doHandover() {
      const dates = {
        shiftStart: DateTime.fromFormat(this.shiftStart, 'yyyy-MM-dd HH:mm').toUTC().toISO(),
        shiftEnd: DateTime.fromFormat(this.shiftEnd, 'yyyy-MM-dd HH:mm').toUTC().toISO(),
        nextStart: DateTime.fromFormat(this.nextShiftStart, 'yyyy-MM-dd HH:mm').toUTC().toISO(),
        nextEnd: DateTime.fromFormat(this.nextShiftEnd, 'yyyy-MM-dd HH:mm').toUTC().toISO(),
      };

      this.shiftHandover.currentShiftTo = dates.shiftEnd;
      this.shiftHandover.nextShiftFrom = dates.nextStart;
      this.shiftHandover.nextShiftTo = dates.nextEnd;
      this.shiftHandover.comment = 'Auto';
      this.shiftHandover.boardGuid = this.boardStore.board.id;
      this.shiftHandover.relevantItems = this.relevantItemIds;
      this.shiftHandover.shiftTypeId = this.shiftTypeId;
      this.shiftHandover.comment = this.comment;
      this.shiftHandover.supervisorId = this.supervisorId;

      await this.boardStore.handoverShift(this.shiftHandover);
      this.$bvModal.hide('shiftHandover');

      GlobalHelper.NotifySuccess('Shift was handed over...');
    }

    get availableSupervisors() {
      const users = this.userStore.availableUsers?.filter((_) => _.isShiftSupervisor);
      return this.lodash.orderBy(users, 'displayName');
    }

    get shiftTypes(): ShiftTypeDto[] {
      return this.lodash.sortBy(this.boardStore.board.shiftTypes, 'sortOrder');
    }

    get currentShift(): ShiftDto {
      return this.boardStore.board.currentShift;
    }

    get groupedByLabel() {
      const allItems = [...this.boardStore.boardItems, ...this.boardStore.delegatedItems, ...this.boardStore.sharedBoardItems];

      let distinctLabels = _.uniqBy(_.flatten(allItems.map((_) => _.labels)), 'id');

      const emptyLabel = Object.assign(new LabelDto(), { id: '', title: '~ No Label ~', color: '357ebd', icon: null });
      distinctLabels.push(emptyLabel);
      distinctLabels = _.orderBy(distinctLabels, [(label) => label.title.toLowerCase()]);

      const groups = distinctLabels.map((_) => {
        const group: any = {};
        group.icon = _.icon;
        group.title = _.title;
        group.id = _.id;
        group.items = allItems.filter((item) => item.labels.map((label) => label.id).indexOf(_.id) !== -1);

        if (_.id === '') {
          group.items = allItems.filter((item) => item.labels.length == 0);
        }

        return group;
      });

      return groups;
    }

    get groupedByCategory(): any[] {
      const allItems = [...this.boardStore.boardItems, ...this.boardStore.delegatedItems, ...this.boardStore.sharedBoardItems];

      const categories = this.boardStore.boardItemCategories as BoardItemCategoryDto[];
      const groups = categories.map((_) => {
        const group: any = {};
        group.icon = _.icon;
        group.title = _.name;
        group.id = _.id;
        group.items = allItems.filter((item) => item.boardItemCategoryId == _.id);
        return group;
      });

      return groups.filter((_) => _.items.length);
    }

    get groupedItems(): any[] {
      if (this.grouping == 'category') return this.groupedByCategory;
      else return this.groupedByLabel;
    }

    changeItem(id: string) {
      if (this.relevantItemIds.indexOf(id) !== -1) {
        this.relevantItemIds = this.relevantItemIds.filter((_) => _ !== id);
      } else {
        this.relevantItemIds.push(id);
      }
    }

    private lastComment(item) {
      if (item.comments?.length) {
        const userComments = item.comments.filter((_) => !_.isSystemComment);

        if (userComments?.length) {
          return this.lodash.orderBy(userComments, 'createdDate', 'desc')[0].text;
        }
      }

      return '';
    }
  }
