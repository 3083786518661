
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component({})
  export default class ModalWindow extends Vue {
    @Prop() mainWidth: number;
    @Prop() paddings: string;

    dragEnter(e) {
      e.preventDefault();
      this.$emit('dragenter', e);
    }

    dragLeave(e) {
      e.preventDefault();
      this.$emit('dragleave', e);
    }

    dragEnd(e) {
      e.preventDefault();
      this.$emit('dragend', e);
    }
    
    get mainClass(): string {
      if (this.mainWidth) {
        return 'col-' + this.mainWidth.toString();
      }

      return 'col-9';
    }

    get sideBarClass(): string {
      if (this.mainWidth) {
        return 'col-' + (12 - this.mainWidth).toString();
      }

      return 'col-3';
    }

    get modalPaddings() {
      return this.paddings || 'p-4 pl-5';
    }
  }
