
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { LabelModule, LabelStore } from '@/store/label';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import { IServiceResultOfSystemSettingsDto, LabelDto } from '@/apiclient/apiclient_generated';
  import { GlobalHelper } from '@/helpers/global-helper';
  import PublicConstants from '@/types/publicConstants';
  import VSwatches from 'vue-swatches';
  import OpsBoardIcons from '@/store/labelIcons';
  import RouteNames from '@/types/routeNames';
  import LabelsTable from './LabelsTable.vue';
  import { materialRequestClient } from '@/apiclient/opsboardapi';
  import MaterialRequestService from '@/services/materialRequest.service';
  import { UserModule, UserStore } from '@/store/user';

  @Component({ components: { VSwatches, LabelsTable } })
  export default class MaterialRequestEmails extends Vue {
    private userStore: UserModule = UserStore;
    private labelStore: LabelModule = LabelStore;
    private manuallyAddedLabels: LabelDto[] = [];
    isLoading = false;
    isDirty = false;
    swatches = PublicConstants.labelColors;

    mrEmailsDto = {
      entity: {
        materialRequestCc: '',
        materialRequestBcc: '',
        materialRequestManagementReportTo: '',
        materialRequestManagementReportCc: '',
        materialRequestLogisticsEmailCc: '',
        materialRequestReplyTo: '',
        materialRequestAogDeskNotificationEmailTo: '',
        materialRequestAogDeskNotificationEmailCc: '',
        autoManagementReportIntervalMinutes: 0
      },
    } as IServiceResultOfSystemSettingsDto;

    async mounted() {
      if (!MaterialRequestService.isMaterialRequestEmailsAvailable(this.userStore.user)) {
        return await this.$router.push({ name: RouteNames.boards });
      }

      this.mrEmailsDto = await materialRequestClient.getMaterialRequestSettings();
    }

    destroyed() {
      this.mrEmailsDto = { entity: {} } as IServiceResultOfSystemSettingsDto;
    }

    get entity() {
      return this.mrEmailsDto?.entity;
    }

    async save() {
      this.isLoading = true;
      const result = await materialRequestClient.updateMaterialRequestSettings(this.entity);
      this.isLoading = false;

      if (!result.isSuccess) {
        result.errors.forEach((error) => {
          GlobalHelper.NotifyError(error);
        });
      } else {
        GlobalHelper.NotifySuccess('Material Request Emails were successfully updated');
        this.mrEmailsDto = result;
      }
    }
  }
