import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import {
  BoardItemStatusDto,
  OverviewClient,
  ShiftHandoverBoardItemDto,
  ShiftHandoverOverviewDto,
  SpecialOccurrenceOverviewDto,
} from '@/apiclient/apiclient_generated';
import RequestParams from '@/types/requestParams/requestParams';
import ShiftOverviewRequestParams from '@/types/requestParams/shiftOverviewRequestParams';
import Vue from 'vue';
import SpecialOccurrencesRequestParams from '@/types/requestParams/soRequestParams';

@Module({ dynamic: true, namespaced: true, store, name: 'overview' })
export class OverviewModule extends VuexModule {
  private overviewClient = new OverviewClient(ApiUrl, axios);
  shiftsOverview: ShiftHandoverOverviewDto[] = [];
  soOverview: SpecialOccurrenceOverviewDto[] = [];

  soFilters: any = {};

  private shiftOverviewItemsSortOrder = [
    BoardItemStatusDto.Done,
    BoardItemStatusDto.InProgress,
    BoardItemStatusDto.Planned,
    BoardItemStatusDto.Information,
  ];

  @Action
  async loadSpecialOccurrencesOverview(params: SpecialOccurrencesRequestParams) {
    const { dateRangeFrom, dateRangeTo, orderByDesc, searchString } = params;

    const overview = await this.overviewClient.getSpecialOccurrencesOverview(dateRangeFrom, dateRangeTo, orderByDesc, searchString);

    this.setSpecialOccurrencesOverview(overview);
  }

  @Action
  async loadShiftOverview(params: ShiftOverviewRequestParams) {
    const { entityId, searchString } = params;

    const response = await this.overviewClient.getShiftOverview(entityId, searchString);

    this.setShiftOverview({ items: response, entityId });
  }

  @Action
  async loadShiftsHandoverOverview(params: RequestParams) {
    const { entityId, orderByDesc, dateRangeFrom, dateRangeTo, searchString } = params;

    const overview = await this.overviewClient.getShiftsHandoverOverview(entityId, dateRangeFrom, dateRangeTo, orderByDesc, searchString);

    this.setShiftsHandoverOverview(overview);
  }

  @Mutation
  setShiftOverview(params) {
    const { items, entityId }: { items: ShiftHandoverBoardItemDto[]; entityId: string } = params;

    const currentShiftHandover = this.shiftsOverview.find((_) => _.id == entityId);
    if (currentShiftHandover) {
      items;

      const sortedItems = items.sort(
        (a, b) => this.shiftOverviewItemsSortOrder.indexOf(a.status) - this.shiftOverviewItemsSortOrder.indexOf(b.status)
      );

      sortedItems.forEach((_) => {
        Vue.set(_.boardItem, 'handoverStatus', _.status);
      });

      currentShiftHandover.boardItems = sortedItems;
    }
  }

  @Mutation
  setSOFilters(filters) {
    this.soFilters = filters;
  }

  @Mutation
  setSpecialOccurrencesOverview(items: SpecialOccurrenceOverviewDto[]) {
    this.soOverview = items;
  }

  @Mutation
  setShiftsHandoverOverview(shiftsOverview: ShiftHandoverOverviewDto[]) {
    this.shiftsOverview = shiftsOverview;
  }
}

export const OverviewStore = getModule(OverviewModule);
