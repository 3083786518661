import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import { IServiceResultOfManagementReportInfoDto, MaterialRequestClient, MaterialRequestDto } from '@/apiclient/apiclient_generated';

@Module({ dynamic: true, namespaced: true, store, name: 'material-request' })
export class MaterialRequestModule extends VuexModule {
  private client = new MaterialRequestClient(ApiUrl, axios);

  materialRequest: MaterialRequestDto = MaterialRequestDto.fromJS({});

  @Action
  async getMaterialRequest(id) {
    const request = await this.client.getById(id);

    this.setMaterialRequest(request);
  }

  @Mutation
  setMaterialRequest(request: MaterialRequestDto) {
    this.materialRequest = request;
  }

  @Mutation
  processMaterialRequestChanges(updates: IServiceResultOfManagementReportInfoDto) {
    if (this.materialRequest && updates.isSuccess && updates.entity.materialRequestId === this.materialRequest.id) {
      this.materialRequest.managementReportLastSent = updates.entity.lastSentDate;
      this.materialRequest.managementReportNextSend = updates.entity.nextSendDate;
    }
  }
}

export const MaterialRequestStore = getModule(MaterialRequestModule);
