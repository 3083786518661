
  import Component from 'vue-class-component';
  import Vue from 'vue';
  import { UserStore } from '@/store/user';
  import { BoardStore } from '@/store/board';
  import { LookupStore } from '@/store/lookup';
  import RouteNames from '@/types/routeNames';
  import { UserDto } from '@/apiclient/apiclient_generated';

  @Component({})
  export default class App extends Vue {
    async mounted() {
      await UserStore.loadData();

      // Check if User has access to the system
      const currentUser: UserDto = UserStore.user;
      if (currentUser.isMasterUser) {
        await this.$router.push({ name: RouteNames.userselect });
      }

      if (currentUser && !currentUser.userHasAccess) {
        await this.$router.push({ name: RouteNames.unauthorized });
      }

      await BoardStore.loadBoardItemTypes();
      await LookupStore.loadLookupData();
    }
  }
