import { render, staticRenderFns } from "./AddLabels.vue?vue&type=template&id=9a24868c&scoped=true&"
import script from "./AddLabels.vue?vue&type=script&lang=ts&"
export * from "./AddLabels.vue?vue&type=script&lang=ts&"
import style0 from "./AddLabels.vue?vue&type=style&index=0&id=9a24868c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a24868c",
  null
  
)

export default component.exports