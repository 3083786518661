
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop, Emit } from 'vue-property-decorator';
  import ToggleSwitchColors from '@/types/toggleSwitchColors';

  @Component
  export default class ToggleSwitch extends Vue {
    @Prop() value!: boolean;
    @Prop() label!: string;
    @Prop() color!: ToggleSwitchColors;

    toggleSwitchColors = ToggleSwitchColors;

    @Emit('update')
    toggle() {
      return !this.value;
    }
  }
