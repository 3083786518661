
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import TaskDetail from './TaskDetail/TaskDetail.vue';
  import BoardItemAcceptReject from '@/components/BoardItem/BoardItemAcceptReject.vue';
  import BoardItemListType from '@/types/boardItemListType';

  import {
    IBoardItemDto,
    ListGroupTypeDto,
    BoardItemPriorityDto,
    BoardItemStatusDto,
    IBoardItemListDto,
    TechnicalOccurrenceViewDto,
    SharedBoardDto,
  } from '@/apiclient/apiclient_generated';
  import DueBadge from '@/components/DueBadge.vue';
  import BoardItemPlannedDate from '@/components/BoardItem/BoardItemPlannedDate.vue';
  import { BoardStore, BoardModule } from '@/store/board';
  import { NotificationStore, NotificationModule } from '@/store/notification';
  import AcceptRejectView from '@/types/acceptRejectView';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import BoardItemLabels from '@/components/Labels/BoardItemLabels.vue';
  import RouteNames from '@/types/routeNames';
  import formatDateTime from '@/filters/formatDateTime';

  @Component({
    components: {
      TaskDetail,
      DueBadge,
      BoardItemAcceptReject,
      BoardItemLabels,
      BoardItemPlannedDate,
    },
  })
  export default class TaskCard extends Vue {
    @Prop()
    public item: IBoardItemDto;
    @Prop()
    public isSO: boolean;
    @Prop()
    public list: IBoardItemListDto;
    @Prop({ default: false }) minimal: boolean;
    private notificationStore: NotificationModule = NotificationStore;
    private boardStore: BoardModule = BoardStore;
    private occurrenceStore: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private boardItemListType = BoardItemListType;
    private boardItemStatus = BoardItemStatusDto;
    private listGroupType = ListGroupTypeDto;
    private boardItemPriority = BoardItemPriorityDto;
    private acceptRejectView = AcceptRejectView;

    mounted() {
      // recalculate times etc.
      setInterval(() => this.$forceUpdate(), 30000);
    }

    get sharedBoards(): SharedBoardDto[] {
      return this.item.sharedBoards.filter((_) => _.boardId != this.boardStore.currentBoardId);
    }

    getSharedBadgeColor(board: SharedBoardDto) {
      return this.item.boardItemViews.some((t) => t.boardId === board.boardId) ? 'badge-success' : 'badge-secondary';
    }

    getSharedBadgeTooltip(board: SharedBoardDto) {
      const boardItemViews = this.item.boardItemViews.filter((t) => t.boardId === board.boardId && t.boardItemId == this.item.id);

      if (!boardItemViews.length) {
        return 'Not yet opened';
      }

      const latestView = this.lodash.orderBy(boardItemViews, (t) => t.lastViewDate, 'desc')[0];

      return `Last accessed: ${formatDateTime(latestView.lastViewDate)}`;
    }

    navigate(item: IBoardItemDto) {
      let routeName = '';

      if (this.isSO) {
        routeName = RouteNames.specialOccurrence.task;
      } else {
        routeName = 'BoardItemDetail';
      }

      if (this.$route.name == RouteNames.biTemplates) {
        routeName = 'TemplateDetail';
      }

      if (this.$route.name == RouteNames.specialOccurrence.attachments) {
        routeName = RouteNames.specialOccurrence.attachmentitem;
      }

      this.$router.push({ name: routeName, params: { itemid: item.id } });
    }

    getOccurrenceClass(boardItem: IBoardItemDto): string {
      const occurrences = this.occurrenceStore.activeAndArchivedOccurrences;

      const occ =
        occurrences.activeSpecialOccurrences?.find((_) => _.id == boardItem.specialOccurrenceId) ||
        occurrences.archivedSpecialOccurrences?.find((_) => _.id == boardItem.specialOccurrenceId);

      if (!occ) {
        return 'badge-so';
      }

      if (occ.isCompleted) {
        return 'badge-so-completed';
      }

      if (occ.type === 'TechnicalOccurrence' && (occ as TechnicalOccurrenceViewDto).technicalOccurrenceTypeId) {
        const typeInfo = this.occurrenceStore.technicalOccurrenceTypes.find(
          (_) => _.id === (occ as TechnicalOccurrenceViewDto).technicalOccurrenceTypeId
        );

        return typeInfo?.cssClass ?? 'badge-so';
      }

      return 'badge-so';
    }

    get displayChecklistProgress() {
      return this.item.checklists.length && this.item.checklists.some((_) => _.items.length);
    }

    get closedChecklistsCount() {
      const tasks = this.item.checklists.map((_) => _.items.length).reduce((a, b) => a + b, 0);

      const completedTasks = this.item.checklists.map((_) => _.items.filter((_) => _.isCompleted).length).reduce((a, b) => a + b);

      return `${completedTasks} of ${tasks}`;
    }

    get displayStatus() {
      return (
        this.item.isDelegated &&
        (this.item.ownerBoardId === this.boardStore.currentBoardId || this.item.boardItemStatus === BoardItemStatusDto.UnderReview)
      );
    }

    get initialBoardName() {
      return this.boardStore.allBoards.find((_) => _.id === this.item.initialOwnerBoardId).title;
    }

    get boardItemStatusDisplayName() {
      switch (this.item.boardItemStatus) {
        case BoardItemStatusDto.InProgress:
          return 'In Progress';
        case BoardItemStatusDto.UnderReview:
          return 'Under Review';
        default:
          return this.item.boardItemStatus;
      }
    }

    itemHasError(item: IBoardItemDto) {
      const hasError = item?.actions?.some((_) => _.error);
      return hasError;
    }

    private showNotification(item: IBoardItemDto) {
      return this.notificationStore.notifications.includes(item.id) && this.list.displayNotifications;
    }

    private selectUser() {
      this.$root.$emit('boardItem::userSelected', this.item.assignedToUser);
    }
  }
