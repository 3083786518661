
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SettingsStore, SettingsModule } from '@/store/settings';
  import CheckboxWithLabel from '../Misc/CheckboxWithLabel.vue';

  @Component({
    components: {
      'checkbox-with-label': CheckboxWithLabel,
    },
  })
  export default class BoardInitialSettings extends Vue {
    private isDirty = false;
    private settingsStore: SettingsModule = SettingsStore;

    get settings() {
      return this.settingsStore.initialBoardSettings;
    }

    async save() {
      await this.settingsStore.updateBoardInitialSettings();
      this.isDirty = false;
    }
  }
