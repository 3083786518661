
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from "vue-property-decorator";
    import { DateTime, Interval } from "luxon";
    import { IBoardItemDto } from "@/apiclient/apiclient_generated";

    @Component({})
    export default class DueBadge extends Vue {
      @Prop() item: IBoardItemDto;
      @Prop() onlyOverdue: boolean;
      private currentTime: DateTime = DateTime.utc();

      mounted() {
        setInterval(() => {
          //set reactive property to trigger recalulation of getters
          this.currentTime = DateTime.utc();
        }, 60000)
      }

      get isVisible(): boolean {
        const statusVisible = ['Planned', 'InProgress'];
        return this.item.dueDate != null && statusVisible.indexOf(this.item.boardItemStatus) !== -1 && (this.onlyOverdue && this.formattedDue == 'Overdue' || !this.onlyOverdue);
      }

      get formattedDue() {
        const now = this.currentTime;
        const due = DateTime.fromISO(this.item.dueDate);
        const duration = due.diff(now, 'minutes').toObject().minutes;

        if (duration > 120) return 'Due at: ' + due.toFormat('HH:mm') + ' h';

        if (duration > 0) {
          return (
            'Due in ' +
            Math.floor(Interval.fromDateTimes(now, due).length('minute')) +
            ' minutes'
          );
        } else {
          return 'Overdue';
        }
      }

      get dueMinutes() {
        if (this.item.dueDate === null) return null;

        const now = this.currentTime;
        const due = DateTime.fromISO(this.item.dueDate);

        return due.diff(now, 'minutes').toObject().minutes;
      }

      get durationClass() {
        if (this.dueMinutes == null) return '';

        if (this.dueMinutes <= 60) return 'badge-danger';

        if (this.dueMinutes > 60 && this.dueMinutes <= 120) return 'badge-warning';

        return '';
      }
    }
