
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import TaskCard from '@/components/TaskCard.vue';
  import { MaterialRequestDto, SpecialOccurrenceAccessLevel, TechnicalOccurrenceDto } from '@/apiclient/apiclient_generated';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import { materialRequestClient } from '@/apiclient/opsboardapi';

  @Component({
    components: {
      TaskCard,
      LoadingSpinner,
    },
  })
  export default class MaterialRequestLink extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    materialRequest: MaterialRequestDto = MaterialRequestDto.fromJS({ name: 'unknown' });

    async mounted() {
      if (this.occurrence.materialRequestGuid) {
        this.materialRequest = await materialRequestClient.getById(this.occurrence.materialRequestGuid);
      }
    }

    get occurrence(): TechnicalOccurrenceDto {
      return this.store.currentOccurrence;
    }

    navigateMaterialRequest() {
      this.$router.push({ name: 'MaterialRequestLinkIntermediate', params: { id: this.occurrence.materialRequestGuid } });
    }

    async createMaterialRequest() {
      this.materialRequest = await materialRequestClient.createFromTechnicalOccurrence(this.occurrence.id);
      this.$router.push({ name: 'MaterialRequestLinkIntermediate', params: { id: this.materialRequest.id } });
    }

    get userCanEdit() {
      return (
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin ||
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor
      );
    }
  }
