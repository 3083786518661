
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { OverviewModule, OverviewStore } from '@/store/overview';
  import DateRangeDto from '@/types/dateRangeDto';
  import BoardItemDatePicker from '@/components/BoardItem/BoardItemDatePicker.vue';
  import { Watch } from 'vue-property-decorator';
  import { BoardItemStatusDto, BoardItemsSummary, SpecialOccurrenceOverviewDto } from '@/apiclient/apiclient_generated';
  import ShiftOverview from './ShiftOverview.vue';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import SpecialOccurrencesRequestParams from '@/types/requestParams/soRequestParams';
  import RouteNames from '@/types/routeNames';

  @Component({
    components: {
      BoardItemDatePicker,
      ShiftOverview,
      LoadingSpinner,
    },
  })
  export default class SpecialOccurrencesOverview extends Vue {
    private overviewStore: OverviewModule = OverviewStore;
    private loading = false;
    private itemsStatus = BoardItemStatusDto;

    private searchDateRange: DateRangeDto = new DateRangeDto();
    private search = '';

    async created() {
      const filters = this.overviewStore.soFilters;

      if (filters && (filters.searchDateRange || filters.search)) {
        this.searchDateRange = filters.searchDateRange;
        this.search = filters.search;
      }

      await this.submitSearch();
    }

    private get items() {
      return this.overviewStore.soOverview;
    }

    @Watch('searchDateRange')
    async changeFromDate() {
      await this.submitSearch();
    }

    async submitSearch() {
      this.loading = true;

      this.overviewStore.setSOFilters({
        searchDateRange: this.searchDateRange,
        search: this.search,
      });

      await this.overviewStore.loadSpecialOccurrencesOverview(this.requestParams);

      this.loading = false;
    }

    private getItemsCount(itemsSummary: BoardItemsSummary[], status: BoardItemStatusDto) {
      const data = itemsSummary.find((_) => _.status == status);

      return data || { count: 0, status };
    }

    navigateToOccurrence(so: SpecialOccurrenceOverviewDto) {
      this.$router.push({
        name: RouteNames.specialOccurrence.root,
        params: { occId: so.id },
      });
    }

    get requestParams(): SpecialOccurrencesRequestParams {
      return {
        orderByDesc: true,
        dateRangeFrom: this.searchDateRange.start,
        dateRangeTo: this.searchDateRange.end,
        searchString: this.search,
      } as SpecialOccurrencesRequestParams;
    }
  }
