
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import TaskCard from '@/components/TaskCard.vue';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import RouteNames from '@/types/routeNames';

  @Component({
    components: {
      TaskCard,
      LoadingSpinner
    },
  })
  export default class MaterialRequestLinkIntermediate extends Vue {
    id = '';
    async mounted() {
      this.$router.push({ name: RouteNames.specialOccurrence.root, params: { occId: this.id } });
    }

    created() {
      this.id = this.$route.params.id;
    }
  }
