
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { BoardItemStatusDto } from '@/apiclient/apiclient_generated';

  @Component({})
  export default class BoardItemStatus extends Vue {
    @Prop()
    status: string;

    @Prop()
    size: string;

    @Prop()
    styles: any;

    statusType = BoardItemStatusDto;
  }
