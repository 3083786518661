
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { boardItemTemplateScheduleClient } from '@/apiclient/opsboard-api';
  import {
    BoardItemScheduleType,
    BoardItemTemplateScheduleDto,
    BoardItemTemplateScheduleInsertDto,
    ShiftTypeDto,
  } from '@/apiclient/apiclient_generated';

  import VueCronEditorBuefy from 'vue-cron-editor-buefy';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import { Prop } from 'vue-property-decorator';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { GlobalHelper } from '@/helpers/global-helper';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import { BoardStore } from '@/store/board';
  import { orderBy } from 'lodash';

  @Component({ components: { ModalWindow, LoadingSpinner, 'vue-cron-editor': VueCronEditorBuefy } })
  export default class BoardItemScheduleEdit extends Vue {
    @Prop() currentTemplateId: string;

    private templateSchedules: BoardItemTemplateScheduleDto[] = [];
    private newSchedule: BoardItemTemplateScheduleInsertDto = BoardItemTemplateScheduleInsertDto.fromJS({
      scheduleType: BoardItemScheduleType.ShiftStart,
      cronExpression: '',
      boardItemId: '',
      plannedMinutesAfterCreation: 0,
      dueMinutesAfterCreation: 0,
    });

    cronTabs = ['daily', 'weekly', 'monthly', 'advanced'];
    monthlyStartingAt = null;
    monthlyStartingAtOptions = [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' },
    ];

    private warningMessage = '';
    boardItemScheduleType = BoardItemScheduleType;

    private loading = true;
    private boardId: string;
    isMonthlySelectedState = false;

    created() {
      this.boardId = this.$route.params.id;
    }

    get shiftTypeOptions(): ShiftTypeDto[] {
      return orderBy(BoardStore.board.shiftTypes, 'sortOrder');
    }

    async mounted() {
      this.initNewSchedule();
      await BoardStore.getBoard(this.boardId);
      await this.loadData();
    }

    async loadData() {
      this.loading = true;
      this.templateSchedules = await boardItemTemplateScheduleClient.get(this.currentTemplateId);
      this.loading = false;
    }

    initNewSchedule() {
      this.newSchedule.scheduleType = BoardItemScheduleType.Date;
      this.newSchedule.cronExpression = '0 0 0 ? * MON';
      this.newSchedule.boardItemId = this.currentTemplateId;
      this.newSchedule.cronExpressionIsLocalTime = false;
      this.newSchedule.shiftTypeId = null;
      this.newSchedule.plannedMinutesAfterCreation = 0;
      this.newSchedule.dueMinutesAfterCreation = 0;

      this.resetMonthlyState();
    }

    get isScheduleTypeDateSelected() {
      return this.newSchedule.scheduleType == BoardItemScheduleType.Date;
    }

    get isScheduleDataValid() {
      return (
        this.newSchedule.scheduleType == BoardItemScheduleType.ShiftStart ||
        (this.newSchedule.scheduleType == BoardItemScheduleType.Date && this.newSchedule.cronExpression)
      );
    }

    confirmDeleteSchedule(id: string) {
      const success = () => {
        this.deleteSchedule(id);
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to delete this schedule?`,
        'Confirm delete',
        'Yes, delete',
        'No',
        success,
        null,
        'danger'
      );
    }

    async deleteSchedule(id: string) {
      try {
        await boardItemTemplateScheduleClient.delete(id);
        //lade schedules neu
        this.templateSchedules = await boardItemTemplateScheduleClient.get(this.currentTemplateId);
        GlobalHelper.NotifySuccess('Schedule deleted');
      } catch (error) {
        GlobalHelper.NotifyError('Schedule could not be deleted!');
      }
    }

    async addSchedule() {
      try {
        if (this.newSchedule.scheduleType == BoardItemScheduleType.ShiftStart) {
          this.newSchedule.cronExpression = '';
        }

        if (this.newSchedule.scheduleType == BoardItemScheduleType.Date) {
          this.newSchedule.shiftTypeId = null;
        }

        const existingSchedules = await boardItemTemplateScheduleClient.get(this.currentTemplateId);
        console.log(this.newSchedule);
        const sameSchedules = existingSchedules.find(
          (existing) =>
            (existing.scheduleType == BoardItemScheduleType.ShiftStart &&
              this.newSchedule.scheduleType == BoardItemScheduleType.ShiftStart &&
              existing.shiftTypeId == this.newSchedule.shiftTypeId) ||
            (existing.scheduleType == BoardItemScheduleType.Date &&
              this.newSchedule.scheduleType == BoardItemScheduleType.Date &&
              existing.cronExpression == this.newSchedule.cronExpression &&
              existing.cronExpressionIsLocalTime == this.newSchedule.cronExpressionIsLocalTime)
        );

        if (sameSchedules) {
          this.warningMessage = 'Schedule with same settings already exists!';
        } else {
          this.newSchedule.cronExpression = this.rebuildCronExpression(this.newSchedule.cronExpression);

          await boardItemTemplateScheduleClient.post(this.newSchedule);
          //lade schedules neu
          this.templateSchedules = await boardItemTemplateScheduleClient.get(this.currentTemplateId);
          this.initNewSchedule();
          GlobalHelper.NotifySuccess('Schedule successfully created!');
        }
      } catch (error) {
        GlobalHelper.NotifyError('Schedule could not be created!');
      }
    }

    private rebuildCronExpression(source) {
      const regex = /[\d|\*]{1,2}(?=\/\d)/;

      return regex.test(source) ? source.replace(regex, this.monthlyStartingAt || '*') : source;
    }

    clearWarning() {
      this.warningMessage = '';
    }

    //TODO: I know this is a code smell. Looks like vue-cron-editor doesn't have any possibility to track the selected cron tab.
    isMonthlySelected() {
      const monthlyContainer = document.getElementById('schedule-cron-editor');

      if (!monthlyContainer) {
        this.resetMonthlyState();
        return;
      }

      const lastChild = monthlyContainer.querySelector('li:nth-child(3)');

      if (!lastChild) {
        this.resetMonthlyState();
        return;
      }

      this.isMonthlySelectedState =
        lastChild.classList.contains('is-active') &&
        lastChild.textContent &&
        lastChild.textContent.trim().toLowerCase() == this.cronTabs[this.cronTabs.length - 2];

      if (!this.isMonthlySelectedState) {
        this.resetMonthlyState();
      }
    }

    private resetMonthlyState() {
      this.isMonthlySelectedState = false;
      this.monthlyStartingAt = null;
    }
  }
