
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { LabelModule, LabelStore } from '@/store/label';

  @Component
  export default class AddLabelsFilter extends Vue {
    private labelStore: LabelModule = LabelStore;

    private filter = '';

    async mounted() {
      await this.labelStore.getAllLabelsAsync(this.boardId);
    }

    get filteredLabels() {
      return this.labels.filter((item) => item.title.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0);
    }

    async addLabel(label) {
      this.$emit('labeladded', label);
      this.$root.$emit('bv::hide::popover');
    }

    get labels() {
      return this.labelStore.allActiveSelectedLabels;
    }

    get boardId() {
      return this.$route.params.id;
    }
  }
