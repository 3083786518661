import axios from 'axios';
import {
  BoardClient,
  BoardItemTemplateClient,
  BoardItemTemplateScheduleClient,
  SettingsClient
} from "@/apiclient/apiclient_generated";
import { WS_URL as ApiUrl } from "@/config";

export const boardItemTemplateClient = new BoardItemTemplateClient(ApiUrl, axios);
export const boardItemTemplateScheduleClient = new BoardItemTemplateScheduleClient(ApiUrl, axios);
export const boardClient = new BoardClient(ApiUrl, axios);
export const settingsClient = new SettingsClient(ApiUrl, axios);