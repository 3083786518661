var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.boardItemHasConnections())?_c('div',[_c('div',{staticClass:"label mt-4 mb-1"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('h5',[_c('b-badge',[_vm._v("Links")]),_c('i',{staticClass:"far fa-link ml-2"})],1)])])]),(_vm.boardItem.parentBoardItem)?_c('div',[_c('router-link',{attrs:{"to":{
        name: 'BoardItemDetail',
        params: { itemid: _vm.boardItem.parentBoardItem.id },
      }}},[_vm._v(" "+_vm._s(_vm.boardItem.parentBoardItem.title)+" ")])],1):_vm._e(),_vm._l((_vm.boardItem.linkedBoardItems),function(item){return _c('div',{key:item.id},[_c('router-link',{attrs:{"to":{
        name: 'BoardItemDetail',
        params: { itemid: item.id },
      }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }