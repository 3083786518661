
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import {
    RequestStatusEnum,
    SendLogisticsEmailDto,
    SendVendorRequestDto,
    VendorRequestDto,
    VendorRequestEditRemarksDto
  } from "@/apiclient/apiclient_generated";
  import { Emit, Prop } from 'vue-property-decorator';
  import TechnicalOccurrenceFlightsSelector from '@/components/SpecialOccurrence/Data/Flights/TechnicalOccurrenceFlightsSelector.vue';
  import { GridColumnProps } from '@progress/kendo-vue-grid';
  import AddVendor from '@/components/SpecialOccurrence/MaterialRequest/AddVendor.vue';
  import VendorRequestEmail from '@/components/SpecialOccurrence/MaterialRequest/VendorRequestEmail.vue';
  import { materialRequestClient } from '@/apiclient/opsboardapi';
  import { GlobalHelper } from '@/helpers/global-helper';
  import ModalWindow from '@/components/ModalWindow.vue';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';

  @Component({
    components: { LoadingSpinner, ModalWindow, VendorRequestEmail, AddVendor, TechnicalOccurrenceFlightsSelector },
  })
  export default class VendorRequest extends Vue {
    @Prop() vendorRequest: VendorRequestDto;

    materialRequestId: string;
    isExpanded = false;

    emailPreview = '';
    logisticsEmailLoading = false;
    currentShipperId = '';

    created() {
      this.materialRequestId = this.$router.currentRoute.params.occId;
    }

    get isExternallyOrdered(): boolean {
      return this.vendorRequest.vendor.isExternallyOrderedVendor;
    }

    get noPartsAvailable(): boolean {
      const parts = this.vendorRequest.parts;
      const notAvailable = this.vendorRequest.parts.filter((p) => p.requestStatus.requestStatusEnum == RequestStatusEnum.NotAvailable);
      return parts.length == notAvailable.length;
    }

    get vendorRequestColumns(): GridColumnProps[] {
      return [
        { field: 'pn', title: 'PN', filterable: false, width: 100 },
        { field: 'ipc', title: 'IPC', filterable: false, width: 100 },
        { field: 'quantityRequested', title: '# Req.', filterable: false, width: 60 },
        { field: 'quantityAvailable', title: '# Avail.', filterable: false, width: 60 },
        { field: 'quantityOrdered', title: '# Ordered', filterable: false, width: 75 },
        { field: 'location', title: 'Location', filterable: false, width: 130 },
        { field: 'dateAvailable', title: 'Time', filterable: false, width: 150 },
        { field: 'pricePerUnit', title: 'Price', filterable: false, width: 120 },
        { field: 'requestStatus', title: 'Request Status', filterable: false },
        { field: 'orderStatus', title: 'Order Status', filterable: false },
        { field: 'shipmentStatus', title: 'Shipment Status', filterable: false },
        { field: 'action', title: ' ', filterable: false, width: 190 },
      ];
    }

    previewEmail() {
      const modal: any = this.$refs['email'];
      modal.show();
    }

    closeEmailModal() {
      const modal: any = this.$refs['email'];
      modal.hide();
    }

    async previewLogisticsEmail(shipperId: string) {
      const modal: any = this.$refs['emailLogistics'];
      modal.show();

      this.logisticsEmailLoading = true;
      this.currentShipperId = shipperId;
      this.emailPreview = await materialRequestClient.getLogisticsEmailById(this.vendorRequest.id, shipperId);
      this.logisticsEmailLoading = false;
    }

    closeLogisticsEmailModal() {
      const modal: any = this.$refs['emailLogistics'];
      modal.hide();
    }

    @Emit('reload')
    async sendEmail() {
      try {
        const dto = new SendVendorRequestDto();
        dto.vendorRequestId = this.vendorRequest.id;
        await materialRequestClient.sendVendorRequest(dto);
        GlobalHelper.NotifySuccess('E-Mail was sent');
        this.closeEmailModal();
      } catch (e) {
        GlobalHelper.NotifyError('Error sending E-Mail');
      }
    }

    @Emit('reload')
    async sendLogisticsEmail(currentShipperId: string) {
      try {
        const dto = new SendLogisticsEmailDto();
        dto.vendorRequestId = this.vendorRequest.id;
        dto.shipperId = currentShipperId;
        await materialRequestClient.sendLogisticsEmail(dto);
        GlobalHelper.NotifySuccess('E-Mail was sent');
        this.closeLogisticsEmailModal();
        this.emailPreview = '';
        this.currentShipperId = '';
      } catch (e) {
        GlobalHelper.NotifyError('Error sending E-Mail');
      }
    }

    editVendorRequestPart(vendorRequestPartId: string) {
      this.$router.push({
        name: 'MaterialRequestVendorRequestPartEdit',
        params: { materialRequestId: this.materialRequestId, vendorRequestPartId: vendorRequestPartId },
      });
    }

    editVendorRequestPartOrder(vendorRequestPartId: string) {
      this.$router.push({
        name: 'MaterialRequestVendorRequestPartOrderEdit',
        params: { materialRequestId: this.materialRequestId, vendorRequestPartId: vendorRequestPartId },
      });
    }

    partToBeRemovedId = '';
    openRemovePartModal(id: string) {
      this.partToBeRemovedId = id;
      const modal: any = this.$refs['removePartModal'];
      modal.show();
    }

    cancelRemovePart() {
      const modal: any = this.$refs['removePartModal'];
      modal.hide();
      this.partToBeRemovedId = '';
    }

    @Emit('reload')
    async removePart() {
      if (this.partToBeRemovedId) {
        await materialRequestClient.deleteVendorRequestPart(this.partToBeRemovedId);
        this.partToBeRemovedId = '';
        const modal: any = this.$refs['removePartModal'];
        modal.hide();
      }
    }

    openSetAllPartsAsNotAvailableModal() {
      const modal: any = this.$refs['setAllPartsAsNotAvailableModal'];
      modal.show();
    }

    closeSetAllPartsAsNotAvailableModal() {
      const modal: any = this.$refs['setAllPartsAsNotAvailableModal'];
      modal.hide();
    }

    @Emit('reload')
    async setAllPartsAsNotAvailable() {
      try {
        await materialRequestClient.markAllPartsAsNotAvailable(this.vendorRequest.id);
        GlobalHelper.NotifySuccess('Parts set as not available');
        this.closeSetAllPartsAsNotAvailableModal();
      } catch (e) {
        GlobalHelper.NotifyError('Error settings parts as not available');
      }
    }

    openEditRemark() {
      const modal: any = this.$refs['editRemark'];
      modal.show();
    }

    @Emit('reload')
    closeEditRemark() {
      const modal: any = this.$refs['editRemark'];
      modal.hide();
    }

    @Emit('reload')
    async saveRemark() {
        const dto = VendorRequestEditRemarksDto.fromJS({});
        dto.id = this.vendorRequest.id;
        dto.remarks = this.vendorRequest.remarks;

        await materialRequestClient.editVendorRequestRemarks(dto);
        this.closeEditRemark();
      }
  }
