import { NotificationClient } from '@/apiclient/apiclient_generated';
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import { UserStore, UserModule } from './user';

@Module({ dynamic: true, namespaced: true, store, name: 'notification' })
export class NotificationModule extends VuexModule {
  public notificationClient = new NotificationClient(ApiUrl, axios);
  private userStore: UserModule = UserStore;

  notifications: string[] = [];

  @Action
  async loadAllNotifications(boardId) {
    this.setNotifications(await this.notificationClient.getAllBoardNotifications(boardId));
  }

  @Mutation
  setNotifications(notifications: any) {
    this.notifications = notifications;
  }

  @Mutation
  addNotification(notification: any) {
    if (!this.notifications.includes(notification.entityId) && this.userStore.user.id !== notification.authorId)
      this.notifications.push(notification.entityId);
  }

  @Mutation
  removeNotification(notification) {
    if (this.notifications.includes(notification.entityId) && this.userStore.user.id === notification.authorId)
      this.notifications = this.notifications.filter((_) => _ != notification.entityId);
  }
}

export const NotificationStore = getModule(NotificationModule);
