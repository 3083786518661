
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import BuildNumber from '@/components/AppInfo/BuildNumber.vue';
  import UserInfo from '@/components/AppInfo/UserInfo.vue';

  @Component({
    components: {
      BuildNumber,
      UserInfo,
    },
  })
  export default class AppInfo extends Vue {}
