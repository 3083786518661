
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { ShiftHandoverBoardItemDto, ShiftHandoverOverviewDto } from "@/apiclient/apiclient_generated";
  import { OverviewModule, OverviewStore } from '@/store/overview';
  import ShiftOverviewRequestParams from '@/types/requestParams/shiftOverviewRequestParams';
  import BoardItemOverview from '@/components/Overview/BoardItemOverview.vue';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import CheckboxWithLabel from '@/components/Misc/CheckboxWithLabel.vue';
  import { debounce } from 'vue-debounce';

  @Component({
    components: {
      BoardItemOverview,
      LoadingSpinner,
      'checkbox-with-label': CheckboxWithLabel,
    },
  })
  export default class ShiftOverview extends Vue {
    private overviewStore: OverviewModule = OverviewStore;
    private search = '';
    private loading = false;
    private onlyRelevantItems = false;

    @Prop()
    shiftOverview: ShiftHandoverOverviewDto;

    async created() {
      this.submitSearch(true);
    }

    private get items(): ShiftHandoverBoardItemDto[] {
      return this.shiftOverview.boardItems.filter((_) => (this.onlyRelevantItems ? _.reportRelevant : true));
    }

    get requestParams(): ShiftOverviewRequestParams {
      return {
        entityId: this.shiftOverview.id,
        searchString: this.search,
      } as ShiftOverviewRequestParams;
    }

    async submitSearch(immediate = false) {
      const wait = immediate ? '0ms' : '1000ms';
      debounce(async () => {
        this.loading = true;
        await this.overviewStore.loadShiftOverview(this.requestParams);
        this.loading = false;
      }, wait)();
    }

    navigateToBoardItem(event) {
      this.$router.push({
        name: 'shiftBoardItemOverview',
        params: { shiftBoardItemOverviewId: event },
      });
    }
  }
