import {
  ActionsClient,
  BoardClient,
  BoardDto,
  BoardInsertDto,
  BoardItemCategoryDto,
  BoardItemClickDto,
  BoardItemClient,
  BoardItemCommentDto,
  BoardItemCreateFromTemplateDto,
  BoardItemDto,
  BoardItemPriorityDto,
  BoardItemShareClient,
  BoardItemShareDto,
  BoardItemTransferClient,
  BoardItemTransferDto,
  BoardItemUnshareDto,
  CommentClient,
  IBoardDto,
  IBoardItemDto,
  IBoardItemTypeDto,
  IVarianceChangeSet,
  ListGroupTypeDto,
  ShiftHandoverDto,
  UpdateAttachmentDto,
} from '@/apiclient/apiclient_generated';
import lodash from 'lodash';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import Mapper from '@/helpers/mapper';
import { GlobalHelper } from '@/helpers/global-helper';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import BoardItemSeparators from '@/helpers/separators';
import { SelectListItemGroup } from '@/components/Misc/SelectListItemGroup';
import { DateTime } from 'luxon';
import { BoardItemNotificationHelper } from '@/helpers/borditemnotification-helper';
import { NotificationStore } from './notification';

@Module({ dynamic: true, namespaced: true, store, name: 'board' })
export class BoardModule extends VuexModule {
  private boardItemClient = new BoardItemClient(ApiUrl, axios);
  private actionsClient = new ActionsClient(ApiUrl, axios);
  private boardClient = new BoardClient(ApiUrl, axios);
  private commentClient = new CommentClient(ApiUrl, axios);
  private biTransferClient = new BoardItemTransferClient(ApiUrl, axios);
  private biShareClient = new BoardItemShareClient(ApiUrl, axios);
  private boardInfoCache = [];

  boardItemTypes?: IBoardItemTypeDto[] = [];

  allBoards?: IBoardDto[] = [];
  availableBoards?: IBoardDto[] = [];

  board?: IBoardDto = new BoardDto();
  boardItems: IBoardItemDto[] = [];
  delegatedBoardItems: IBoardItemDto[] = [];
  sharedBoardItems: IBoardItemDto[] = [];

  boardItemCategories: BoardItemCategoryDto[] = [];
  boardItemsChanges: { [id: string]: IVarianceChangeSet[] } = {};
  currentBoardItem: IBoardItemDto = new BoardItemDto();

  dragId = '';
  hubStatus = false;

  currentTime: DateTime = DateTime.utc();

  get currentBoardId() {
    return this.board?.id;
  }

  get allBoardsExceptCurrent() {
    const store = getModule(BoardModule);
    const boards = store.allBoards.slice();
    const currentBoardId = store.currentBoardId;

    const index = boards.findIndex((_) => _.id === currentBoardId);
    if (index > -1) {
      boards.splice(index, 1);
    }
    return boards;
  }

  get availableCategories() {
    if (!this.boardItems?.length && !this.delegatedBoardItems?.length) {
      return [];
    }

    return lodash.uniqBy(lodash.map([...this.boardItems, ...this.delegatedBoardItems], 'boardItemCategory'), (_) => _?.id);
  }

  get groupedSelect(): SelectListItemGroup[] {
    const result = this.boardItemCategories.map((_) => {
      return {
        id: _.id,
        text: _.name,
        icon: _.icon,
        items: _.boardItemTypes.map((type) => {
          return { id: type.id, text: type.name, icon: null };
        }),
      };
    });
    return result;
  }

  @Mutation
  tickTime() {
    this.currentTime = DateTime.utc();
  }

  get separators(): any[] {
    const boardItemLists = lodash.map(
      lodash.filter(this.board.lists, (_) => _.displayShiftTime),
      (_) => _.id
    );

    // we don't really need this variable
    // we set an interval from main.ts to call tickTime in this store which
    // updates the reactive variable this.currentTime, which then triggers an update on this
    // getter
    // this triggers an update of the items() getter => update items with separators on the board
    // eslint-disable-next-line
    const time = this.currentTime;

    if (!this.board.currentShift) {
      return null;
    }

    const from = this.board.currentShift.dateFrom;
    const to = this.board.currentShift.dateTo;
    const spsContainer = lodash.map(boardItemLists, (_) =>
      BoardItemSeparators.getSeparators(_, from, to, this.board.currentShift, this.board.shiftTypes)
    );
    const separators = [].concat(...spsContainer);

    return separators;
  }

  get groupedDelegatedItems() {
    const store = getModule(BoardModule);

    const boardsInfo = [];

    const getBoardInfo = (listId) => {
      if (store.boardInfoCache[listId]) {
        return store.boardInfoCache[listId];
      }

      const board = store.allBoards.find((b) => {
        const list = b.lists.filter((_) => _.id === listId);
        if (list?.length) {
          return list;
        }
      });

      if (board) {
        store.boardInfoCache[listId] = { id: board.id, title: board.title };
        return store.boardInfoCache[listId];
      }
    };

    this.delegatedBoardItems.forEach((_) => {
      const boardInfo = getBoardInfo(_.boardItemListId);

      if (!boardInfo) {
        return;
      }

      boardsInfo[boardInfo.id] = boardInfo;
      _['delegatedBoardId'] = boardInfo.id;
    });

    const items = lodash
      .chain(this.delegatedBoardItems)
      .groupBy('delegatedBoardId')
      .map((value, key) => ({
        key: boardsInfo[key],
        isGrouped: true,
        groupedItems: lodash.sortBy(value, ['sortOrder']),
      }))
      .value();

    const result = lodash.sortBy(items, ['key.title']);
    return result;
  }

  get delegatedItems() {
    return lodash.sortBy(this.delegatedBoardItems, ['sortOrder']);
  }

  get items(): IBoardItemDto[] {
    if (!this.board.enableShifts || !this.board.currentShift) {
      return lodash.sortBy(this.boardItems, ['sortOrder']);
    }

    const separators = this.separators;

    if (!this.boardItems?.length) {
      return separators;
    }

    const tasksWithHours = [].concat.apply(this.boardItems, separators);
    return lodash.sortBy(tasksWithHours, ['sortOrder']);
  }

  @Action
  async processDelegatedItem(params: any) {
    const { boardItemId, accept } = params;
    await this.biTransferClient.processDelegatedItem(boardItemId, accept);
  }

  @Action
  async moveTo(params: any) {
    const { currentBoardId, newBoardId, boardItemId } = params;

    const moveTo = {
      currentBoardId,
      newBoardId,
      boardItemId,
    } as BoardItemTransferDto;

    await this.biTransferClient.move(moveTo);
  }

  @Action
  async delegate(params: any) {
    const { currentBoardId, newBoardId, boardItemId } = params;

    const moveTo = {
      currentBoardId,
      newBoardId,
      boardItemId,
    } as BoardItemTransferDto;

    await this.biTransferClient.delegate(moveTo);
  }

  @Action
  async moveBack(params) {
    const { boardItemId, moveType } = params;

    await this.biTransferClient.moveBack(boardItemId, moveType);
  }

  @Action
  async shareWith(params: BoardItemShareDto) {
    const response = await this.biShareClient.share(params);

    return response;
  }

  @Action
  async unShareWith(params: BoardItemUnshareDto) {
    const result = await this.biShareClient.unShare(params);
    return result;
  }

  @Action
  async leaveSharing(params: BoardItemUnshareDto) {
    await this.biShareClient.leaveSharing(params);
  }

  @Action
  async stopSharing(params: any) {
    await this.biShareClient.stopSharing(params);
  }

  @Action
  async loadAllBoards() {
    this.setAllBoards(await this.boardClient.getAllBoards());
  }

  @Action
  async loadAvailableBoards() {
    this.setAvailableBoards(await this.boardClient.getAvailableBoards());
  }

  @Action
  async refreshAction(params) {
    const { id, boardId } = params;
    await this.actionsClient.refreshAction(boardId, this.currentBoardItem.id, id);
  }

  @Action
  async loadBoardItemTypes() {
    this.setBoardItemTypes(await this.boardItemClient.getAllBoardItemTypes());
  }

  @Action
  async processBoardItemView(dto: BoardItemClickDto) {
    await this.boardItemClient.processBoardItemView(dto);
  }

  @Action
  async toggleSubscription(boardItemId) {
    await this.boardItemClient.toggleSubscription(boardItemId);
  }

  @Action
  async toggleAutomaticStatusChange(boardItemId) {
    await this.boardItemClient.toggleAutomaticStatusChange(boardItemId);
  }

  @Action
  async createBoard(boardInsertDto: BoardInsertDto) {
    await this.boardClient.createBoard(boardInsertDto);
    await this.loadAvailableBoards();
    await this.loadAllBoards();
  }

  @Action
  async updateComment(comment: BoardItemCommentDto) {
    const updatedComment = await this.commentClient.updateComment(comment);
    GlobalHelper.NotifySuccess('Comment updated.');
    return updatedComment;
  }

  @Action
  async loadBoardCategories() {
    this.setBoardCategories(await this.boardClient.getBoardCategories());
  }

  @Action
  async getBoard(boardId: string) {
    const board = await this.boardClient.getBoard(boardId);
    this.setBoard(board);
  }

  @Action
  async loadData(boardId: string) {
    const board = await this.boardClient.getBoard(boardId);
    this.setBoard(board);

    if (!board) {
      return;
    }

    //load required data parallel
    const [categories, boardItems, delegatedItems, sharedItems] = await Promise.all([
      this.boardClient.getBoardCategories(),
      this.boardItemClient.getItems(this.board.id, 0),
      this.biTransferClient.getDelegatedBoardItems(this.board.id),
      this.biShareClient.getSharedBoardItems(this.board.id),
    ]);

    this.setBoardCategories(categories);
    this.setBoardItems(boardItems);
    this.setDelegatedItems(delegatedItems);
    this.setSharedItems(sharedItems);

    if (board.backgroundImage) {
      document.documentElement.style.setProperty('--bg-image', "url('" + board.backgroundImage + "')");
    } else {
      document.documentElement.style.setProperty('--bg-image', "url('/background.jpg')");
    }

    if (board.backgroundImageOpacity) {
      document.documentElement.style.setProperty('--bg-image-opacity', board.backgroundImageOpacity.toString());
    } else {
      document.documentElement.style.setProperty('--bg-image-opacity', '0.2');
    }
  }

  @Action
  async loadSharedBoardItems() {
    const items = await this.biShareClient.getSharedBoardItems(this.board.id);
    this.setSharedItems(items);
  }

  @Action
  async handoverShift(shiftHandoverDto: ShiftHandoverDto) {
    await this.boardClient.handoverShift(this.board.id, shiftHandoverDto);
    await this.loadData(this.board.id);
  }

  @Action
  async endDrag(moveParams) {
    if (moveParams.addedIndex == null) {
      return;
    }

    this.endDragMutation(moveParams);

    const items: any = this.items;
    const currentItem: any = this.boardItems.find((x: any) => x.id === this.dragId);

    const { initialListId, sortOrderChanged } = currentItem;

    if (initialListId === currentItem.boardItemListId && !sortOrderChanged) {
      currentItem.sortOrderChanged = undefined; //reset sortOrderChanged, because the next time we want to check again
      return;
    }

    const itemIndex = items.indexOf(currentItem);

    const newList = this.board.lists.find((_) => _.id === currentItem.boardItemListId);

    // move item below shift hour divider, calculate planned date
    if (newList.displayShiftTime && !moveParams.isFutureItem) {
      let plannedDate = null;

      //if we find a time divider above the item, set the boardItems planned date to
      //to the value of the divider
      for (let index = 0; index < items.length; index++) {
        const upperItem = items[itemIndex - index];
        if (upperItem?.isFix) {
          plannedDate = upperItem.value;
          break;
        }
      }

      if (plannedDate) {
        currentItem.plannedDate = plannedDate;
        currentItem.sortOrder = plannedDate.toFormat('yyyyMMddHH00');
      }
    }

    if (moveParams.reorder) {
      currentItem.sortOrder = parseInt(DateTime.fromISO(currentItem.plannedDate).toFormat('yyyyMMddHH00'));
    }

    const updates = Mapper.mapToUpdate(currentItem, newList);

    await this.boardItemClient.save(updates);
  }

  @Action
  async checkUpdate(maxRowNumber) {
    if (!this.currentBoardId) return;

    const localMax = !this.boardItems.length ? 0 : lodash.maxBy(this.boardItems, 'rowVersion').rowVersion;
    if (localMax < maxRowNumber) {
      const boardItems = await this.boardItemClient.getItems(this.board.id, localMax);
      await NotificationStore.loadAllNotifications(this.currentBoardId);

      for (let i = 0; i < boardItems.length; i++) {
        this.updateTask(boardItems[i]);
      }
    }
  }

  @Action
  async saveComment(comment: BoardItemCommentDto) {
    await this.boardItemClient.comment(comment);
  }

  @Action
  async saveBoardItem(dto: IBoardItemDto) {
    let newList = null;

    if (this.board && this.board.lists) {
      newList = this.board.lists.find((_) => _.id === dto.boardItemListId);

      if (!newList) {
        for (let i = 0; i < this.availableBoards.length; i++) {
          newList = this.availableBoards[i].lists.find((_) => _.id === dto.boardItemListId);
          if (newList) {
            break;
          }
        }
      }
    }

    const originalItem = this.boardItems.find((_) => _.id === dto.id);

    if (originalItem && originalItem.plannedDate !== dto.plannedDate) {
      dto.sortOrder = parseInt(DateTime.fromISO(dto.plannedDate, { zone: 'utc' }).toFormat('yyyyMMddHHmm'));
    }

    const updates = Mapper.mapToUpdate(dto, newList);
    try {
      await this.boardItemClient.save(updates);
      GlobalHelper.NotifySuccess('Changes saved.');
    } catch (err) {
      GlobalHelper.NotifyError(err);
      throw err;
    }
  }

  @Action
  async addBoardItem(dto: IBoardItemDto) {
    const item = await this.boardItemClient.add(Mapper.mapToInsert(dto.initialOwnerBoardId ?? this.board.id, dto));
    return item;
  }

  @Action
  async addBoardItemFromTemplate(id: string) {
    const dto = new BoardItemCreateFromTemplateDto();
    dto.templateId = id;
    const item = await this.boardItemClient.addBoardItemFromTemplate(dto);
    return item;
  }

  @Action
  async cleanupBoard(id: string): Promise<any> {
    await this.boardClient.cleanup(id);
    GlobalHelper.NotifySuccess('Cleanup process finished');
  }

  @Action
  async archiveDone(id: string): Promise<any> {
    await this.boardClient.archiveDone(id);
    GlobalHelper.NotifySuccess('All done items archived');
  }

  @Action
  async getById(id: string) {
    const boardItem = await this.boardItemClient.getById(id);
    this.setCurrentBoardItem(boardItem);

    return boardItem;
  }

  @Action
  async createTemplateFromBoardItem() {
    await this.boardItemClient.createTemplateFromBoardItem(this.currentBoardItem.id);
    GlobalHelper.NotifySuccess('Template created');
  }

  @Mutation initNewBoardItem(isTemplate = false) {
    this.currentBoardItem = new BoardItemDto();
    this.currentBoardItem.dueDate = null;
    this.currentBoardItem.isTemplate = isTemplate;
    this.currentBoardItem.actionsJson = isTemplate ? '[]' : null;
    this.currentBoardItem.priority = BoardItemPriorityDto.Normal;
  }

  @Mutation
  setCurrentBoardItem(boardItem: IBoardItemDto) {
    this.currentBoardItem = boardItem;
  }

  @Mutation
  setBoardCategories(categories: BoardItemCategoryDto[]) {
    this.boardItemCategories = categories;
  }

  @Mutation
  setAllBoards(boards: IBoardDto[]) {
    this.allBoards = boards;
  }

  @Mutation
  setAvailableBoards(boards: IBoardDto[]) {
    this.availableBoards = boards;
  }

  @Mutation
  setBoardItemTypes(types: IBoardItemTypeDto[]) {
    this.boardItemTypes = types;
  }

  @Mutation
  setActionUpdates(data) {
    // set action updates for board item in the list
    const boardItem = this.boardItems.find((_) => _.id === data.boardItemId);
    if (boardItem.actions && boardItem.actions) {
      const boardItemActionIndex = boardItem.actions.findIndex((_) => _.id === data.updates.id);
      boardItem.actions.splice(boardItemActionIndex, 1, data.updates);
    }

    // set action updates for current (opened) board item
    if (this.currentBoardItem?.id === data.boardItemId) {
      const currentIndex = this.currentBoardItem?.actions.findIndex((_) => _.id == data.updates.id);
      this.currentBoardItem?.actions.splice(currentIndex, 1, data.updates);
    }
  }

  @Mutation
  setBoardTitle(updates) {
    if (this.board.id === updates.id) {
      this.board.title = updates.title;
    }
  }

  @Mutation
  setShiftTypes(shiftTypes) {
    this.board.shiftTypes = shiftTypes;
  }

  @Mutation
  setBoard(board: IBoardDto) {
    this.board = board;
  }

  @Mutation
  setBoardItems(boardItems: IBoardItemDto[]) {
    this.boardItems = boardItems;
  }

  @Mutation
  setDelegatedItems(delegatedBoardItems: any[]) {
    this.delegatedBoardItems = delegatedBoardItems;
  }

  @Mutation
  setSharedItems(sharedItems: any[]) {
    this.sharedBoardItems = sharedItems;
  }

  @Mutation
  addBoardItems(boardItems: IBoardItemDto[]) {
    if (!boardItems?.length) {
      return;
    }

    if (!this.boardItems) {
      this.boardItems = [];
    }

    this.boardItems = this.boardItems.concat(...boardItems);
  }

  @Mutation
  updateBoardItemAttachment(updates: UpdateAttachmentDto) {
    if (this.currentBoardItem?.id == updates.boardItemId) {
      const attachment = this.currentBoardItem.attachments?.find((t) => t.id == updates.id);

      if (attachment) {
        attachment.isWideBoardItemAttachment = updates.isWideBoardItemAttachment;
      }
    }
  }

  @Mutation
  updateCurrentShift(isHandoverPrepared) {
    this.board.currentShift.isHandoverPrepared = isHandoverPrepared;
  }

  @Mutation
  setBoardItemHistory(params: { id: string; changes: IVarianceChangeSet[] }) {
    this.boardItemsChanges[params.id] = params.changes;
  }

  @Mutation
  resetBoardItemType(boardItemId: string) {
    const item = this.boardItems.find((_) => _.id === boardItemId);
    item.boardItemCategoryId = item.boardItemCategory.id;
    item.boardItemTypeId = item.boardItemType.id;
  }

  @Mutation
  setHubStatus(hubStatus) {
    this.hubStatus = hubStatus;
  }

  @Mutation
  cleanup() {
    this.board = new BoardDto();
    this.boardItems = [];
    this.sharedBoardItems = [];
    this.delegatedBoardItems = [];
  }

  @Mutation
  updateTask(task: IBoardItemDto) {
    //update currently open boardItem
    if (task.id == this.currentBoardItem.id) {
      this.currentBoardItem = task;
    }

    //ignore boardItems, which are not in scope of the current board
    if (!this.board?.lists || !this.board.lists.some((_) => _.id === task.boardItemListId)) {
      return;
    }

    const index = this.boardItems.findIndex((_) => _.id === task.id);

    if (index !== -1) {
      this.boardItems.splice(index, 1, task);
    } else {
      //add newly created task
      this.boardItems.push(task);
      BoardItemNotificationHelper.NotifyUser(task);
    }
  }

  @Mutation
  startDrag(id) {
    this.dragId = id;
  }

  @Mutation
  endDragMutation(moveParams) {
    if (moveParams.addedIndex == null) return;
    const collection = moveParams.collection;
    const removedIndex = moveParams.removedIndex;
    const addedIndex = moveParams.addedIndex;

    const newList = this.board.lists.find((_) => _.id === collection);
    if (newList.listGroupType === ListGroupTypeDto.Delegated) {
      return;
    }

    const filteredItems = getModule(BoardModule).items.filter((x) => x.boardItemListId === collection);

    const task = this.boardItems.find((x) => x.id === this.dragId);

    //Sortierung in gleicher Liste
    if (removedIndex != null && addedIndex !== null) {
      if (removedIndex === addedIndex) {
        task['sortOrderChanged'] = false;
        task['initialListId'] = task.boardItemListId;
        return; //dropped at same position
      }

      const addedItem = filteredItems[addedIndex];
      let otherItem = null;
      let newSortOrder = 0;

      if (addedIndex < removedIndex) {
        //Sortierung nach unten
        otherItem = filteredItems[addedIndex - 1];
        newSortOrder =
          otherItem != null
            ? (otherItem.sortOrder + addedItem.sortOrder) / 2
            : (lodash.minBy(filteredItems, 'sortOrder') as IBoardItemDto).sortOrder - 1000;
      } else {
        //Sortierung nach oben
        otherItem = filteredItems[addedIndex + 1];
        newSortOrder =
          otherItem != null
            ? (otherItem.sortOrder + addedItem.sortOrder) / 2
            : (lodash.maxBy(filteredItems, 'sortOrder') as IBoardItemDto).sortOrder + 1000;
      }
      task.sortOrder = newSortOrder;
    } else if (addedIndex != null) {
      //reorder to other list
      task.boardItemListId = collection;
      if (addedIndex === 0) {
        task.sortOrder = filteredItems.length ? (lodash.minBy(filteredItems, 'sortOrder') as IBoardItemDto).sortOrder - 1000 : 0;
      } else if (addedIndex === filteredItems.length) {
        task.sortOrder = filteredItems.length ? (lodash.maxBy(filteredItems, 'sortOrder') as IBoardItemDto).sortOrder + 1000 : 0;
      } else {
        const itemAfter = filteredItems[addedIndex];
        const itemBefore = filteredItems[addedIndex - 1];
        task.sortOrder = (itemAfter.sortOrder + itemBefore.sortOrder) / 2;
      }
    }
  }

  @Mutation
  updateBoardItemViews({ boardItemId, boardItemViews }) {
    if (!boardItemId) {
      return;
    }

    const index = this.sharedBoardItems.findIndex((t) => t.id === boardItemId);

    if (index < 0) {
      return;
    }

    this.sharedBoardItems[index].boardItemViews = boardItemViews;
  }
}

export const BoardStore = getModule(BoardModule);
