
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component({})
  export default class CrewContactInformation extends Vue {
    @Prop() data: any;

    get mailtoLinkAll() {
      return 'mailto:' + this.data.map((_) => _.companyEmail).join(',');
    }
  }
