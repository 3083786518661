var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"badge",class:{
    'badge-primary': _vm.status === _vm.statusType.Planned,
    'badge-secondary': _vm.status === _vm.statusType.Shared,
    'badge-warning': _vm.status === _vm.statusType.InProgress,
    'badge-success': _vm.status === _vm.statusType.Done || _vm.status === _vm.statusType.UnderReview,
    'badge-info': _vm.status === _vm.statusType.Information,
    'bi-badge': _vm.size === 'xl',
    'badge-pill': _vm.size !== 'xl',
  },style:(_vm.styles)},[_vm._v(" "+_vm._s(_vm.status)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }