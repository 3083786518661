
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { BoardItemDelayItemInfo } from '@/types/boardItemDelayInfo';

  @Component({})
  export default class BoardItemDelayInfo extends Vue {
    @Prop() value: any;

    get delayInfo() {
      return this.value && this.value.length ? (this.value as BoardItemDelayItemInfo[]) : [];
    }
  }
