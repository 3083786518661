
  import Vue from "vue";
  import Component from "vue-class-component";
  import { Prop } from "vue-property-decorator";
  import { IBoardItemDto } from "@/apiclient/apiclient_generated";

  @Component({})
  export default class BoardItemConnections extends Vue {
    @Prop()
    boardItem: IBoardItemDto;

    private boardItemHasConnections() {
      return (
        (this.boardItem.linkedBoardItems && this.boardItem.linkedBoardItems.length) ||
        this.boardItem.parentBoardItemId
      );
    }
  }
