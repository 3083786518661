
import Vue from "vue";
import Component from "vue-class-component";
import { SpecialOccurrenceModule, SpecialOccurrenceStore } from "@/store/specialOccurrence";
import { AttachmentsModule, AttachmentsStore } from "@/store/attachments";
import AttachmentView from "@/components/Attachments/AttachmentView.vue";
import UploadAttachment from "@/components/Attachments/UploadAttachment.vue";
import {
  AttachmentDestination,
  SpecialOccurrenceAccessLevel,
  SpecialOccurrenceBoardDto
} from "@/apiclient/apiclient_generated";
import TaskCard from "@/components/TaskCard.vue";
import { attachmentClient } from "@/apiclient/opsboardapi";
import FileSaver from "file-saver";

@Component({
    components: {
      TaskCard,
      UploadAttachment,
      AttachmentView,
    },
  })
  export default class SpecialOccurrenceAttachments extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private attachmentstore: AttachmentsModule = AttachmentsStore;

    private get occId() {
      return this.$route.params.occId;
    }

    created() {
      this.attachmentstore.loadOccurrenceAttachments(this.$route.params.occId);
      this.store.getSOBoards(this.$route.params.occId);
    }

    get boardsWithAttachments(): SpecialOccurrenceBoardDto[] {
      const boardsWithAttachments = this.store.soBoards.filter( _=> _.items.filter(item => item.attachments.length).length);
      return this.lodash.orderBy(boardsWithAttachments, 'boardTitle');
    }

    get userCanEdit() {
      return this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin || this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor;
    }

    async downloadAllAttachments() {
      const fileData = await attachmentClient.downloadAllAttachments(this.occId, AttachmentDestination.SpecialOccurrence);
      FileSaver.saveAs(fileData.data, fileData.fileName);
    }

    private get attachments() {
      return this.attachmentstore.occurrenceAttachments;
    }
  }
