
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SettingsStore, SettingsModule } from '@/store/settings';
  import { BoardStore, BoardModule } from '@/store/board';
  import { BoardItemValidationTypeDto } from '@/apiclient/apiclient_generated';

  @Component({
    components: {},
  })
  export default class ShiftValidation extends Vue {
    private settingsStore: SettingsModule = SettingsStore;
    private boardStore: BoardModule = BoardStore;
    private boardId = '';
    private isDirty = false;
    private categories = [];
    private selectAllRelevant = false;
    private selectAllAvailable = false;

    async mounted() {
      this.boardId = this.$route.params.id;

      await this.boardStore.loadBoardCategories();
      await this.settingsStore.loadShiftValidationSettings(this.boardId);

      const types = this.settings.types;

      this.boardStore.groupedSelect.forEach((cat) => {
        cat.items.forEach((t: any) => {
          const setting: BoardItemValidationTypeDto = types.find((_) => _.typeId == t.id);

          t.isRelevant = setting ? setting.isRelevant : false;
          t.isAvailable = setting ? setting.isAvailable : false;
          t.isRelevantForShiftReport = setting ? setting.isRelevantForShiftReport : false
        });
        this.categories.push(cat);
      });
    }

    get settings() {
      return this.settingsStore.shiftValidationSettings;
    }

    processRelevantChanges(item) {
      if (item.isRelevant) {
        item.isAvailable = item.isRelevant;
      }
      this.isDirty = true;
    }

    processRelevantForShiftReportChanges() {
      this.isDirty = true;
    }

    processAvailableChanges(item) {
      if (!item.isAvailable) {
        item.isRelevant = false;
      }
      this.isDirty = true;
    }

    async save() {
      const relevantTypes: BoardItemValidationTypeDto[] = this.categories
        .map((cat) => {
          return cat.items
            .filter((_) => _.isRelevant || _.isAvailable || _.isRelevantForShiftReport)
            .map((_) => {
              const dto = new BoardItemValidationTypeDto();
              dto.isRelevant = _.isRelevant;
              dto.isAvailable = _.isAvailable;
              dto.isRelevantForShiftReport = _.isRelevantForShiftReport;
              dto.typeId = _.id;
              return dto;
            });
        })
        .filter((_) => _ && _.length)
        .flat();

      await this.settingsStore.updateShiftValidationSettings(relevantTypes);

      this.isDirty = false;
    }

    setRelevantStateForAll(state: boolean) {
      this.categories.forEach((cat) => {
        cat.items.forEach((t) => {
          t.isRelevant = state;
          if (t.isRelevant && !t.isAvailable) {
            t.isAvailable = true;
          }
        });
      });
    }

    setRelevantForShiftReportStateForAll(state: boolean) {
      this.categories.forEach((cat) => {
        cat.items.forEach((t) => {
          t.isRelevantForShiftReport = state;
        });
      });
    }

    setAvailableStateForAll(state: boolean) {
      this.categories.forEach((cat) => {
        cat.items.forEach((t) => (t.isAvailable = state));
      });
    }
  }
