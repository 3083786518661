
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import {
    AttachmentDto,
    BoardItemClient,
    EmailContactDto,
    IBoardItemDto,
    SendAsEmailDto,
    SendAsEmailGetOptionsDto,
    SendAsEmailOptionsDto,
  } from '@/apiclient/apiclient_generated';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { DateTime } from 'luxon';
  import axios from 'axios';
  import { WS_URL as ApiUrl } from '@/config';
  import { GlobalHelper } from '@/helpers/global-helper';
  import { sortBy } from 'lodash';
  import { lookupClient } from '@/apiclient/opsboardapi';
  import WysiwigEditor from '@/components/Misc/WysiwigEditor.vue';
  import { BoardStore } from '@/store/board';
  import CheckboxWithLabel from './Misc/CheckboxWithLabel.vue';

  @Component({
    components: { WysiwigEditor, ModalWindow, 'checkbox-with-label': CheckboxWithLabel },
  })
  export default class SendAsEmail extends Vue {
    @Prop() item: IBoardItemDto;

    private loading = false;
    private boardItemClient = new BoardItemClient(ApiUrl, axios);
    sendAsEmail: SendAsEmailDto = { body: '' } as SendAsEmailDto;

    private receiverMailPattern = /#SER:([^#]*)#/;
    private contentFlagsPattern = /#SERM:([^#]*)#/;
    private descriptionSplitter = '#####';
    private ccReceiverSplitter = '|';
    private optionsChanged = false;
    includeAttachments = false;
    selectedAttachments: AttachmentDto[] = [];

    private sendAsEmailOptions: SendAsEmailOptionsDto = SendAsEmailOptionsDto.fromJS({
      replyToAddresses: [],
      signature: null,
    });

    calculateBody(): string {
      let text = '';

      text += this.getFormattedDescription(this.item.description);
      text += '\n\n';

      if (this.sendAsEmail.includeChecklists && this.item.checklists.length) {
        text += '<strong>CHECKLISTS</strong><br>';

        sortBy(this.item.checklists, 'sortOrder').forEach((checklist) => {
          text += '<strong>' + checklist.title + '</strong>';

          text += '<ul>';
          sortBy(checklist.items, 'sortOrder').forEach((item) => {
            text += `<li> ${item.isCompleted ? '<strong>[DONE]</strong> ' : '[OPEN] '} ${item.title}`;
            if (item.description) {
              text += `   ${item.description}\n`;
            }
            text += '</li>';
          });
          text += '</ul>';
        });
      }

      if (this.sendAsEmail.includeComments && this.item.comments.filter((_) => !_.isSystemComment).length) {
        text += '<br><strong>COMMENTS</strong>';

        const userComments = this.item.comments.filter((_) => !_.isSystemComment);

        this.lodash.orderBy(userComments, 'createdDate', 'desc').forEach((comment) => {
          text += '<br><em>' + DateTime.fromISO(comment.createdDate).toFormat('dd.MM.yyyy HH:mm') + ' - ' + comment.userTlc + '</em><br>';
          text += comment.text + '<br>';
        });
      }

      if (this.sendAsEmail.includeLabels && this.item.labels?.length) {
        text += '<br><strong>LABELS</strong>';
        text += '<ul>';
        this.item.labels.forEach((label) => {
          text += '<li>' + label.title + '</li>';
        });

        text += '</ul>';
      }

      if (this.includeAttachments && this.selectedAttachments?.length) {
        text += '<br><strong>FILE ATTACHMENTS</strong>';
        text += '<ul>';

        this.selectedAttachments.forEach((attachment) => {
          text += '<li>' + attachment.fileName + '</li>';
        });

        text += '</ul>';
      }

      text += this.sendAsEmailOptions.signature ? '<br>' + this.sendAsEmailOptions.signature : '';

      return text;
    }

    generateText() {
      const body = this.calculateBody();
      this.sendAsEmail.body = body.replace(this.receiverMailPattern, '');
      this.optionsChanged = false;
    }

    attachmentIsSelected(attachment: AttachmentDto): boolean {
      return this.selectedAttachments.map((_) => _.id).indexOf(attachment.id) !== -1;
    }

    async sendEmail() {
      const styleTag =
        '<html><head><style type="text/css"> ' +
        'p { margin:0 !important;} ' +
        "p:empty:before { content: ' '; white-space: pre; }" +
        'td, th { padding: 4px; border-width:1px; border-style:solid; border-color:rgb(171,171,171); box-sizing:border-box; word-break:break-word; white-space:normal;background-color:transparent; }' +
        'table { width: auto;border-collapse: collapse; }' +
        '</style>' +
        '</head>' +
        '<body>@body@' +
        '</body>' +
        '</html>';

      this.loading = true;
      this.sendAsEmail.to = this.recipientAddresses;
      this.sendAsEmail.cc = this.recipientCcAddresses;
      this.sendAsEmail.body = '<div style="font-family:Arial;width: 100%">' + this.sendAsEmail.body + '</div>';
      this.sendAsEmail.body = this.sendAsEmail.body.replace(/<p><\/p>/g, '<br>');
      this.sendAsEmail.body = styleTag.replace('@body@', this.sendAsEmail.body);

      this.sendAsEmail.attachmentIds = this.includeAttachments ? this.selectedAttachments.map((t) => t.id) : [];

      const boardId = BoardStore.currentBoardId;

      const result = await this.boardItemClient.sendAsEmail(boardId, this.item.id, this.sendAsEmail);
      if (result) {
        GlobalHelper.NotifySuccess('E-Mail has been successfully sent.');
        this.close();
      } else {
        GlobalHelper.NotifyError('Error sending E-Mail. See system-comment in BoardItem.');
      }
      this.loading = false;
    }

    close() {
      this.$bvModal.hide('sendAsEmailModal');
    }

    processEmailAttachmentSelection(attachment: AttachmentDto) {
      const index = this.selectedAttachments.findIndex((t) => t.id === attachment.id);

      if (index >= 0) {
        this.selectedAttachments.splice(index, 1);
      } else {
        this.selectedAttachments.push(attachment);
      }

      this.optionsChanged = true;
    }

    async mounted() {
      const getOptionsDto = SendAsEmailGetOptionsDto.fromJS({});
      getOptionsDto.boardItemId = this.item.id;
      getOptionsDto.currentBoardId = BoardStore.currentBoardId;

      this.sendAsEmailOptions = await this.boardItemClient.getSendAsEmailOptions(getOptionsDto);

      this.sendAsEmail = new SendAsEmailDto({
        to: '',
        subject: this.item.title,
        body: this.calculateBody(),
        cc: '',
        replyTo: this.sendAsEmailOptions.replyToAddresses[0]?.emailAddress,
        sender: this.sendAsEmailOptions.sender,
      });

      //pre-populate E-Mail Addresses to send to.
      const addresses = this.item.description?.match(this.receiverMailPattern);

      if (addresses?.length > 1) {
        const matchResult = addresses[1];

        if (matchResult.indexOf(this.ccReceiverSplitter) === -1) {
          const to = matchResult.split(',');
          to.forEach((add) => {
            this.addRecipient(add.trim(), 'to');
          });
        } else {
          const receivers = matchResult.split(this.ccReceiverSplitter);

          const to = receivers[0].split(',');
          to.forEach((add) => {
            this.addRecipient(add.trim(), 'to');
          });

          const cc = receivers[1].split(',');
          cc.forEach((add) => {
            this.addRecipient(add.trim(), 'cc');
          });
        }
      }

      const flags = this.getContentAvailability(this.item.description);

      this.includeAttachments = flags.includeAttachments;
      this.sendAsEmail.includeChecklists = flags.includeChecklists;
      this.sendAsEmail.includeLabels = flags.includeLabels;

      await this.$nextTick();
      this.optionsChanged = false;
    }

    private getFormattedDescription(description: string) {
      if (!description) {
        return '';
      }

      if (description.indexOf(this.descriptionSplitter) === -1) {
        return description;
      }

      const result = this.item.description.split(this.descriptionSplitter);
      return result[0];
    }

    private getContentAvailability(content: string) {
      const contentAvailability = {
        includeAttachments: false,
        includeChecklists: false,
        includeLabels: false,
      };

      if (!content) {
        return contentAvailability;
      }

      const result = this.item.description?.match(this.contentFlagsPattern);

      if (result && result.length) {
        const flags = result[1].split('|');

        contentAvailability.includeAttachments = flags.some((_) => _.toLowerCase() === 'a');
        contentAvailability.includeChecklists = flags.some((_) => _.toLowerCase() === 'c');
        contentAvailability.includeLabels = flags.some((_) => _.toLowerCase() === 'l');
      }

      return contentAvailability;
    }

    private chosenRecipients: EmailContactDto[] = [];
    private chosenRecipientsCc: EmailContactDto[] = [];
    private options: EmailContactDto[] = [];

    addRecipient(tag, type) {
      const regEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!regEx.test(tag)) {
        alert(`${tag} is no valid E-Mail address.`);
        return;
      }

      const recipient = new EmailContactDto();
      recipient.email = tag;
      recipient.name = tag;

      switch (type) {
        case 'to':
          this.chosenRecipients.push(recipient);
          break;
        case 'cc':
          this.chosenRecipientsCc.push(recipient);
          break;
      }
    }

    async searchReceivers(query: string) {
      const result = await lookupClient.getEmailContacts(BoardStore.currentBoardId, query);
      this.options = result;
    }

    get recipientAddresses(): string {
      return this.chosenRecipients.map((_) => (_.email as any).replaceAll(';', ',')).join(',');
    }

    get recipientCcAddresses(): string {
      return this.chosenRecipientsCc.map((_) => (_.email as any).replaceAll(';', ',')).join(',');
    }

    async includeAttachmentsClicked() {
      await this.$nextTick();
      if (this.includeAttachments) {
        for (let i = 0; i < this.item.attachments.length; i++) {
          this.selectedAttachments.push(this.item.attachments[i]);
        }
      } else {
        this.selectedAttachments = [];
      }
    }
  }
