import { IBoardItemListDto, ActionTypeDto, SpecialOccurrenceDto, SpecialOccurrenceListDto } from './../apiclient/apiclient_generated';
import { IBoardItemDto, BoardItemUpdateDto, BoardItemInsertDto, BoardInsertDto } from '@/apiclient/apiclient_generated';
import BoardItemListType from '@/types/boardItemListType';

export default class Mapper {
  static mapToUpdate(dto: IBoardItemDto, list: IBoardItemListDto): BoardItemUpdateDto {
    const updates = {} as BoardItemUpdateDto;
    updates.id = dto.id;
    updates.title = dto.title || 'New Task';
    updates.description = dto.description;
    updates.sortOrder = dto.sortOrder || 1;
    updates.dueDate = dto.dueDate;
    updates.rowVersion = dto.rowVersion;
    updates.boardItemTypeId = dto.boardItemTypeId;
    updates.boardItemListId = dto.boardItemListId || BoardItemListType.Planned;
    updates.plannedDate = dto.plannedDate;
    updates.boardItemStatus = dto.boardItemStatus;
    updates.actionsDataJson = dto.actionsJson;
    updates.dynamicTemplateFilesJson = dto.dynamicTemplateFilesJson;
    updates.isRelevantForReport = dto.isRelevantForReport;
    updates.isConfidential = dto.isConfidential;
    updates.spreadsheetData = dto.spreadsheetData;

    if (list) {
      updates.boardItemStatus = list.newBoardItemStatus;
    }

    updates.priority = dto.priority;
    updates.assignedToUserId = dto.assignedToUser ? dto.assignedToUser.id : null;

    updates.validFromDate = dto.validFromDate;
    updates.validToDate = dto.validToDate;

    updates.postActionUpdates = dto.actions?.filter((_) => _.type == ActionTypeDto.Post);

    return updates;
  }

  static mapToInsert(boardId: any, dto: IBoardItemDto): BoardItemInsertDto {
    const item = new BoardItemInsertDto();
    item.boardId = boardId;
    item.title = dto.title;
    item.boardItemCategoryId = dto.boardItemCategoryId;
    item.boardItemTypeId = dto.boardItemTypeId;
    item.dueDate = dto.dueDate;
    item.plannedDate = dto.plannedDate;
    item.description = dto.description;
    item.assignedToUserId = dto.assignedToUser ? dto.assignedToUser.id : null;
    item.specialOccurrenceId = dto.specialOccurrenceId;
    item.isTemplate = dto.isTemplate;
    item.isConfidential = dto.isConfidential;
    item.spreadsheetData = dto.spreadsheetData;

    return item;
  }

  static mapBoardToInsert(title: string, enableShifts: boolean): BoardInsertDto {
    const item = new BoardInsertDto();
    item.title = title;
    item.enableShifts = enableShifts;

    return item;
  }

  static mapSpecialOccurrence(occurrence: SpecialOccurrenceDto): SpecialOccurrenceListDto {
    const dto = {
      id: occurrence.id,
      linkedMaterialRequestId: occurrence.linkedMaterialRequestId,
      name: occurrence.name,
      type: occurrence.type,
      boardIds: occurrence.boardIds,
      cssClass: occurrence.cssClass,
    } as SpecialOccurrenceListDto;

    return dto;
  }
}
