import { ShiftClient } from '@/apiclient/apiclient_generated';
import { Action, VuexModule, Module, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';

@Module({ dynamic: true, namespaced: true, store, name: 'shift' })
export class ShiftModule extends VuexModule {
  private shiftClient = new ShiftClient(ApiUrl, axios);

  @Action
  async prepareShiftHandover(boardId) {
    await this.shiftClient.prepareShiftHandover(boardId);
  }
}

export const ShiftStore = getModule(ShiftModule);
