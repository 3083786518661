
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import {
    BoardItemStatusDto,
    IBoardItemDto,
    IBoardItemListDto,
    ListGroupTypeDto,
  } from '@/apiclient/apiclient_generated';
  import AcceptRejectView from '@/types/acceptRejectView';
  import { BoardModule, BoardStore } from '@/store/board';

  @Component({})
  export default class BoardItemAcceptReject extends Vue {
    @Prop() item: IBoardItemDto;
    @Prop() view: AcceptRejectView;
    @Prop() list: IBoardItemListDto;
    private boardStore: BoardModule = BoardStore;
    private acceptReject = AcceptRejectView;
    private boardItemStatus = BoardItemStatusDto;
    private listGroupType = ListGroupTypeDto;

    get displayAcceptReject() {
      const display =
        this.view === AcceptRejectView.Horizontal
          ? () => {
              return (
                this.list &&
                this.list.listGroupType === this.listGroupType.Delegated &&
                this.item.boardItemStatus === this.boardItemStatus.UnderReview &&
                this.item.isDelegated
              );
            }
          : () => {
              return (
                this.boardStore.currentBoardId === this.item.ownerBoardId &&
                this.item.boardItemStatus === this.boardItemStatus.UnderReview &&
                this.item.isDelegated
              );
            };

      return display();
    }

    async processDelegatedItem(accept, event) {
      if (event) {
        event.stopPropagation();
      }

      await this.boardStore.processDelegatedItem({ boardItemId: this.item.id, accept });

      if (this.view === AcceptRejectView.Vertical) {
        this.$root.$emit('bv::hide::modal', 'taskdetail');
      }
    }
  }
