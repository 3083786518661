import { DateTime, Interval } from "luxon";

export default function formatDue(value) {
  const now = DateTime.utc();
  const due = DateTime.fromISO(value);
  const duration = due.diff(now, "minutes").toObject().minutes;

  if (duration > 120) return "Due at: " + due.toFormat("HH:mm") + " h";

  if (duration > 0) {
    return (
      "Due in " +
      Math.floor(Interval.fromDateTimes(now, due).length("minutes")) +
      " minutes"
    );
  } else {
    return "Overdue";
  }
}
