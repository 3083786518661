
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import RouteNames from '@/types/routeNames';
  import EditVendorRequestPartRequest from '@/components/SpecialOccurrence/MaterialRequest/EditVendorRequestPartRequest.vue';
  import EditVendorRequestPartOrder from '@/components/SpecialOccurrence/MaterialRequest/EditVendorRequestPartOrder.vue';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { VendorRequestPartEditDto } from '@/apiclient/apiclient_generated';
  import { materialRequestClient } from '@/apiclient/opsboardapi';

  @Component({
    components: { ModalWindow, EditVendorRequestPartOrder, EditVendorRequestPartRequest },
  })
  export default class EditVendorRequestPart extends Vue {
    materialRequestId: string;
    vendorRequestPart: VendorRequestPartEditDto = VendorRequestPartEditDto.fromJS({ part: { pn: '' } });
    vendorRequestPartId: string;

    created() {
      this.materialRequestId = this.$router.currentRoute.params.materialRequestId;
      this.vendorRequestPartId = this.$router.currentRoute.params.vendorRequestPartId;
    }

    async mounted() {
      this.$bvModal.show('editVendorRequestPart');
      await this.loadData();
    }

    async loadData() {
      this.vendorRequestPart = await materialRequestClient.getVendorRequestPartEditDto(this.vendorRequestPartId);
    }

    cancel() {
      this.$bvModal.hide('editVendorRequestPart');
    }

    modalClosed() {
      this.$router.push({ name: RouteNames.specialOccurrence.root, params: { occId: this.materialRequestId } });
    }
  }
