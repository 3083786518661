
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop, Watch } from "vue-property-decorator";
  import { DateTime } from 'luxon';
  import { IActionDataDto } from '@/apiclient/apiclient_generated';
  import BoardItemDatePicker from "@/components/BoardItem/BoardItemDatePicker.vue";

  @Component({
    components: { BoardItemDatePicker },
  })
  export default class ActionDate extends Vue {
    @Prop()
    actionData: IActionDataDto;
    @Prop()
    name!: string;

    private actionDate = null;
    private from = null;
    private to = null;

    async mounted() {
      this.from = this.actionData.rules.from
        ? DateTime.fromISO(this.actionData.rules.from).toString()
        : null;

      this.to = this.actionData.rules.to
        ? DateTime.fromISO(this.actionData.rules.to).toString()
        : null;

      this.actionDate = this.actionData.value
        ? DateTime.fromISO(this.actionData.value).toString()
        : null;
    }

    @Watch('actionDate')
    async processUpdates() {
      this.actionData.value = this.actionDate ? DateTime.fromISO(this.actionDate).toString() : null;

      await this.$root.$emit('actionDateUpdated', this.actionData);
    }
  }
