export class ConfirmModal {
  static confirm(context, header, title, okTitle, cancelTitle, successCallback, errorCallback, variant = 'success') {
    context.$bvModal.msgBoxConfirm(header, {
        title: title,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: variant,
        okTitle: okTitle,
        cancelTitle: cancelTitle,
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: false,
      })
      .then((value) => {
        if (value) {
          successCallback();
        }
      })
      .catch(() => {
        errorCallback();
      });
  }
}
