
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { UserDto } from '@/apiclient/apiclient_generated';
  import { UserModule, UserStore } from '@/store/user';

  @Component({})
  export default class UserTimeZone extends Vue {
    private userStore: UserModule = UserStore;

    get user(): UserDto {
      return this.userStore.user;
    }

    async toggleTimezone() {
      if (this.user.timeZone == 'utc') {
        await this.userStore.setTimezone('local');
      } else {
        await this.userStore.setTimezone('utc');
      }
    }
  }
