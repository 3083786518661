
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { Spreadsheet, SpreadsheetSheet } from '@progress/kendo-spreadsheet-vue-wrapper';
  import { ConfirmModal } from '@/helpers/confirm-modal';

  @Component({
    components: {
      Spreadsheet,
      SpreadsheetSheet,
    },
  })
  export default class BoardItemSpreadsheet extends Vue {
    @Prop() json;
    @Prop() sheetName;

    async mounted() {
      this.initSpreadSheetData();
    }

    updateState() {
      this.getSpreadSheetJson().then((json) => {
        this.$emit('modelUpdate', json);
      });
    }

    removeSpreadsheet() {
      const success = async () => {
        this.$emit('spreadsheetDelete');
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to delete this spreadsheet? This action cannot be undone and all data will be permanently lost.`,
        'Confirm',
        'Yes, delete Spreadsheet',
        'No',
        success,
        null,
          'danger'
      );
    }

    getSpreadSheetJson() {
      const spreadsheet: any = this.$refs.spreadsheet;
      const widget = spreadsheet.kendoWidget();

      return widget.saveJSON();
    }

    initSpreadSheetData() {
      const spreadsheet: any = this.$refs.spreadsheet;
      const widget = spreadsheet.kendoWidget();

      widget.renameSheet(widget.activeSheet(), this.sheetName);

      const jsonObj: any = JSON.parse(this.json);

      if (jsonObj && jsonObj.sheets && jsonObj.sheets.length) {
        widget.activeSheet().fromJSON(jsonObj.sheets[0]);
      }
    }
  }
