
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { LabelDto } from '@/apiclient/apiclient_generated';
  import PublicConstants from '@/types/publicConstants';
  import VSwatches from 'vue-swatches';
  import OpsBoardIcons from '@/store/labelIcons';
  import { Prop } from 'vue-property-decorator';

  @Component({ components: { VSwatches } })
  export default class LabelsTable extends Vue {
    @Prop()
    public labels: LabelDto[];
    @Prop()
    public labelsHeader: string;
    @Prop() deleteLabelFunc: any;
    @Prop() archiveLabelFunc: any;
    @Prop() reactivateLabelFunc: any;
    @Prop() isArchivedLabels: boolean;

    isLoading = false;
    isDirty = false;

    swatches = PublicConstants.labelColors;

    get labelsIcons() {
      return OpsBoardIcons.icons;
    }

    async deleteLabel(label: LabelDto) {
      return this.deleteLabelFunc(label);
    }
    async archiveLabel(label: LabelDto) {
      return this.archiveLabelFunc(label);
    }
    async reactivateLabel(label) {
      return this.reactivateLabelFunc(label);
    }
  }
