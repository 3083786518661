import * as Msal from 'msal';
import { AUTH_RETURN_URL, AUTH_CLIENTID, AUTH_TENANTID } from "@/config";

export default class AuthService {
  private app: Msal.UserAgentApplication;
  private applicationConfig: any;

  constructor() {
    this.applicationConfig = { auth: {
        clientId: AUTH_CLIENTID,
        authority: "https://login.microsoftonline.com/" + AUTH_TENANTID,
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri:  AUTH_RETURN_URL,
      }
    };

    this.app = new Msal.UserAgentApplication(this.applicationConfig);
  }

  async getUser() {
    return this.app.getAccount();
  }

  async login() {
    const loginRequest = {
      scopes: ["User.Read"],
      redirectUri: AUTH_RETURN_URL
    }

    const loginresult = await this.app.loginRedirect(loginRequest);
    console.log("loginresult",loginresult);
  }

  async renewToken() {
    const renewIdTokenRequest = {
      scopes: [AUTH_CLIENTID],
      redirectUri: AUTH_RETURN_URL
    };

    try
    {
      const response = await this.app.acquireTokenSilent(renewIdTokenRequest);
      return response;
    } catch (err)
    {
      console.log(err);
    }
  }

  logout() {
    this.app.logout()
  }
}