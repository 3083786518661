import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Boards from '@/views/Boards.vue';
import Board from '@/views/Board.vue';
import ShiftsOverview from '@/views/ShiftsOverview.vue';
import BoardOverview from '@/views/BoardOverview.vue';
import BoardLabelsStatistic from '@/views/BoardLabelsStatistic.vue';
import Unauthorized from '@/views/Unauthorized.vue';
import BoardSettings from '@/views/BoardSettings.vue';
import TaskDetail from '@/components/TaskDetail/TaskDetail.vue';
import LogsComponent from '@/views/Logs.vue';
import Login from '@/views/Login.vue';
import SpecialOccurrence from '@/views/SpecialOccurrence.vue';
import OccurrencesOverview from '@/views/OccurrencesOverview.vue';

import BoardInitialSettings from '@/components/BoardSettings/BoardInitialSettings.vue';
import ShiftValidation from '@/components/BoardSettings/ShiftValidation.vue';
import BoardLabels from '@/components/BoardSettings/BoardLabels.vue';

import SpecialOccurrenceData from '@/components/SpecialOccurrence/Data/Data.vue';
import SOTaskGeneration from '@/components/SpecialOccurrence/TaskGeneration/SOTaskGeneration.vue';
import SpecialOccurrenceCompletion from '@/components/SpecialOccurrence/Completion/Completion.vue';
import SpecialOccurrenceAttachments from '@/components/SpecialOccurrence/Attachments/SpecialOccurrenceAttachments.vue';
import TechnicalOccurrenceUpdates from '@/components/SpecialOccurrence/Updates/TechnicalOccurrenceUpdates.vue';

import RouteNames from '@/types/routeNames';
import AuthService from '@/services/auth.service';
import ApplicationSettings from '@/views/ApplicationSettings.vue';
import Users from '@/components/ApplicationSettings/Users.vue';
import { AUTH_MODE } from '@/config';
import { specialOccurrenceResolver } from '@/resolvers/specialOccurrenceResolver';
import BoardTemplates from '@/components/BoardSettings/BoardTemplates.vue';
import SpecialOccurrenceAccess from '@/components/SpecialOccurrence/Access/SpecialOccurrenceAccess.vue';
import Email2BoardSettings from '@/components/BoardSettings/Email2BoardSettings.vue';
import UserSettings from '@/views/UserSettings.vue';
import BoardsSettings from '@/views/BoardsSettings.vue';
import UserNotificationSettings from '@/components/UserSettings/UserNotificationSettings.vue';
import BoardsLabels from '@/components/BoardsSettings/BoardsLabels.vue';
import MaterialRequestEmails from '@/components/BoardsSettings/MaterialRequestEmails.vue';
import SendAsEmailSettings from '@/components/BoardSettings/SendAsEmailSettings.vue';
import EditPart from '@/components/SpecialOccurrence/MaterialRequest/EditPart.vue';
import EditVendorRequestPart from '@/components/SpecialOccurrence/MaterialRequest/EditVendorRequestPart.vue';
import MaterialRequestLink from '@/components/SpecialOccurrence/MaterialRequestLink/MaterialRequestLink.vue';
import MaterialRequestLinkIntermediate from '@/components/SpecialOccurrence/MaterialRequestLink/MaterialRequestLinkIntermediate.vue';
import UserSelect from '@/views/UserSelect.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { anonymous: true },
  },
  {
    path: '/userselect',
    name: RouteNames.userselect,
    component: UserSelect,
  },
  {
    path: '/postlogin',
    name: 'postLogin',
    component: Login,
  },
  {
    path: '/logs',
    name: 'Logs',
    component: LogsComponent,
  },
  {
    path: '/',
    name: RouteNames.boards,
    component: Boards,
  },
  {
    path: '/board/:id',
    name: 'Board',
    component: Board,
    children: [
      {
        name: 'BoardItemDetail',
        path: 'item/:itemid',
        component: TaskDetail,
      },
    ],
  },

  {
    name: 'MaterialRequestLinkIntermediate',
    path: '/mrl/:id',
    component: MaterialRequestLinkIntermediate,
  },

  {
    name: 'SpecialOccurrenceData',
    path: '/special-occurrence/:occId',
    component: SpecialOccurrence,
    beforeEnter: specialOccurrenceResolver,
    children: [
      {
        name: RouteNames.specialOccurrence.root,
        path: '',
        component: SpecialOccurrenceData,
        children: [
          {
            name: 'MaterialRequestPartEdit',
            path: 'mr/:materialRequestId/:partId',
            component: EditPart,
          },
          {
            name: 'MaterialRequestVendorRequestPartEdit',
            path: 'mrp/:materialRequestId/:vendorRequestPartId',
            component: EditVendorRequestPart,
          },
        ],
      },
      {
        name: RouteNames.specialOccurrence.taskGeneration,
        path: '/special-occurrence/:occId/tasks',
        component: SOTaskGeneration,
        children: [
          {
            name: RouteNames.specialOccurrence.task,
            path: ':itemid',
            component: TaskDetail,
          },
        ],
      },
      {
        name: RouteNames.specialOccurrence.completion,
        path: '/special-occurrence/:occId/completion',
        component: SpecialOccurrenceCompletion,
      },
      {
        name: RouteNames.specialOccurrence.attachments,
        path: '/special-occurrence/:occId/attachments',
        component: SpecialOccurrenceAttachments,
        children: [
          {
            name: RouteNames.specialOccurrence.attachmentitem,
            path: ':itemid',
            component: TaskDetail,
          },
        ],
      },
      {
        name: RouteNames.specialOccurrence.updates,
        path: '/special-occurrence/:occId/updates',
        component: TechnicalOccurrenceUpdates,
      },
      {
        name: RouteNames.specialOccurrence.materialRequestLink,
        path: '/special-occurrence/:occId/materialRequestLink',
        component: MaterialRequestLink,
      },
      {
        name: RouteNames.specialOccurrence.accessLevels,
        path: '/special-occurrence/:occId/access',
        component: SpecialOccurrenceAccess,
      },
    ],
  },

  {
    path: '/so-overview/',
    name: RouteNames.specialOccurrence.overview,
    component: OccurrencesOverview,
  },

  {
    path: '/shifts-overview/:id',
    name: 'shifts-overview',
    component: ShiftsOverview,
    children: [
      {
        name: 'shiftBoardItemOverview',
        path: 'item/:shiftBoardItemOverviewId',
        component: TaskDetail,
      },
    ],
  },
  {
    path: '/board-overview/:id',
    name: 'board-overview',
    component: BoardOverview,
    children: [
      {
        name: 'boardItemOverview',
        path: 'item/:boardItemOverviewId',
        component: TaskDetail,
      },
    ],
  },
  {
    path: RouteNames.labels.statistic.path,
    name: RouteNames.labels.statistic.name,
    component: BoardLabelsStatistic,
  },
  {
    path: '/Unauthorized',
    name: RouteNames.unauthorized,
    component: Unauthorized,
  },
  {
    path: '/settings/:id',
    component: BoardSettings,
    children: [
      {
        name: RouteNames.settings,
        path: '',
        component: BoardInitialSettings,
      },
      {
        name: RouteNames.shiftValidationSettings,
        path: '/settings/:id/shift-validation',
        component: ShiftValidation,
      },
      {
        name: RouteNames.labels.boardLabels.name,
        path: RouteNames.labels.boardLabels.path,
        component: BoardLabels,
      },
      {
        name: RouteNames.biTemplates,
        path: '/settings/:id/templates',
        component: BoardTemplates,
        children: [
          {
            name: 'TemplateDetail',
            path: ':itemid',
            component: TaskDetail,
          },
        ],
      },
      {
        name: RouteNames.emailToBoard,
        path: '/settings/:id/emailtoboard',
        component: Email2BoardSettings,
      },
      {
        name: RouteNames.sendAsEmailSettings,
        path: '/settings/:id/sendasemailsettings',
        component: SendAsEmailSettings,
      },
    ],
  },
  {
    name: RouteNames.applicationSettings,
    path: '/applicationsettings',
    component: ApplicationSettings,
    children: [
      {
        name: RouteNames.users,
        path: '',
        component: Users,
      },
    ],
  },
  {
    name: RouteNames.usersettings,
    path: '/usersettings',
    component: UserSettings,
    children: [
      {
        name: RouteNames.notificationsettings,
        path: '',
        component: UserNotificationSettings,
      },
    ],
  },
  {
    name: RouteNames.boardsSettings.name,
    path: RouteNames.boardsSettings.path,
    component: BoardsSettings,
    children: [
      {
        name: RouteNames.labels.generalLabels.name,
        path: '',
        component: BoardsLabels,
      },
      {
        name: RouteNames.generalSettings.materialRequest.name,
        path: RouteNames.generalSettings.materialRequest.path,
        component: MaterialRequestEmails,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (AUTH_MODE == 'azuread') {
    if (!to.matched.some((record) => record.meta.anonymous)) {
      try {
        const authService = new AuthService();
        const user = await authService.getUser();

        if (user) {
          next();
        } else {
          console.log('Not logged in, redirecting to login page');
          next({
            path: '/login',
            query: { nextUrl: to.fullPath },
          });
        }
      } catch (err) {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath },
        });
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
