import axios, { AxiosRequestConfig } from 'axios';
import { UserStore } from './store/user';
import router from './router/router';
import RouteNames from './types/routeNames';
import AuthService from "@/services/auth.service";
import { UserDto } from "@/apiclient/apiclient_generated";
import { AUTH_MODE } from "@/config";
import { GlobalHelper } from "@/helpers/global-helper";

export function setupAxios() {
  axios.defaults.withCredentials = true;

  axios.interceptors.request.use(async (config: AxiosRequestConfig) => {

    if (AUTH_MODE == 'azuread')
    {
      console.log(AUTH_MODE)
      //refresh the token from Azure (will request to Azure only if token is old enough, otherwise from cache, so no performance penalty)
      const auth = new AuthService();
      await auth.renewToken();

      //set token to auth header
      config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('msal.idtoken');
    }

    // Check if User has access to the system
    const user: UserDto = UserStore.user;
    if (UserStore.userSet && Object.keys(user).length && !user.userHasAccess) {
      router.push({ name: RouteNames.unauthorized });
    }

    return config;
  }, (err) => {
    return Promise.reject(err)
  });

  axios.interceptors.response.use((resp) => {
    return resp;
  }, (error) => {
    //if we receive a 401 redirect to login page
    if (error.response && error.response.status == 401) {
      router.push('/login');
    }

    if (error.response && error.response.status == 403) {
     GlobalHelper.NotifyError("Access denied");
    }

    return Promise.reject(error);
  });
}
