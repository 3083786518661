
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { BoardModule, BoardStore } from '@/store/board';
  import { BoardInsertDto } from '@/apiclient/apiclient_generated';
  import ModalWindow from '@/components/ModalWindow.vue';

  @Component({
    components: { ModalWindow },
  })
  export default class AddBoard extends Vue {
    private boardStore: BoardModule = BoardStore;
    private boardInsertDto = new BoardInsertDto({ title: '', enableShifts: false });

    async saveBoard() {
      await this.boardStore.createBoard(this.boardInsertDto);
      this.$bvModal.hide('newBoard');
    }
  }
