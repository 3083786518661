
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { LabelModule, LabelStore } from '@/store/label';
  import PublicConstants from '@/types/publicConstants';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  import VSwatches from 'vue-swatches';
  import BoardLabel from '@/types/boardLabel';
  import { GlobalHelper } from '@/helpers/global-helper';
  import BoardsLabels from '../BoardsSettings/BoardsLabels.vue';

  @Component({ components: { VSwatches, BoardsLabels } })
  export default class BoardLabels extends Vue {
    private labelStore: LabelModule = LabelStore;
    swatches = PublicConstants.labelColors;
    isLoading = false;

    isGeneralLabelsSelectedAll_ = false;
    selectableGeneralBoardsLabels: BoardLabel[] = [];

    async mounted() {
      await Promise.all([this.labelStore.getGeneralLabelsAsync(), this.labelStore.loadSelectedBoardLabels(this.boardId)]);

      this.initGeneralLabels();

      this.isGeneralLabelsSelectedAll_ = this.isGeneralLabelsSelectedAll;
    }

    get boardId() {
      return this.$route.params.id;
    }

    private initGeneralLabels() {
      this.selectableGeneralBoardsLabels = [];

      this.labelStore.generalActiveLabels.forEach((boardLabel: BoardLabel) => {
        boardLabel.isAvailable = this.labelStore.selectedGeneralBoardLabels.some((t) => t.labelId === boardLabel.id && t.isAvailable);

        this.selectableGeneralBoardsLabels.push({ ...boardLabel } as BoardLabel);
      });
    }

    get isGeneralLabelsSelectedAll() {
      return (
        this.selectableGeneralBoardsLabels &&
        this.selectableGeneralBoardsLabels.length &&
        this.selectableGeneralBoardsLabels.every((t) => t.isAvailable)
      );
    }

    toggleAllGeneralLabels(state) {
      this.selectableGeneralBoardsLabels.forEach((item) => {
        item.isAvailable = !state;
      });
    }

    async saveGeneralBoardsLabels() {
      this.isLoading = true;
      const labelsIds = this.selectableGeneralBoardsLabels.filter((t) => t.isAvailable).map((t) => t.id);

      await this.labelStore.updateSelectedGeneralBoardLabels({
        boardId: this.boardId,
        selectedLabels: labelsIds,
      });

      this.isLoading = false;

      GlobalHelper.NotifySuccess('Labels were successfully updated');

      this.initGeneralLabels();
    }
  }
