
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import {
    SpecialOccurrenceAccessLevel,
    SpecialOccurrenceTypeDto
  } from "@/apiclient/apiclient_generated";
  import TechnicalOccurrenceData from './TechnicalOccurrenceData.vue';
  import DiversionData from './DiversionData.vue';
  import ComatData from "@/components/SpecialOccurrence/Data/ComatData.vue";
  import MaterialRequestData from "@/components/SpecialOccurrence/Data/MaterialRequestData.vue";
  import RouteNames from "@/types/routeNames";

  Component.registerHooks(["beforeRouteUpdate"]);

  @Component({
    components: {
      MaterialRequestData,
      ComatData,
      TechnicalOccurrenceData,
      DiversionData
    },
  })
  export default class SpecialOccurrenceData extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private occType = SpecialOccurrenceTypeDto;

    beforeRouteUpdate(to: any, from: any, next: any) {
      next();
      //Update Material request overview <= before route update gets triggered on the data page
      //not on the Material Request Page itself
      if (to.name == RouteNames.specialOccurrence.root && this.occurrence.type === this.occType.MaterialRequest) {
        (this.$refs.materialRequestdata as MaterialRequestData).loadData();
      }
    }

    get occurrence() {
      return this.store.currentOccurrence;
    }

    get userCanEdit() {
      return (
          this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin ||
          this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor
      );
    }
  }
