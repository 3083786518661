
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { sortBy } from 'lodash';
  import { ChecklistModule, ChecklistStore } from '@/store/checklist';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import { BoardItemDto, ChecklistItemDto } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';
  import ChecklistItem from './ChecklistItem.vue';
  import { GlobalHelper } from '@/helpers/global-helper';

  @Component({
    components: {
      ChecklistItem,
    },
  })
  export default class Checklist extends Vue {
    private checklistStore: ChecklistModule = ChecklistStore;
    private newItemState: ChecklistItemDto = { isEditable: true } as ChecklistItemDto;
    @Prop() item: BoardItemDto;
    @Prop({ default: false }) isTemplate: boolean;
    @Prop() readOnly: boolean;

    get filteredChecklists() {
      return sortBy(this.item.checklists, 'sortOrder');
    }

    filteredChecklistItems(items) {
      return sortBy(items, 'sortOrder', 'updatedDate');
    }

    mounted() {
      this.resetNewItemState();
    }

    getFinishedPercentage(items: ChecklistItemDto[]) {
      const closedCount = items.filter((_) => _.isCompleted).length;
      return Math.min(Math.max(Math.floor((closedCount / items.length) * 100), 0), 100) + '%';
    }

    getFinishedCount(items: ChecklistItemDto[]) {
      const result = items.filter((_) => _.isCompleted).length;
      return result;
    }

    async deleteChecklist(checklistId) {
      const boardItemId = this.item.id;

      const success = () => {
        this.checklistStore.deleteChecklist({ checklistId, boardItemId });
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to delete this checklist with all items?`,
        'Confirm delete',
        'Yes, delete',
        'No',
        success,
        null
      );
    }

    async addNewItem(checklistId) {
      this.newItemState.checklistId = checklistId;
      await this.$nextTick();
      (this.$refs.title as HTMLInputElement[])[0].focus();
    }

    async saveNewItem() {
      const params = {
        boardItemId: this.item.id,
        item: {
          title: this.newItemState.title,
          description: this.newItemState.description,
          checklistId: this.newItemState.checklistId,
        } as ChecklistItemDto,
      };

      await this.checklistStore.addChecklistItem(params);
      this.cancelAddNewItem();
    }

    async renameChecklist(checklistId: string, newTitle: string) {
      const params = {
        boardItemId: this.item.id,
        checklistId: checklistId,
        title: newTitle,
      };
      await this.checklistStore.renameChecklist(params);
      this.$root.$emit('bv::hide::popover');
      GlobalHelper.NotifySuccess('Checklist renamed');
    }

    cancelAddNewItem() {
      this.resetNewItemState();
    }

    async changeSortOrder(direction: number, checklistId: string) {
      const params = {
        boardItemId: this.item.id,
        checklistId: checklistId,
        direction: direction,
      };
      await this.checklistStore.changeSortOrder(params);
    }

    resetNewItemState() {
      this.newItemState = {
        title: '',
        checklistId: '',
        isEditable: true,
      } as ChecklistItemDto;
    }
  }
