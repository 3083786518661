
  import Vue from "vue";
  import Component from "vue-class-component";
  import { Prop } from "vue-property-decorator";
  import {
    IBoardItemDto,
    BoardItemPriorityDto,
  } from "@/apiclient/apiclient_generated";

  @Component({})
  export default class BoardItemPriority extends Vue {
    @Prop()
    item: IBoardItemDto;
    private priority = BoardItemPriorityDto;

    updatePriority(newPriority: BoardItemPriorityDto) {
      if (this.item.priority !== newPriority) {
        this.$emit("priorityUpdates", newPriority);
      }
    }
  }
