
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { BoardItemDto, BoardItemStatusDto, LabelDto } from '@/apiclient/apiclient_generated';
  import BoardItemStatus from '../BoardItem/BoardItemStatus.vue';

  @Component({
    components: {
      BoardItemStatus,
    },
  })
  export default class BoardItemOverview extends Vue {
    @Prop() item: BoardItemDto;
    @Prop() action: any;
    @Prop() actionParam: any;
    @Prop() displayRelevantBadge: boolean;

    get displayChecklistProgress() {
      return this.item.checklists.length && this.item.checklists.some((_) => _.items.length);
    }

    get closedChecklistsCount() {
      const tasks = this.item.checklists.map((_) => _.items.length).reduce((a, b) => a + b, 0);
      const completedTasks = this.item.checklists.map((_) => _.items.filter((_) => _.isCompleted).length).reduce((a, b) => a + b);

      return `${completedTasks} of ${tasks}`;
    }

    selectLabel(label: LabelDto) {
      this.$root.$emit('boardItemOverview::labelSelected', label);
    }

    processClick() {
      if (this.action) {
        this.action(this.actionParam);
      }
    }

    getBoardItemStatus(item) {
      return item.isShared ? BoardItemStatusDto.Shared : item.handoversStatus || item.boardItemStatus;
    }
  }
