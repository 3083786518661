
  import { Component, Vue } from "vue-property-decorator";
  import { DateTime } from "luxon";

  @Component
  export default class MocDueDateCalculator extends Vue {
    getDueDate(days: number): string
    {
      const date = DateTime.utc();
      const due = date.plus({ days: days});
      return due.toFormat('dd.MM.yyyy');
    }
  }
