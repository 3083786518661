
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SettingsStore, SettingsModule } from '@/store/settings';
  import CheckboxWithLabel from '../Misc/CheckboxWithLabel.vue';
  import { EmailConfigDto } from '@/apiclient/apiclient_generated';
  import { SelectListItemGroup } from '@/components/Misc/SelectListItemGroup';
  import { BoardModule, BoardStore } from '@/store/board';
  import GroupedSelect from '@/components/Misc/GroupedSelect.vue';

  @Component({
    components: {
      GroupedSelect,
      'checkbox-with-label': CheckboxWithLabel,
    },
  })
  export default class Email2BoardSettings extends Vue {
    private isDirty = false;
    private settingsStore: SettingsModule = SettingsStore;
    private boardStore: BoardModule = BoardStore;
    private boardId: string;

    created() {
      this.boardId = this.$route.params.id;
    }

    get email2BoardSettings(): EmailConfigDto[] {
      return this.settingsStore.email2BoardSettings;
    }

    async mounted() {
      await this.settingsStore.loadEmail2BoardSettings(this.boardId);
      await this.settingsStore.loadShiftValidationSettings(this.boardId);
      await this.boardStore.getBoard(this.boardId);
      await this.boardStore.loadBoardCategories();
    }

    get availableBoardCategories() {
      const settings = this.settingsStore.shiftValidationSettings;

      const availableTypes = settings.types?.filter((_) => _.isAvailable) ?? [];
      const chosenTypes = this.email2BoardSettings.filter(_=>_.boardItemTypeGuid).map(_=> _.boardItemTypeGuid);
      const result: SelectListItemGroup[] = [];

      this.boardStore.groupedSelect.forEach((category) => {
        const types = category.items.filter((_) => availableTypes.some((t) => t.typeId === _.id) || chosenTypes.some((t) => t === _.id));

        if (types && types.length) {
          category.items = types;
          result.push(category);
        }
      });

      return result;
    }

    async save() {
      for (const _ of this.email2BoardSettings) {
        await this.settingsStore.updateEmail2BoardSettings(_);
      }
    }
  }
