
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import CheckboxWithLabel from '../Misc/CheckboxWithLabel.vue';
  import { userClient } from '@/apiclient/opsboardapi';
  import { UserEditDto } from '@/apiclient/apiclient_generated';
  import { GlobalHelper } from '@/helpers/global-helper';

  @Component({
    components: {
      'checkbox-with-label': CheckboxWithLabel,
    },
  })
  export default class UserNotificationSettings extends Vue {
    private isDirty = false;
    private user: UserEditDto = new UserEditDto();

    async mounted() {
      this.user = await userClient.getNotificationSettings();
    }

    async save() {
      await userClient.updateNotificationSettings(this.user);
      GlobalHelper.NotifySuccess('Changes saved');
    }
  }
