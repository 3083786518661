
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import {
    ComatDto,
    SpecialOccurrenceAccessLevel,
    SpecialOccurrenceFlightInsertDto
  } from "@/apiclient/apiclient_generated";
  import { Prop } from 'vue-property-decorator';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import { GlobalHelper } from '@/helpers/global-helper';
  import ComatFlightsSelector from '@/components/SpecialOccurrence/Data/Flights/ComatFlightsSelector.vue';
  import FlightInfo from "@/components/SpecialOccurrence/Data/Flights/FlightInfo.vue";

  @Component({
    components: { FlightInfo, ComatFlightsSelector },
  })
  export default class ComatData extends Vue {
    @Prop() occurrence: ComatDto;
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private showAddFlightsModal = false;

    get comatClearances() {
      return ['Done', 'Not Done'];
    }

    get certificates() {
      return ['EASA Form 1', 'FAA 8130-3', 'Certificate of Conformity'];
    }

    get origins() {
      return this.store.airports;
    }

    async getTransportRequestUrl() {
      const file = await this.store.getComatTransportReport(this.occurrence.id);

      this.downloadFile(file.data, file.fileName);
    }

    get errorTitle() {
      if (this.modelInvalid) return 'Please select Origin, Destination and Planned date.';
      return null;
    }

    get userCanEdit() {
      return (
          this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin ||
          this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor
      );
    }

    get modelInvalid() {
      const occ = this.occurrence as ComatDto;
      return !occ.origin || !occ.destination || !occ.plannedDate;
    }

    private downloadFile(blob, label) {
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();

      URL.revokeObjectURL(link.href);
    }

    async getStickerUrl() {
      const file = await this.store.getComatStickerReport(this.occurrence.id);

      this.downloadFile(file.data, file.fileName);
    }

    async saveChanges() {
      await this.store.saveOccurrenceDataChanges(this.occurrence);
      GlobalHelper.NotifySuccess('Special Occurrence updated');

      await this.store.loadData();
    }

    async saveRoutes(flights) {
      this.store.currentOccurrence.flights = [];
      this.store.currentOccurrence.flights = [...flights];

      const dto = {
        occurrenceId: this.occurrence.id,
        flights: this.store.currentOccurrence.flights,
      } as SpecialOccurrenceFlightInsertDto;

      await this.store.saveOccurrenceFlights(dto);

      GlobalHelper.NotifySuccess('COMAT routes updated');
    }

    async addAffectedFlights() {
      this.showAddFlightsModal = true;
      await this.store.saveOccurrenceDataChanges(this.occurrence);

      const comat = this.occurrence as ComatDto;

      await this.store.getComatRoutings({
        departure: comat.origin,
        arrival: comat.destination,
        date: comat.plannedDate,
      });

      const modal: any = this.$refs['addComatFlight'];
      modal.show();
    }

    async removeFlight() {
      this.occurrence.flights = [];
      await this.saveRoutes(this.occurrence.flights);
    }
  }
