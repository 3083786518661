
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { ActionTypeDto, IBoardItemActionDto } from '@/apiclient/apiclient_generated';
  import BoardItemGetAction from './BoardItemGetAction.vue';
  import ActionResultData from './components/ActionResultData.vue';
  import BoardItemPostAction from './BoardItemPostAction.vue';
  import { BoardStore, BoardModule } from '@/store/board';

  @Component({
    components: {
      BoardItemGetAction,
      BoardItemPostAction,
      ActionResultData
    },
  })
  export default class BoardItemAction extends Vue {
    private boardStore: BoardModule = BoardStore;

    @Prop()
    action: IBoardItemActionDto;
    @Prop()
    boardId: string;

    actionType = ActionTypeDto;
    loading = false;

    async refreshAction(id) {
      this.loading = true;

      await this.boardStore.refreshAction({ id, boardId: this.boardId });
      this.loading = false;
    }
  }
