
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { IBoardItemActionDto } from '@/apiclient/apiclient_generated';

  @Component({
    components: {},
  })
  export default class BoardItemGetAction extends Vue {
    @Prop()
    action: IBoardItemActionDto;
  }
