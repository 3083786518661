import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import { BoardLabelDto, LabelDto, LabelsClient } from '@/apiclient/apiclient_generated';
import { GlobalHelper } from '@/helpers/global-helper';

@Module({ dynamic: true, namespaced: true, store, name: 'label' })
export class LabelModule extends VuexModule {
  private labelsClient = new LabelsClient(ApiUrl, axios);

  private generalBoardsLabels_: LabelDto[] = [];
  private boardSpecificLabels_: LabelDto[] = [];

  selectedGeneralBoardLabels: BoardLabelDto[] = [];

  get generalActiveLabels() {
    return this.generalBoardsLabels_.filter((t) => !t.isDeleted && !t.isArchived);
  }
  get boardActiveLabels() {
    return this.boardSpecificLabels_.filter((t) => !t.isDeleted && !t.isArchived);
  }

  get generalArchivedLabels() {
    return this.generalBoardsLabels_.filter((t) => t.isArchived && t.isArchived);
  }
  get boardArchivedLabels() {
    return this.boardSpecificLabels_.filter((t) => t.isArchived);
  }

  get allActiveLabels() {
    return [...this.generalActiveLabels, ...this.boardActiveLabels];
  }

  get activeGeneralBoardLabels() {
    return this.selectedGeneralBoardLabels.filter((t) => t.isAvailable);
  }
  get allActiveSelectedLabels() {
    return this.allActiveLabels.filter((t) => this.activeGeneralBoardLabels.some((selectedLabel) => selectedLabel.labelId === t.id));
  }

  @Action
  async getGeneralLabelsAsync() {
    const labels = await this.labelsClient.getGeneralLabels();

    this.setGeneralLabels(labels);
  }

  @Action
  async getBoardSpecificLabelsAsync(boardId) {
    const labels = await this.labelsClient.getBoardSpecificLabels(boardId);

    this.setBoardSpecificLabels(labels);
  }

  @Action
  async reactivateLabelAsync(labelId) {
    await this.labelsClient.reactivateLabel(labelId);

    GlobalHelper.NotifySuccess('Label was reactivated.');
  }

  @Action
  async getAllLabelsAsync(boardId) {
    const tasks = [this.labelsClient.getGeneralLabels(), this.labelsClient.getBoardSpecificLabels(boardId)];
    await Promise.all(tasks);

    const allLabels = await tasks[0];
    const boardSpecificLabels = await tasks[1];

    this.setGeneralLabels(allLabels);
    this.setBoardSpecificLabels(boardSpecificLabels);
  }

  @Action
  async updateSelectedGeneralBoardLabels({ boardId, selectedLabels }) {
    const result = await this.labelsClient.updateSelectedBoardLabels(boardId, selectedLabels);

    this.setSelectedBoardLabels(result);
  }

  @Action
  async loadSelectedBoardLabels(boardId) {
    const selectedLabels = await this.labelsClient.getSelectedBoardLabels(boardId);

    this.setSelectedBoardLabels(selectedLabels);
  }

  @Action
  async updateBoardLabelsAsync({ boardId, labels }) {
    const result = await this.labelsClient.updateBoardLabels(boardId, labels);

    this.setBoardLabels({ boardId, labels: result });
  }

  @Action
  async deleteLabelAsync(label: LabelDto) {
    const result = await this.labelsClient.deleteLabel(label.id);

    if (result.isSuccess) {
      this.deleteLabelById(label.id);
    } else {
      return result;
    }
  }

  @Action
  async archiveLabelAsync(id) {
    const result = await this.labelsClient.archiveLabel(id);

    if (result.isSuccess) {
      this.archiveLabel(id);
      GlobalHelper.NotifySuccess('Label was archived.');
    } else {
      return result;
    }
  }

  @Action
  async addLabel(params) {
    const { boardItemId, labelId } = params;
    await this.labelsClient.addLabelToBoardItem(boardItemId, labelId);
    GlobalHelper.NotifySuccess('Label was added.');
  }

  @Action
  async removeLabel(params) {
    const { boardItemId, labelId } = params;
    await this.labelsClient.removeLabelFromBoardItem(boardItemId, labelId);
    GlobalHelper.NotifySuccess('Label was removed.');
  }

  @Mutation
  async deleteLabelById(labelId: string) {
    let source = [];

    let labelIndex = this.boardSpecificLabels_.findIndex((t) => t.id === labelId);

    if (labelIndex >= 0) {
      source = this.boardSpecificLabels_;
    } else {
      labelIndex = this.generalBoardsLabels_.findIndex((t) => t.id === labelId);

      if (labelIndex >= 0) {
        source = this.generalBoardsLabels_;
      }
    }

    if (source && source.length && labelIndex >= 0) {
      source[labelIndex].isDeleted = true;
    }
  }

  @Mutation
  async archiveLabel(labelId: string) {
    let source: LabelDto[] = [];

    let labelIndex = this.boardSpecificLabels_.findIndex((t) => t.id === labelId);

    if (labelIndex >= 0) {
      source = this.boardSpecificLabels_;
    } else {
      labelIndex = this.generalBoardsLabels_.findIndex((t) => t.id === labelId);

      if (labelIndex >= 0) {
        source = this.generalBoardsLabels_;
      }
    }

    if (source && source.length && labelIndex >= 0) {
      source[labelIndex].isDeleted = true;
      source[labelIndex].isArchived = true;
    }
  }

  @Mutation
  setGeneralLabels(labels: LabelDto[]) {
    this.generalBoardsLabels_ = labels;
  }
  @Mutation
  setBoardSpecificLabels(labels: LabelDto[]) {
    this.boardSpecificLabels_ = labels;
  }

  @Mutation
  setSelectedBoardLabels(selectedLabels: BoardLabelDto[]) {
    this.selectedGeneralBoardLabels = selectedLabels;
  }

  @Mutation
  setBoardLabels({ boardId, labels }) {
    const store = getModule(LabelModule);

    if (boardId) {
      store.setBoardSpecificLabels(labels);
    } else {
      store.setGeneralLabels(labels);
    }
  }

  @Mutation
  reactivateLabel(id) {
    let source: LabelDto[] = [];

    let labelIndex = this.boardSpecificLabels_.findIndex((t) => t.id === id);

    if (labelIndex >= 0) {
      source = this.boardSpecificLabels_;
    } else {
      labelIndex = this.generalBoardsLabels_.findIndex((t) => t.id === id);

      if (labelIndex >= 0) {
        source = this.generalBoardsLabels_;
      }
    }

    if (source && source.length && labelIndex >= 0) {
      source[labelIndex].isDeleted = false;
      source[labelIndex].isArchived = false;
    }
  }
}

export const LabelStore = getModule(LabelModule);
