import { render, staticRenderFns } from "./SendAsEmail.vue?vue&type=template&id=6c6b91d6&scoped=true&"
import script from "./SendAsEmail.vue?vue&type=script&lang=ts&"
export * from "./SendAsEmail.vue?vue&type=script&lang=ts&"
import style1 from "./SendAsEmail.vue?vue&type=style&index=1&id=6c6b91d6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c6b91d6",
  null
  
)

export default component.exports