var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"board-labels-container"},[_c('div',{staticClass:"general-labels"},[_c('div',{staticClass:"label mt-2 mb-1"},[_vm._v("General Labels")]),_c('p',[_vm._v("Here you can select which labels should be available for selection on the board.")]),_c('div',{staticClass:"content-container ob-scroll"},[_c('div',{staticClass:"font-weight-bold container-fluid"},[_vm._m(0),_c('div',{staticClass:"row pt-2"},[_c('div',{staticClass:"col-2 offset-7"},[_c('checkbox-with-label',{attrs:{"label":_vm.isGeneralLabelsSelectedAll ? 'Unselect all' : 'Select all'},on:{"input":function($event){return _vm.toggleAllGeneralLabels(_vm.isGeneralLabelsSelectedAll)}},model:{value:(_vm.isGeneralLabelsSelectedAll_),callback:function ($$v) {_vm.isGeneralLabelsSelectedAll_=$$v},expression:"isGeneralLabelsSelectedAll_"}})],1)])]),_c('div',{staticClass:"container-fluid pt-2 pb-2"},_vm._l((_vm.selectableGeneralBoardsLabels),function(bl){return _c('div',{key:bl.id,staticClass:"row pt-2 pb-1"},[_c('div',{staticClass:"col-3"},[_vm._v(" "+_vm._s(bl.category)+" ")]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"bi-label mr-2 mb-1 d-flex justify-content-between align-items-center",style:({
                  'background-color': bl.color,
                  color: 'white',
                  padding: '1px 3px',
                  'border-radius': '3px',
                })},[(bl.icon)?_c('i',{staticClass:"mr-1 ml-1 pr-1",class:bl.icon}):_vm._e(),_c('div',{staticClass:"pl-1 pr-1"},[_vm._v(" "+_vm._s(bl.title)+" ")])])])]),_c('div',{staticClass:"col-2"},[_c('checkbox-with-label',{attrs:{"label":"Available"},model:{value:(bl.isAvailable),callback:function ($$v) {_vm.$set(bl, "isAvailable", $$v)},expression:"bl.isAvailable"}})],1)])}),0)]),_c('div',{staticClass:"d-flex justify-content-end pt-2"},[_c('button',{staticClass:"btn btn-save btn-sm btn-success",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.saveGeneralBoardsLabels()}}},[_c('i',{staticClass:"far fa-save"}),_vm._v(" Save changes ")])])]),_c('hr'),_c('div',{staticClass:"board-labels mt-4"},[_c('BoardsLabels')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row pt-2"},[_c('div',{staticClass:"col-3"},[_vm._v("Category")]),_c('div',{staticClass:"col-4"},[_vm._v("Label")]),_c('div',{staticClass:"col-2"},[_vm._v("Available")])])
}]

export { render, staticRenderFns }