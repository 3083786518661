
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { IBoardItemDto } from '@/apiclient/apiclient_generated';
  import { LabelModule, LabelStore } from '@/store/label';

  @Component
  export default class AddLabels extends Vue {
    private labelStore: LabelModule = LabelStore;
    @Prop() item: IBoardItemDto;

    private filter = '';

    get filteredLabels() {
      const filteredData = this.labelStore.allActiveSelectedLabels.filter((item) => {
        return !this.item.labels?.some((_) => _.id === item.id);
      });

      if (!this.filter) {
        return filteredData;
      }

      return filteredData.filter((item) => item.title.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0);
    }

    async addLabel(labelId) {
      await this.labelStore.addLabel({
        boardItemId: this.item.id,
        labelId,
      });

      const popover: any = this.$refs.addLabel;
      if (popover) {
        this.filter = '';
        popover.doClose();
      }
    }
  }
