
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { IBoardItemDto } from '@/apiclient/apiclient_generated';

  @Component({})
  export default class BoardItemSwitcher extends Vue {
    @Prop() label;
    @Prop() negativeText: string;
    @Prop() positiveText: string;
    @Prop() value: boolean;

    @Prop()
    item: IBoardItemDto;

    updateState(newState) {
      if (this.value !== newState) {
        this.$emit('modelUpdate', newState);
      }
    }
  }
