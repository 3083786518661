
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { ChecklistModule, ChecklistStore } from '@/store/checklist';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import { ChecklistItemDto } from '@/apiclient/apiclient_generated';

  import { Prop } from 'vue-property-decorator';
  import formatDateTime from '@/filters/formatDateTime';

  @Component({})
  export default class ChecklistItem extends Vue {
    @Prop() item: ChecklistItemDto;
    @Prop() itemIndex: number;
    @Prop() boardItemId: string;
    @Prop() isTemplate: boolean;
    @Prop() itemcount: number;
    @Prop() readOnly: boolean;
    private checklistStore: ChecklistModule = ChecklistStore;
    private editing = false;
    private newItemState: ChecklistItemDto;

    mounted() {
      this.resetNewItemState();
    }

    get itemIndex_() {
      return this.itemIndex + 1;
    }

    get itemToolTip() {
      let tooltip = '';
      if (this.item.createdDate) {
        tooltip = `<strong>Created</strong><br>${formatDateTime(this.item.createdDate)}`;

        if (this.item.createdUser) {
          tooltip += `<br>by ${this.item.createdUser}`;
        }
        tooltip += '<br>';
      }

      if (this.item.updatedDate) {
        tooltip += `<strong>Last Updated</strong><br>${formatDateTime(this.item.updatedDate)}`;

        if (this.item.updatedUser) {
          tooltip += `<br>by ${this.item.updatedUser}`;
        }
        tooltip += '<br>';
      }

      if (this.item.completedDate) {
        tooltip += `<strong>Completed</strong><br>${formatDateTime(this.item.completedDate)}`;
        if (this.item.completedUser) {
          tooltip += `<br>by ${this.item.completedUser}`;
        }
      }

      return tooltip;
    }

    resetNewItemState() {
      this.newItemState = {} as ChecklistItemDto;
      this.editing = false;
    }

    async startEditItem() {
      if (this.readOnly) return;

      this.editing = true;
      this.newItemState.title = this.item.title;
      this.newItemState.description = this.item.description;

      await this.$nextTick();
      (this.$refs.title as HTMLInputElement).focus();
    }

    cancelEdit() {
      this.item.title = this.newItemState.title;
      this.item.description = this.newItemState.description;

      this.resetNewItemState();
    }

    async updateIsCompletedState(isCompleted) {
      this.item.isCompleted = isCompleted;

      await this.updateItem();
    }

    async updateItem() {
      const params = { boardItemId: this.boardItemId, item: this.item };
      await this.checklistStore.updateChecklistItem(params);

      this.resetNewItemState();
    }

    async removeItem() {
      const params = {
        checklistId: this.item.checklistId,
        boardItemId: this.boardItemId,
        checklistItemId: this.item.id,
      };

      const success = () => {
        this.checklistStore.deleteChecklistItem(params);
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to delete this checklist item?`,
        'Confirm delete',
        'Yes, delete',
        'No',
        success,
        null
      );
    }

    async changeSortOrder(direction: number) {
      const params = {
        boardItemId: this.boardItemId,
        checklistItemId: this.item.id,
        direction: direction,
      };
      await this.checklistStore.changeItemSortOrder(params);
    }
  }
