
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { attachmentClient, materialRequestClient } from "@/apiclient/opsboardapi";
  import { AttachmentDestination, AttachmentDto, PartDto } from "@/apiclient/apiclient_generated";
  import RouteNames from '@/types/routeNames';
  import { GlobalHelper } from '@/helpers/global-helper';
  import UploadAttachment from "@/components/Attachments/UploadAttachment.vue";
  import AttachmentView from "@/components/Attachments/AttachmentView.vue";

  @Component({
    components: { AttachmentView, UploadAttachment },
  })
  export default class EditPart extends Vue {
    materialRequestId: string;
    partId: string;
    part: PartDto = PartDto.fromJS({});
    attachments: AttachmentDto[] = [];

    created() {
      this.materialRequestId = this.$router.currentRoute.params.materialRequestId;
      this.partId = this.$router.currentRoute.params.partId;
    }

    async mounted() {
      this.$bvModal.show('editPart');
      await this.loadData();
    }

    async loadData() {
      if (this.partId == '0') this.partId = '00000000-0000-0000-0000-000000000000';

      this.part = await materialRequestClient.getPartEditDto(this.materialRequestId, this.partId);
      await this.loadAttachments();
      await this.$nextTick();
      (this.$refs.form as any).validate();
    }

    async loadAttachments() {
      this.attachments = await attachmentClient.getAllAttachments(this.part.id, AttachmentDestination.MaterialPart);
    }

    async savePart() {
      try {
        await materialRequestClient.editPart(this.part);
        GlobalHelper.NotifySuccess('Part saved');
        this.$bvModal.hide('editPart');
      } catch {
        GlobalHelper.NotifyError('Error occured');
      }
    }

    cancel() {
      this.$bvModal.hide('editPart');
    }

    modalClosed() {
      this.$router.push({ name: RouteNames.specialOccurrence.root, params: { occId: this.materialRequestId } });
    }
  }
