
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import AttachmentView from '@/components/Attachments/AttachmentView.vue';
  import UploadAttachment from '@/components/Attachments/UploadAttachment.vue';
  import {
    SpecialOccurrenceAccessLevelDto,
    SpecialOccurrenceAccessLevelUpdateDto,
    SpecialOccurrenceDto
  } from "@/apiclient/apiclient_generated";
  import { GlobalHelper } from '@/helpers/global-helper';

  @Component({
    components: {
      UploadAttachment,
      AttachmentView,
    },
  })
  export default class SpecialOccurrenceAccess extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;

    private get occId() {
      return this.$route.params.occId;
    }

    get occurrence(): SpecialOccurrenceDto {
      return this.store.currentOccurrence;
    }

    get accessLevels(): SpecialOccurrenceAccessLevelDto[] {
      return this.store.currentOccurrenceAccessLevels;
    }

    async setAccess(boardId: string, accessLevel: any) {
      if (boardId == this.occurrence.ownerBoardId)
      {
        GlobalHelper.NotifyError('Access level of the owner board can not be changed - always admin');
      } else {

        const dto = new SpecialOccurrenceAccessLevelUpdateDto();
        dto.accessLevel = accessLevel;
        dto.specialOccurrenceId = this.occId;
        dto.boardId = boardId;
        await this.store.setAccessLevel(dto);
        GlobalHelper.NotifySuccess('Access level set');
      }
    }

    created() {
      this.store.getAccessLevels(this.$route.params.occId);
    }
  }
