
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({
    components: {},
  })
  export default class Users extends Vue {
    private isDirty = false;

    async save() {
      this.isDirty = false;
    }
  }
