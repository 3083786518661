import { DateTime } from 'luxon';

export default function formatDate(value, formatString) {
  if (!value) {
    return value;
  }

  let date;

  switch (typeof value) {
    case 'string':
      date = DateTime.fromISO(value);
      break;

    case 'object':
      date = DateTime.fromISO(value.toISOString());
      break;
  }

  if (!formatString) return date.toFormat('dd.MM.yyyy HH:mm');

  return date.toFormat(formatString);
}
