export default function stripHtml(value) {
  if (!value) {
    return value;
  }

  const div = document.createElement('div')
  value = value.replace(/<\/p>/g, ' ');
  value = value.replace(/<br>/g, ' ');
  div.innerHTML = value
  const text = div.textContent || div.innerText || ''
  return text
}
