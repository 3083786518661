var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"label mt-3 mb-1"},[_vm._v("Priority")]),_c('div',{staticClass:"btn-group d-flex align-items-center",attrs:{"role":"group"}},[_c('button',{staticClass:"btn btn-xs",class:{
        'btn-primary': _vm.item.priority === _vm.priority.Normal,
        'btn-outline-primary': _vm.item.priority !== _vm.priority.Normal,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.updatePriority(_vm.priority.Normal)}}},[_vm._v(" "+_vm._s(_vm.priority.Normal)+" ")]),_c('button',{staticClass:"btn btn-xs",class:{
        'btn-danger': _vm.item.priority === _vm.priority.High,
        'btn-outline-danger': _vm.item.priority !== _vm.priority.High,
      },attrs:{"type":"button"},on:{"click":function($event){return _vm.updatePriority(_vm.priority.High)}}},[_vm._v(" "+_vm._s(_vm.priority.High)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }