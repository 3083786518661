var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{},[_c('div',{},[(_vm.boardItem.changeSets && _vm.boardItem.changeSets.length > 0)?_c('div',{staticClass:"mt-2 mb-2"},[_c('button',{staticClass:"btn btn-block btn-info outline-none",on:{"click":function($event){_vm.toggleBoardItemHistory = !_vm.toggleBoardItemHistory}}},[_c('i',{staticClass:"far fa-clock icon-left"}),_vm._v(" "+_vm._s(_vm.toggleBoardItemHistory ? 'Hide History' : 'Show History')+" ")])]):_vm._e(),(_vm.toggleBoardItemHistory === true)?_c('div',_vm._l((_vm.boardItem.changeSets),function(changes){return _c('div',{key:changes.id},_vm._l((changes.variance),function(change){return _c('div',{key:change.newValue},[(_vm.shouldDisplayChange(change))?_c('div',{staticClass:"change"},[_c('div',{staticClass:"changes-date"},[_c('div',{staticClass:"badge badge-primary bi-badge"},[_vm._v(" "+_vm._s(_vm._f("formatDateTime")(changes.createdDate))+" ")])]),(change.name === _vm.changeTypes.Title || change.name === _vm.changeTypes.Description)?_c('div',[_c('b',[_vm._v(_vm._s(changes.author.firstName))]),_vm._v(" "+_vm._s(' changed ')+" "),_c('b',[_vm._v(_vm._s(change.name))]),_vm._v(" "+_vm._s(' from ' + change.oldValue + ' to ' + change.newValue)+" ")]):_vm._e(),(change.name === _vm.changeTypes.BoardItemStatus)?_c('div',[_c('b',[_vm._v(_vm._s(changes.author.firstName))]),_vm._v(" "+_vm._s('changed ')+" "),_c('b',[_vm._v(_vm._s(change.name))]),_vm._v(" "+_vm._s(' from ')+" "),_c('span',{staticClass:"badge badge-pill",class:{
                  'badge-primary': change.oldValue === _vm.statusType.Planned,
                  'badge-warning': change.oldValue === _vm.statusType.InProgress,
                  'badge-success': change.oldValue === _vm.statusType.Done,
                  'badge-info': change.oldValue === _vm.statusType.Information,
                }},[_vm._v(" "+_vm._s(change.oldValue)+" ")]),_vm._v(" "+_vm._s(' to ')+" "),_c('span',{staticClass:"badge badge-pill",class:{
                  'badge-primary': change.newValue === _vm.statusType.Planned,
                  'badge-warning': change.newValue === _vm.statusType.InProgress,
                  'badge-success': change.newValue === _vm.statusType.Done,
                  'badge-info': change.newValue === _vm.statusType.Information,
                }},[_vm._v(" "+_vm._s(change.newValue)+" ")])]):_vm._e(),(change.name === _vm.changeTypes.AssignedTo)?_c('div',[_c('b',[_vm._v(_vm._s(changes.author.firstName))]),_vm._v(" changed "),_c('b',[_vm._v("Assignment")]),_vm._v(" from "),_c('b',[_vm._v(_vm._s(_vm.getUserName(change.oldValue)))]),_vm._v(" to "),_c('b',[_vm._v(_vm._s(_vm.getUserName(change.newValue)))])]):_vm._e(),(
                change.name === _vm.changeTypes.PlannedDate ||
                change.name === _vm.changeTypes.DueDate ||
                change.name === _vm.changeTypes.ValidFromDate ||
                change.name === _vm.changeTypes.ValidToDate
              )?_c('div',{staticClass:"change"},[_c('b',[_vm._v(_vm._s(changes.author.firstName))]),_vm._v(" changed "),_c('b',[_vm._v(_vm._s(change.name))]),(change.oldValue)?_c('span',[_vm._v(" from "),_c('br'),_c('b',[_vm._v(_vm._s(_vm.getFormattedDate(change.oldValue)))]),_vm._v(" to "),_c('br'),_c('b',[_vm._v(_vm._s(_vm.getFormattedDate(change.newValue)))])]):_c('span',[_vm._v(" to "),_c('b',[_vm._v(_vm._s(_vm.getFormattedDate(change.newValue)))])])]):_vm._e(),(change.name === _vm.changeTypes.BoardItemTypeGuid)?_c('div',[_c('b',[_vm._v(_vm._s(changes.author.firstName))]),_vm._v(" changed "),_c('b',[_vm._v("Category/Type")]),_vm._v(" from "),_c('br'),_c('i',{staticClass:"far",class:_vm.getBoardItemType(change.oldValue).boardItemCategory.icon}),_c('b',[_vm._v(_vm._s(_vm.getBoardItemType(change.oldValue).boardItemCategory.name))]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.getBoardItemType(change.oldValue).name))]),_c('br'),_vm._v(" to "),_c('br'),_c('i',{staticClass:"far",class:_vm.getBoardItemType(change.newValue).boardItemCategory.icon}),_c('b',[_vm._v(_vm._s(_vm.getBoardItemType(change.newValue).boardItemCategory.name))]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.getBoardItemType(change.newValue).name))])]):_vm._e()]):_vm._e()])}),0)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }