export default class OpsBoardIcons {
  static icons = [
    { key: '', label: '' },
    { key: 'fa fa-glass-martini', label: 'glass-martini' },
    { key: 'fa fa-music', label: 'music' },
    { key: 'fa fa-search', label: 'search' },
    { key: 'fa fa-heart', label: 'heart' },
    { key: 'fa fa-star', label: 'star' },
    { key: 'fa fa-user', label: 'user' },
    { key: 'fa fa-film', label: 'film' },
    { key: 'fa fa-th-large', label: 'th-large' },
    { key: 'fa fa-th', label: 'th' },
    { key: 'fa fa-th-list', label: 'th-list' },
    { key: 'fa fa-check', label: 'check' },
    { key: 'fa fa-times', label: 'times' },
    { key: 'fa fa-search-plus', label: 'search-plus' },
    { key: 'fa fa-search-minus', label: 'search-minus' },
    { key: 'fa fa-power-off', label: 'power-off' },
    { key: 'fa fa-signal', label: 'signal' },
    { key: 'fa fa-cog', label: 'cog' },
    { key: 'fa fa-home', label: 'home' },
    { key: 'fa fa-clock', label: 'clock' },
    { key: 'fa fa-road', label: 'road' },
    { key: 'fa fa-download', label: 'download' },
    { key: 'fa fa-inbox', label: 'inbox' },
    { key: 'fa fa-redo', label: 'redo' },
    { key: 'fa fa-sync', label: 'sync' },
    { key: 'fa fa-list-alt', label: 'list-alt' },
    { key: 'fa fa-lock', label: 'lock' },
    { key: 'fa fa-flag', label: 'flag' },
    { key: 'fa fa-headphones', label: 'headphones' },
    { key: 'fa fa-volume-off', label: 'volume-off' },
    { key: 'fa fa-volume-down', label: 'volume-down' },
    { key: 'fa fa-volume-up', label: 'volume-up' },
    { key: 'fa fa-qrcode', label: 'qrcode' },
    { key: 'fa fa-barcode', label: 'barcode' },
    { key: 'fa fa-tag', label: 'tag' },
    { key: 'fa fa-tags', label: 'tags' },
    { key: 'fa fa-book', label: 'book' },
    { key: 'fa fa-bookmark', label: 'bookmark' },
    { key: 'fa fa-print', label: 'print' },
    { key: 'fa fa-camera', label: 'camera' },
    { key: 'fa fa-font', label: 'font' },
    { key: 'fa fa-bold', label: 'bold' },
    { key: 'fa fa-italic', label: 'italic' },
    { key: 'fa fa-text-height', label: 'text-height' },
    { key: 'fa fa-text-width', label: 'text-width' },
    { key: 'fa fa-align-left', label: 'align-left' },
    { key: 'fa fa-align-center', label: 'align-center' },
    { key: 'fa fa-align-right', label: 'align-right' },
    { key: 'fa fa-align-justify', label: 'align-justify' },
    { key: 'fa fa-list', label: 'list' },
    { key: 'fa fa-outdent', label: 'outdent' },
    { key: 'fa fa-indent', label: 'indent' },
    { key: 'fa fa-video', label: 'video' },
    { key: 'fa fa-image', label: 'image' },
    { key: 'fa fa-pencil', label: 'pencil' },
    { key: 'fa fa-map-marker', label: 'map-marker' },
    { key: 'fa fa-adjust', label: 'adjust' },
    { key: 'fa fa-tint', label: 'tint' },
    { key: 'fa fa-edit', label: 'edit' },
    { key: 'fa fa-arrows', label: 'arrows' },
    { key: 'fa fa-step-backward', label: 'step-backward' },
    { key: 'fa fa-fast-backward', label: 'fast-backward' },
    { key: 'fa fa-backward', label: 'backward' },
    { key: 'fa fa-play', label: 'play' },
    { key: 'fa fa-pause', label: 'pause' },
    { key: 'fa fa-stop', label: 'stop' },
    { key: 'fa fa-forward', label: 'forward' },
    { key: 'fa fa-fast-forward', label: 'fast-forward' },
    { key: 'fa fa-step-forward', label: 'step-forward' },
    { key: 'fa fa-eject', label: 'eject' },
    { key: 'fa fa-chevron-left', label: 'chevron-left' },
    { key: 'fa fa-chevron-right', label: 'chevron-right' },
    { key: 'fa fa-plus-circle', label: 'plus-circle' },
    { key: 'fa fa-minus-circle', label: 'minus-circle' },
    { key: 'fa fa-times-circle', label: 'times-circle' },
    { key: 'fa fa-check-circle', label: 'check-circle' },
    { key: 'fa fa-question-circle', label: 'question-circle' },
    { key: 'fa fa-info-circle', label: 'info-circle' },
    { key: 'fa fa-crosshairs', label: 'crosshairs' },
    { key: 'fa fa-ban', label: 'ban' },
    { key: 'fa fa-arrow-left', label: 'arrow-left' },
    { key: 'fa fa-arrow-right', label: 'arrow-right' },
    { key: 'fa fa-arrow-up', label: 'arrow-up' },
    { key: 'fa fa-arrow-down', label: 'arrow-down' },
    { key: 'fa fa-share', label: 'share' },
    { key: 'fa fa-expand', label: 'expand' },
    { key: 'fa fa-compress', label: 'compress' },
    { key: 'fa fa-plus', label: 'plus' },
    { key: 'fa fa-minus', label: 'minus' },
    { key: 'fa fa-asterisk', label: 'asterisk' },
    { key: 'fa fa-exclamation-circle', label: 'exclamation-circle' },
    { key: 'fa fa-gift', label: 'gift' },
    { key: 'fa fa-leaf', label: 'leaf' },
    { key: 'fa fa-fire', label: 'fire' },
    { key: 'fa fa-eye', label: 'eye' },
    { key: 'fa fa-eye-slash', label: 'eye-slash' },
    { key: 'fa fa-exclamation-triangle', label: 'exclamation-triangle' },
    { key: 'fa fa-plane', label: 'plane' },
    { key: 'fa fa-calendar-alt', label: 'calendar-alt' },
    { key: 'fa fa-random', label: 'random' },
    { key: 'fa fa-comment', label: 'comment' },
    { key: 'fa fa-magnet', label: 'magnet' },
    { key: 'fa fa-chevron-up', label: 'chevron-up' },
    { key: 'fa fa-chevron-down', label: 'chevron-down' },
    { key: 'fa fa-retweet', label: 'retweet' },
    { key: 'fa fa-shopping-cart', label: 'shopping-cart' },
    { key: 'fa fa-folder', label: 'folder' },
    { key: 'fa fa-folder-open', label: 'folder-open' },
    { key: 'fa fa-arrows-v', label: 'arrows-v' },
    { key: 'fa fa-arrows-h', label: 'arrows-h' },
    { key: 'fa fa-chart-bar', label: 'chart-bar' },
    { key: 'fa fa-camera-retro', label: 'camera-retro' },
    { key: 'fa fa-key', label: 'key' },
    { key: 'fa fa-cogs', label: 'cogs' },
    { key: 'fa fa-comments', label: 'comments' },
    { key: 'fa fa-star-half', label: 'star-half' },
    { key: 'fa fa-sign-out', label: 'sign-out' },
    { key: 'fa fa-thumbtack', label: 'thumbtack' },
    { key: 'fa fa-external-link', label: 'external-link' },
    { key: 'fa fa-sign-in', label: 'sign-in' },
    { key: 'fa fa-trophy', label: 'trophy' },
    { key: 'fa fa-upload', label: 'upload' },
    { key: 'fa fa-lemon', label: 'lemon' },
    { key: 'fa fa-phone', label: 'phone' },
    { key: 'fa fa-phone-square', label: 'phone-square' },
    { key: 'fa fa-unlock', label: 'unlock' },
    { key: 'fa fa-credit-card', label: 'credit-card' },
    { key: 'fa fa-rss', label: 'rss' },
    { key: 'fa fa-hdd', label: 'hdd' },
    { key: 'fa fa-bullhorn', label: 'bullhorn' },
    { key: 'fa fa-certificate', label: 'certificate' },
    { key: 'fa fa-hand-point-right', label: 'hand-point-right' },
    { key: 'fa fa-hand-point-left', label: 'hand-point-left' },
    { key: 'fa fa-hand-point-up', label: 'hand-point-up' },
    { key: 'fa fa-hand-point-down', label: 'hand-point-down' },
    { key: 'fa fa-arrow-circle-left', label: 'arrow-circle-left' },
    { key: 'fa fa-arrow-circle-right', label: 'arrow-circle-right' },
    { key: 'fa fa-arrow-circle-up', label: 'arrow-circle-up' },
    { key: 'fa fa-arrow-circle-down', label: 'arrow-circle-down' },
    { key: 'fa fa-globe', label: 'globe' },
    { key: 'fa fa-wrench', label: 'wrench' },
    { key: 'fa fa-tasks', label: 'tasks' },
    { key: 'fa fa-filter', label: 'filter' },
    { key: 'fa fa-briefcase', label: 'briefcase' },
    { key: 'fa fa-arrows-alt', label: 'arrows-alt' },
    { key: 'fa fa-users', label: 'users' },
    { key: 'fa fa-link', label: 'link' },
    { key: 'fa fa-cloud', label: 'cloud' },
    { key: 'fa fa-flask', label: 'flask' },
    { key: 'fa fa-cut', label: 'cut' },
    { key: 'fa fa-copy', label: 'copy' },
    { key: 'fa fa-paperclip', label: 'paperclip' },
    { key: 'fa fa-save', label: 'save' },
    { key: 'fa fa-square', label: 'square' },
    { key: 'fa fa-bars', label: 'bars' },
    { key: 'fa fa-list-ul', label: 'list-ul' },
    { key: 'fa fa-list-ol', label: 'list-ol' },
    { key: 'fa fa-strikethrough', label: 'strikethrough' },
    { key: 'fa fa-underline', label: 'underline' },
    { key: 'fa fa-table', label: 'table' },
    { key: 'fa fa-magic', label: 'magic' },
    { key: 'fa fa-truck', label: 'truck' },
    { key: 'fa fa-money-bill', label: 'money-bill' },
    { key: 'fa fa-caret-down', label: 'caret-down' },
    { key: 'fa fa-caret-up', label: 'caret-up' },
    { key: 'fa fa-caret-left', label: 'caret-left' },
    { key: 'fa fa-caret-right', label: 'caret-right' },
    { key: 'fa fa-columns', label: 'columns' },
    { key: 'fa fa-sort', label: 'sort' },
    { key: 'fa fa-sort-down', label: 'sort-down' },
    { key: 'fa fa-sort-up', label: 'sort-up' },
    { key: 'fa fa-envelope', label: 'envelope' },
    { key: 'fa fa-undo', label: 'undo' },
    { key: 'fa fa-gavel', label: 'gavel' },
    { key: 'fa fa-tachometer', label: 'tachometer' },
    { key: 'fa fa-bolt', label: 'bolt' },
    { key: 'fa fa-sitemap', label: 'sitemap' },
    { key: 'fa fa-umbrella', label: 'umbrella' },
    { key: 'fa fa-paste', label: 'paste' },
    { key: 'fa fa-lightbulb', label: 'lightbulb' },
    { key: 'fa fa-exchange', label: 'exchange' },
    { key: 'fa fa-cloud-download', label: 'cloud-download' },
    { key: 'fa fa-cloud-upload', label: 'cloud-upload' },
    { key: 'fa fa-user-md', label: 'user-md' },
    { key: 'fa fa-stethoscope', label: 'stethoscope' },
    { key: 'fa fa-suitcase', label: 'suitcase' },
    { key: 'fa fa-bell', label: 'bell' },
    { key: 'fa fa-coffee', label: 'coffee' },
    { key: 'fa fa-hospital', label: 'hospital' },
    { key: 'fa fa-ambulance', label: 'ambulance' },
    { key: 'fa fa-medkit', label: 'medkit' },
    { key: 'fa fa-fighter-jet', label: 'fighter-jet' },
    { key: 'fa fa-beer', label: 'beer' },
    { key: 'fa fa-h-square', label: 'h-square' },
    { key: 'fa fa-plus-square', label: 'plus-square' },
    { key: 'fa fa-angle-double-left', label: 'angle-double-left' },
    { key: 'fa fa-angle-double-right', label: 'angle-double-right' },
    { key: 'fa fa-angle-double-up', label: 'angle-double-up' },
    { key: 'fa fa-angle-double-down', label: 'angle-double-down' },
    { key: 'fa fa-angle-left', label: 'angle-left' },
    { key: 'fa fa-angle-right', label: 'angle-right' },
    { key: 'fa fa-angle-up', label: 'angle-up' },
    { key: 'fa fa-angle-down', label: 'angle-down' },
    { key: 'fa fa-desktop', label: 'desktop' },
    { key: 'fa fa-laptop', label: 'laptop' },
    { key: 'fa fa-tablet', label: 'tablet' },
    { key: 'fa fa-mobile', label: 'mobile' },
    { key: 'fa fa-quote-left', label: 'quote-left' },
    { key: 'fa fa-quote-right', label: 'quote-right' },
    { key: 'fa fa-spinner', label: 'spinner' },
    { key: 'fa fa-circle', label: 'circle' },
    { key: 'fa fa-smile', label: 'smile' },
    { key: 'fa fa-frown', label: 'frown' },
    { key: 'fa fa-meh', label: 'meh' },
    { key: 'fa fa-gamepad', label: 'gamepad' },
    { key: 'fa fa-keyboard', label: 'keyboard' },
    { key: 'fa fa-flag-checkered', label: 'flag-checkered' },
    { key: 'fa fa-terminal', label: 'terminal' },
    { key: 'fa fa-code', label: 'code' },
    { key: 'fa fa-reply-all', label: 'reply-all' },
    { key: 'fa fa-location-arrow', label: 'location-arrow' },
    { key: 'fa fa-crop', label: 'crop' },
    { key: 'fa fa-code-branch', label: 'code-branch' },
    { key: 'fa fa-unlink', label: 'unlink' },
    { key: 'fa fa-question', label: 'question' },
    { key: 'fa fa-info', label: 'info' },
    { key: 'fa fa-exclamation', label: 'exclamation' },
    { key: 'fa fa-superscript', label: 'superscript' },
    { key: 'fa fa-subscript', label: 'subscript' },
    { key: 'fa fa-eraser', label: 'eraser' },
    { key: 'fa fa-puzzle-piece', label: 'puzzle-piece' },
    { key: 'fa fa-microphone', label: 'microphone' },
    { key: 'fa fa-microphone-slash', label: 'microphone-slash' },
    { key: 'fa fa-shield', label: 'shield' },
    { key: 'fa fa-calendar', label: 'calendar' },
    { key: 'fa fa-fire-extinguisher', label: 'fire-extinguisher' },
    { key: 'fa fa-rocket', label: 'rocket' },
    { key: 'fa fa-chevron-circle-left', label: 'chevron-circle-left' },
    { key: 'fa fa-chevron-circle-right', label: 'chevron-circle-right' },
    { key: 'fa fa-chevron-circle-up', label: 'chevron-circle-up' },
    { key: 'fa fa-chevron-circle-down', label: 'chevron-circle-down' },
    { key: 'fa fa-anchor', label: 'anchor' },
    { key: 'fa fa-unlock-alt', label: 'unlock-alt' },
    { key: 'fa fa-bullseye', label: 'bullseye' },
    { key: 'fa fa-ellipsis-h', label: 'ellipsis-h' },
    { key: 'fa fa-ellipsis-v', label: 'ellipsis-v' },
    { key: 'fa fa-rss-square', label: 'rss-square' },
    { key: 'fa fa-play-circle', label: 'play-circle' },
    { key: 'fa fa-ticket', label: 'ticket' },
    { key: 'fa fa-minus-square', label: 'minus-square' },
    { key: 'fa fa-level-up', label: 'level-up' },
    { key: 'fa fa-level-down', label: 'level-down' },
    { key: 'fa fa-check-square', label: 'check-square' },
    { key: 'fa fa-pen-square', label: 'pen-square' },
    { key: 'fa fa-external-link-square', label: 'external-link-square' },
    { key: 'fa fa-share-square', label: 'share-square' },
    { key: 'fa fa-compass', label: 'compass' },
    { key: 'fa fa-caret-square-down', label: 'caret-square-down' },
    { key: 'fa fa-caret-square-up', label: 'caret-square-up' },
    { key: 'fa fa-caret-square-right', label: 'caret-square-right' },
    { key: 'fa fa-euro-sign', label: 'euro-sign' },
    { key: 'fa fa-pound-sign', label: 'pound-sign' },
    { key: 'fa fa-dollar-sign', label: 'dollar-sign' },
    { key: 'fa fa-rupee-sign', label: 'rupee-sign' },
    { key: 'fa fa-yen-sign', label: 'yen-sign' },
    { key: 'fa fa-ruble-sign', label: 'ruble-sign' },
    { key: 'fa fa-won-sign', label: 'won-sign' },
    { key: 'fa fa-file', label: 'file' },
    { key: 'fa fa-file-alt', label: 'file-alt' },
    { key: 'fa fa-sort-alpha-down', label: 'sort-alpha-down' },
    { key: 'fa fa-sort-alpha-up', label: 'sort-alpha-up' },
    { key: 'fa fa-sort-amount-down', label: 'sort-amount-down' },
    { key: 'fa fa-sort-amount-up', label: 'sort-amount-up' },
    { key: 'fa fa-sort-numeric-down', label: 'sort-numeric-down' },
    { key: 'fa fa-sort-numeric-up', label: 'sort-numeric-up' },
    { key: 'fa fa-thumbs-up', label: 'thumbs-up' },
    { key: 'fa fa-thumbs-down', label: 'thumbs-down' },
    { key: 'fa fa-long-arrow-down', label: 'long-arrow-down' },
    { key: 'fa fa-long-arrow-up', label: 'long-arrow-up' },
    { key: 'fa fa-long-arrow-left', label: 'long-arrow-left' },
    { key: 'fa fa-long-arrow-right', label: 'long-arrow-right' },
    { key: 'fa fa-female', label: 'female' },
    { key: 'fa fa-male', label: 'male' },
    { key: 'fa fa-sun', label: 'sun' },
    { key: 'fa fa-moon', label: 'moon' },
    { key: 'fa fa-archive', label: 'archive' },
    { key: 'fa fa-bug', label: 'bug' },
    { key: 'fa fa-caret-square-left', label: 'caret-square-left' },
    { key: 'fa fa-dot-circle', label: 'dot-circle' },
    { key: 'fa fa-wheelchair', label: 'wheelchair' },
    { key: 'fa fa-lira-sign', label: 'lira-sign' },
    { key: 'fa fa-space-shuttle', label: 'space-shuttle' },
    { key: 'fa fa-envelope-square', label: 'envelope-square' },
    { key: 'fa fa-university', label: 'university' },
    { key: 'fa fa-graduation-cap', label: 'graduation-cap' },
    { key: 'fa fa-language', label: 'language' },
    { key: 'fa fa-fax', label: 'fax' },
    { key: 'fa fa-building', label: 'building' },
    { key: 'fa fa-child', label: 'child' },
    { key: 'fa fa-paw', label: 'paw' },
    { key: 'fa fa-cube', label: 'cube' },
    { key: 'fa fa-cubes', label: 'cubes' },
    { key: 'fa fa-recycle', label: 'recycle' },
    { key: 'fa fa-car', label: 'car' },
    { key: 'fa fa-taxi', label: 'taxi' },
    { key: 'fa fa-tree', label: 'tree' },
    { key: 'fa fa-database', label: 'database' },
    { key: 'fa fa-file-pdf', label: 'file-pdf' },
    { key: 'fa fa-file-word', label: 'file-word' },
    { key: 'fa fa-file-excel', label: 'file-excel' },
    { key: 'fa fa-file-powerpoint', label: 'file-powerpoint' },
    { key: 'fa fa-file-image', label: 'file-image' },
    { key: 'fa fa-file-archive', label: 'file-archive' },
    { key: 'fa fa-file-audio', label: 'file-audio' },
    { key: 'fa fa-file-video', label: 'file-video' },
    { key: 'fa fa-file-code', label: 'file-code' },
    { key: 'fa fa-life-ring', label: 'life-ring' },
    { key: 'fa fa-circle-notch', label: 'circle-notch' },
    { key: 'fa fa-paper-plane', label: 'paper-plane' },
    { key: 'fa fa-history', label: 'history' },
    { key: 'fa fa-heading', label: 'heading' },
    { key: 'fa fa-paragraph', label: 'paragraph' },
    { key: 'fa fa-sliders-h', label: 'sliders-h' },
    { key: 'fa fa-share-alt', label: 'share-alt' },
    { key: 'fa fa-share-alt-square', label: 'share-alt-square' },
    { key: 'fa fa-bomb', label: 'bomb' },
    { key: 'fa fa-futbol', label: 'futbol' },
    { key: 'fa fa-tty', label: 'tty' },
    { key: 'fa fa-binoculars', label: 'binoculars' },
    { key: 'fa fa-plug', label: 'plug' },
    { key: 'fa fa-newspaper', label: 'newspaper' },
    { key: 'fa fa-wifi', label: 'wifi' },
    { key: 'fa fa-calculator', label: 'calculator' },
    { key: 'fa fa-bell-slash', label: 'bell-slash' },
    { key: 'fa fa-trash', label: 'trash' },
    { key: 'fa fa-copyright', label: 'copyright' },
    { key: 'fa fa-at', label: 'at' },
    { key: 'fa fa-eye-dropper', label: 'eye-dropper' },
    { key: 'fa fa-paint-brush', label: 'paint-brush' },
    { key: 'fa fa-birthday-cake', label: 'birthday-cake' },
    { key: 'fa fa-chart-area', label: 'chart-area' },
    { key: 'fa fa-chart-pie', label: 'chart-pie' },
    { key: 'fa fa-chart-line', label: 'chart-line' },
    { key: 'fa fa-toggle-off', label: 'toggle-off' },
    { key: 'fa fa-toggle-on', label: 'toggle-on' },
    { key: 'fa fa-bicycle', label: 'bicycle' },
    { key: 'fa fa-bus', label: 'bus' },
    { key: 'fa fa-closed-captioning', label: 'closed-captioning' },
    { key: 'fa fa-shekel-sign', label: 'shekel-sign' },
    { key: 'fa fa-cart-plus', label: 'cart-plus' },
    { key: 'fa fa-cart-arrow-down', label: 'cart-arrow-down' },
    { key: 'fa fa-diamond', label: 'diamond' },
    { key: 'fa fa-ship', label: 'ship' },
    { key: 'fa fa-user-secret', label: 'user-secret' },
    { key: 'fa fa-motorcycle', label: 'motorcycle' },
    { key: 'fa fa-street-view', label: 'street-view' },
    { key: 'fa fa-heartbeat', label: 'heartbeat' },
    { key: 'fa fa-venus', label: 'venus' },
    { key: 'fa fa-mars', label: 'mars' },
    { key: 'fa fa-mercury', label: 'mercury' },
    { key: 'fa fa-transgender', label: 'transgender' },
    { key: 'fa fa-transgender-alt', label: 'transgender-alt' },
    { key: 'fa fa-venus-double', label: 'venus-double' },
    { key: 'fa fa-mars-double', label: 'mars-double' },
    { key: 'fa fa-venus-mars', label: 'venus-mars' },
    { key: 'fa fa-mars-stroke', label: 'mars-stroke' },
    { key: 'fa fa-mars-stroke-v', label: 'mars-stroke-v' },
    { key: 'fa fa-mars-stroke-h', label: 'mars-stroke-h' },
    { key: 'fa fa-neuter', label: 'neuter' },
    { key: 'fa fa-genderless', label: 'genderless' },
    { key: 'fa fa-server', label: 'server' },
    { key: 'fa fa-user-plus', label: 'user-plus' },
    { key: 'fa fa-user-times', label: 'user-times' },
    { key: 'fa fa-bed', label: 'bed' },
    { key: 'fa fa-train', label: 'train' },
    { key: 'fa fa-subway', label: 'subway' },
    { key: 'fa fa-battery-full', label: 'battery-full' },
    { key: 'fa fa-battery-three-quarters', label: 'battery-three-quarters' },
    { key: 'fa fa-battery-half', label: 'battery-half' },
    { key: 'fa fa-battery-quarter', label: 'battery-quarter' },
    { key: 'fa fa-battery-empty', label: 'battery-empty' },
    { key: 'fa fa-mouse-pointer', label: 'mouse-pointer' },
    { key: 'fa fa-i-cursor', label: 'i-cursor' },
    { key: 'fa fa-object-group', label: 'object-group' },
    { key: 'fa fa-object-ungroup', label: 'object-ungroup' },
    { key: 'fa fa-sticky-note', label: 'sticky-note' },
    { key: 'fa fa-clone', label: 'clone' },
    { key: 'fa fa-balance-scale', label: 'balance-scale' },
    { key: 'fa fa-hourglass-start', label: 'hourglass-start' },
    { key: 'fa fa-hourglass-half', label: 'hourglass-half' },
    { key: 'fa fa-hourglass-end', label: 'hourglass-end' },
    { key: 'fa fa-hourglass', label: 'hourglass' },
    { key: 'fa fa-hand-rock', label: 'hand-rock' },
    { key: 'fa fa-hand-paper', label: 'hand-paper' },
    { key: 'fa fa-hand-scissors', label: 'hand-scissors' },
    { key: 'fa fa-hand-lizard', label: 'hand-lizard' },
    { key: 'fa fa-hand-spock', label: 'hand-spock' },
    { key: 'fa fa-hand-pointer', label: 'hand-pointer' },
    { key: 'fa fa-hand-peace', label: 'hand-peace' },
    { key: 'fa fa-trademark', label: 'trademark' },
    { key: 'fa fa-registered', label: 'registered' },
    { key: 'fa fa-tv', label: 'tv' },
    { key: 'fa fa-calendar-plus', label: 'calendar-plus' },
    { key: 'fa fa-calendar-minus', label: 'calendar-minus' },
    { key: 'fa fa-calendar-times', label: 'calendar-times' },
    { key: 'fa fa-calendar-check', label: 'calendar-check' },
    { key: 'fa fa-industry', label: 'industry' },
    { key: 'fa fa-map-pin', label: 'map-pin' },
    { key: 'fa fa-map-signs', label: 'map-signs' },
    { key: 'fa fa-map', label: 'map' },
    { key: 'fa fa-comment-alt', label: 'comment-alt' },
    { key: 'fa fa-pause-circle', label: 'pause-circle' },
    { key: 'fa fa-stop-circle', label: 'stop-circle' },
    { key: 'fa fa-shopping-bag', label: 'shopping-bag' },
    { key: 'fa fa-shopping-basket', label: 'shopping-basket' },
    { key: 'fa fa-hashtag', label: 'hashtag' },
    { key: 'fa fa-percent', label: 'percent' },
    { key: 'fa fa-universal-access', label: 'universal-access' },
    { key: 'fa fa-blind', label: 'blind' },
    { key: 'fa fa-audio-description', label: 'audio-description' },
    { key: 'fa fa-phone-volume', label: 'phone-volume' },
    { key: 'fa fa-braille', label: 'braille' },
    { key: 'fa fa-assistive-listening-systems', label: 'assistive-listening-systems' },
    { key: 'fa fa-american-sign-language-interpreting', label: 'american-sign-language-interpreting' },
    { key: 'fa fa-deaf', label: 'deaf' },
    { key: 'fa fa-sign-language', label: 'sign-language' },
    { key: 'fa fa-low-vision', label: 'low-vision' },
    { key: 'fa fa-handshake', label: 'handshake' },
    { key: 'fa fa-envelope-open', label: 'envelope-open' },
    { key: 'fa fa-address-book', label: 'address-book' },
    { key: 'fa fa-address-card', label: 'address-card' },
    { key: 'fa fa-user-circle', label: 'user-circle' },
    { key: 'fa fa-id-badge', label: 'id-badge' },
    { key: 'fa fa-id-card', label: 'id-card' },
    { key: 'fa fa-thermometer-full', label: 'thermometer-full' },
    { key: 'fa fa-thermometer-three-quarters', label: 'thermometer-three-quarters' },
    { key: 'fa fa-thermometer-half', label: 'thermometer-half' },
    { key: 'fa fa-thermometer-quarter', label: 'thermometer-quarter' },
    { key: 'fa fa-thermometer-empty', label: 'thermometer-empty' },
    { key: 'fa fa-shower', label: 'shower' },
    { key: 'fa fa-bath', label: 'bath' },
    { key: 'fa fa-podcast', label: 'podcast' },
    { key: 'fa fa-window-maximize', label: 'window-maximize' },
    { key: 'fa fa-window-minimize', label: 'window-minimize' },
    { key: 'fa fa-window-restore', label: 'window-restore' },
    { key: 'fa fa-times-square', label: 'times-square' },
    { key: 'fa fa-microchip', label: 'microchip' },
    { key: 'fa fa-snowflake', label: 'snowflake' },
    { key: 'fa fa-watch', label: 'watch' },
    { key: 'fa fa-volume-slash', label: 'volume-slash' },
    { key: 'fa fa-utensil-fork', label: 'utensil-fork' },
    { key: 'fa fa-utensil-knife', label: 'utensil-knife' },
    { key: 'fa fa-utensil-spoon', label: 'utensil-spoon' },
    { key: 'fa fa-utensils-alt', label: 'utensils-alt' },
    { key: 'fa fa-utensils', label: 'utensils' },
    { key: 'fa fa-usd-circle', label: 'usd-circle' },
    { key: 'fa fa-usd-square', label: 'usd-square' },
    { key: 'fa fa-undo-alt', label: 'undo-alt' },
    { key: 'fa fa-trophy-alt', label: 'trophy-alt' },
    { key: 'fa fa-triangle', label: 'triangle' },
    { key: 'fa fa-trash-alt', label: 'trash-alt' },
    { key: 'fa fa-times-hexagon', label: 'times-hexagon' },
    { key: 'fa fa-times-octagon', label: 'times-octagon' },
    { key: 'fa fa-sync-alt', label: 'sync-alt' },
    { key: 'fa fa-stopwatch', label: 'stopwatch' },
    { key: 'fa fa-star-exclamation', label: 'star-exclamation' },
    { key: 'fa fa-spade', label: 'spade' },
    { key: 'fa fa-sign-out-alt', label: 'sign-out-alt' },
    { key: 'fa fa-sign-in-alt', label: 'sign-in-alt' },
    { key: 'fa fa-shield-check', label: 'shield-check' },
    { key: 'fa fa-scrubber', label: 'scrubber' },
    { key: 'fa fa-redo-alt', label: 'redo-alt' },
    { key: 'fa fa-rectangle-landscape', label: 'rectangle-landscape' },
    { key: 'fa fa-rectangle-portrait', label: 'rectangle-portrait' },
    { key: 'fa fa-rectangle-wide', label: 'rectangle-wide' },
    { key: 'fa fa-question-square', label: 'question-square' },
    { key: 'fa fa-poo', label: 'poo' },
    { key: 'fa fa-plus-hexagon', label: 'plus-hexagon' },
    { key: 'fa fa-plus-octagon', label: 'plus-octagon' },
    { key: 'fa fa-images', label: 'images' },
    { key: 'fa fa-pencil-alt', label: 'pencil-alt' },
    { key: 'fa fa-pen', label: 'pen' },
    { key: 'fa fa-pen-alt', label: 'pen-alt' },
    { key: 'fa fa-octagon', label: 'octagon' },
    { key: 'fa fa-minus-hexagon', label: 'minus-hexagon' },
    { key: 'fa fa-minus-octagon', label: 'minus-octagon' },
    { key: 'fa fa-long-arrow-alt-down', label: 'long-arrow-alt-down' },
    { key: 'fa fa-long-arrow-alt-left', label: 'long-arrow-alt-left' },
    { key: 'fa fa-long-arrow-alt-right', label: 'long-arrow-alt-right' },
    { key: 'fa fa-long-arrow-alt-up', label: 'long-arrow-alt-up' },
    { key: 'fa fa-lock-alt', label: 'lock-alt' },
    { key: 'fa fa-jack-o-lantern', label: 'jack-o-lantern' },
    { key: 'fa fa-info-square', label: 'info-square' },
    { key: 'fa fa-inbox-in', label: 'inbox-in' },
    { key: 'fa fa-inbox-out', label: 'inbox-out' },
    { key: 'fa fa-hexagon', label: 'hexagon' },
    { key: 'fa fa-h1', label: 'h1' },
    { key: 'fa fa-h2', label: 'h2' },
    { key: 'fa fa-h3', label: 'h3' },
    { key: 'fa fa-file-check', label: 'file-check' },
    { key: 'fa fa-file-times', label: 'file-times' },
    { key: 'fa fa-file-minus', label: 'file-minus' },
    { key: 'fa fa-file-plus', label: 'file-plus' },
    { key: 'fa fa-file-exclamation', label: 'file-exclamation' },
    { key: 'fa fa-file-edit', label: 'file-edit' },
    { key: 'fa fa-expand-arrows', label: 'expand-arrows' },
    { key: 'fa fa-expand-arrows-alt', label: 'expand-arrows-alt' },
    { key: 'fa fa-expand-wide', label: 'expand-wide' },
    { key: 'fa fa-exclamation-square', label: 'exclamation-square' },
    { key: 'fa fa-chevron-double-down', label: 'chevron-double-down' },
    { key: 'fa fa-chevron-double-left', label: 'chevron-double-left' },
    { key: 'fa fa-chevron-double-right', label: 'chevron-double-right' },
    { key: 'fa fa-chevron-double-up', label: 'chevron-double-up' },
    { key: 'fa fa-compress-wide', label: 'compress-wide' },
    { key: 'fa fa-club', label: 'club' },
    { key: 'fa fa-clipboard', label: 'clipboard' },
    { key: 'fa fa-chevron-square-down', label: 'chevron-square-down' },
    { key: 'fa fa-chevron-square-left', label: 'chevron-square-left' },
    { key: 'fa fa-chevron-square-right', label: 'chevron-square-right' },
    { key: 'fa fa-chevron-square-up', label: 'chevron-square-up' },
    { key: 'fa fa-caret-circle-down', label: 'caret-circle-down' },
    { key: 'fa fa-caret-circle-left', label: 'caret-circle-left' },
    { key: 'fa fa-caret-circle-right', label: 'caret-circle-right' },
    { key: 'fa fa-caret-circle-up', label: 'caret-circle-up' },
    { key: 'fa fa-camera-alt', label: 'camera-alt' },
    { key: 'fa fa-calendar-edit', label: 'calendar-edit' },
    { key: 'fa fa-calendar-exclamation', label: 'calendar-exclamation' },
    { key: 'fa fa-badge', label: 'badge' },
    { key: 'fa fa-badge-check', label: 'badge-check' },
    { key: 'fa fa-arrows-alt-h', label: 'arrows-alt-h' },
    { key: 'fa fa-arrows-alt-v', label: 'arrows-alt-v' },
    { key: 'fa fa-arrow-square-down', label: 'arrow-square-down' },
    { key: 'fa fa-arrow-square-left', label: 'arrow-square-left' },
    { key: 'fa fa-arrow-square-right', label: 'arrow-square-right' },
    { key: 'fa fa-arrow-square-up', label: 'arrow-square-up' },
    { key: 'fa fa-arrow-to-bottom', label: 'arrow-to-bottom' },
    { key: 'fa fa-arrow-to-left', label: 'arrow-to-left' },
    { key: 'fa fa-arrow-to-right', label: 'arrow-to-right' },
    { key: 'fa fa-arrow-to-top', label: 'arrow-to-top' },
    { key: 'fa fa-arrow-from-bottom', label: 'arrow-from-bottom' },
    { key: 'fa fa-arrow-from-left', label: 'arrow-from-left' },
    { key: 'fa fa-arrow-from-right', label: 'arrow-from-right' },
    { key: 'fa fa-arrow-from-top', label: 'arrow-from-top' },
    { key: 'fa fa-arrow-alt-from-bottom', label: 'arrow-alt-from-bottom' },
    { key: 'fa fa-arrow-alt-from-left', label: 'arrow-alt-from-left' },
    { key: 'fa fa-arrow-alt-from-right', label: 'arrow-alt-from-right' },
    { key: 'fa fa-arrow-alt-from-top', label: 'arrow-alt-from-top' },
    { key: 'fa fa-arrow-alt-to-bottom', label: 'arrow-alt-to-bottom' },
    { key: 'fa fa-arrow-alt-to-left', label: 'arrow-alt-to-left' },
    { key: 'fa fa-arrow-alt-to-right', label: 'arrow-alt-to-right' },
    { key: 'fa fa-arrow-alt-to-top', label: 'arrow-alt-to-top' },
    { key: 'fa fa-alarm-clock', label: 'alarm-clock' },
    { key: 'fa fa-arrow-alt-square-down', label: 'arrow-alt-square-down' },
    { key: 'fa fa-arrow-alt-square-left', label: 'arrow-alt-square-left' },
    { key: 'fa fa-arrow-alt-square-right', label: 'arrow-alt-square-right' },
    { key: 'fa fa-arrow-alt-square-up', label: 'arrow-alt-square-up' },
    { key: 'fa fa-arrow-alt-down', label: 'arrow-alt-down' },
    { key: 'fa fa-arrow-alt-left', label: 'arrow-alt-left' },
    { key: 'fa fa-arrow-alt-right', label: 'arrow-alt-right' },
    { key: 'fa fa-arrow-alt-up', label: 'arrow-alt-up' },
    { key: 'fa fa-arrow-alt-circle-down', label: 'arrow-alt-circle-down' },
    { key: 'fa fa-arrow-alt-circle-left', label: 'arrow-alt-circle-left' },
    { key: 'fa fa-arrow-alt-circle-right', label: 'arrow-alt-circle-right' },
    { key: 'fa fa-arrow-alt-circle-up', label: 'arrow-alt-circle-up' },
    { key: 'fa fa-external-link-alt', label: 'external-link-alt' },
    { key: 'fa fa-external-link-square-alt', label: 'external-link-square-alt' },
    { key: 'fa fa-retweet-alt', label: 'retweet-alt' },
    { key: 'fa fa-exchange-alt', label: 'exchange-alt' },
    { key: 'fa fa-repeat', label: 'repeat' },
    { key: 'fa fa-repeat-alt', label: 'repeat-alt' },
    { key: 'fa fa-repeat-1', label: 'repeat-1' },
    { key: 'fa fa-repeat-1-alt', label: 'repeat-1-alt' },
    { key: 'fa fa-share-all', label: 'share-all' },
    { key: 'fa fa-battery-bolt', label: 'battery-bolt' },
    { key: 'fa fa-battery-slash', label: 'battery-slash' },
    { key: 'fa fa-browser', label: 'browser' },
    { key: 'fa fa-cloud-download-alt', label: 'cloud-download-alt' },
    { key: 'fa fa-cloud-upload-alt', label: 'cloud-upload-alt' },
    { key: 'fa fa-code-commit', label: 'code-commit' },
    { key: 'fa fa-code-merge', label: 'code-merge' },
    { key: 'fa fa-credit-card-blank', label: 'credit-card-blank' },
    { key: 'fa fa-credit-card-front', label: 'credit-card-front' },
    { key: 'fa fa-desktop-alt', label: 'desktop-alt' },
    { key: 'fa fa-ellipsis-h-alt', label: 'ellipsis-h-alt' },
    { key: 'fa fa-ellipsis-v-alt', label: 'ellipsis-v-alt' },
    { key: 'fa fa-film-alt', label: 'film-alt' },
    { key: 'fa fa-gem', label: 'gem' },
    { key: 'fa fa-industry-alt', label: 'industry-alt' },
    { key: 'fa fa-level-down-alt', label: 'level-down-alt' },
    { key: 'fa fa-level-up-alt', label: 'level-up-alt' },
    { key: 'fa fa-lock-open', label: 'lock-open' },
    { key: 'fa fa-lock-open-alt', label: 'lock-open-alt' },
    { key: 'fa fa-map-marker-alt', label: 'map-marker-alt' },
    { key: 'fa fa-microphone-alt', label: 'microphone-alt' },
    { key: 'fa fa-mobile-alt', label: 'mobile-alt' },
    { key: 'fa fa-mobile-android', label: 'mobile-android' },
    { key: 'fa fa-mobile-android-alt', label: 'mobile-android-alt' },
    { key: 'fa fa-money-bill-alt', label: 'money-bill-alt' },
    { key: 'fa fa-phone-slash', label: 'phone-slash' },
    { key: 'fa fa-plane-alt', label: 'plane-alt' },
    { key: 'fa fa-portrait', label: 'portrait' },
    { key: 'fa fa-reply', label: 'reply' },
    { key: 'fa fa-shield-alt', label: 'shield-alt' },
    { key: 'fa fa-sliders-h-square', label: 'sliders-h-square' },
    { key: 'fa fa-sliders-v', label: 'sliders-v' },
    { key: 'fa fa-sliders-v-square', label: 'sliders-v-square' },
    { key: 'fa fa-spinner-third', label: 'spinner-third' },
    { key: 'fa fa-tablet-alt', label: 'tablet-alt' },
    { key: 'fa fa-tablet-android', label: 'tablet-android' },
    { key: 'fa fa-tablet-android-alt', label: 'tablet-android-alt' },
    { key: 'fa fa-tachometer-alt', label: 'tachometer-alt' },
    { key: 'fa fa-ticket-alt', label: 'ticket-alt' },
    { key: 'fa fa-tree-alt', label: 'tree-alt' },
    { key: 'fa fa-tv-retro', label: 'tv-retro' },
    { key: 'fa fa-user-alt', label: 'user-alt' },
    { key: 'fa fa-window', label: 'window' },
    { key: 'fa fa-window-alt', label: 'window-alt' },
    { key: 'fa fa-window-close', label: 'window-close' },
    { key: 'fa fa-compress-alt', label: 'compress-alt' },
    { key: 'fa fa-expand-alt', label: 'expand-alt' },
    { key: 'fa fa-baseball', label: 'baseball' },
    { key: 'fa fa-baseball-ball', label: 'baseball-ball' },
    { key: 'fa fa-basketball-ball', label: 'basketball-ball' },
    { key: 'fa fa-basketball-hoop', label: 'basketball-hoop' },
    { key: 'fa fa-bowling-ball', label: 'bowling-ball' },
    { key: 'fa fa-bowling-pins', label: 'bowling-pins' },
    { key: 'fa fa-boxing-glove', label: 'boxing-glove' },
    { key: 'fa fa-chess', label: 'chess' },
    { key: 'fa fa-chess-bishop', label: 'chess-bishop' },
    { key: 'fa fa-chess-bishop-alt', label: 'chess-bishop-alt' },
    { key: 'fa fa-chess-board', label: 'chess-board' },
    { key: 'fa fa-chess-clock', label: 'chess-clock' },
    { key: 'fa fa-chess-clock-alt', label: 'chess-clock-alt' },
    { key: 'fa fa-chess-king', label: 'chess-king' },
    { key: 'fa fa-chess-king-alt', label: 'chess-king-alt' },
    { key: 'fa fa-chess-knight', label: 'chess-knight' },
    { key: 'fa fa-chess-knight-alt', label: 'chess-knight-alt' },
    { key: 'fa fa-chess-pawn', label: 'chess-pawn' },
    { key: 'fa fa-chess-pawn-alt', label: 'chess-pawn-alt' },
    { key: 'fa fa-chess-queen', label: 'chess-queen' },
    { key: 'fa fa-chess-queen-alt', label: 'chess-queen-alt' },
    { key: 'fa fa-chess-rook', label: 'chess-rook' },
    { key: 'fa fa-chess-rook-alt', label: 'chess-rook-alt' },
    { key: 'fa fa-cricket', label: 'cricket' },
    { key: 'fa fa-curling', label: 'curling' },
    { key: 'fa fa-dumbbell', label: 'dumbbell' },
    { key: 'fa fa-field-hockey', label: 'field-hockey' },
    { key: 'fa fa-football-ball', label: 'football-ball' },
    { key: 'fa fa-football-helmet', label: 'football-helmet' },
    { key: 'fa fa-golf-ball', label: 'golf-ball' },
    { key: 'fa fa-golf-club', label: 'golf-club' },
    { key: 'fa fa-hockey-puck', label: 'hockey-puck' },
    { key: 'fa fa-hockey-sticks', label: 'hockey-sticks' },
    { key: 'fa fa-luchador', label: 'luchador' },
    { key: 'fa fa-pennant', label: 'pennant' },
    { key: 'fa fa-quidditch', label: 'quidditch' },
    { key: 'fa fa-racquet', label: 'racquet' },
    { key: 'fa fa-shuttlecock', label: 'shuttlecock' },
    { key: 'fa fa-square-full', label: 'square-full' },
    { key: 'fa fa-table-tennis', label: 'table-tennis' },
    { key: 'fa fa-tennis-ball', label: 'tennis-ball' },
    { key: 'fa fa-volleyball-ball', label: 'volleyball-ball' },
    { key: 'fa fa-whistle', label: 'whistle' },
    { key: 'fa fa-allergies', label: 'allergies' },
    { key: 'fa fa-band-aid', label: 'band-aid' },
    { key: 'fa fa-barcode-alt', label: 'barcode-alt' },
    { key: 'fa fa-barcode-read', label: 'barcode-read' },
    { key: 'fa fa-barcode-scan', label: 'barcode-scan' },
    { key: 'fa fa-box', label: 'box' },
    { key: 'fa fa-box-check', label: 'box-check' },
    { key: 'fa fa-boxes', label: 'boxes' },
    { key: 'fa fa-briefcase-medical', label: 'briefcase-medical' },
    { key: 'fa fa-burn', label: 'burn' },
    { key: 'fa fa-capsules', label: 'capsules' },
    { key: 'fa fa-clipboard-check', label: 'clipboard-check' },
    { key: 'fa fa-clipboard-list', label: 'clipboard-list' },
    { key: 'fa fa-conveyor-belt', label: 'conveyor-belt' },
    { key: 'fa fa-conveyor-belt-alt', label: 'conveyor-belt-alt' },
    { key: 'fa fa-diagnoses', label: 'diagnoses' },
    { key: 'fa fa-dna', label: 'dna' },
    { key: 'fa fa-dolly', label: 'dolly' },
    { key: 'fa fa-dolly-empty', label: 'dolly-empty' },
    { key: 'fa fa-dolly-flatbed', label: 'dolly-flatbed' },
    { key: 'fa fa-dolly-flatbed-alt', label: 'dolly-flatbed-alt' },
    { key: 'fa fa-dolly-flatbed-empty', label: 'dolly-flatbed-empty' },
    { key: 'fa fa-file-medical', label: 'file-medical' },
    { key: 'fa fa-file-medical-alt', label: 'file-medical-alt' },
    { key: 'fa fa-first-aid', label: 'first-aid' },
    { key: 'fa fa-forklift', label: 'forklift' },
    { key: 'fa fa-hand-holding-box', label: 'hand-holding-box' },
    { key: 'fa fa-hand-receiving', label: 'hand-receiving' },
    { key: 'fa fa-hospital-alt', label: 'hospital-alt' },
    { key: 'fa fa-hospital-symbol', label: 'hospital-symbol' },
    { key: 'fa fa-id-card-alt', label: 'id-card-alt' },
    { key: 'fa fa-inventory', label: 'inventory' },
    { key: 'fa fa-notes-medical', label: 'notes-medical' },
    { key: 'fa fa-pallet', label: 'pallet' },
    { key: 'fa fa-pallet-alt', label: 'pallet-alt' },
    { key: 'fa fa-pills', label: 'pills' },
    { key: 'fa fa-prescription-bottle', label: 'prescription-bottle' },
    { key: 'fa fa-prescription-bottle-alt', label: 'prescription-bottle-alt' },
    { key: 'fa fa-procedures', label: 'procedures' },
    { key: 'fa fa-scanner', label: 'scanner' },
    { key: 'fa fa-scanner-keyboard', label: 'scanner-keyboard' },
    { key: 'fa fa-scanner-touchscreen', label: 'scanner-touchscreen' },
    { key: 'fa fa-shipping-fast', label: 'shipping-fast' },
    { key: 'fa fa-shipping-timed', label: 'shipping-timed' },
    { key: 'fa fa-smoking', label: 'smoking' },
    { key: 'fa fa-syringe', label: 'syringe' },
    { key: 'fa fa-tablet-rugged', label: 'tablet-rugged' },
    { key: 'fa fa-tablets', label: 'tablets' },
    { key: 'fa fa-thermometer', label: 'thermometer' },
    { key: 'fa fa-vial', label: 'vial' },
    { key: 'fa fa-vials', label: 'vials' },
    { key: 'fa fa-warehouse', label: 'warehouse' },
    { key: 'fa fa-warehouse-alt', label: 'warehouse-alt' },
    { key: 'fa fa-weight', label: 'weight' },
    { key: 'fa fa-x-ray', label: 'x-ray' },
    { key: 'fa fa-blanket', label: 'blanket' },
    { key: 'fa fa-book-heart', label: 'book-heart' },
    { key: 'fa fa-box-alt', label: 'box-alt' },
    { key: 'fa fa-box-fragile', label: 'box-fragile' },
    { key: 'fa fa-box-full', label: 'box-full' },
    { key: 'fa fa-box-heart', label: 'box-heart' },
    { key: 'fa fa-box-open', label: 'box-open' },
    { key: 'fa fa-box-up', label: 'box-up' },
    { key: 'fa fa-box-usd', label: 'box-usd' },
    { key: 'fa fa-boxes-alt', label: 'boxes-alt' },
    { key: 'fa fa-comment-alt-check', label: 'comment-alt-check' },
    { key: 'fa fa-comment-alt-dots', label: 'comment-alt-dots' },
    { key: 'fa fa-comment-alt-edit', label: 'comment-alt-edit' },
    { key: 'fa fa-comment-alt-exclamation', label: 'comment-alt-exclamation' },
    { key: 'fa fa-comment-alt-lines', label: 'comment-alt-lines' },
    { key: 'fa fa-comment-alt-minus', label: 'comment-alt-minus' },
    { key: 'fa fa-comment-alt-plus', label: 'comment-alt-plus' },
    { key: 'fa fa-comment-alt-slash', label: 'comment-alt-slash' },
    { key: 'fa fa-comment-alt-smile', label: 'comment-alt-smile' },
    { key: 'fa fa-comment-alt-times', label: 'comment-alt-times' },
    { key: 'fa fa-comment-check', label: 'comment-check' },
    { key: 'fa fa-comment-dots', label: 'comment-dots' },
    { key: 'fa fa-comment-edit', label: 'comment-edit' },
    { key: 'fa fa-comment-exclamation', label: 'comment-exclamation' },
    { key: 'fa fa-comment-lines', label: 'comment-lines' },
    { key: 'fa fa-comment-minus', label: 'comment-minus' },
    { key: 'fa fa-comment-plus', label: 'comment-plus' },
    { key: 'fa fa-comment-slash', label: 'comment-slash' },
    { key: 'fa fa-comment-smile', label: 'comment-smile' },
    { key: 'fa fa-comment-times', label: 'comment-times' },
    { key: 'fa fa-comments-alt', label: 'comments-alt' },
    { key: 'fa fa-container-storage', label: 'container-storage' },
    { key: 'fa fa-couch', label: 'couch' },
    { key: 'fa fa-donate', label: 'donate' },
    { key: 'fa fa-dove', label: 'dove' },
    { key: 'fa fa-fragile', label: 'fragile' },
    { key: 'fa fa-hand-heart', label: 'hand-heart' },
    { key: 'fa fa-hand-holding', label: 'hand-holding' },
    { key: 'fa fa-hand-holding-heart', label: 'hand-holding-heart' },
    { key: 'fa fa-hand-holding-seedling', label: 'hand-holding-seedling' },
    { key: 'fa fa-hand-holding-usd', label: 'hand-holding-usd' },
    { key: 'fa fa-hand-holding-water', label: 'hand-holding-water' },
    { key: 'fa fa-hands', label: 'hands' },
    { key: 'fa fa-hands-heart', label: 'hands-heart' },
    { key: 'fa fa-hands-helping', label: 'hands-helping' },
    { key: 'fa fa-hands-usd', label: 'hands-usd' },
    { key: 'fa fa-handshake-alt', label: 'handshake-alt' },
    { key: 'fa fa-heart-circle', label: 'heart-circle' },
    { key: 'fa fa-heart-square', label: 'heart-square' },
    { key: 'fa fa-home-heart', label: 'home-heart' },
    { key: 'fa fa-lamp', label: 'lamp' },
    { key: 'fa fa-leaf-heart', label: 'leaf-heart' },
    { key: 'fa fa-loveseat', label: 'loveseat' },
    { key: 'fa fa-parachute-box', label: 'parachute-box' },
    { key: 'fa fa-people-carry', label: 'people-carry' },
    { key: 'fa fa-person-carry', label: 'person-carry' },
    { key: 'fa fa-person-dolly', label: 'person-dolly' },
    { key: 'fa fa-person-dolly-empty', label: 'person-dolly-empty' },
    { key: 'fa fa-phone-plus', label: 'phone-plus' },
    { key: 'fa fa-piggy-bank', label: 'piggy-bank' },
    { key: 'fa fa-ramp-loading', label: 'ramp-loading' },
    { key: 'fa fa-ribbon', label: 'ribbon' },
    { key: 'fa fa-route', label: 'route' },
    { key: 'fa fa-seedling', label: 'seedling' },
    { key: 'fa fa-sign', label: 'sign' },
    { key: 'fa fa-smile-wink', label: 'smile-wink' },
    { key: 'fa fa-tape', label: 'tape' },
    { key: 'fa fa-truck-container', label: 'truck-container' },
    { key: 'fa fa-truck-couch', label: 'truck-couch' },
    { key: 'fa fa-truck-loading', label: 'truck-loading' },
    { key: 'fa fa-truck-moving', label: 'truck-moving' },
    { key: 'fa fa-truck-ramp', label: 'truck-ramp' },
    { key: 'fa fa-video-plus', label: 'video-plus' },
    { key: 'fa fa-video-slash', label: 'video-slash' },
    { key: 'fa fa-wine-glass', label: 'wine-glass' },
    { key: 'fa fa-user-alt-slash', label: 'user-alt-slash' },
    { key: 'fa fa-user-astronaut', label: 'user-astronaut' },
    { key: 'fa fa-user-check', label: 'user-check' },
    { key: 'fa fa-user-clock', label: 'user-clock' },
    { key: 'fa fa-user-cog', label: 'user-cog' },
    { key: 'fa fa-user-edit', label: 'user-edit' },
    { key: 'fa fa-user-friends', label: 'user-friends' },
    { key: 'fa fa-user-graduate', label: 'user-graduate' },
    { key: 'fa fa-user-lock', label: 'user-lock' },
    { key: 'fa fa-user-minus', label: 'user-minus' },
    { key: 'fa fa-user-ninja', label: 'user-ninja' },
    { key: 'fa fa-user-shield', label: 'user-shield' },
    { key: 'fa fa-user-slash', label: 'user-slash' },
    { key: 'fa fa-user-tag', label: 'user-tag' },
    { key: 'fa fa-user-tie', label: 'user-tie' },
    { key: 'fa fa-users-cog', label: 'users-cog' },
    { key: 'fa fa-balance-scale-left', label: 'balance-scale-left' },
    { key: 'fa fa-balance-scale-right', label: 'balance-scale-right' },
    { key: 'fa fa-blender', label: 'blender' },
    { key: 'fa fa-book-open', label: 'book-open' },
    { key: 'fa fa-broadcast-tower', label: 'broadcast-tower' },
    { key: 'fa fa-broom', label: 'broom' },
    { key: 'fa fa-chalkboard', label: 'chalkboard' },
    { key: 'fa fa-chalkboard-teacher', label: 'chalkboard-teacher' },
    { key: 'fa fa-church', label: 'church' },
    { key: 'fa fa-coins', label: 'coins' },
    { key: 'fa fa-compact-disc', label: 'compact-disc' },
    { key: 'fa fa-crow', label: 'crow' },
    { key: 'fa fa-crown', label: 'crown' },
    { key: 'fa fa-dice', label: 'dice' },
    { key: 'fa fa-dice-five', label: 'dice-five' },
    { key: 'fa fa-dice-four', label: 'dice-four' },
    { key: 'fa fa-dice-one', label: 'dice-one' },
    { key: 'fa fa-dice-six', label: 'dice-six' },
    { key: 'fa fa-dice-three', label: 'dice-three' },
    { key: 'fa fa-dice-two', label: 'dice-two' },
    { key: 'fa fa-divide', label: 'divide' },
    { key: 'fa fa-door-closed', label: 'door-closed' },
    { key: 'fa fa-door-open', label: 'door-open' },
    { key: 'fa fa-equals', label: 'equals' },
    { key: 'fa fa-feather', label: 'feather' },
    { key: 'fa fa-frog', label: 'frog' },
    { key: 'fa fa-gas-pump', label: 'gas-pump' },
    { key: 'fa fa-glasses', label: 'glasses' },
    { key: 'fa fa-greater-than', label: 'greater-than' },
    { key: 'fa fa-greater-than-equal', label: 'greater-than-equal' },
    { key: 'fa fa-helicopter', label: 'helicopter' },
    { key: 'fa fa-infinity', label: 'infinity' },
    { key: 'fa fa-kiwi-bird', label: 'kiwi-bird' },
    { key: 'fa fa-less-than', label: 'less-than' },
    { key: 'fa fa-less-than-equal', label: 'less-than-equal' },
    { key: 'fa fa-memory', label: 'memory' },
    { key: 'fa fa-microphone-alt-slash', label: 'microphone-alt-slash' },
    { key: 'fa fa-money-bill-wave', label: 'money-bill-wave' },
    { key: 'fa fa-money-bill-wave-alt', label: 'money-bill-wave-alt' },
    { key: 'fa fa-money-check', label: 'money-check' },
    { key: 'fa fa-money-check-alt', label: 'money-check-alt' },
    { key: 'fa fa-not-equal', label: 'not-equal' },
    { key: 'fa fa-palette', label: 'palette' },
    { key: 'fa fa-parking', label: 'parking' },
    { key: 'fa fa-percentage', label: 'percentage' },
    { key: 'fa fa-project-diagram', label: 'project-diagram' },
    { key: 'fa fa-receipt', label: 'receipt' },
    { key: 'fa fa-robot', label: 'robot' },
    { key: 'fa fa-ruler', label: 'ruler' },
    { key: 'fa fa-ruler-combined', label: 'ruler-combined' },
    { key: 'fa fa-ruler-horizontal', label: 'ruler-horizontal' },
    { key: 'fa fa-ruler-vertical', label: 'ruler-vertical' },
    { key: 'fa fa-school', label: 'school' },
    { key: 'fa fa-screwdriver', label: 'screwdriver' },
    { key: 'fa fa-shoe-prints', label: 'shoe-prints' },
    { key: 'fa fa-skull', label: 'skull' },
    { key: 'fa fa-smoking-ban', label: 'smoking-ban' },
    { key: 'fa fa-store', label: 'store' },
    { key: 'fa fa-store-alt', label: 'store-alt' },
    { key: 'fa fa-stream', label: 'stream' },
    { key: 'fa fa-stroopwafel', label: 'stroopwafel' },
    { key: 'fa fa-toolbox', label: 'toolbox' },
    { key: 'fa fa-tshirt', label: 'tshirt' },
    { key: 'fa fa-walking', label: 'walking' },
    { key: 'fa fa-wallet', label: 'wallet' },
    { key: 'fa fa-angry', label: 'angry' },
    { key: 'fa fa-archway', label: 'archway' },
    { key: 'fa fa-atlas', label: 'atlas' },
    { key: 'fa fa-award', label: 'award' },
    { key: 'fa fa-backspace', label: 'backspace' },
    { key: 'fa fa-bezier-curve', label: 'bezier-curve' },
    { key: 'fa fa-bong', label: 'bong' },
    { key: 'fa fa-brush', label: 'brush' },
    { key: 'fa fa-bus-alt', label: 'bus-alt' },
    { key: 'fa fa-cannabis', label: 'cannabis' },
    { key: 'fa fa-check-double', label: 'check-double' },
    { key: 'fa fa-cocktail', label: 'cocktail' },
    { key: 'fa fa-concierge-bell', label: 'concierge-bell' },
    { key: 'fa fa-cookie', label: 'cookie' },
    { key: 'fa fa-cookie-bite', label: 'cookie-bite' },
    { key: 'fa fa-crop-alt', label: 'crop-alt' },
    { key: 'fa fa-digital-tachograph', label: 'digital-tachograph' },
    { key: 'fa fa-dizzy', label: 'dizzy' },
    { key: 'fa fa-drafting-compass', label: 'drafting-compass' },
    { key: 'fa fa-drum', label: 'drum' },
    { key: 'fa fa-drum-steelpan', label: 'drum-steelpan' },
    { key: 'fa fa-feather-alt', label: 'feather-alt' },
    { key: 'fa fa-file-contract', label: 'file-contract' },
    { key: 'fa fa-file-download', label: 'file-download' },
    { key: 'fa fa-file-export', label: 'file-export' },
    { key: 'fa fa-file-import', label: 'file-import' },
    { key: 'fa fa-file-invoice', label: 'file-invoice' },
    { key: 'fa fa-file-invoice-dollar', label: 'file-invoice-dollar' },
    { key: 'fa fa-file-prescription', label: 'file-prescription' },
    { key: 'fa fa-file-signature', label: 'file-signature' },
    { key: 'fa fa-file-upload', label: 'file-upload' },
    { key: 'fa fa-fill', label: 'fill' },
    { key: 'fa fa-fill-drip', label: 'fill-drip' },
    { key: 'fa fa-fingerprint', label: 'fingerprint' },
    { key: 'fa fa-fish', label: 'fish' },
    { key: 'fa fa-flushed', label: 'flushed' },
    { key: 'fa fa-frown-open', label: 'frown-open' },
    { key: 'fa fa-glass-martini-alt', label: 'glass-martini-alt' },
    { key: 'fa fa-globe-africa', label: 'globe-africa' },
    { key: 'fa fa-globe-americas', label: 'globe-americas' },
    { key: 'fa fa-globe-asia', label: 'globe-asia' },
    { key: 'fa fa-grimace', label: 'grimace' },
    { key: 'fa fa-grin', label: 'grin' },
    { key: 'fa fa-grin-alt', label: 'grin-alt' },
    { key: 'fa fa-grin-beam', label: 'grin-beam' },
    { key: 'fa fa-grin-beam-sweat', label: 'grin-beam-sweat' },
    { key: 'fa fa-grin-hearts', label: 'grin-hearts' },
    { key: 'fa fa-grin-squint', label: 'grin-squint' },
    { key: 'fa fa-grin-squint-tears', label: 'grin-squint-tears' },
    { key: 'fa fa-grin-stars', label: 'grin-stars' },
    { key: 'fa fa-grin-tears', label: 'grin-tears' },
    { key: 'fa fa-grin-tongue', label: 'grin-tongue' },
    { key: 'fa fa-grin-tongue-squint', label: 'grin-tongue-squint' },
    { key: 'fa fa-grin-tongue-wink', label: 'grin-tongue-wink' },
    { key: 'fa fa-grin-wink', label: 'grin-wink' },
    { key: 'fa fa-grip-horizontal', label: 'grip-horizontal' },
    { key: 'fa fa-grip-vertical', label: 'grip-vertical' },
    { key: 'fa fa-headphones-alt', label: 'headphones-alt' },
    { key: 'fa fa-headset', label: 'headset' },
    { key: 'fa fa-highlighter', label: 'highlighter' },
    { key: 'fa fa-hot-tub', label: 'hot-tub' },
    { key: 'fa fa-hotel', label: 'hotel' },
    { key: 'fa fa-joint', label: 'joint' },
    { key: 'fa fa-kiss', label: 'kiss' },
    { key: 'fa fa-kiss-beam', label: 'kiss-beam' },
    { key: 'fa fa-kiss-wink-heart', label: 'kiss-wink-heart' },
    { key: 'fa fa-laugh', label: 'laugh' },
    { key: 'fa fa-laugh-beam', label: 'laugh-beam' },
    { key: 'fa fa-laugh-squint', label: 'laugh-squint' },
    { key: 'fa fa-laugh-wink', label: 'laugh-wink' },
    { key: 'fa fa-luggage-cart', label: 'luggage-cart' },
    { key: 'fa fa-map-marked', label: 'map-marked' },
    { key: 'fa fa-map-marked-alt', label: 'map-marked-alt' },
    { key: 'fa fa-marker', label: 'marker' },
    { key: 'fa fa-medal', label: 'medal' },
    { key: 'fa fa-meh-blank', label: 'meh-blank' },
    { key: 'fa fa-meh-rolling-eyes', label: 'meh-rolling-eyes' },
    { key: 'fa fa-monument', label: 'monument' },
    { key: 'fa fa-mortar-pestle', label: 'mortar-pestle' },
    { key: 'fa fa-paint-brush-alt', label: 'paint-brush-alt' },
    { key: 'fa fa-paint-roller', label: 'paint-roller' },
    { key: 'fa fa-passport', label: 'passport' },
    { key: 'fa fa-pen-fancy', label: 'pen-fancy' },
    { key: 'fa fa-pen-nib', label: 'pen-nib' },
    { key: 'fa fa-pencil-ruler', label: 'pencil-ruler' },
    { key: 'fa fa-plane-arrival', label: 'plane-arrival' },
    { key: 'fa fa-plane-departure', label: 'plane-departure' },
    { key: 'fa fa-prescription', label: 'prescription' },
    { key: 'fa fa-sad-cry', label: 'sad-cry' },
    { key: 'fa fa-sad-tear', label: 'sad-tear' },
    { key: 'fa fa-shuttle-van', label: 'shuttle-van' },
    { key: 'fa fa-signature', label: 'signature' },
    { key: 'fa fa-smile-beam', label: 'smile-beam' },
    { key: 'fa fa-smile-plus', label: 'smile-plus' },
    { key: 'fa fa-solar-panel', label: 'solar-panel' },
    { key: 'fa fa-spa', label: 'spa' },
    { key: 'fa fa-splotch', label: 'splotch' },
    { key: 'fa fa-spray-can', label: 'spray-can' },
    { key: 'fa fa-stamp', label: 'stamp' },
    { key: 'fa fa-star-half-alt', label: 'star-half-alt' },
    { key: 'fa fa-suitcase-rolling', label: 'suitcase-rolling' },
    { key: 'fa fa-surprise', label: 'surprise' },
    { key: 'fa fa-swatchbook', label: 'swatchbook' },
    { key: 'fa fa-swimmer', label: 'swimmer' },
    { key: 'fa fa-swimming-pool', label: 'swimming-pool' },
    { key: 'fa fa-tint-slash', label: 'tint-slash' },
    { key: 'fa fa-tired', label: 'tired' },
    { key: 'fa fa-tooth', label: 'tooth' },
    { key: 'fa fa-umbrella-beach', label: 'umbrella-beach' },
    { key: 'fa fa-vector-square', label: 'vector-square' },
    { key: 'fa fa-weight-hanging', label: 'weight-hanging' },
    { key: 'fa fa-wine-glass-alt', label: 'wine-glass-alt' },
    { key: 'fa fa-air-freshener', label: 'air-freshener' },
    { key: 'fa fa-apple-alt', label: 'apple-alt' },
    { key: 'fa fa-atom', label: 'atom' },
    { key: 'fa fa-atom-alt', label: 'atom-alt' },
    { key: 'fa fa-backpack', label: 'backpack' },
    { key: 'fa fa-bell-school', label: 'bell-school' },
    { key: 'fa fa-bell-school-slash', label: 'bell-school-slash' },
    { key: 'fa fa-bone', label: 'bone' },
    { key: 'fa fa-bone-break', label: 'bone-break' },
    { key: 'fa fa-book-alt', label: 'book-alt' },
    { key: 'fa fa-book-reader', label: 'book-reader' },
    { key: 'fa fa-books', label: 'books' },
    { key: 'fa fa-brain', label: 'brain' },
    { key: 'fa fa-bus-school', label: 'bus-school' },
    { key: 'fa fa-car-alt', label: 'car-alt' },
    { key: 'fa fa-car-battery', label: 'car-battery' },
    { key: 'fa fa-car-bump', label: 'car-bump' },
    { key: 'fa fa-car-crash', label: 'car-crash' },
    { key: 'fa fa-car-garage', label: 'car-garage' },
    { key: 'fa fa-car-mechanic', label: 'car-mechanic' },
    { key: 'fa fa-car-side', label: 'car-side' },
    { key: 'fa fa-car-tilt', label: 'car-tilt' },
    { key: 'fa fa-car-wash', label: 'car-wash' },
    { key: 'fa fa-charging-station', label: 'charging-station' },
    { key: 'fa fa-clipboard-prescription', label: 'clipboard-prescription' },
    { key: 'fa fa-compass-slash', label: 'compass-slash' },
    { key: 'fa fa-diploma', label: 'diploma' },
    { key: 'fa fa-directions', label: 'directions' },
    { key: 'fa fa-do-not-enter', label: 'do-not-enter' },
    { key: 'fa fa-draw-circle', label: 'draw-circle' },
    { key: 'fa fa-draw-polygon', label: 'draw-polygon' },
    { key: 'fa fa-draw-square', label: 'draw-square' },
    { key: 'fa fa-ear', label: 'ear' },
    { key: 'fa fa-engine-warning', label: 'engine-warning' },
    { key: 'fa fa-file-certificate', label: 'file-certificate' },
    { key: 'fa fa-gas-pump-slash', label: 'gas-pump-slash' },
    { key: 'fa fa-glasses-alt', label: 'glasses-alt' },
    { key: 'fa fa-globe-stand', label: 'globe-stand' },
    { key: 'fa fa-heart-rate', label: 'heart-rate' },
    { key: 'fa fa-inhaler', label: 'inhaler' },
    { key: 'fa fa-kidneys', label: 'kidneys' },
    { key: 'fa fa-laptop-code', label: 'laptop-code' },
    { key: 'fa fa-layer-group', label: 'layer-group' },
    { key: 'fa fa-layer-minus', label: 'layer-minus' },
    { key: 'fa fa-layer-plus', label: 'layer-plus' },
    { key: 'fa fa-lips', label: 'lips' },
    { key: 'fa fa-location', label: 'location' },
    { key: 'fa fa-location-circle', label: 'location-circle' },
    { key: 'fa fa-location-slash', label: 'location-slash' },
    { key: 'fa fa-lungs', label: 'lungs' },
    { key: 'fa fa-map-marker-alt-slash', label: 'map-marker-alt-slash' },
    { key: 'fa fa-map-marker-check', label: 'map-marker-check' },
    { key: 'fa fa-map-marker-edit', label: 'map-marker-edit' },
    { key: 'fa fa-map-marker-exclamation', label: 'map-marker-exclamation' },
    { key: 'fa fa-map-marker-minus', label: 'map-marker-minus' },
    { key: 'fa fa-map-marker-plus', label: 'map-marker-plus' },
    { key: 'fa fa-map-marker-question', label: 'map-marker-question' },
    { key: 'fa fa-map-marker-slash', label: 'map-marker-slash' },
    { key: 'fa fa-map-marker-smile', label: 'map-marker-smile' },
    { key: 'fa fa-map-marker-times', label: 'map-marker-times' },
    { key: 'fa fa-microscope', label: 'microscope' },
    { key: 'fa fa-monitor-heart-rate', label: 'monitor-heart-rate' },
    { key: 'fa fa-oil-can', label: 'oil-can' },
    { key: 'fa fa-oil-temp', label: 'oil-temp' },
    { key: 'fa fa-parking-circle', label: 'parking-circle' },
    { key: 'fa fa-parking-circle-slash', label: 'parking-circle-slash' },
    { key: 'fa fa-parking-slash', label: 'parking-slash' },
    { key: 'fa fa-pencil-paintbrush', label: 'pencil-paintbrush' },
    { key: 'fa fa-poop', label: 'poop' },
    { key: 'fa fa-route-highway', label: 'route-highway' },
    { key: 'fa fa-route-interstate', label: 'route-interstate' },
    { key: 'fa fa-ruler-triangle', label: 'ruler-triangle' },
    { key: 'fa fa-scalpel', label: 'scalpel' },
    { key: 'fa fa-scalpel-path', label: 'scalpel-path' },
    { key: 'fa fa-shapes', label: 'shapes' },
    { key: 'fa fa-skeleton', label: 'skeleton' },
    { key: 'fa fa-star-of-life', label: 'star-of-life' },
    { key: 'fa fa-steering-wheel', label: 'steering-wheel' },
    { key: 'fa fa-stomach', label: 'stomach' },
    { key: 'fa fa-tachometer-alt-average', label: 'tachometer-alt-average' },
    { key: 'fa fa-tachometer-alt-fast', label: 'tachometer-alt-fast' },
    { key: 'fa fa-tachometer-alt-fastest', label: 'tachometer-alt-fastest' },
    { key: 'fa fa-tachometer-alt-slow', label: 'tachometer-alt-slow' },
    { key: 'fa fa-tachometer-alt-slowest', label: 'tachometer-alt-slowest' },
    { key: 'fa fa-tachometer-average', label: 'tachometer-average' },
    { key: 'fa fa-tachometer-fast', label: 'tachometer-fast' },
    { key: 'fa fa-tachometer-fastest', label: 'tachometer-fastest' },
    { key: 'fa fa-tachometer-slow', label: 'tachometer-slow' },
    { key: 'fa fa-tachometer-slowest', label: 'tachometer-slowest' },
    { key: 'fa fa-teeth', label: 'teeth' },
    { key: 'fa fa-teeth-open', label: 'teeth-open' },
    { key: 'fa fa-theater-masks', label: 'theater-masks' },
    { key: 'fa fa-tire', label: 'tire' },
    { key: 'fa fa-tire-flat', label: 'tire-flat' },
    { key: 'fa fa-tire-pressure-warning', label: 'tire-pressure-warning' },
    { key: 'fa fa-tire-rugged', label: 'tire-rugged' },
    { key: 'fa fa-toothbrush', label: 'toothbrush' },
    { key: 'fa fa-traffic-cone', label: 'traffic-cone' },
    { key: 'fa fa-traffic-light', label: 'traffic-light' },
    { key: 'fa fa-traffic-light-go', label: 'traffic-light-go' },
    { key: 'fa fa-traffic-light-slow', label: 'traffic-light-slow' },
    { key: 'fa fa-traffic-light-stop', label: 'traffic-light-stop' },
    { key: 'fa fa-truck-monster', label: 'truck-monster' },
    { key: 'fa fa-truck-pickup', label: 'truck-pickup' },
    { key: 'fa fa-users-class', label: 'users-class' },
    { key: 'fa fa-watch-fitness', label: 'watch-fitness' },
    { key: 'fa fa-abacus', label: 'abacus' },
    { key: 'fa fa-ad', label: 'ad' },
    { key: 'fa fa-analytics', label: 'analytics' },
    { key: 'fa fa-ankh', label: 'ankh' },
    { key: 'fa fa-badge-dollar', label: 'badge-dollar' },
    { key: 'fa fa-badge-percent', label: 'badge-percent' },
    { key: 'fa fa-bible', label: 'bible' },
    { key: 'fa fa-bullseye-arrow', label: 'bullseye-arrow' },
    { key: 'fa fa-bullseye-pointer', label: 'bullseye-pointer' },
    { key: 'fa fa-business-time', label: 'business-time' },
    { key: 'fa fa-cabinet-filing', label: 'cabinet-filing' },
    { key: 'fa fa-calculator-alt', label: 'calculator-alt' },
    { key: 'fa fa-chart-line-down', label: 'chart-line-down' },
    { key: 'fa fa-chart-pie-alt', label: 'chart-pie-alt' },
    { key: 'fa fa-city', label: 'city' },
    { key: 'fa fa-comment-alt-dollar', label: 'comment-alt-dollar' },
    { key: 'fa fa-comment-dollar', label: 'comment-dollar' },
    { key: 'fa fa-comments-alt-dollar', label: 'comments-alt-dollar' },
    { key: 'fa fa-comments-dollar', label: 'comments-dollar' },
    { key: 'fa fa-cross', label: 'cross' },
    { key: 'fa fa-dharmachakra', label: 'dharmachakra' },
    { key: 'fa fa-empty-set', label: 'empty-set' },
    { key: 'fa fa-envelope-open-dollar', label: 'envelope-open-dollar' },
    { key: 'fa fa-envelope-open-text', label: 'envelope-open-text' },
    { key: 'fa fa-file-chart-line', label: 'file-chart-line' },
    { key: 'fa fa-file-chart-pie', label: 'file-chart-pie' },
    { key: 'fa fa-file-spreadsheet', label: 'file-spreadsheet' },
    { key: 'fa fa-file-user', label: 'file-user' },
    { key: 'fa fa-folder-minus', label: 'folder-minus' },
    { key: 'fa fa-folder-plus', label: 'folder-plus' },
    { key: 'fa fa-folder-times', label: 'folder-times' },
    { key: 'fa fa-folders', label: 'folders' },
    { key: 'fa fa-function', label: 'function' },
    { key: 'fa fa-funnel-dollar', label: 'funnel-dollar' },
    { key: 'fa fa-gift-card', label: 'gift-card' },
    { key: 'fa fa-gopuram', label: 'gopuram' },
    { key: 'fa fa-hamsa', label: 'hamsa' },
    { key: 'fa fa-haykal', label: 'haykal' },
    { key: 'fa fa-integral', label: 'integral' },
    { key: 'fa fa-intersection', label: 'intersection' },
    { key: 'fa fa-jedi', label: 'jedi' },
    { key: 'fa fa-journal-whills', label: 'journal-whills' },
    { key: 'fa fa-kaaba', label: 'kaaba' },
    { key: 'fa fa-keynote', label: 'keynote' },
    { key: 'fa fa-khanda', label: 'khanda' },
    { key: 'fa fa-lambda', label: 'lambda' },
    { key: 'fa fa-landmark', label: 'landmark' },
    { key: 'fa fa-lightbulb-dollar', label: 'lightbulb-dollar' },
    { key: 'fa fa-lightbulb-exclamation', label: 'lightbulb-exclamation' },
    { key: 'fa fa-lightbulb-on', label: 'lightbulb-on' },
    { key: 'fa fa-lightbulb-slash', label: 'lightbulb-slash' },
    { key: 'fa fa-mail-bulk', label: 'mail-bulk' },
    { key: 'fa fa-megaphone', label: 'megaphone' },
    { key: 'fa fa-menorah', label: 'menorah' },
    { key: 'fa fa-mind-share', label: 'mind-share' },
    { key: 'fa fa-mosque', label: 'mosque' },
    { key: 'fa fa-om', label: 'om' },
    { key: 'fa fa-omega', label: 'omega' },
    { key: 'fa fa-pastafarianism', label: 'pastafarianism' },
    { key: 'fa fa-peace', label: 'peace' },
    { key: 'fa fa-phone-office', label: 'phone-office' },
    { key: 'fa fa-pi', label: 'pi' },
    { key: 'fa fa-place-of-worship', label: 'place-of-worship' },
    { key: 'fa fa-podium', label: 'podium' },
    { key: 'fa fa-poll', label: 'poll' },
    { key: 'fa fa-poll-h', label: 'poll-h' },
    { key: 'fa fa-pray', label: 'pray' },
    { key: 'fa fa-praying-hands', label: 'praying-hands' },
    { key: 'fa fa-presentation', label: 'presentation' },
    { key: 'fa fa-print-slash', label: 'print-slash' },
    { key: 'fa fa-quran', label: 'quran' },
    { key: 'fa fa-search-dollar', label: 'search-dollar' },
    { key: 'fa fa-search-location', label: 'search-location' },
    { key: 'fa fa-shredder', label: 'shredder' },
    { key: 'fa fa-sigma', label: 'sigma' },
    { key: 'fa fa-signal-1', label: 'signal-1' },
    { key: 'fa fa-signal-2', label: 'signal-2' },
    { key: 'fa fa-signal-3', label: 'signal-3' },
    { key: 'fa fa-signal-4', label: 'signal-4' },
    { key: 'fa fa-signal-alt', label: 'signal-alt' },
    { key: 'fa fa-signal-alt-1', label: 'signal-alt-1' },
    { key: 'fa fa-signal-alt-2', label: 'signal-alt-2' },
    { key: 'fa fa-signal-alt-3', label: 'signal-alt-3' },
    { key: 'fa fa-signal-alt-slash', label: 'signal-alt-slash' },
    { key: 'fa fa-signal-slash', label: 'signal-slash' },
    { key: 'fa fa-socks', label: 'socks' },
    { key: 'fa fa-square-root', label: 'square-root' },
    { key: 'fa fa-square-root-alt', label: 'square-root-alt' },
    { key: 'fa fa-star-and-crescent', label: 'star-and-crescent' },
    { key: 'fa fa-star-of-david', label: 'star-of-david' },
    { key: 'fa fa-synagogue', label: 'synagogue' },
    { key: 'fa fa-tally', label: 'tally' },
    { key: 'fa fa-theta', label: 'theta' },
    { key: 'fa fa-tilde', label: 'tilde' },
    { key: 'fa fa-torah', label: 'torah' },
    { key: 'fa fa-torii-gate', label: 'torii-gate' },
    { key: 'fa fa-union', label: 'union' },
    { key: 'fa fa-user-chart', label: 'user-chart' },
    { key: 'fa fa-user-crown', label: 'user-crown' },
    { key: 'fa fa-users-crown', label: 'users-crown' },
    { key: 'fa fa-value-absolute', label: 'value-absolute' },
    { key: 'fa fa-vihara', label: 'vihara' },
    { key: 'fa fa-volume', label: 'volume' },
    { key: 'fa fa-volume-mute', label: 'volume-mute' },
    { key: 'fa fa-wifi-1', label: 'wifi-1' },
    { key: 'fa fa-wifi-2', label: 'wifi-2' },
    { key: 'fa fa-wifi-slash', label: 'wifi-slash' },
    { key: 'fa fa-yin-yang', label: 'yin-yang' },
    { key: 'fa fa-acorn', label: 'acorn' },
    { key: 'fa fa-alicorn', label: 'alicorn' },
    { key: 'fa fa-apple-crate', label: 'apple-crate' },
    { key: 'fa fa-axe', label: 'axe' },
    { key: 'fa fa-axe-battle', label: 'axe-battle' },
    { key: 'fa fa-badger-honey', label: 'badger-honey' },
    { key: 'fa fa-bat', label: 'bat' },
    { key: 'fa fa-blender-phone', label: 'blender-phone' },
    { key: 'fa fa-book-dead', label: 'book-dead' },
    { key: 'fa fa-book-spells', label: 'book-spells' },
    { key: 'fa fa-bow-arrow', label: 'bow-arrow' },
    { key: 'fa fa-campfire', label: 'campfire' },
    { key: 'fa fa-campground', label: 'campground' },
    { key: 'fa fa-candle-holder', label: 'candle-holder' },
    { key: 'fa fa-candy-corn', label: 'candy-corn' },
    { key: 'fa fa-cat', label: 'cat' },
    { key: 'fa fa-cauldron', label: 'cauldron' },
    { key: 'fa fa-chair', label: 'chair' },
    { key: 'fa fa-chair-office', label: 'chair-office' },
    { key: 'fa fa-claw-marks', label: 'claw-marks' },
    { key: 'fa fa-cloud-moon', label: 'cloud-moon' },
    { key: 'fa fa-cloud-sun', label: 'cloud-sun' },
    { key: 'fa fa-coffee-togo', label: 'coffee-togo' },
    { key: 'fa fa-coffin', label: 'coffin' },
    { key: 'fa fa-corn', label: 'corn' },
    { key: 'fa fa-cow', label: 'cow' },
    { key: 'fa fa-dagger', label: 'dagger' },
    { key: 'fa fa-dice-d10', label: 'dice-d10' },
    { key: 'fa fa-dice-d12', label: 'dice-d12' },
    { key: 'fa fa-dice-d20', label: 'dice-d20' },
    { key: 'fa fa-dice-d4', label: 'dice-d4' },
    { key: 'fa fa-dice-d6', label: 'dice-d6' },
    { key: 'fa fa-dice-d8', label: 'dice-d8' },
    { key: 'fa fa-dog', label: 'dog' },
    { key: 'fa fa-dog-leashed', label: 'dog-leashed' },
    { key: 'fa fa-dragon', label: 'dragon' },
    { key: 'fa fa-drumstick', label: 'drumstick' },
    { key: 'fa fa-drumstick-bite', label: 'drumstick-bite' },
    { key: 'fa fa-duck', label: 'duck' },
    { key: 'fa fa-dungeon', label: 'dungeon' },
    { key: 'fa fa-elephant', label: 'elephant' },
    { key: 'fa fa-eye-evil', label: 'eye-evil' },
    { key: 'fa fa-file-csv', label: 'file-csv' },
    { key: 'fa fa-fist-raised', label: 'fist-raised' },
    { key: 'fa fa-flame', label: 'flame' },
    { key: 'fa fa-flask-poison', label: 'flask-poison' },
    { key: 'fa fa-flask-potion', label: 'flask-potion' },
    { key: 'fa fa-ghost', label: 'ghost' },
    { key: 'fa fa-hammer', label: 'hammer' },
    { key: 'fa fa-hammer-war', label: 'hammer-war' },
    { key: 'fa fa-hand-holding-magic', label: 'hand-holding-magic' },
    { key: 'fa fa-hanukiah', label: 'hanukiah' },
    { key: 'fa fa-hat-witch', label: 'hat-witch' },
    { key: 'fa fa-hat-wizard', label: 'hat-wizard' },
    { key: 'fa fa-head-side', label: 'head-side' },
    { key: 'fa fa-head-vr', label: 'head-vr' },
    { key: 'fa fa-helmet-battle', label: 'helmet-battle' },
    { key: 'fa fa-hiking', label: 'hiking' },
    { key: 'fa fa-hippo', label: 'hippo' },
    { key: 'fa fa-hockey-mask', label: 'hockey-mask' },
    { key: 'fa fa-hood-cloak', label: 'hood-cloak' },
    { key: 'fa fa-horse', label: 'horse' },
    { key: 'fa fa-house-damage', label: 'house-damage' },
    { key: 'fa fa-hryvnia', label: 'hryvnia' },
    { key: 'fa fa-key-skeleton', label: 'key-skeleton' },
    { key: 'fa fa-kite', label: 'kite' },
    { key: 'fa fa-knife-kitchen', label: 'knife-kitchen' },
    { key: 'fa fa-leaf-maple', label: 'leaf-maple' },
    { key: 'fa fa-leaf-oak', label: 'leaf-oak' },
    { key: 'fa fa-mace', label: 'mace' },
    { key: 'fa fa-mandolin', label: 'mandolin' },
    { key: 'fa fa-mask', label: 'mask' },
    { key: 'fa fa-monkey', label: 'monkey' },
    { key: 'fa fa-mountain', label: 'mountain' },
    { key: 'fa fa-mountains', label: 'mountains' },
    { key: 'fa fa-narwhal', label: 'narwhal' },
    { key: 'fa fa-network-wired', label: 'network-wired' },
    { key: 'fa fa-otter', label: 'otter' },
    { key: 'fa fa-paw-alt', label: 'paw-alt' },
    { key: 'fa fa-paw-claws', label: 'paw-claws' },
    { key: 'fa fa-pegasus', label: 'pegasus' },
    { key: 'fa fa-pie', label: 'pie' },
    { key: 'fa fa-pig', label: 'pig' },
    { key: 'fa fa-pumpkin', label: 'pumpkin' },
    { key: 'fa fa-rabbit', label: 'rabbit' },
    { key: 'fa fa-rabbit-fast', label: 'rabbit-fast' },
    { key: 'fa fa-ram', label: 'ram' },
    { key: 'fa fa-ring', label: 'ring' },
    { key: 'fa fa-running', label: 'running' },
    { key: 'fa fa-scarecrow', label: 'scarecrow' },
    { key: 'fa fa-scroll', label: 'scroll' },
    { key: 'fa fa-scroll-old', label: 'scroll-old' },
    { key: 'fa fa-scythe', label: 'scythe' },
    { key: 'fa fa-sheep', label: 'sheep' },
    { key: 'fa fa-shield-cross', label: 'shield-cross' },
    { key: 'fa fa-shovel', label: 'shovel' },
    { key: 'fa fa-skull-crossbones', label: 'skull-crossbones' },
    { key: 'fa fa-slash', label: 'slash' },
    { key: 'fa fa-snake', label: 'snake' },
    { key: 'fa fa-spider', label: 'spider' },
    { key: 'fa fa-spider-black-widow', label: 'spider-black-widow' },
    { key: 'fa fa-spider-web', label: 'spider-web' },
    { key: 'fa fa-squirrel', label: 'squirrel' },
    { key: 'fa fa-staff', label: 'staff' },
    { key: 'fa fa-sword', label: 'sword' },
    { key: 'fa fa-swords', label: 'swords' },
    { key: 'fa fa-toilet-paper', label: 'toilet-paper' },
    { key: 'fa fa-toilet-paper-alt', label: 'toilet-paper-alt' },
    { key: 'fa fa-tombstone', label: 'tombstone' },
    { key: 'fa fa-tombstone-alt', label: 'tombstone-alt' },
    { key: 'fa fa-tractor', label: 'tractor' },
    { key: 'fa fa-treasure-chest', label: 'treasure-chest' },
    { key: 'fa fa-trees', label: 'trees' },
    { key: 'fa fa-turkey', label: 'turkey' },
    { key: 'fa fa-turtle', label: 'turtle' },
    { key: 'fa fa-unicorn', label: 'unicorn' },
    { key: 'fa fa-user-injured', label: 'user-injured' },
    { key: 'fa fa-vr-cardboard', label: 'vr-cardboard' },
    { key: 'fa fa-wand', label: 'wand' },
    { key: 'fa fa-wand-magic', label: 'wand-magic' },
    { key: 'fa fa-whale', label: 'whale' },
    { key: 'fa fa-wheat', label: 'wheat' },
    { key: 'fa fa-wind', label: 'wind' },
    { key: 'fa fa-wine-bottle', label: 'wine-bottle' },
    { key: 'fa fa-ballot', label: 'ballot' },
    { key: 'fa fa-ballot-check', label: 'ballot-check' },
    { key: 'fa fa-booth-curtain', label: 'booth-curtain' },
    { key: 'fa fa-box-ballot', label: 'box-ballot' },
    { key: 'fa fa-calendar-star', label: 'calendar-star' },
    { key: 'fa fa-clipboard-list-check', label: 'clipboard-list-check' },
    { key: 'fa fa-cloud-drizzle', label: 'cloud-drizzle' },
    { key: 'fa fa-cloud-hail', label: 'cloud-hail' },
    { key: 'fa fa-cloud-hail-mixed', label: 'cloud-hail-mixed' },
    { key: 'fa fa-cloud-meatball', label: 'cloud-meatball' },
    { key: 'fa fa-cloud-moon-rain', label: 'cloud-moon-rain' },
    { key: 'fa fa-cloud-rain', label: 'cloud-rain' },
    { key: 'fa fa-cloud-rainbow', label: 'cloud-rainbow' },
    { key: 'fa fa-cloud-showers', label: 'cloud-showers' },
    { key: 'fa fa-cloud-showers-heavy', label: 'cloud-showers-heavy' },
    { key: 'fa fa-cloud-sleet', label: 'cloud-sleet' },
    { key: 'fa fa-cloud-snow', label: 'cloud-snow' },
    { key: 'fa fa-cloud-sun-rain', label: 'cloud-sun-rain' },
    { key: 'fa fa-clouds', label: 'clouds' },
    { key: 'fa fa-clouds-moon', label: 'clouds-moon' },
    { key: 'fa fa-clouds-sun', label: 'clouds-sun' },
    { key: 'fa fa-democrat', label: 'democrat' },
    { key: 'fa fa-dewpoint', label: 'dewpoint' },
    { key: 'fa fa-eclipse', label: 'eclipse' },
    { key: 'fa fa-eclipse-alt', label: 'eclipse-alt' },
    { key: 'fa fa-fire-smoke', label: 'fire-smoke' },
    { key: 'fa fa-flag-alt', label: 'flag-alt' },
    { key: 'fa fa-flag-usa', label: 'flag-usa' },
    { key: 'fa fa-fog', label: 'fog' },
    { key: 'fa fa-house-flood', label: 'house-flood' },
    { key: 'fa fa-humidity', label: 'humidity' },
    { key: 'fa fa-hurricane', label: 'hurricane' },
    { key: 'fa fa-landmark-alt', label: 'landmark-alt' },
    { key: 'fa fa-meteor', label: 'meteor' },
    { key: 'fa fa-moon-cloud', label: 'moon-cloud' },
    { key: 'fa fa-moon-stars', label: 'moon-stars' },
    { key: 'fa fa-person-booth', label: 'person-booth' },
    { key: 'fa fa-person-sign', label: 'person-sign' },
    { key: 'fa fa-podium-star', label: 'podium-star' },
    { key: 'fa fa-poll-people', label: 'poll-people' },
    { key: 'fa fa-poo-storm', label: 'poo-storm' },
    { key: 'fa fa-rainbow', label: 'rainbow' },
    { key: 'fa fa-raindrops', label: 'raindrops' },
    { key: 'fa fa-republican', label: 'republican' },
    { key: 'fa fa-smog', label: 'smog' },
    { key: 'fa fa-smoke', label: 'smoke' },
    { key: 'fa fa-snow-blowing', label: 'snow-blowing' },
    { key: 'fa fa-stars', label: 'stars' },
    { key: 'fa fa-sun-cloud', label: 'sun-cloud' },
    { key: 'fa fa-sun-dust', label: 'sun-dust' },
    { key: 'fa fa-sun-haze', label: 'sun-haze' },
    { key: 'fa fa-sunrise', label: 'sunrise' },
    { key: 'fa fa-sunset', label: 'sunset' },
    { key: 'fa fa-temperature-frigid', label: 'temperature-frigid' },
    { key: 'fa fa-temperature-high', label: 'temperature-high' },
    { key: 'fa fa-temperature-hot', label: 'temperature-hot' },
    { key: 'fa fa-temperature-low', label: 'temperature-low' },
    { key: 'fa fa-thunderstorm', label: 'thunderstorm' },
    { key: 'fa fa-thunderstorm-moon', label: 'thunderstorm-moon' },
    { key: 'fa fa-thunderstorm-sun', label: 'thunderstorm-sun' },
    { key: 'fa fa-tornado', label: 'tornado' },
    { key: 'fa fa-volcano', label: 'volcano' },
    { key: 'fa fa-vote-nay', label: 'vote-nay' },
    { key: 'fa fa-vote-yea', label: 'vote-yea' },
    { key: 'fa fa-water', label: 'water' },
    { key: 'fa fa-water-lower', label: 'water-lower' },
    { key: 'fa fa-water-rise', label: 'water-rise' },
    { key: 'fa fa-wind-warning', label: 'wind-warning' },
    { key: 'fa fa-windsock', label: 'windsock' },
    { key: 'fa fa-angel', label: 'angel' },
    { key: 'fa fa-baby', label: 'baby' },
    { key: 'fa fa-baby-carriage', label: 'baby-carriage' },
    { key: 'fa fa-ball-pile', label: 'ball-pile' },
    { key: 'fa fa-bells', label: 'bells' },
    { key: 'fa fa-biohazard', label: 'biohazard' },
    { key: 'fa fa-blog', label: 'blog' },
    { key: 'fa fa-boot', label: 'boot' },
    { key: 'fa fa-calendar-day', label: 'calendar-day' },
    { key: 'fa fa-calendar-week', label: 'calendar-week' },
    { key: 'fa fa-candy-cane', label: 'candy-cane' },
    { key: 'fa fa-carrot', label: 'carrot' },
    { key: 'fa fa-cash-register', label: 'cash-register' },
    { key: 'fa fa-chart-network', label: 'chart-network' },
    { key: 'fa fa-chimney', label: 'chimney' },
    { key: 'fa fa-compress-arrows-alt', label: 'compress-arrows-alt' },
    { key: 'fa fa-deer', label: 'deer' },
    { key: 'fa fa-deer-rudolph', label: 'deer-rudolph' },
    { key: 'fa fa-dreidel', label: 'dreidel' },
    { key: 'fa fa-dumpster', label: 'dumpster' },
    { key: 'fa fa-dumpster-fire', label: 'dumpster-fire' },
    { key: 'fa fa-ear-muffs', label: 'ear-muffs' },
    { key: 'fa fa-ethernet', label: 'ethernet' },
    { key: 'fa fa-fireplace', label: 'fireplace' },
    { key: 'fa fa-frosty-head', label: 'frosty-head' },
    { key: 'fa fa-gifts', label: 'gifts' },
    { key: 'fa fa-gingerbread-man', label: 'gingerbread-man' },
    { key: 'fa fa-glass-champagne', label: 'glass-champagne' },
    { key: 'fa fa-glass-cheers', label: 'glass-cheers' },
    { key: 'fa fa-glass-whiskey', label: 'glass-whiskey' },
    { key: 'fa fa-glass-whiskey-rocks', label: 'glass-whiskey-rocks' },
    { key: 'fa fa-globe-europe', label: 'globe-europe' },
    { key: 'fa fa-globe-snow', label: 'globe-snow' },
    { key: 'fa fa-grip-lines', label: 'grip-lines' },
    { key: 'fa fa-grip-lines-vertical', label: 'grip-lines-vertical' },
    { key: 'fa fa-guitar', label: 'guitar' },
    { key: 'fa fa-hat-santa', label: 'hat-santa' },
    { key: 'fa fa-hat-winter', label: 'hat-winter' },
    { key: 'fa fa-heart-broken', label: 'heart-broken' },
    { key: 'fa fa-holly-berry', label: 'holly-berry' },
    { key: 'fa fa-horse-head', label: 'horse-head' },
    { key: 'fa fa-ice-skate', label: 'ice-skate' },
    { key: 'fa fa-icicles', label: 'icicles' },
    { key: 'fa fa-igloo', label: 'igloo' },
    { key: 'fa fa-lights-holiday', label: 'lights-holiday' },
    { key: 'fa fa-mistletoe', label: 'mistletoe' },
    { key: 'fa fa-mitten', label: 'mitten' },
    { key: 'fa fa-mug-hot', label: 'mug-hot' },
    { key: 'fa fa-mug-marshmallows', label: 'mug-marshmallows' },
    { key: 'fa fa-ornament', label: 'ornament' },
    { key: 'fa fa-radiation', label: 'radiation' },
    { key: 'fa fa-radiation-alt', label: 'radiation-alt' },
    { key: 'fa fa-restroom', label: 'restroom' },
    { key: 'fa fa-rv', label: 'rv' },
    { key: 'fa fa-satellite', label: 'satellite' },
    { key: 'fa fa-satellite-dish', label: 'satellite-dish' },
    { key: 'fa fa-scarf', label: 'scarf' },
    { key: 'fa fa-sd-card', label: 'sd-card' },
    { key: 'fa fa-shovel-snow', label: 'shovel-snow' },
    { key: 'fa fa-sim-card', label: 'sim-card' },
    { key: 'fa fa-skating', label: 'skating' },
    { key: 'fa fa-ski-jump', label: 'ski-jump' },
    { key: 'fa fa-ski-lift', label: 'ski-lift' },
    { key: 'fa fa-skiing', label: 'skiing' },
    { key: 'fa fa-skiing-nordic', label: 'skiing-nordic' },
    { key: 'fa fa-sledding', label: 'sledding' },
    { key: 'fa fa-sleigh', label: 'sleigh' },
    { key: 'fa fa-sms', label: 'sms' },
    { key: 'fa fa-snowboarding', label: 'snowboarding' },
    { key: 'fa fa-snowflakes', label: 'snowflakes' },
    { key: 'fa fa-snowman', label: 'snowman' },
    { key: 'fa fa-snowmobile', label: 'snowmobile' },
    { key: 'fa fa-snowplow', label: 'snowplow' },
    { key: 'fa fa-star-christmas', label: 'star-christmas' },
    { key: 'fa fa-stocking', label: 'stocking' },
    { key: 'fa fa-tenge', label: 'tenge' },
    { key: 'fa fa-toilet', label: 'toilet' },
    { key: 'fa fa-tools', label: 'tools' },
    { key: 'fa fa-tram', label: 'tram' },
    { key: 'fa fa-tree-christmas', label: 'tree-christmas' },
    { key: 'fa fa-tree-decorated', label: 'tree-decorated' },
    { key: 'fa fa-tree-large', label: 'tree-large' },
    { key: 'fa fa-truck-plow', label: 'truck-plow' },
    { key: 'fa fa-wreath', label: 'wreath' },
    { key: 'fa fa-fire-alt', label: 'fire-alt' },
    { key: 'fa fa-bacon', label: 'bacon' },
    { key: 'fa fa-book-medical', label: 'book-medical' },
    { key: 'fa fa-book-user', label: 'book-user' },
    { key: 'fa fa-books-medical', label: 'books-medical' },
    { key: 'fa fa-brackets', label: 'brackets' },
    { key: 'fa fa-brackets-curly', label: 'brackets-curly' },
    { key: 'fa fa-bread-loaf', label: 'bread-loaf' },
    { key: 'fa fa-bread-slice', label: 'bread-slice' },
    { key: 'fa fa-burrito', label: 'burrito' },
    { key: 'fa fa-chart-scatter', label: 'chart-scatter' },
    { key: 'fa fa-cheese', label: 'cheese' },
    { key: 'fa fa-cheese-swiss', label: 'cheese-swiss' },
    { key: 'fa fa-cheeseburger', label: 'cheeseburger' },
    { key: 'fa fa-clinic-medical', label: 'clinic-medical' },
    { key: 'fa fa-clipboard-user', label: 'clipboard-user' },
    { key: 'fa fa-comment-alt-medical', label: 'comment-alt-medical' },
    { key: 'fa fa-comment-medical', label: 'comment-medical' },
    { key: 'fa fa-croissant', label: 'croissant' },
    { key: 'fa fa-crutch', label: 'crutch' },
    { key: 'fa fa-crutches', label: 'crutches' },
    { key: 'fa fa-debug', label: 'debug' },
    { key: 'fa fa-disease', label: 'disease' },
    { key: 'fa fa-egg', label: 'egg' },
    { key: 'fa fa-egg-fried', label: 'egg-fried' },
    { key: 'fa fa-files-medical', label: 'files-medical' },
    { key: 'fa fa-fish-cooked', label: 'fish-cooked' },
    { key: 'fa fa-flower', label: 'flower' },
    { key: 'fa fa-flower-daffodil', label: 'flower-daffodil' },
    { key: 'fa fa-flower-tulip', label: 'flower-tulip' },
    { key: 'fa fa-folder-tree', label: 'folder-tree' },
    { key: 'fa fa-french-fries', label: 'french-fries' },
    { key: 'fa fa-glass', label: 'glass' },
    { key: 'fa fa-hamburger', label: 'hamburger' },
    { key: 'fa fa-hand-middle-finger', label: 'hand-middle-finger' },
    { key: 'fa fa-hard-hat', label: 'hard-hat' },
    { key: 'fa fa-head-side-brain', label: 'head-side-brain' },
    { key: 'fa fa-head-side-medical', label: 'head-side-medical' },
    { key: 'fa fa-home-alt', label: 'home-alt' },
    { key: 'fa fa-home-lg', label: 'home-lg' },
    { key: 'fa fa-home-lg-alt', label: 'home-lg-alt' },
    { key: 'fa fa-hospital-user', label: 'hospital-user' },
    { key: 'fa fa-hospitals', label: 'hospitals' },
    { key: 'fa fa-hotdog', label: 'hotdog' },
    { key: 'fa fa-ice-cream', label: 'ice-cream' },
    { key: 'fa fa-island-tropical', label: 'island-tropical' },
    { key: 'fa fa-laptop-medical', label: 'laptop-medical' },
    { key: 'fa fa-mailbox', label: 'mailbox' },
    { key: 'fa fa-meat', label: 'meat' },
    { key: 'fa fa-pager', label: 'pager' },
    { key: 'fa fa-pepper-hot', label: 'pepper-hot' },
    { key: 'fa fa-pizza', label: 'pizza' },
    { key: 'fa fa-pizza-slice', label: 'pizza-slice' },
    { key: 'fa fa-popcorn', label: 'popcorn' },
    { key: 'fa fa-print-search', label: 'print-search' },
    { key: 'fa fa-rings-wedding', label: 'rings-wedding' },
    { key: 'fa fa-sack', label: 'sack' },
    { key: 'fa fa-sack-dollar', label: 'sack-dollar' },
    { key: 'fa fa-salad', label: 'salad' },
    { key: 'fa fa-sandwich', label: 'sandwich' },
    { key: 'fa fa-sausage', label: 'sausage' },
    { key: 'fa fa-shish-kebab', label: 'shish-kebab' },
    { key: 'fa fa-sickle', label: 'sickle' },
    { key: 'fa fa-soup', label: 'soup' },
    { key: 'fa fa-steak', label: 'steak' },
    { key: 'fa fa-stretcher', label: 'stretcher' },
    { key: 'fa fa-taco', label: 'taco' },
    { key: 'fa fa-tanakh', label: 'tanakh' },
    { key: 'fa fa-tasks-alt', label: 'tasks-alt' },
    { key: 'fa fa-trash-restore', label: 'trash-restore' },
    { key: 'fa fa-trash-restore-alt', label: 'trash-restore-alt' },
    { key: 'fa fa-tree-palm', label: 'tree-palm' },
    { key: 'fa fa-user-hard-hat', label: 'user-hard-hat' },
    { key: 'fa fa-user-headset', label: 'user-headset' },
    { key: 'fa fa-user-md-chat', label: 'user-md-chat' },
    { key: 'fa fa-user-nurse', label: 'user-nurse' },
    { key: 'fa fa-users-medical', label: 'users-medical' },
    { key: 'fa fa-walker', label: 'walker' },
    { key: 'fa fa-webcam', label: 'webcam' },
    { key: 'fa fa-webcam-slash', label: 'webcam-slash' },
    { key: 'fa fa-wave-square', label: 'wave-square' },
  ];
}
