
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import TaskCard from '@/components/TaskCard.vue';
  import {
    OccurrenceUpdateRecipientDto,
    SpecialOccurrenceAccessLevel,
    SpecialOccurrenceUpdateDto,
    SpecialOccurrenceUpdatesDto,
    TechnicalOccurrenceDto,
  } from '@/apiclient/apiclient_generated';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import RouteNames from '@/types/routeNames';
  import PublicConstants from '@/types/publicConstants';

  @Component({
    components: {
      TaskCard,
      LoadingSpinner,
    },
  })
  export default class TechnicalOccurrenceUpdates extends Vue {
    isSaving = false;
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    update: SpecialOccurrenceUpdateDto = new SpecialOccurrenceUpdateDto();

    private manualRecipients: OccurrenceUpdateRecipientDto[] = [];
    private chosenRecipients: OccurrenceUpdateRecipientDto[] = [];

    publicConstants = PublicConstants;

    async mounted() {
      await this.store.loadSpecialOccurrenceUpdates(this.$route.params.occId);
      await this.store.loadOccurrenceUpdateRecipients();

      this.setDefaultRecipients();

      if (this.hasSOUpdateValues(this.store.soUpdateBackup)) {
        this.update = this.store.soUpdateBackup;
      }
    }

    get isOccurrenceFilledWithMandatoryData() {
      return (
        this.occurrence.name &&
        this.occurrence.technicalOccurrenceTypeId &&
        this.occurrence.aircraft &&
        this.occurrence.airport &&
        this.occurrence.groundTimeAta &&
        this.occurrence.scheduledTimeOfDeparture &&
        this.occurrence.generalRemarks
      );
    }

    private setDefaultRecipients() {
      Vue.set(this.update, 'recipients', []);

      const defaults = this.allReceiversList.filter((_) => _.isDefault);

      if (defaults.length) {
        const recipients: any = defaults.map((_) => {
          return {
            id: _.id,
            name: _.name,
            email: _.email,
          };
        });

        this.chosenRecipients = recipients;
      }
    }

    private hasSOUpdateValues(update: SpecialOccurrenceUpdateDto) {
      return update && Object.values(update).some((t) => t || t?.length);
    }

    beforeDestroy() {
      let updateBackupData = null;

      if (
        this.$route.name === RouteNames.specialOccurrence.root ||
        this.$route.name === RouteNames.specialOccurrence.attachments ||
        this.$route.name === RouteNames.specialOccurrence.taskGeneration ||
        this.$route.name === RouteNames.specialOccurrence.completion ||
        this.$route.name === RouteNames.specialOccurrence.accessLevels
      ) {
        updateBackupData = this.hasSOUpdateValues(this.update) ? this.update : null;
      }

      this.store.setSpecialOccurrenceUpdatesBackup(updateBackupData);
      this.store.setSpecialOccurrenceUpdates({} as SpecialOccurrenceUpdatesDto);
    }

    getEmailName(email) {
      return this.allReceiversList.find((_) => _.email === email)?.name || email.substr(0, email.indexOf('@'));
    }

    get updateIsValid() {
      return (
        this.update.text &&
        (this.update.includeEstimatedDelay ? this.update.estimatedDelayHours && this.update.estimatedDelayDescription : true)
      );
    }

    get allReceiversList() {
      return this.store.updateRecipients.concat(this.manualRecipients);
    }

    get occurrence(): TechnicalOccurrenceDto {
      return this.store.currentOccurrence;
    }

    get updates() {
      return this.lodash.orderBy(this.occurrence.specialOccurrenceUpdates, 'createdDate', 'desc');
    }

    get userCanEdit() {
      return (
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin ||
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor
      );
    }

    addRecipient(tag) {
      const recipient = new OccurrenceUpdateRecipientDto();
      recipient.email = tag;
      recipient.name = tag;
      this.manualRecipients.push(recipient);
      this.chosenRecipients.push(recipient);
    }

    async saveUpdate() {
      if (!this.updateIsValid || this.isSaving) {
        return;
      }

      this.isSaving = true;
      const recipients = this.chosenRecipients ? this.chosenRecipients.map((_) => _.email) : [];

      const updateObj = new SpecialOccurrenceUpdateDto(this.update);
      updateObj.specialOccurrenceId = this.$route.params.occId;
      updateObj.recipients = recipients;

      await this.store.createSpecialOccurrenceUpdate(updateObj);
      this.update = new SpecialOccurrenceUpdateDto();
      this.isSaving = false;

      this.setDefaultRecipients();
    }
  }
