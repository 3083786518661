
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import { BoardItemCreateFromTemplateDto, BoardItemDto, BoardItemStatusDto } from '@/apiclient/apiclient_generated';
  import TaskCard from '@/components/TaskCard.vue';
  import lodash from 'lodash';
  import { UserModule, UserStore } from '@/store/user';

  @Component({
    components: {
      TaskCard,
    },
  })
  export default class SOTaskGeneration extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private userStore: UserModule = UserStore;
    private search = '';
    private showTemplates = true;
    private openedTemplates = [];
    private loadingTemplateIds = [];

    get boardLists(): BoardItemStatusDto[] {
      return [BoardItemStatusDto.Planned, BoardItemStatusDto.InProgress, BoardItemStatusDto.Done];
    }

    private isOpened(boardId, id) {
      return this.openedTemplates.some((_) => _.boardId === boardId && _.id === id);
    }

    toggleTemplate(boardId, id) {
      const index = this.openedTemplates.findIndex((_) => _.boardId === boardId && _.id === id);

      if (index >= 0) {
        this.openedTemplates.splice(index, 1);
      } else {
        this.openedTemplates.push({ boardId, id });
      }
    }

    get showNoAvailableTasks() {
      return !this.soBoards || !this.soBoards.length || this.soBoards.every((_) => !_.items || !_.items.length);
    }

    async mounted() {
      this.store.clearData();

      await this.store.getTemplates(this.$route.params.occId);
      await this.store.getSOBoards(this.$route.params.occId);
      await this.store.getActiveAndArchivedOccurrences();
    }

    get occurrence() {
      return this.store.currentOccurrence;
    }

    get soBoards() {
      const boards = this.store.soBoards;

      boards.forEach((b: any) => {
        const soBoard: any = this.store.soTemplates.find((_) => _.boardId === b.boardId);
        b.sortOrder = soBoard?.sortOrder;
      });

      return this.lodash.orderBy(boards, 'boardTitle');
    }

    get templates(): any[] {
      const soTemplates: any = this.store.soTemplates;
      const searchTemplate = this.search.toLowerCase();

      for (let bi = 0; bi < soTemplates.length; bi++) {
        const board = soTemplates[bi];

        const filteredTemplates = this.search.length
          ? board.templates.filter(
              (_) => _.title.toLowerCase().includes(searchTemplate) || _.description.toLowerCase().includes(searchTemplate)
            )
          : board.templates;

        board.filteredTemplates = filteredTemplates;
      }

      return lodash.orderBy(soTemplates, 'boardTitle');
    }

    async createBoardItem(boardId, templateId, flightLegEventId) {
      this.loadingTemplateIds.push(templateId);

      const popover: any = this.$refs['createItemFromTemplateRef_' + templateId + '_' + boardId];
      if (popover && popover[0]) {
        popover[0].$emit('close');
      }

      await this.store.createSpecialOccurrenceBoardItem({
        specialOccurrenceId: this.$route.params.occId,
        templateId: templateId,
        flightLegEventId: flightLegEventId,
      } as BoardItemCreateFromTemplateDto);

      this.loadingTemplateIds = this.loadingTemplateIds.filter((_) => _ != templateId);
    }

    filteredBoardItems(status: BoardItemStatusDto, items: BoardItemDto[]) {
      return items.filter((_) => _.boardItemStatus === status);
    }

    filteredSelectedFlights(flights) {
      return flights ? lodash.orderBy(flights, 'scheduledTimeOfDeparture') : [];
    }

    addNewBoardItem(boardId) {
      this.$router.push({ name: 'so-item', params: { itemid: 'new', board: boardId, occId: this.$route.params.occId } });
    }
  }
