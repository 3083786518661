
  import Vue from 'vue';
  import { Container, Draggable } from 'vue-smooth-dnd';
  import TaskCard from '@/components/TaskCard.vue';
  import TaskDetail from '@/components/TaskDetail/TaskDetail.vue';
  import Component from 'vue-class-component';
  import {
    BoardItemDto,
    BoardItemListDto,
    BoardItemStatusDto,
    IBoardDto,
    IBoardItemDto,
    IBoardItemListDto,
    IBoardItemTemplateViewDto,
    LabelDto,
    ListGroupTypeDto
  } from '@/apiclient/apiclient_generated';
  import { BoardModule, BoardStore } from '@/store/board';
  import { LabelModule, LabelStore } from '@/store/label';
  import { NotificationModule, NotificationStore } from '@/store/notification';
  import PageHeader from '@/components/PageHeader.vue';
  import ShiftHandover from '@/components/ShiftHandover.vue';
  import { DateTime } from 'luxon';
  import BISeparator from '@/types/boardItemSeparator';
  import RouteNames from '@/types/routeNames';
  import { ShiftModule, ShiftStore } from '@/store/shift';
  import { SettingsModule, SettingsStore } from '@/store/settings';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import { BoardItemTemplateModule, BoardItemTemplateStore } from '@/store/boardItemTemplate';
  import UserTimeZone from '@/components/User/UserTimeZone.vue';
  import MocDueDateCalculator from '@/components/Misc/MocDueDateCalculator.vue';
  import SOCard from '@/components/SOCard.vue';
  import { UserModule, UserStore } from '@/store/user';

  @Component({
    components: {
      SOCard,
      MocDueDateCalculator,
      UserTimeZone,
      PageHeader,
      Container,
      Draggable,
      TaskCard,
      TaskDetail,
      ShiftHandover,
      ModalWindow,
    },
  })
  export default class BoardComponent extends Vue {
    private boardStore: BoardModule = BoardStore;
    private labelStore: LabelModule = LabelStore;
    private userStore: UserModule = UserStore;
    private templateStore: BoardItemTemplateModule = BoardItemTemplateStore;
    private occurrenceStore: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private settingsStore: SettingsModule = SettingsStore;
    private shiftStore: ShiftModule = ShiftStore;
    private notificationStore: NotificationModule = NotificationStore;
    private itemIdToDelegate = '';

    displayIsRelevantTasksOnly = false;
    private selectedCategories: string[] = [];
    private selectedLabels: LabelDto[] = [];
    private selectedOcurrences = [];
    private selectedUser = null;

    ListGroupType = ListGroupTypeDto;
    searchString = '';
    toggledSeparators: any[] = [];
    toggledLists: any[] = [];
    boardItemStatus = BoardItemStatusDto;
    showAllInfo = false;
    routes = RouteNames;

    private dndParams: any = {};
    boardItemPlannedDate = null;

    get displayDemoButtons() {
      return window.location.href.indexOf('azurewebsite') != -1 || window.location.href.indexOf('localhost') != -1;
    }

    get canArchiveDone(): boolean {
      //only available on boards which dont use shifts
      if (this.boardStore.board.enableShifts) return false;

      if (this.userStore.user.isAdmin || this.userStore.user.isSuperAdmin) return true;

      if (
        this.userStore.user &&
        this.userStore.user.boardPermissions &&
        this.userStore.user.boardPermissions.find((_) => _.boardGuid == this.boardStore.board.id)?.isBoardAdmin
      )
        return true;

      return false;
    }

    async demo(e) {
      await this.boardStore.addBoardItemFromTemplate(this.availableTemplates[e].id);
    }

    toggleList(listId) {
      const index = this.toggledLists.indexOf(listId);
      if (index >= 0) {
        this.toggledLists.splice(index, 1);
      } else {
        this.toggledLists.push(listId);
      }
    }

    get availableSpecialOccurrences() {
      const soIds = this.lodash.uniq(this.boardStore.boardItems.map((_) => _.specialOccurrenceId)).filter((_) => !!_);

      if (!soIds || !soIds.length) {
        return [];
      }

      const result = [];

      const occurrences = [
        ...this.occurrenceStore.activeAndArchivedOccurrences.activeSpecialOccurrences,
        ...this.occurrenceStore.activeAndArchivedOccurrences.archivedSpecialOccurrences,
      ];

      soIds.forEach((_) => {
        const so = occurrences.find((occ) => occ.id === _);

        if (so) {
          result.push({
            id: so.id,
            name: so.name,
          });
        }
      });

      return result;
    }

    get boardOccurrences() {
      const occurrences = this.occurrenceStore.groupedOccurrences.filter((_) => _.boardIds.indexOf(this.board.id) !== -1);

      return occurrences;
    }

    get allboardsExceptCurrent() {
      return this.lodash.orderBy(this.boardStore.allBoardsExceptCurrent, 'title');
    }

    get showShiftPreparation() {
      return (
        this.boardStore.board.isValidationEnabled &&
        this.boardStore.board.currentShift &&
        !this.boardStore.board.currentShift.isHandoverPrepared
      );
    }

    get board(): IBoardDto {
      return this.boardStore.board;
    }

    get availableTemplates(): IBoardItemTemplateViewDto[] {
      return this.templateStore.currentBoardTemplates;
    }

    private showAllInfoBtn(list) {
      return !this.isListToggled(list.id) && list.newBoardItemStatus === this.boardItemStatus.Information;
    }

    get currentUserIsBoardAdmin() {
      if (!this.userStore.user || !this.userStore.user.boardPermissions) return false;

      return (
        this.userStore.user.isAdmin ||
        this.userStore.user.boardPermissions.filter((_) => _.boardGuid == this.board.id && _.isBoardAdmin).length > 0
      );
    }

    isValidItemDate(item: IBoardItemDto) {
      if (item.boardItemStatus === this.boardItemStatus.Information && item.validToDate && !this.showAllInfo) {
        const now = DateTime.utc();
        const fromDate = DateTime.fromISO(item.validFromDate);
        const durationFrom = fromDate.diff(now, 'minutes').toObject().minutes;

        const toDate = DateTime.fromISO(item.validToDate);
        const durationTo = toDate.diff(now, 'minutes').toObject().minutes;

        return durationFrom <= 0 && durationTo >= 0;
      }
      return true;
    }

    isListToggled(listId) {
      return this.toggledLists.includes(listId);
    }

    async mounted() {
      await Promise.all([
        this.boardStore.loadAllBoards(),

        this.labelStore.getAllLabelsAsync(this.$route.params.id),
        this.labelStore.loadSelectedBoardLabels(this.$route.params.id),

        this.boardStore.loadData(this.$route.params.id),
        this.templateStore.getTemplatesForBoard(this.$route.params.id),
        this.occurrenceStore.getActiveAndArchivedOccurrences(),
        this.occurrenceStore.loadTechnicalOccurrenceTypes(),
        this.occurrenceStore.getAll(),

        this.occurrenceStore.getAllGroupedAsync(),

        this.occurrenceStore.getAircrafts(),
      ]);

      if (!this.boardStore.board?.id) {
        return;
      }

      await this.$opstaskhub.subscribeToBoard(this.boardStore.currentBoardId);
      await this.notificationStore.loadAllNotifications(this.$route.params.id);

      // recalculate times etc.
      setInterval(() => this.$forceUpdate(), 30000);
    }

    async created() {
      this.$root.$on('boardItem::labelSelected', (label: LabelDto) => {
        if (!this.selectedLabels.some((_) => _.id === label.id)) {
          this.selectedLabels.push(label);
        }
      });

      this.$root.$on('boardItem::userSelected', (user) => {
        this.selectedUser = user;
      });
    }

    async beforeDestroy() {
      if (this.boardStore.board?.id) {
        await this.$opstaskhub.leaveBoard(this.boardStore.currentBoardId);
      }

      this.occurrenceStore.setGroupedOccurrences([]);
    }

    destroyed() {
      //prevent flickering of boardItems of "previous" board
      this.boardStore.cleanup();
    }

    get currentShiftFrom() {
      return this.boardStore.board.currentShift.dateFrom;
    }

    get currentShiftTo() {
      return this.boardStore.board.currentShift.dateTo;
    }

    async delegate(newBoard) {
      const currentBoardId = this.boardStore.currentBoardId;
      const newBoardId = newBoard.id;
      const boardItemId = this.itemIdToDelegate;

      const success = () => {
        this.boardStore.delegate({ currentBoardId, newBoardId, boardItemId });
        this.$root.$emit('bv::hide::modal', 'delegate-to');
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to delegate this item to ${newBoard.title} ?`,
        'Confirm delegate to',
        'Yes, delegate',
        'No',
        success,
        null
      );
    }

    async onDrop(collection: BoardItemListDto, dropResult) {
      if (this.isConfidentialMove(dropResult.payload, collection)) {
        //dropping Confidential items is not supported
        return;
      }

      if (collection.listGroupType === ListGroupTypeDto.Shared) {
        //dropping on shared not supported
        return;
      }

      if (collection.listGroupType === ListGroupTypeDto.Delegated && dropResult.addedIndex !== null) {
        const boardItem: IBoardItemDto = dropResult.payload;
        const currentBoard = this.boardStore.currentBoardId;

        if (boardItem.isDelegated && boardItem.ownerBoardId !== currentBoard) {
          this.showModal('reject-delegate');
          return;
        }

        const newIndex = dropResult.addedIndex;

        if (newIndex === 0) {
          this.itemIdToDelegate = dropResult.payload.id;
          this.showModal('delegate-to');
        } else {
          const groupedList = this.filteredBoardItems(collection);
          const boardData = groupedList[newIndex - 1];

          if (!boardData) {
            return;
          }

          const board: any = boardData.key;

          const success = () => {
            this.boardStore.delegate({
              currentBoard,
              newBoardId: board.id,
              boardItemId: dropResult.payload.id,
            });
          };

          ConfirmModal.confirm(
            this,
            `Are you sure you want to delegate this item to ${board.title} ?`,
            'Confirm delegate to',
            'Yes, delegate',
            'No',
            success,
            null
          );
        }
      } else {
        const moveParams = {
          collection: collection.id,
          removedIndex: dropResult.removedIndex,
          addedIndex: dropResult.addedIndex,
          payload: dropResult.payload,
          isFutureItem: false,
        };

        //check if we find a future item above to display modal
        if (collection.displayShiftTime) {
          if (moveParams.addedIndex !== moveParams.removedIndex) {
            const items = this.boardStore.items.filter((_) => _.boardItemListId === collection.id);

            for (let index = 0; index < moveParams.addedIndex; index++) {
              const upperItem: any = items[moveParams.addedIndex - index];
              if (upperItem && upperItem.isFix && upperItem.isFuture && moveParams.addedIndex >= moveParams.removedIndex) {
                moveParams.isFutureItem = true;
                this.boardItemPlannedDate = upperItem.value.toLocal().toFormat("yyyy-MM-dd'T'HH:mm:ss");
                break;
              }
            }
          }
        }

        if (moveParams.isFutureItem) {
          this.dndParams = moveParams;
          this.showModal('proccess-future-item');
        } else {
          await this.boardStore.endDrag(moveParams);
        }
      }
    }

    async endDropFutureBoardItem() {
      this.dndParams.reorder = true;
      this.dndParams.payload.plannedDate = DateTime.fromISO(this.boardItemPlannedDate).toUTC().toFormat("yyyy-MM-dd'T'HH:mm'Z'");
      await this.boardStore.endDrag(this.dndParams);
      this.cancelDropFutureBoardItem();
    }

    cancelDropFutureBoardItem() {
      this.dndParams = {};
      this.boardItemPlannedDate = '';
      this.$root.$emit('bv::hide::modal', 'proccess-future-item');
    }

    get orderedLists(): IBoardItemListDto[] {
      return this.lodash.orderBy(this.boardStore.board.lists, 'sortOrder');
    }

    onDrag(collection, dragResult) {
      this.boardStore.startDrag(dragResult.payload.id);
    }

    getChildPayload(list) {
      return (index) => {
        const data = this.filteredItems.filter((x) => x.boardItemListId === list)[index];
        return data;
      };
    }

    toggleItems(item: any) {
      if (this.toggledSeparators.indexOf(item.sortOrder) != -1) {
        this.toggledSeparators = this.toggledSeparators.filter((_) => _ != item.sortOrder);
      } else {
        this.toggledSeparators.push(item.sortOrder);
      }
    }

    isItemHidden(item: IBoardItemDto) {
      const sortOrder = Math.trunc(item.sortOrder).toString().substr(0, 10) + '00';

      if (this.toggledSeparators.filter((_) => _ == sortOrder).length) return true;
      else return false;
    }

    countSeparatorItems(separator) {
      return this.filteredItems.filter((_) => Math.trunc(_.sortOrder).toString().substr(0, 10) + '00' == separator.sortOrder).length - 1; //-1 because we count the separators;
    }

    processIsRelevantFilter() {
      this.displayIsRelevantTasksOnly = !this.displayIsRelevantTasksOnly;
      this.selectedCategories = [];

      if (!this.displayIsRelevantTasksOnly) {
        this.chooseCategory('all');
      }
    }

    chooseCategory(category) {
      if (category === 'all') {
        this.selectedCategories = [];
        this.displayIsRelevantTasksOnly = false;
      } else {
        this.displayIsRelevantTasksOnly = false;

        const categoryIndex = this.selectedCategories.indexOf(category);
        if (categoryIndex === -1) {
          this.selectedCategories.push(category);
        } else {
          this.selectedCategories.splice(categoryIndex, 1);
        }
      }
    }

    selectNone = false;

    toggleSelectNone() {
      this.selectNone = !this.selectNone;
      if (this.selectNone) {
        this.selectedOcurrences = [];
      }
    }

    chooseOccurrence(occurenceId) {
      if (!occurenceId) {
        this.selectedOcurrences = [];
      } else {
        if (occurenceId === -1) {
          this.selectedOcurrences = [];
          this.availableSpecialOccurrences.forEach((_) => this.chooseOccurrence(_.id));
        } else {
          const occIndex = this.selectedOcurrences.indexOf(occurenceId);
          if (occIndex === -1) {
            this.selectedOcurrences.push(occurenceId);
          } else {
            this.selectedOcurrences.splice(occIndex, 1);
          }
        }
      }
    }

    addTask() {
      this.$router.push({ name: 'BoardItemDetail', params: { itemid: 'new' } });
    }

    private addingTaskFromTemplate = false;

    async addTaskFromTemplate(templateId: string) {
      this.addingTaskFromTemplate = true;
      const boardItem = await this.boardStore.addBoardItemFromTemplate(templateId);
      this.$router.push({ name: 'BoardItemDetail', params: { itemid: boardItem.id } });
      this.addingTaskFromTemplate = false;
    }

    async showModal(name: string) {
      const modal: any = this.$refs[name];
      modal.show();
    }

    private filterBySelectedLabels(source) {
      if (this.selectedLabels?.length) {
        return source.filter(
          (item) => (item as BISeparator).isFix || item.labels.some((label) => this.selectedLabels.some((_) => _.id === label.id))
        );
      }
      return source;
    }

    private filterBySelectedOcurrences(source) {
      if (this.selectedOcurrences?.length) {
        return source.filter((item) => (item as BISeparator).isFix || this.selectedOcurrences.indexOf(item.specialOccurrenceId) !== -1);
      }

      if (this.selectNone) {
        return source.filter((item) => (item as BISeparator).isFix || item.specialOccurrenceId === null);
      }
      return source;
    }

    private filterBySelectedCategories(source) {
      if (this.selectedCategories?.length) {
        return source.filter((item) => (item as BISeparator).isFix || this.selectedCategories.indexOf(item.boardItemCategory?.id) !== -1);
      } else if (this.displayIsRelevantTasksOnly) {
        return source.filter((item) => (item as BISeparator).isFix || item.isRelevantForReport);
      }
      return source;
    }

    private filterBySelectedUser(source) {
      if (this.selectedUser?.id) {
        return source.filter((item) => (item as BISeparator).isFix || item.assignedToUser?.id === this.selectedUser.id);
      }
      return source;
    }

    private filterByAllSelectedFilters(source) {
      source = this.filterBySelectedCategories(source);
      source = this.filterBySelectedOcurrences(source);
      source = this.filterBySelectedLabels(source);
      source = this.filterBySelectedUser(source);
      return source;
    }

    filterBoardItemsWithSearchInput(boardItems) {
      if (this.searchString) {
        boardItems = boardItems.filter((item) => {
          const isFix = (item as BISeparator).isFix;
          const byTitle = item.title ? item.title.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 : false;
          const byDescription = item.description ? item.description.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 : false;

          return isFix || byTitle || byDescription;
        });
      }

      return this.filterByAllSelectedFilters(boardItems);
    }

    get filteredItems(): IBoardItemDto[] {
      const boardItems = this.boardStore.items;

      return this.filterBoardItemsWithSearchInput(boardItems);
    }

    private filteredBoardItems(list: BoardItemListDto) {
      if (list.listGroupType === ListGroupTypeDto.Delegated) {
        let filteredDelegatedItems = [];

        if (this.isFiltersActivated) {
          this.boardStore.groupedDelegatedItems.forEach((delegatedData) => {
            const filteredData = this.filterByAllSelectedFilters(delegatedData.groupedItems);

            if (filteredData?.length) {
              const processedDelegatedData: any = {
                key: delegatedData.key,
                isGrouped: delegatedData.isGrouped,
                groupedItems: filteredData,
              };

              filteredDelegatedItems.push(processedDelegatedData);
            }
          });
        } else {
          filteredDelegatedItems = this.boardStore.groupedDelegatedItems;
        }

        return filteredDelegatedItems;
      }

      if (list.listGroupType === ListGroupTypeDto.Shared) {
        const filteredSharedItems = this.filterBoardItemsWithSearchInput(this.boardStore.sharedBoardItems);

        return this.isFiltersActivated ? this.filterByAllSelectedFilters(filteredSharedItems) : filteredSharedItems;
      }

      const listItems = this.filteredItems.filter((x) => x.boardItemListId === list.id);

      return listItems;
    }

    get isFiltersActivated() {
      return (
        this.selectedLabels?.length ||
        this.selectedCategories?.length ||
        this.selectedOcurrences?.length ||
        this.selectedUser ||
        this.displayIsRelevantTasksOnly
      );
    }

    confirmCleanup() {
      this.$bvModal
        .msgBoxConfirm(
          'ATTENTION! Cleanup will archive all currently active tasks and information items. After cleanup the board will appear empty. Are you sure you want to start the cleanup process?',
          {
            title: 'Confirm cleanup',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Cleanup board',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: false,
          }
        )
        .then((value) => {
          if (value) {
            this.boardStore.cleanupBoard(this.$route.params.id);
          }
        })
        .catch(() => {
          // An error occurred
        });
    }

    confirmArchiveDone() {
      this.$bvModal
        .msgBoxConfirm('This will archive all items in the "Done" list. Proceed?', {
          title: 'Confirm archive',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Archive done items',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.boardStore.archiveDone(this.$route.params.id);
          }
        })
        .catch(() => {
          // An error occurred
        });
    }

    async confirmShiftPreparation() {
      await this.settingsStore.loadShiftValidationSettings(this.$route.params.id);
      let body = '';

      const validationTypes = this.getValidationTypes();

      if (validationTypes?.length) {
        const categories = this.boardStore.groupedSelect;

        validationTypes.forEach((t) => {
          const cat = categories.find((_) => _.items.some((item) => item.id === t));
          const type = cat.items.find((_) => _.id === t);

          body += `<div>- ${cat.text} - ${type.text}</div>`;
        });
      }

      return this.showPrepareShiftHandoverConfirm(body);
    }

    private getValidationTypes() {
      const validationTypes = this.settingsStore.shiftValidationSettings.types.filter((_) => _.isRelevant).map((_) => _.typeId);

      const uniqueSelectedTypes = [...new Set(this.boardStore.boardItems.map((_) => _.boardItemTypeId))];

      uniqueSelectedTypes.forEach((_) => {
        const selectedTypeIndex = validationTypes.indexOf(_);
        if (selectedTypeIndex !== -1) {
          validationTypes.splice(selectedTypeIndex, 1);
        }
      });

      return validationTypes;
    }

    private showPrepareShiftHandoverConfirm(body) {
      const h = this.$createElement;
      const titleVNode = h('div', { class: ['prepare-title'] }, 'Prepare for shift handover');

      const p1 = body
        ? 'If you prepare the board for the shift handover, reminder tasks for the following task types will be created:'
        : `All relevant task types for this board have been used during this shift. Prepare for handover will not create extra tasks.`;

      const messageVNode = h('div', { class: [''] }, [
        h('p', { class: [''] }, p1),
        h('br'),
        h('div', { domProps: { innerHTML: body } }),
        h('br'),
        h('br'),
        h('p', { class: ['prepare-notice'] }, `Relevant Task Types can be configured in the boarding settings`),
      ]);

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: titleVNode,
          buttonSize: 'sm',
          centered: true,
          headerClass: 'border-bottom-0',

          okVariant: 'success',
          okTitle: 'Prepare shift handover',
          hideHeaderClose: false,
          cancelTitle: 'Cancel',
        })
        .then((value) => {
          if (value) {
            this.shiftStore.prepareShiftHandover(this.$route.params.id);
          }
        });
    }

    private removeLabel(label: LabelDto) {
      const index = this.selectedLabels.findIndex((_) => _.id === label.id);

      if (index >= 0) {
        this.selectedLabels.splice(index, 1);
      }
    }

    private isConfidentialMove(boardItem: BoardItemDto, list: BoardItemListDto) {
      return (
        boardItem?.isConfidential && (list.listGroupType === ListGroupTypeDto.Shared || list.listGroupType === ListGroupTypeDto.Delegated)
      );
    }
  }
