
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import {
    IActionDataDto,
    ActionDataTypeDto,
    IBoardItemActionDto,
    ActionDataDto
  } from "@/apiclient/apiclient_generated";
  import ActionDate from '@/components/BoardItemActions/components/ActionDate.vue';
  import ActionMultiSelect from "@/components/BoardItemActions/components/ActionMultiSelect.vue";

  @Component({
    components: {
      ActionMultiSelect,
      ActionDate,
    },
  })
  export default class BoardItemPostAction extends Vue {
    @Prop()
    action: IBoardItemActionDto;
    actionType = ActionDataTypeDto;

    get actionData(): ActionDataDto[] {
      return this.lodash.sortBy(this.action.data, 'sortOrder');
    }

    getRequiredRule(actionData: IActionDataDto) {
      return { required: actionData.rules?.isRequired ?? false };
    }

    getInputRules(actionData: IActionDataDto) {
      const inputRules = this.getRequiredRule(actionData);

      if (inputRules.required) {
        inputRules['max'] = actionData.rules.length;
      }

      return inputRules;
    }

    getMinMaxRules(actionData: IActionDataDto) {
      const minMaxRules = this.getRequiredRule(actionData);

      if (minMaxRules.required) {
        minMaxRules['min_value'] = actionData.rules.min;
        minMaxRules['max_value'] = actionData.rules.max;
      }

      return minMaxRules;
    }

    getTextAreaRules(actionData: IActionDataDto) {
      const textAreaRules = this.getRequiredRule(actionData);

      if (textAreaRules.required) {
        textAreaRules['max'] = actionData.rules.length;
      }

      return textAreaRules;
    }
  }
