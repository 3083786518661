
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import SpecialOccurrence from '@/components/SpecialOccurrence/SpecialOccurrence.vue';
  import { LabelModule, LabelStore } from '@/store/label';
  import { SpecialOccurrenceDto } from '@/apiclient/apiclient_generated';

  @Component({
    components: {
      PageHeader,
      SpecialOccurrence,
    },
  })
  export default class SpecialOccurrenceComponent extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private labelStore: LabelModule = LabelStore;

    get so() {
      return this.store.currentOccurrence;
    }

    async created() {
      await this.labelStore.getGeneralLabelsAsync();
    }

    beforeDestroy() {
      this.store.setCurrentOccurrence({} as SpecialOccurrenceDto);
    }
  }
