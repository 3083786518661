
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { UserModule, UserStore } from '@/store/user';
  import { BoardModule, BoardStore } from '@/store/board';
  import AuthService from '@/services/auth.service';
  import { AUTH_MODE } from '@/config';

  @Component({})
  export default class PageHeader extends Vue {
    private userStore: UserModule = UserStore;
    private boardStore: BoardModule = BoardStore;
    private authService: AuthService = new AuthService();
    private authMode: string = AUTH_MODE;

    logout() {
      this.authService.logout();
    }
  }
