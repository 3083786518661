
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
  import {
    Bold,
    BulletList,
    Doc,
    FontSize,
    FontType,
    HardBreak,
    Italic,
    ListItem,
    OrderedList,
    Paragraph,
    Table,
    TableCell,
    TableHeader,
    TableRow,
    Text,
    TextColor,
    TextHighlight,
    TrailingNode,
    Underline,
  } from 'element-tiptap';

  @Component
  export default class GroupedSelect extends Vue {
    @Prop() value!: number;
    @Prop() disabled!: boolean;
    @Prop({ default: true }) simpleMode!: boolean;

    get extensions(): any[] {
      return [
        new Doc(),
        new Text(),
        new Paragraph(),
        //@ts-ignore
        new TextColor({
          colors: ['#FFCC00', '#5F6A6D', '#e74c3c', '#27ae60', '#2c3e50', '#f39c12'],
          menubar: !this.simpleMode,
        }),
        //@ts-ignore
        new TextHighlight({
          colors: ['#FFCC00', '#5F6A6D', '#e74c3c', '#27ae60', '#2c3e50', '#f39c12'],
          menubar: !this.simpleMode,
        }),
        //@ts-ignore
        new FontSize({ menubar: !this.simpleMode }),
        new Bold(),
        new Underline(),
        new Italic(),
        new ListItem(),
        new BulletList(),
        new OrderedList(),
        new HardBreak(),
        //@ts-ignore
        new Table({ menubar: !this.simpleMode }),
        new TableHeader(),
        new TableRow(),
        new TableCell(),
        new HardBreak(),
        new TrailingNode(),
        //@ts-ignore
        new FontType({ menubar: !this.simpleMode }),
      ];
    }

    editorProperties: any = {
      editorProps: {
        handlePaste: this.paste,
        transformPastedHTML: this.transform
      },
    };

    focus(position: string) {
      //@ts-ignore
      (this.$refs.editor.editor as any).focus(position);
    }

    blur() {
      this.$emit('blur');
    }

    @Emit('input')
    onEditorUpdate(val) {
      return val;
    }

    transform(e) {
      const d = document.createElement('p');
      d.innerHTML = this.stripTags(e).replace(/\n\n\n/g, '\n').replace(/\n/ig, '<br>').replace('<br><br><br>', '<br>');
      return d.innerHTML;
    }

    stripTags(str) {
      const tmp = document.createElement("div");
      tmp.innerHTML = str
          .replace(/(<(br[^>]*)>)/ig, '\n')
          .replace(/(<(p[^>]*)>)/ig, '\n')
          .replace(/(<(div[^>]*)>)/ig, '\n')
          .replace(/(<(h[1-6][^>]*)>)/ig, '\n')
          .replace(/(<(li[^>]*)>)/ig, '\n')
          .replace(/(<(ul[^>]*)>)/ig, '\n')
          .replace(/(<(ol[^>]*)>)/ig, '\n')
          .replace(/(<(blockquote[^>]*)>)/ig, '\n')
          .replace(/(<(pre[^>]*)>)/ig, '\n')
          .replace(/(<(hr[^>]*)>)/ig, '\n')
          .replace(/(<(table[^>]*)>)/ig, '\n')
          .replace(/(<(tr[^>]*)>)/ig, '\n')
          .replace(/(<(td[^>]*)>)/ig, '\n')
          .replace(/(<(th[^>]*)>)/ig, '\n')
          .replace(/(<(caption[^>]*)>)/ig, '\n')
          .replace(/(<(dl[^>]*)>)/ig, '\n')
          .replace(/(<(dt[^>]*)>)/ig, '\n')
          .replace(/(<(dd[^>]*)>)/ig, '\n')
          .replace(/(<(address[^>]*)>)/ig, '\n')
          .replace(/(<(section[^>]*)>)/ig, '\n')
          .replace(/(<(article[^>]*)>)/ig, '\n')
          .replace(/(<(aside[^>]*)>)/ig, '\n');
      return tmp.textContent || tmp.innerText || "";
    }

    paste(a, evt) {
      console.log(evt);
      const items = (evt.clipboardData || evt.originalEvent.clipboardData).items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();

          reader.onload = async (e) => {
            this.$emit('imagepasted', e.target.result);
          };
          reader.readAsDataURL(blob);
          return true;
        }
      }
    }
  }
