
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { materialRequestClient } from '@/apiclient/opsboardapi';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { Prop } from 'vue-property-decorator';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';

  @Component({
    components: { ModalWindow, LoadingSpinner },
  })
  export default class VendorRequestEmail extends Vue {
    @Prop() vendorRequestId: string;
    @Prop() type: string;
    loading = true;
    email = '';

    async mounted() {
      await this.loadData();
    }

    async loadData() {
      this.loading = true;
      if (this.type == 'request') {
        this.email = await materialRequestClient.getVendorRequestEmailById(this.vendorRequestId);
      }

      if (this.type == 'management') {
        this.email = await materialRequestClient.getManagementStatusEmailById(this.vendorRequestId);
      }

      this.loading = false;
    }
  }
