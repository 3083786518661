
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import DateRangeDto from '@/types/dateRangeDto';
  import BoardItemDatePicker from '@/components/BoardItem/BoardItemDatePicker.vue';
  import BoardItemOverview from '@/components/Overview/BoardItemOverview.vue';
  import { Watch } from 'vue-property-decorator';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import { debounce } from 'vue-debounce';
  import { IBoardItemDto, ILabelDto, LabelDto } from '@/apiclient/apiclient_generated';
  import { overviewClient } from '@/apiclient/opsboardapi';
  import lodash from 'lodash';
  import AddLabelsFilter from "@/components/Labels/AddLabelsFilter.vue";

  @Component({
    components: {
      AddLabelsFilter,
      BoardItemDatePicker,
      BoardItemOverview,
      LoadingSpinner,
    },
  })
  export default class BoardOverview extends Vue {
    private search = '';
    private loading = false;
    private searchDateRange: DateRangeDto = new DateRangeDto();
    private selectedCategories: string[] = [];
    private selectedLabels: LabelDto[] = [];
    private items: IBoardItemDto[] = [];
    private labels: ILabelDto[] = [];

    async created() {
      await this.submitSearch(true);

      this.$root.$on('boardItemOverview::labelSelected', (label: LabelDto) => {
        if (!this.selectedLabels.some((_) => _.id === label.id)) {
          this.selectedLabels.push(label);
        }
      });
    }

    destroyed() {
      this.$root.$off('boardItemOverview::labelSelected');
    }

    async submitSearch(immediate = false) {
      const wait = immediate ? '0ms' : '1000ms';

      debounce(async () => {
        this.loading = true;
        this.selectedLabels = [];
        this.selectedCategories = [];
        this.items = await overviewClient.getBoardOverview(
          this.$route.params.id,
          this.searchDateRange.start,
          this.searchDateRange.end,
          true,
          this.search
        );
        this.loading = false;
      }, wait)();
    }

    chooseCategory(category) {
      if (category === 'all') {
        this.selectedCategories = [];
      } else {
        const categoryIndex = this.selectedCategories.indexOf(category);
        if (categoryIndex === -1) {
          this.selectedCategories.push(category);
        } else {
          this.selectedCategories.splice(categoryIndex, 1);
        }
      }
    }

    get filteredItems(): IBoardItemDto[] {
      let items = this.items;
      if (this.selectedCategories?.length) {
        items = this.items.filter((item) => this.selectedCategories.indexOf(item.boardItemCategory?.id) !== -1);
      }

      if (this.selectedLabels?.length) {
        items = items.filter((item) => item.labels.some((label) => this.selectedLabels.some((_) => _.id === label.id)));
      }

      return items;
    }

    get availableCategories() {
      if (!this.items?.length) {
        return [];
      }

      return lodash.uniqBy(lodash.map(this.items, 'boardItemCategory'), (_) => _?.id);
    }

    private removeLabel(label: LabelDto) {
      const index = this.selectedLabels.findIndex((_) => _.id === label.id);

      if (index >= 0) {
        this.selectedLabels.splice(index, 1);
      }
    }

    get filteredLabels() {
      return this.labels;
    }

    async addLabel(label) {
      if (!this.selectedLabels.some((_) => _.id === label.id)) {
        this.selectedLabels.push(label);
      }
    }

    @Watch('searchDateRange')
    changeFromDate() {
      this.submitSearch(true);
    }

    navigateToBoardItem(id) {
      this.$router.push({
        name: 'boardItemOverview',
        params: { boardItemOverviewId: id },
      });
    }
  }
