
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import {
    AircraftDto,
    AirportDto,
    SpecialOccurrenceAccessLevel,
    SpecialOccurrenceFlightInsertDto,
    TechnicalOccurrenceDto,
  } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';
  import { GlobalHelper } from '@/helpers/global-helper';
  import TechnicalOccurrenceFlightsSelector from '@/components/SpecialOccurrence/Data/Flights/TechnicalOccurrenceFlightsSelector.vue';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import PublicConstants from '@/types/publicConstants';

  @Component({
    components: { LoadingSpinner, TechnicalOccurrenceFlightsSelector },
  })
  export default class TechnicalOccurrenceData extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    @Prop() occurrence: TechnicalOccurrenceDto;

    addFlightsLoading = false;
    publicConstants = PublicConstants;
    async mounted() {
      await this.store.loadMaintenanceCompanies();
      await this.store.loadTechnicalOccurrenceTypes();
      await this.store.loadSelectedFlightReasons();

      this.$forceUpdate();
    }

    get stationInfo(): AirportDto {
      return this.store.airports.find((_) => _.id == this.occurrence.airport) || new AirportDto();
    }

    get aircraftInfo(): AircraftDto {
      return this.store.aircrafts.find((_) => _.id == this.occurrence.aircraft) || new AircraftDto();
    }

    get airports() {
      return this.store.airports;
    }

    get aircrafts() {
      return this.store.aircrafts;
    }

    get soTypes() {
      return this.store.technicalOccurrenceTypes;
    }

    get maintenanceCompanies() {
      return this.lodash.orderBy(this.store.maintenanceCompanies, 'name');
    }

    get isFreeCompanySelected() {
      return this.store.maintenanceCompanies.some((_) => _.id === this.occurrence.maintenanceCompanyId && _.name.toLowerCase() === 'free');
    }

    get selectedReasons() {
      return this.store.selectedFlightReasons.map((_) => _.name);
    }

    get errorTitle(): string {
      if (this.modelInvalid) {
        return 'Please select Aircraft and Start date';
      }
      return '';
    }

    get modelInvalid(): boolean {
      const occ = this.occurrence as TechnicalOccurrenceDto;
      return !occ.aircraft || !occ.groundTimeAta;
    }

    get userCanEdit(): boolean {
      return (
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin ||
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor
      );
    }

    aircraftSelected() {
      const airportId = this.aircraftInfo.previousFlightArrAirportId;
      const airport = this.airports.find((_) => _.id == airportId.toString());

      if (airport && !this.occurrence.airport) {
        this.occurrence.airport = airport.id.toString();
      }
    }

    useAsStd(std) {
      this.occurrence.scheduledTimeOfDeparture = std;
    }

    useAsAta(ata) {
      this.occurrence.groundTimeAta = ata;
    }

    async saveChanges() {
      await this.store.saveOccurrenceDataChanges(this.occurrence);
      GlobalHelper.NotifySuccess('Special Occurrence updated');

      await this.store.loadData();
    }

    async saveFlights(flights) {
      this.store.currentOccurrence.flights = [];
      this.store.currentOccurrence.flights = [...flights];

      const dto = {
        occurrenceId: this.occurrence.id,
        flights: this.store.currentOccurrence.flights,
      } as SpecialOccurrenceFlightInsertDto;

      await this.store.saveOccurrenceFlights(dto);

      GlobalHelper.NotifySuccess('Special Occurrence flights updated');
    }

    async addAffectedFlights() {
      this.addFlightsLoading = true;

      await this.store.saveOccurrenceDataChanges(this.occurrence);
      await this.store.getAogFlights((this.occurrence as TechnicalOccurrenceDto).groundTimeAta);

      this.addFlightsLoading = false;

      const modal: any = this.$refs['addFlights'];
      modal.show();
    }

    closeModal() {
      const modal: any = this.$refs['addFlights'];
      modal.hide();
    }

    async removeFlight(flight) {
      const index = this.occurrence.flights.findIndex((_) => _.id === flight.id);
      this.occurrence.flights.splice(index, 1);
      await this.saveFlights(this.occurrence.flights);
    }
  }
