import {
  AirportDto,
  LookupClient
} from "@/apiclient/apiclient_generated";
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';

@Module({ dynamic: true, namespaced: true, store, name: 'lookup' })
export class LookupModule extends VuexModule {
  private client = new LookupClient(ApiUrl, axios);
  airports: AirportDto[] = [];

  @Action
  async loadLookupData() {
    this.setAirports(await this.client.getAirports());
  }

  @Mutation
  setAirports(airports: AirportDto[])
  {
    this.airports = airports;
  }
}

export const LookupStore = getModule(LookupModule);
