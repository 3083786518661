export default class RouteNames {
  static settings = 'settings';
  static shiftValidationSettings = 'shift-validation';
  static applicationSettings = 'application-settings';

  static biTemplates = 'templates';

  static emailToBoard = 'emailtoboard';

  static boardItemTemplates = 'boarditemtemplates';
  static boardItemTemplateEdit = 'boardItemTemplateEdit';
  static users = 'users';
  static unauthorized = 'unauthorized';

  static usersettings = 'usersettings';
  static notificationsettings = 'notificationsettings';
  static userselect = 'userselect';
  static boards = 'Boards';

  static specialOccurrence = {
    root: 'special-occurrence',
    taskGeneration: 'task-generation',
    completion: 'completion',
    accessLevels: 'accesslevels',
    attachments: 'attachments',
    updates: 'updates',
    materialRequestLink: 'materialRequestLink',
    overview: 'so-overview',
    overviewRoot: 'data',
    attachmentitem: 'so-item-attachment',
    task: 'so-item',
  };

  static generalSettings = {
    materialRequest: {
      name: 'Material Request',
      path: '/boards-settings/mr-emails',
    },
  };

  static sendAsEmailSettings = 'sendasemailsettings';

  static boardsSettings = {
    path: '/boards-settings',
    name: 'boards-settings',
  };
  static labels = {
    generalLabels: {
      name: 'general-labels',
    },
    boardLabels: {
      name: 'board-labels',
      path: '/settings/:id/labels',
    },
    statistic: {
      name: 'labels-statistic',
      path: '/labels-statistic/:id',
    },
  };
}
