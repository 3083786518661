import {
  AircraftDto,
  AirportDto,
  BoardDto,
  BoardItemCreateFromTemplateDto,
  BoardItemDto,
  BoardItemTemplateSpecialOccurenceDto,
  ComatRoutingDto,
  CompleteOccurrenceDto,
  FlightInfoDto,
  IDiversionReasonDto,
  LookupClient,
  MaintenanceCompanyDto,
  OccurrenceUpdateRecipientDto,
  SelectedFlightReasonDto,
  SpecialOccurrenceAccessLevel,
  SpecialOccurrenceAccessLevelDto,
  SpecialOccurrenceAccessLevelUpdateDto,
  SpecialOccurrenceBoardDto,
  SpecialOccurrenceBoardSummaryDto,
  SpecialOccurrenceClient,
  SpecialOccurrenceDto,
  SpecialOccurrenceFlightInsertDto,
  SpecialOccurrenceListDto,
  SpecialOccurrenceTypeDto,
  SpecialOccurrenceUpdateDto,
  SpecialOccurrenceUpdatesDto,
  SpecialOccurrenceViewDto,
  SpecialOccurrenceViewListDto,
  TechnicalOccurrenceDto,
  TechnicalOccurrenceTypeDto,
} from '@/apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import { GlobalHelper } from '@/helpers/global-helper';

@Module({ dynamic: true, namespaced: true, store, name: 'specialOccurrence' })
export class SpecialOccurrenceModule extends VuexModule {
  private occurrenceClient = new SpecialOccurrenceClient(ApiUrl, axios);
  private lookupClient = new LookupClient(ApiUrl, axios);
  allOccurrences: SpecialOccurrenceDto[] = [];
  activeAndArchivedOccurrences: SpecialOccurrenceViewListDto = new SpecialOccurrenceViewListDto();

  possibleOwnerBoards: BoardDto[] = [];

  maintenanceCompanies: MaintenanceCompanyDto[] = [];
  technicalOccurrenceTypes: TechnicalOccurrenceTypeDto[] = [];
  selectedFlightReasons: SelectedFlightReasonDto[] = [];

  updateRecipients: OccurrenceUpdateRecipientDto[] = [];
  currentOccurrence: SpecialOccurrenceDto = new SpecialOccurrenceDto();
  currentOccurrenceUserAccessLevel: SpecialOccurrenceAccessLevel = SpecialOccurrenceAccessLevel.None;

  currentOccurrenceAccessLevels: SpecialOccurrenceAccessLevelDto[] = [];

  comatRoutings: ComatRoutingDto[] = [];

  airports: AirportDto[] = [];
  aircrafts: AircraftDto[] = [];

  soTemplates: BoardItemTemplateSpecialOccurenceDto[] = [];
  soBoards: SpecialOccurrenceBoardDto[] = [];
  boardSummary: SpecialOccurrenceBoardSummaryDto[] = [];
  aogFlights: FlightInfoDto[] = [];
  diversionFlights: FlightInfoDto[] = [];
  diversionReasons: IDiversionReasonDto[] = [];

  soUpdateBackup = {} as SpecialOccurrenceUpdateDto;

  groupedOccurrences: SpecialOccurrenceListDto[] = [];

  @Action
  async getActiveAndArchivedOccurrences() {
    const data = await this.occurrenceClient.getActiveAndArchivedOccurrences();

    this.setActiveAndArchivedOccurrences(data);
  }

  @Action
  async linkBoardItem(params) {
    const { occurrenceId, boardItemId } = params;
    await this.occurrenceClient.linkBoardItem(occurrenceId, boardItemId);
  }

  @Action
  async unlinkBoardItem(boardItem: string) {
    await this.occurrenceClient.unlinkBoardItem(boardItem);
  }

  @Action
  async createSpecialOccurrenceUpdate(update: SpecialOccurrenceUpdateDto) {
    await this.occurrenceClient.createSpecialOccurrenceUpdate(update);

    GlobalHelper.NotifySuccess('Special Occurrence Update was created');
  }

  @Action
  async getComatTransportReport(occId) {
    return await this.occurrenceClient.getComatTransportReport(occId);
  }

  @Action
  async getComatStickerReport(occId) {
    return await this.occurrenceClient.getComatStickerReport(occId);
  }

  @Action
  async loadSpecialOccurrenceUpdates(occurrenceId) {
    const data = await this.occurrenceClient.getSpecialOccurrenceUpdates(occurrenceId);

    if (occurrenceId === data.occurrenceId) {
      this.setSpecialOccurrenceUpdates(data);
    }
  }

  @Action
  async loadPossibleOwnerBoards() {
    const result = await this.occurrenceClient.getPossibleOwnerBoards();
    this.setPossibleOwnerBoards(result);
  }

  @Mutation
  setPossibleOwnerBoards(possibleBoards: BoardDto[]) {
    this.possibleOwnerBoards = possibleBoards;
  }

  @Action
  async loadOccurrenceUpdateRecipients() {
    const data = await this.occurrenceClient.getOccurrenceUpdateRecipients(this.currentOccurrence.id);
    this.setOccurrenceUpdateRecipients(data);
  }

  @Action
  async loadMaintenanceCompanies() {
    const data = await this.occurrenceClient.getMaintenanceCompanies();
    this.setMaintenanceCompanies(data);
  }

  @Action
  async loadTechnicalOccurrenceTypes() {
    const data = await this.occurrenceClient.getTechnicalOccurrenceTypes();
    this.setTechnicalOccurrenceTypes(data);
  }

  @Action
  async loadSelectedFlightReasons() {
    const data = await this.occurrenceClient.getSelectedFlightReasons();
    this.setSelectedFlightReasons(data);
  }

  @Action
  async loadData() {
    await this.getAirports();
    await this.getAircrafts();

    if (this.currentOccurrence.type === SpecialOccurrenceTypeDto.TechnicalOccurrence) {
      //
    }
    if (this.currentOccurrence.type === SpecialOccurrenceTypeDto.Comat) {
      //
    }
  }

  @Action
  async saveOccurrenceFlights(dto: SpecialOccurrenceFlightInsertDto) {
    this.setCurrentOccurrence(await this.occurrenceClient.saveOccurrenceFlights(dto));
  }

  @Action
  async getTemplates(soId: string) {
    this.setTemplates(await this.occurrenceClient.getBoardItemTemplates(soId));
  }

  @Action
  async getSOBoards(occurrenceId) {
    this.setSOBoards(await this.occurrenceClient.getSpecialOccurrenceBoardItems(occurrenceId));
  }

  @Action
  async getAogFlights(date) {
    this.setAogFlights(await this.occurrenceClient.getAogFlights(date));
  }

  @Action
  async getDiversionFlights() {
    this.setDiversionFlights(await this.occurrenceClient.getDiversionFlights());
  }

  @Action
  async getSpecialOccurrenceBoardSummary(occurrenceId) {
    const summary = await this.occurrenceClient.getSpecialOccurrenceBoardSummary(occurrenceId);
    this.setSpecialOccurrenceBoardSummary(summary);
  }

  @Action
  async getAirports() {
    const data = await this.occurrenceClient.getAirports();
    this.setAirports(data);
  }

  @Action
  async getComatRoutings(params) {
    const { departure, arrival, date } = params;

    const result: ComatRoutingDto[] = await this.occurrenceClient.getComatRoutings(departure, arrival, date);
    this.setComatRoutings(result);
  }

  @Action
  async getAircrafts() {
    const data = await this.occurrenceClient.getAircrafts();
    this.setAircrafts(data);
  }

  @Action
  async saveOccurrenceDataChanges(dto: SpecialOccurrenceDto) {
    const data = await this.occurrenceClient.saveChanges(dto);
    this.setCurrentOccurrence(data);
  }

  @Action
  async getById(id) {
    const occ = await this.occurrenceClient.getById(id);
    const accessLevel = await this.occurrenceClient.getCurrentUserAccessLevel(id);
    this.setCurrentOccurrence(occ);
    this.setCurrentOccurrenceUserAccessLevel(accessLevel);
  }

  @Action
  async complete(dto: CompleteOccurrenceDto) {
    await this.occurrenceClient.complete(dto);

    this.removeCompletedOcc(dto.id);
  }

  @Action
  async getAccessLevels(id) {
    const accessLevels = await this.occurrenceClient.getAccessLevels(id);
    this.setCurrentOccurrenceAccessLevels(accessLevels);
  }

  @Action
  async setAccessLevel(dto: SpecialOccurrenceAccessLevelUpdateDto) {
    await this.occurrenceClient.setAccessLevel(dto);
    await this.getAccessLevels(this.currentOccurrence.id);
  }

  @Action
  async create(dto) {
    const newOcc = await this.occurrenceClient.post(dto);
    await this.getAll();
    return newOcc;
  }

  @Action
  async createSpecialOccurrenceBoardItem(params: BoardItemCreateFromTemplateDto) {
    await this.occurrenceClient.createSpecialOccurrenceBoardItem(params);
    GlobalHelper.NotifySuccess('Board item was created');
  }

  @Action
  async getAll() {
    this.setAll(await this.occurrenceClient.getAll());
  }

  @Action
  async loadDiversionReasons() {
    this.setDiversionReasons(await this.lookupClient.getDiversionReasons());
  }

  @Action
  async getAllGroupedAsync() {
    const groupedOccurrences = await this.occurrenceClient.getAllGrouped();

    this.setGroupedOccurrences(groupedOccurrences);

    return groupedOccurrences;
  }

  @Mutation
  setGroupedOccurrences(occurrences: SpecialOccurrenceListDto[]) {
    this.groupedOccurrences = occurrences;
  }

  @Mutation
  addGroupedOccurrence(occurrence: SpecialOccurrenceListDto) {
    if (!this.groupedOccurrences) {
      this.groupedOccurrences = [];
    }

    this.groupedOccurrences.push(occurrence);
  }

  @Mutation
  updateGroupedOccurrence(occurrence: SpecialOccurrenceListDto) {
    if (!this.groupedOccurrences) {
      this.groupedOccurrences = [];
    }

    const index = this.groupedOccurrences.findIndex((t) => t.id == occurrence.id);

    if (index === -1) {
      this.groupedOccurrences.push(occurrence);
    } else {
      this.groupedOccurrences.splice(index, 1, occurrence);
    }
  }

  @Mutation
  setDiversionReasons(reasons: IDiversionReasonDto[]) {
    this.diversionReasons = reasons;
  }

  @Mutation
  setAirports(airports: AirportDto[]) {
    this.airports = airports;
  }

  @Mutation
  removeCompletedOcc(id) {
    const completedOccIndex = this.allOccurrences.findIndex((_) => _.id == id);
    if (completedOccIndex !== -1) {
      this.allOccurrences.splice(completedOccIndex, 1);
    }
  }

  @Mutation
  setTemplates(boards: BoardItemTemplateSpecialOccurenceDto[]) {
    const paramRegex = new RegExp('{.+?}');

    boards.forEach((b: any, index) => {
      b.sortOrder = index;

      b.templates.forEach((t: any) => {
        const requiresParam =
          t.actions &&
          t.actions.some((ta) => {
            return paramRegex.test(ta.url);
          });

        t.requiresParam = requiresParam;
      });
    });
    this.soTemplates = boards;
  }

  @Mutation
  setSOBoards(boards: SpecialOccurrenceBoardDto[]) {
    this.soBoards = boards;
  }

  @Mutation
  clearData() {
    this.soTemplates = [];
    this.soBoards = [];
  }

  @Mutation
  setSpecialOccurrenceBoardSummary(boardSummary: SpecialOccurrenceBoardSummaryDto[]) {
    this.boardSummary = boardSummary;
  }

  @Mutation
  setAogFlights(flights: FlightInfoDto[]) {
    this.aogFlights = flights;
  }

  @Mutation
  setDiversionFlights(flights: FlightInfoDto[]) {
    this.diversionFlights = flights;
  }

  @Mutation
  setActiveAndArchivedOccurrences(activeAndArchivedOccurrences) {
    this.activeAndArchivedOccurrences = activeAndArchivedOccurrences;
  }

  @Mutation
  setSpecialOccurrenceUpdates(data: SpecialOccurrenceUpdatesDto) {
    const occ = this.currentOccurrence as TechnicalOccurrenceDto;
    occ.specialOccurrenceUpdates = data.updates;
  }

  @Mutation
  setSpecialOccurrenceUpdatesBackup(updates: SpecialOccurrenceUpdateDto) {
    this.soUpdateBackup = updates;
  }

  @Mutation
  addSpecialOccurrenceUpdate(data: SpecialOccurrenceUpdateDto) {
    if (this.currentOccurrence.id === data.specialOccurrenceId) {
      const occ = this.currentOccurrence as TechnicalOccurrenceDto;

      if (!occ.specialOccurrenceUpdates) {
        occ.specialOccurrenceUpdates = [];
      }

      occ.specialOccurrenceUpdates.push(data);
    }
  }

  @Mutation
  setOccurrenceUpdateRecipients(recipients: OccurrenceUpdateRecipientDto[]) {
    this.updateRecipients = recipients;
  }

  @Mutation
  setMaintenanceCompanies(maintenanceCompanies) {
    this.maintenanceCompanies = maintenanceCompanies;
  }

  @Mutation
  setTechnicalOccurrenceTypes(technicalOccurrenceTypes) {
    this.technicalOccurrenceTypes = technicalOccurrenceTypes;
  }

  @Mutation
  setSelectedFlightReasons(selectedFlightReasons) {
    this.selectedFlightReasons = selectedFlightReasons;
  }

  @Mutation
  setComatRoutings(comatRoutings: ComatRoutingDto[]) {
    this.comatRoutings = comatRoutings;
  }

  @Mutation
  setAircrafts(aircrafts: AircraftDto[]) {
    this.aircrafts = aircrafts;
  }

  @Mutation
  setAll(occurrences: SpecialOccurrenceDto[]) {
    this.allOccurrences = occurrences;
  }

  @Mutation
  addNewOccurrence(occurrence: SpecialOccurrenceDto) {
    const index = this.allOccurrences.findIndex((_) => _.id === occurrence.id);
    if (index === -1) {
      this.allOccurrences.push(occurrence);

      if (!this.activeAndArchivedOccurrences.activeSpecialOccurrences.some((_) => _.id === occurrence.id)) {
        this.activeAndArchivedOccurrences.activeSpecialOccurrences.push({
          id: occurrence.id,
          name: occurrence.name,
        } as SpecialOccurrenceViewDto);
      }
    } else {
      this.allOccurrences[index] = occurrence;
    }
  }

  @Mutation
  setCurrentOccurrence(occurrence: SpecialOccurrenceDto) {
    if (occurrence && !occurrence.flights) {
      occurrence.flights = [];
    }
    this.currentOccurrence = occurrence;
  }

  @Mutation
  setCurrentOccurrenceUserAccessLevel(accessLevel: SpecialOccurrenceAccessLevel) {
    this.currentOccurrenceUserAccessLevel = accessLevel;
  }

  @Mutation
  setCurrentOccurrenceAccessLevels(accessLevels: SpecialOccurrenceAccessLevelDto[]) {
    this.currentOccurrenceAccessLevels = accessLevels;
  }

  @Mutation
  processBoardItemUpdate(itemData) {
    const { boardItem, BoardTitle } = itemData;

    if (this.currentOccurrence.id !== boardItem.specialOccurrenceId) {
      return;
    }

    const board = this.soBoards.find((_) => _.boardId == boardItem.initialOwnerBoardId);

    if (board) {
      if (!board.items) {
        board.items = [];
      }

      const oldItemIndex = board.items.findIndex((_) => _.id === boardItem.id);

      if (oldItemIndex >= 0) {
        board.items.splice(oldItemIndex, 1);
      }

      board.items.push(boardItem);
    } else {
      this.soBoards.push({
        boardId: boardItem.initialOwnerBoardId,
        boardTitle: BoardTitle,
        items: [boardItem],
      } as SpecialOccurrenceBoardDto);
    }
  }

  @Mutation
  setSOBoardItem(soBoardItem: any) {
    const siId = soBoardItem.specialOccurrenceId;
    const soBoardId: string = soBoardItem.boardId;
    const boardTitle: string = soBoardItem.boardTitle;
    const soItems: BoardItemDto[] = soBoardItem.items;

    if (this.currentOccurrence.id !== siId) {
      return;
    }

    const index = this.soBoards.findIndex((_) => _.boardId === soBoardId);

    if (index === -1) {
      this.soBoards.push({
        boardId: soBoardId,
        boardTitle: boardTitle,
        items: soItems,
      } as SpecialOccurrenceBoardDto);
    } else {
      this.soBoards[index].items.push(soItems[0]);
    }
  }
}

export const SpecialOccurrenceStore = getModule(SpecialOccurrenceModule);
