
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { UserModule, UserStore } from '@/store/user';
  import { DateTime } from 'luxon';

  @Component({
    components: {},
  })
  export default class BoardItemDatePicker extends Vue {
    private userStore: UserModule = UserStore;

    @Prop() value: string;
    @Prop() placement;
    @Prop() disabled: boolean;
    @Prop({ default: 'dateTime' }) mode: string;
    @Prop({ default: false }) isRange: boolean;
    @Prop() customClass: string;
    @Prop({ default: 1 }) columns;
    @Prop({ default: null }) minDate;
    @Prop({ default: false }) displayQuickAddHours;
    @Prop({ default: false }) hideDateClearBtn;

    attributes = [
      {
        key: 'today',
        highlight: {
          color: 'white',
          fillMode: 'outline',
        },
        dates: new Date(),
      },
    ];

    private innerValue = '';

    mounted() {
      this.innerValue = this.value;
    }

    get popoverPlacement(): string {
      return this.placement ?? 'right';
    }

    get userTimezone(): string | undefined {
      return this.userStore.user.timeZone == 'local' ? undefined : this.userStore.user.timeZone;
    }

    quickAddHours(hours: number) {
      const now = DateTime.utc();
      this.innerValue = now.plus({ hours: hours }).toISO();
      this.save();
    }

    removeDate() {
      this.$emit('input', null);
      this.closePopover();
    }

    save() {
      this.$emit('input', this.innerValue);
      this.closePopover();
    }

    cancel() {
      this.closePopover();
    }

    closePopover() {
      const popover: any = this.$refs.datePopover;
      if (popover) {
        popover.doClose();
      }
    }

    shown() {
      this.$emit('shown');
    }

    hidden() {
      this.$emit('hidden');
    }
  }
