
  import { Component, Emit, Prop, Vue } from "vue-property-decorator";
  import { SelectListItemGroup } from "@/components/Misc/SelectListItemGroup";
  import { sortBy } from 'lodash';

  @Component
  export default class GroupedSelect extends Vue {
    @Prop() options!: SelectListItemGroup[];
    @Prop() value!: number;
    @Prop() disabled!: boolean;

    prepareOptions(options: SelectListItemGroup[]) {
      const result = [];

      sortBy(options, 'text').forEach((group) => {
        result.push({
          id: null,
          isHeader: true,
          group: group.text,
          icon: group.icon,
          label: group.text + group.items.map(i => i.text).join() //for search
        });

        if (!group.items.length)
        {
          result.push({
            id: null,
            text: 'This category has no available types',
            group: group.text,
            isHeader: false,
            icon: '',
            isfix: true,
            label: group.text
          });
        }

        sortBy(group.items, 'text').forEach((item) => {
          result.push({
            id: item.id,
            text: item.text,
            group: group.text,
            isHeader: false,
            icon: item.icon,
            label: group.text + item.text
          });
        });
      });

      return result;
    }

    @Emit("input")
    input(value: any) {
      return value;
    }
  }
