
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { FlightInfoDto } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { DateTime } from 'luxon';
  import { filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
  import { GridColumnProps } from '@progress/kendo-vue-grid';

  @Component({
    components: {
      ModalWindow,
    },
  })
  export default class TechnicalOccurrenceFlightsSelector extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private checkedFlights: FlightInfoDto[] = [];
    private flights: FlightInfoDto[] = [];

    filter: any = null;

    @Prop() registration: string;
    @Prop() date: string;
    @Prop() mode: string;

    get columns(): GridColumnProps[] {
      return [
        {
          field: 'isChecked',
          type: 'boolean',
          title: '',
          filterable: false,
          width: this.mode == 'diversion' ? 90 : 40,
          headerCell: 'customHeader',
        },
        { field: 'dateCalc', title: 'Date From', filterable: true, filterCell: 'filterSlotTemplate', type: 'date' },
        { field: 'flightNo', title: 'FlightNo' },
        { field: 'aircraftRegistration', title: 'Registration' },
        { field: 'departureAirport', title: 'DEP' },
        { field: 'arrivalAirport', title: 'ARR' },
        { field: 'diversionAirport', title: 'DIV' },
        { field: 'stdCalc', title: 'STD', filterable: false },
        { field: 'staCalc', title: 'STA', filterable: false },
        { field: 'flightState', title: 'State' },
      ];
    }

    get sort(): SortDescriptor[] {
      return [{ field: 'stdCalc', dir: 'asc' }];
    }

    filterChange(filter) {
      this.filter = filter.filter;
    }

    async mounted() {
      this.checkedFlights = this.mode == 'diversion' ? [] : this.store.currentOccurrence.flights;
      this.flights = this.mode == 'diversion' ? this.store.diversionFlights : this.store.aogFlights;
    }

    get result() {
      return orderBy(this.filtered, this.sort);
    }

    get filtered() {
      return filterBy(
        this.flights.map((_) => {
          const obj: any = {};
          Object.assign(obj, _);
          obj.dateCalc = DateTime.fromISO(_.date, { zone: 'utc', setZone: true }).toUTC().toJSDate();
          obj.stdCalc = DateTime.fromISO(_.scheduledTimeOfDeparture, { zone: 'utc' }).toJSDate();
          obj.staCalc = DateTime.fromISO(_.scheduledTimeOfArrival, { zone: 'utc' }).toJSDate();
          obj.isChecked = this.checkedFlights.findIndex((cfl) => cfl.id == _.id) != -1;

          return obj;
        }),
        this.filter
      );
    }

    async processFlight(id: string) {
      if (this.mode == 'diversion') {
        this.$emit(
          'flightSelected',
          this.flights.find((_) => _.id == id)
        );
        this.closeModal();
      } else {
        const index = this.checkedFlights.findIndex((_) => _.id == id);

        if (index >= 0) {
          this.checkedFlights.splice(index, 1);
        } else {
          this.checkedFlights.push(this.flights.find((_) => _.id == id));
        }

        await this.saveFlights();
      }
    }

    async closeModal() {
      this.$emit('closeModal');
    }

    async saveFlights() {
      this.$emit('saveFlights', this.checkedFlights);
    }
  }
