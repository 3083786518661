import { SpecialOccurrenceListDto, SpecialOccurrenceTypeDto, TechnicalOccurrenceDto } from '@/apiclient/apiclient_generated';
import RouteNames from '@/types/routeNames';

export default class SpecialOccurrenceService {
  static isTechnicalOccurrence(so: SpecialOccurrenceListDto) {
    return so?.type === SpecialOccurrenceTypeDto.TechnicalOccurrence;
  }

  static isSpecialOccurrenceFulFilled(so: SpecialOccurrenceListDto) {
    return so.isOccurrenceFulFilled;
  }

  static isTechnicalOccurrenceFulFilled(so: TechnicalOccurrenceDto) {
    return (
      so.name &&
      so.technicalOccurrenceTypeId &&
      so.aircraft &&
      so.airport &&
      so.groundTimeAta &&
      so.scheduledTimeOfDeparture &&
      so.generalRemarks
    );
  }

  static getTechnicalOccurrenceRouteName(so: SpecialOccurrenceListDto) {
    const result =
      SpecialOccurrenceService.isTechnicalOccurrence(so) &&
      (SpecialOccurrenceService.isSpecialOccurrenceFulFilled(so) ||
        SpecialOccurrenceService.isTechnicalOccurrenceFulFilled(so as TechnicalOccurrenceDto)) // TODO: change! Input = ListDto, Cast to TechnicalOccurrenceDto "should" not be possible
        ? RouteNames.specialOccurrence.updates
        : RouteNames.specialOccurrence.root;

    return result;
  }
}
