
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import lodash from 'lodash';
  import { BoardModule, BoardStore } from '@/store/board';
  import { UserModule, UserStore } from '@/store/user';

  import {
    BoardItemCommentDto,
    IBoardItemDto,
    IBoardItemCommentDto,
    AttachmentDestination,
    IAttachmentDto,
  } from '@/apiclient/apiclient_generated';
  import { AttachmentsModule, AttachmentsStore } from '@/store/attachments';
  import FileSaver from 'file-saver';
  import { Prop, Watch } from "vue-property-decorator";

  @Component({
    components: {},
  })
  export default class TaskDetailComments extends Vue {
    @Prop() value: boolean;

    private userStore: UserModule = UserStore;
    private boardStore: BoardModule = BoardStore;
    private attachmentssStore: AttachmentsModule = AttachmentsStore;

    private replyComment: IBoardItemCommentDto = null;
    private replyAttachment: IAttachmentDto = null;
    copyEmailToBoardTitle = 'Click to copy E-Mail to Board identifier';

    private comment = '';
    editdescription = false;
    isSaving = false;

    private activeComments = [];

    get readOnly(): boolean {
      return this.item ? this.item.isArchived || this.item.isReadonly : false;
    }

    mounted() {
      this.$root.$on('commentonattachment', (data) => {
        this.replyAttachment = data;
        ((this.$refs.commentInput as any).$el as any).focus();
      });
    }

    destroyed() {
      if (!this.item.boardItemTypeId && this.item.id) {
        this.boardStore.resetBoardItemType(this.item.id);
      }
      this.$root.$off('commentonattachment');
    }

    paste(evt) {
      const items = (evt.clipboardData || evt.originalEvent.clipboardData).items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          const reader = new FileReader();

          reader.onload = async (e) => {
            await this.attachmentssStore.addAttachmentFromBase64({
              destination: AttachmentDestination.BoardItem,
              id: this.item.id,
              imageData: e.target.result,
            });
          };
          reader.readAsDataURL(blob);
        }
      }
    }

    get item(): IBoardItemDto {
      return this.boardStore.currentBoardItem;
    }

    startEditComment(comment) {
      if (!this.activeComments.includes(comment.id)) {
        comment.backup = comment.text;
        this.activeComments.push(comment.id);
      }
    }

    async updateComment(comment: BoardItemCommentDto) {
      const updatedComment = await this.boardStore.updateComment(comment);
      comment.createdDate = updatedComment.createdDate;
      comment.updatedDate = updatedComment.updatedDate;

      const index = this.activeComments.indexOf(comment.id);
      if (index > -1) {
        this.activeComments.splice(index, 1);
      }
    }

    cancelEditComment(comment) {
      const index = this.activeComments.indexOf(comment.id);
      if (index > -1) {
        this.activeComments.splice(index, 1);
        comment.text = comment.backup;
      }
    }

    replyToComment(comment) {
      this.replyComment = comment;
      ((this.$refs.commentInput as any).$el as any).focus();
    }

    couldEditComment(comment: IBoardItemCommentDto) {
      return !comment.isSystemComment && comment.userId === this.userStore.user.id && !this.item.isArchived;
    }

    isEditing(commentId) {
      return this.activeComments.includes(commentId);
    }

    async saveComment() {
      const comment = {} as BoardItemCommentDto;
      comment.boardItemId = this.item.id;
      comment.text = this.comment;
      comment.parentCommentId = this.replyComment ? this.replyComment.id : null;
      comment.attachmentId = this.replyAttachment ? this.replyAttachment.id : null;

      await this.boardStore.saveComment(comment);
      this.comment = '';
      this.replyComment = null;
      this.replyAttachment = null;
    }

    get comments() {
      const comments = this.item.linkedBoardItems.reduce((prev, curr) => {
        curr.comments.forEach((_: any) => {
          _.parentId = curr.id;
          _.parentTitle = curr.title;
        });

        return [...prev, ...curr.comments];
      }, []);

      if (comments.length > 0) {
        comments.forEach((_) => (_.isChildItem = true));
      }

      return lodash.orderBy([...this.item.comments, ...comments], 'createdDate', 'desc');
    }

    showCommentsSection() {
      return this.item.comments?.length > 0 || this.item.linkedBoardItems?.some((_) => _.comments.length > 0);
    }

    copyEmailToBoard() {
      const marker = '#BI:' + this.item.id + '#';
      navigator.clipboard.writeText(marker).then(
        () => {
          const originalTitle = this.copyEmailToBoardTitle;
          this.copyEmailToBoardTitle = 'Copied!';

          setTimeout(() => {
            this.copyEmailToBoardTitle = originalTitle;
          }, 2000);
        },
        function (err) {
          console.log(err);
        }
      );
    }

    scrollToElement(id) {
      const el = this.$refs['comment' + id];
      if (el) {
        el[0].classList.add('highlight');
        el[0].scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          el[0].classList.remove('highlight');
        }, 2000);
      }
    }

    async downloadAttachment(attachment: IAttachmentDto) {
      const fileData = await this.attachmentssStore.downloadAttachment(attachment.id);
      FileSaver.saveAs(fileData.data, fileData.fileName);
    }

    @Watch('comment')
    commentEntered() {
      this.$emit('input', !!this.comment);
    }

    handleEnter(e: KeyboardEvent) {
      if (e.key.toLowerCase() == 'enter' && e.ctrlKey && this.comment) {
        this.saveComment();
      }
    }
  }
