
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import SpecialOccurrenceData from '@/components/SpecialOccurrence/Data/Data.vue';
  import RouteNames from '@/types/routeNames';
  import { BoardModule, BoardStore } from '@/store/board';
  import { SpecialOccurrenceAccessLevel, SpecialOccurrenceTypeDto } from '@/apiclient/apiclient_generated';
  import { systemSettingsClient } from '@/apiclient/opsboardapi';
  import PublicConstants from '@/types/publicConstants';
  import SpecialOccurrenceService from '@/services/specialOccurrence.service';

  @Component({
    components: {
      SpecialOccurrenceData,
    },
  })
  export default class SpecialOccurrence extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private boardStore: BoardModule = BoardStore;
    materialRequestIsEnabled = false;

    copyEmailToSpecialOccurrenceTitle = 'Click to copy email to Special Occurrence identifier';

    private vvieweroptions = {
      inline: false,
      button: false,
      navbar: false,
      title: false,
      toolbar: false,
      tooltip: false,
      movable: true,
      zoomable: true,
      rotatable: true,
      scalable: true,
      transition: true,
      fullscreen: true,
      keyboard: true,
      url: 'data-src',
    };

    get displayFilledWarning() {
      const occurrence = this.store.currentOccurrence;

      const displayWarning =
        SpecialOccurrenceService.isTechnicalOccurrence(occurrence) && !SpecialOccurrenceService.isTechnicalOccurrenceFulFilled(occurrence);

      return displayWarning;
    }
    private navMenu = [
      {
        key: RouteNames.specialOccurrence.root,
        name: 'Data',
        icon: 'far fa-fw fa-cog',
        additionalIcon: 'far fa-info-circle required-warn',
        additionalIconPopover: PublicConstants.fillMandatoryFields,
      },
      {
        key: RouteNames.specialOccurrence.attachments,
        name: 'Attachments',
        icon: 'far fa-fw fa-file',
      },
      {
        key: RouteNames.specialOccurrence.taskGeneration,
        name: 'Task Generation',
        icon: 'far fa-fw fa-check-square',
      },
      {
        key: RouteNames.specialOccurrence.completion,
        name: 'Completion',
        icon: 'far fa-fw fa-plus-square',
      },
    ];

    async mounted() {
      await this.boardStore.loadAllBoards();
      await this.store.loadData();
      this.materialRequestIsEnabled = await systemSettingsClient.getIsMaterialRequestEnabled();
    }

    get currentOccurrence() {
      return this.store.currentOccurrence;
    }

    get displayComatChart() {
      if (!this.currentOccurrence) return false;
      return this.currentOccurrence.type == SpecialOccurrenceTypeDto.Comat;
    }

    get navItems() {
      const occ = this.currentOccurrence;
      const menu = [...this.navMenu];

      switch (occ.type) {
        case SpecialOccurrenceTypeDto.TechnicalOccurrence:
          menu.splice(2, 0, {
            key: RouteNames.specialOccurrence.updates,
            name: 'Updates',
            icon: 'far fa-fw fa-file-alt',
          });
          if (this.materialRequestIsEnabled) {
            menu.splice(4, 0, {
              key: RouteNames.specialOccurrence.materialRequestLink,
              name: 'MaterialRequest',
              icon: 'far fa-fw fa-cogs',
            });
          }
      }

      if (this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin) {
        menu.push({
          key: RouteNames.specialOccurrence.accessLevels,
          name: 'Access',
          icon: 'far fa-fw fa-lock',
        });
      }

      return menu;
    }

    copyEmailToSpecialOccurrence() {
      const marker = '#SO:' + this.currentOccurrence.id + '#';
      navigator.clipboard.writeText(marker).then(
        () => {
          const originalTitle = this.copyEmailToSpecialOccurrenceTitle;
          this.copyEmailToSpecialOccurrenceTitle = 'Copied!';

          setTimeout(() => {
            this.copyEmailToSpecialOccurrenceTitle = originalTitle;
          }, 2000);
        },
        function (err) {
          console.log(err);
        }
      );
    }
  }
