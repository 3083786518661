
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { ISpecialOccurrenceListDto, ITechnicalOccurrenceDto } from '@/apiclient/apiclient_generated';

  @Component({
    components: {},
  })
  export default class SoCard extends Vue {
    @Prop()
    public item: ISpecialOccurrenceListDto;

    get occurrenceClass(): string {
      if (this.item.cssClass) return this.item.cssClass;

      return 'badge-so';
    }

    get typeName(): string {
      if ((this.item as ITechnicalOccurrenceDto).technicalOccurrenceType)
        return (this.item as ITechnicalOccurrenceDto).technicalOccurrenceType?.name;
      return this.item.type;
    }

    navigate(id: string) {
      this.$router.push({ name: 'special-occurrence', params: { occId: id } });
    }
  }
