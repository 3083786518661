
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import SpecialOccurrencesOverview from '@/components/Overview/SpecialOccurrencesOverview.vue';
  import { OverviewModule, OverviewStore } from '@/store/overview';
  import RouteNames from '@/types/routeNames';

  @Component({
    components: {
      PageHeader,
      SpecialOccurrencesOverview,
    },
  })
  export default class OccurrencesOverview extends Vue {
    private store: OverviewModule = OverviewStore;
    private routeNames = RouteNames;

    mounted() {
      this.$router.beforeEach((to, from, next) => {
        if (to.name !== this.routeNames.specialOccurrence.root && to.name !== this.routeNames.specialOccurrence.overview) {
          this.store.setSOFilters(null);
        }
        next();
      });
    }

    beforeDestroy() {
      this.store.setSpecialOccurrencesOverview([]);
    }
  }
