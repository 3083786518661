
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { materialRequestClient } from '@/apiclient/opsboardapi';
  import { RequestStatusDto, VendorRequestPartEditDto } from "@/apiclient/apiclient_generated";
  import { GlobalHelper } from '@/helpers/global-helper';

  @Component({
    components: {},
  })
  export default class EditVendorRequestPartRequest extends Vue {
    materialRequestId: string;
    vendorRequestPartId: string;
    vendorRequestPart: VendorRequestPartEditDto = VendorRequestPartEditDto.fromJS({part: {pn: ''}});

    requestStatus: RequestStatusDto[] =[];

    created() {
      this.materialRequestId = this.$router.currentRoute.params.materialRequestId;
      this.vendorRequestPartId = this.$router.currentRoute.params.vendorRequestPartId;
    }

    async mounted() {
      await this.loadData();
    }

    async loadData() {
      this.vendorRequestPart = await materialRequestClient.getVendorRequestPartEditDto(this.vendorRequestPartId);
      this.requestStatus = await materialRequestClient.getRequestStatus();
      await this.$nextTick();
      (this.$refs.form as any).validate();
    }

    async savePart() {
      try {
        await materialRequestClient.editVendorRequestPart(this.vendorRequestPart);
        GlobalHelper.NotifySuccess('Part saved');
        this.$emit('saved');
      } catch {
        GlobalHelper.NotifyError('Error occured');
      }
    }

    close() {
      this.$emit('close');
    }
  }
