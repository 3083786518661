import {
  BoardItemTemplateClient, BoardItemTemplateViewDto
} from "@/apiclient/apiclient_generated";
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';

@Module({ dynamic: true, namespaced: true, store, name: 'boardItemTemplate' })
export class BoardItemTemplateModule extends VuexModule {
  private boardItemTemplateClient = new BoardItemTemplateClient(ApiUrl, axios);
  currentBoardTemplates: BoardItemTemplateViewDto[] = [];

  @Action
  async getTemplatesForBoard(id: string) {
    this.setBoardTemplates(await this.boardItemTemplateClient.getListForBoard(id));
  }

  @Mutation
  setBoardTemplates(boardItemTemplates: BoardItemTemplateViewDto[]) {
    this.currentBoardTemplates = boardItemTemplates;
  }
}

export const BoardItemTemplateStore = getModule(BoardItemTemplateModule);
