
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { reportsClient } from '@/apiclient/opsboardapi';
  import { GetLabelsStatisticQuery, GeneralLabelUsageInfo, LabelsOverTimeUsageApiDto } from '@/apiclient/apiclient_generated';
  import FileService from '@/services/files.service';
  import PublicConstants from '@/types/publicConstants';
  import { DateTime } from 'luxon';

  @Component({
    components: {},
  })
  export default class LabelsStatistics extends Vue {
    dateFrom: any = new Date();
    dateTo: any = new Date();
    useBoardLabelsOnly = false;
    displayEmptyResult = false;

    allLabelsUsageStatistics: GeneralLabelUsageInfo[] = [];

    pieStatisticOptions = { chart: { type: '', width: 0 }, labels: [], colors: [], legend: {} };
    pieStatisticValues = [];

    barStatisticOptions = {
      chart: { type: '', height: 0, stacked: true, toolbar: {}, zoom: {} },
      colors: [],
      xaxis: { type: '', categories: [] },
    };
    barStatisticValues = [];

    private maxTopUsedLabelsCount = 5;

    async refreshLabelsStatistic() {
      const query = this.getStatisticQuery();

      const labelsUsageStatistics: GeneralLabelUsageInfo[] = await reportsClient.getLabelsUsageStatistics(query);
      const labelsOverTimeUsageStatistics: LabelsOverTimeUsageApiDto = await reportsClient.getLabelsOverTimeUsageStatistic(query);

      this.displayEmptyResult = !labelsUsageStatistics?.length || !labelsOverTimeUsageStatistics?.labels?.length;

      if (this.displayEmptyResult) {
        return;
      }

      this.refreshLabelsUsageStatistics(labelsUsageStatistics);
      this.refreshLabelsOverTimeUsageStatistics(labelsOverTimeUsageStatistics);
    }

    private refreshLabelsUsageStatistics(labelsUsageStatistics: GeneralLabelUsageInfo[]) {
      this.initPieStatisticOptions();

      this.allLabelsUsageStatistics = labelsUsageStatistics;
      this.pieStatisticOptions.colors = this.allLabelsUsageStatistics.map((t) => t.labelColor);

      if (labelsUsageStatistics.length >= this.maxTopUsedLabelsCount) {
        const topUsedLabels = labelsUsageStatistics.slice(0, this.maxTopUsedLabelsCount);

        const others = {
          labelTitle: 'Others',
          usedCount: labelsUsageStatistics.slice(this.maxTopUsedLabelsCount).reduce((counter, entity) => {
            return counter + entity.usedCount;
          }, 0),
          labelColor: PublicConstants.othersLabelsColor,
        } as GeneralLabelUsageInfo;

        this.pieStatisticOptions.colors[this.pieStatisticOptions.colors.length - 1] = PublicConstants.othersLabelsColor;

        this.setPieStatisticData([...topUsedLabels, others]);
      } else {
        this.setPieStatisticData(labelsUsageStatistics);
      }
    }

    private refreshLabelsOverTimeUsageStatistics(labelsOverTimeUsageStatistics: LabelsOverTimeUsageApiDto) {
      this.initBarStatisticOptions();

      this.barStatisticOptions.xaxis.categories = labelsOverTimeUsageStatistics.columnDates.map((t) =>
        DateTime.fromISO(t).toFormat('MMM-yyyy')
      );

      this.barStatisticOptions.colors = labelsOverTimeUsageStatistics.labelsColors;
      this.barStatisticValues = labelsOverTimeUsageStatistics.labels;
    }

    private setPieStatisticData(statisticsSource: GeneralLabelUsageInfo[]) {
      this.pieStatisticOptions.labels = statisticsSource.map((t) => t.labelTitle);
      this.pieStatisticValues = statisticsSource.map((t) => t.usedCount);
    }

    async loadLabelsStatistic() {
      const query = this.getStatisticQuery();

      await this.refreshLabelsStatistic();

      const statistic = await reportsClient.getLabelsReport(query);

      FileService.downloadFile(statistic.data, statistic.fileName);
    }

    private getStatisticQuery() {
      return {
        boardId: this.$route.params.id,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        useOnlyBoardSpecificLabels: this.useBoardLabelsOnly,
      } as GetLabelsStatisticQuery;
    }

    initPieStatisticOptions() {
      this.pieStatisticOptions = {
        chart: {
          type: 'pie',
          width: 500,
        },

        labels: [],

        colors: [],

        legend: {
          position: 'bottom',
        },
      };
    }

    initBarStatisticOptions() {
      this.barStatisticOptions = {
        chart: {
          type: 'bar',
          height: 480,
          stacked: true,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },

        colors: [],

        xaxis: {
          type: 'category',
          categories: [],
        },
      };
    }
  }
