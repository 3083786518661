
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { IActionDataDto } from '@/apiclient/apiclient_generated';
  import BoardItemDatePicker from '@/components/BoardItem/BoardItemDatePicker.vue';

  @Component({
    components: { BoardItemDatePicker },
  })
  export default class ActionDate extends Vue {
    @Prop() actionData: IActionDataDto;
    @Prop() value: string;

    selectOptions: any[] = [];

    get selectedValue(): any[] {
      if (this.selectOptions && this.selectOptions.length && this.actionData.value) {
        const arr = this.actionData.value.split(',');
        const result = this.selectOptions.filter((_) => arr.includes(_.id));
        return result;
      }
      return [];
    }

    set selectedValue(values) {
      this.$emit('input', values.map((_) => _.id).join(','));
    }

    async mounted() {
      this.selectOptions = JSON.parse(this.actionData.selectValues);
    }
  }
