import {
  InitialBoardSettingsDto,
  ShiftValidationSettingsDto,
  BoardItemValidationTypeDto, EmailConfigDto
} from "@/apiclient/apiclient_generated";
import { SettingsClient } from '@/apiclient/apiclient_generated';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import { GlobalHelper } from '@/helpers/global-helper';

@Module({ dynamic: true, namespaced: true, store, name: 'settings' })
export class SettingsModule extends VuexModule {
  private settings = new SettingsClient(ApiUrl, axios);
  initialBoardSettings: InitialBoardSettingsDto = new InitialBoardSettingsDto();
  shiftValidationSettings: ShiftValidationSettingsDto = new ShiftValidationSettingsDto();
  email2BoardSettings: EmailConfigDto[] = [];

  @Action
  async loadBoardInitialSettings(boardId: string) {
    this.setBoardInitialSettings(await this.settings.getBoardInitialSettings(boardId));
  }

  @Action
  async updateBoardInitialSettings() {
    await this.settings.updateBoardInitialSettings(this.initialBoardSettings);
    GlobalHelper.NotifySuccess('Changes saved.');
  }

  @Action
  async loadShiftValidationSettings(boardId: string) {
    this.setShiftValidationSettings(await this.settings.getShiftValidationSettings(boardId));
  }

  @Action
  async loadEmail2BoardSettings(boardId: string) {
    this.setEmail2BoardSettings(await this.settings.getEmailConfig(boardId));
  }

  @Action
  async updateEmail2BoardSettings(settings: EmailConfigDto) {
    await this.settings.storeEmailConfig(settings);
  }

  @Action
  async updateShiftValidationSettings(relevantTypes: BoardItemValidationTypeDto[]) {
    this.shiftValidationSettings.types = relevantTypes;
    await this.settings.updateShiftValidationSettings(this.shiftValidationSettings);

    GlobalHelper.NotifySuccess('Changes saved.');
  }

  @Mutation
  setEmail2BoardSettings(settings: EmailConfigDto[]) {
    this.email2BoardSettings = settings;
  }

  @Mutation
  setBoardInitialSettings(settings: InitialBoardSettingsDto) {
    this.initialBoardSettings = settings;
  }

  @Mutation
  setShiftValidationSettings(settings: ShiftValidationSettingsDto) {
    this.shiftValidationSettings = settings;
  }
}

export const SettingsStore = getModule(SettingsModule);
