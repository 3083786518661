
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { OverviewModule, OverviewStore } from '@/store/overview';
  import DateRangeDto from '@/types/dateRangeDto';
  import BoardItemDatePicker from '@/components/BoardItem/BoardItemDatePicker.vue';
  import { Watch } from 'vue-property-decorator';
  import { BoardItemStatusDto, BoardItemsSummary, ShiftHandoverOverviewDto } from '@/apiclient/apiclient_generated';
  import ShiftOverview from './ShiftOverview.vue';
  import RequestParams from '@/types/requestParams/requestParams';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import { DateTime } from "luxon";

  @Component({
    components: {
      BoardItemDatePicker,
      ShiftOverview,
      LoadingSpinner,
    },
  })
  export default class ShiftsOverview extends Vue {
    private overviewStore: OverviewModule = OverviewStore;
    private searchDateRange: DateRangeDto = new DateRangeDto(DateTime.utc().plus({ days: -14 }).toString());

    private loading = false;
    private itemsStatus = BoardItemStatusDto;

    async created() {
      await this.submitSearch();
    }

    @Watch('searchDateRange')
    async changeFromDate() {
      await this.submitSearch();
    }

    async submitSearch() {
      this.loading = true;
      await this.overviewStore.loadShiftsHandoverOverview(this.requestParams);

      this.loading = false;
    }

    get shiftsOverview() {
      const data: ShiftHandoverOverviewDto[] = this.overviewStore.shiftsOverview;

      data.forEach((_) => {
        Vue.set(_, 'isCollapsed', true);
      });

      return data;
    }

    private getItemsCount(itemsSummary: BoardItemsSummary[], status: BoardItemStatusDto) {
      const data = itemsSummary.find((_) => _.status == status);

      return data || { count: 0, status };
    }

    get requestParams(): RequestParams {
      return {
        entityId: this.$route.params.id,
        orderByDesc: true,
        dateRangeFrom: this.searchDateRange.start,
        dateRangeTo: this.searchDateRange.end,
      } as RequestParams;
    }
  }
