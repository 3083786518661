import { render, staticRenderFns } from "./BoardOverview.vue?vue&type=template&id=f33c5a24&scoped=true&"
import script from "./BoardOverview.vue?vue&type=script&lang=ts&"
export * from "./BoardOverview.vue?vue&type=script&lang=ts&"
import style0 from "./BoardOverview.vue?vue&type=style&index=0&id=f33c5a24&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f33c5a24",
  null
  
)

export default component.exports