export default class PublicConstants {
  static EntityNotFound = 'Entity Not Found';
  static EntityInUse = 'Entity In Use';

  static labelColors = ['#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085', '#f1c40f', '#e74c3c', '#f39c12', '#7f8c8d'];
  static othersLabelsColor = '#95a5a6';

  static missingOccurrenceData = 'Mandatory field';
  static fillMandatoryFields = 'Please fill out all mandatory fields on the Data-Page';
  static notAllMandatoryFieldsFilled = 'Not all mandatory fields are filled out on the data page';
}
