
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { IBoardItemDto } from '@/apiclient/apiclient_generated';
  import { LabelModule, LabelStore } from '@/store/label';

  @Component
  export default class BoardItemLabels extends Vue {
    private labelStore: LabelModule = LabelStore;

    @Prop() item: IBoardItemDto;
    @Prop() isEditable: boolean;
    @Prop() isSelectable: boolean;

    async removeLabel(labelId) {
      await this.labelStore.removeLabel({
        boardItemId: this.item.id,
        labelId,
      });
    }

    async selectLabel(label, event) {
      if (this.isSelectable && event) {
        event.stopPropagation();

        this.$root.$emit('boardItem::labelSelected', label);
      }
    }
  }
