
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { IBoardItemActionDto, ActionResponseTypeDto, ActionTypeDto } from '@/apiclient/apiclient_generated';
  import CrewContactInformation from '@/components/BoardItemActions/ActionResponseTypes/CrewContactInformation.vue';
  import GroundOpsFlightInformation from '@/components/BoardItemActions/ActionResponseTypes/GroundOpsFlightInformation.vue';
  import BoardItemDelayInfo from '@/components/BoardItem/BoardItemDelayInfo.vue';

  @Component({
    components: {
      GroundOpsFlightInformation,
      CrewContactInformation,
      BoardItemDelayInfo,
    },
  })
  export default class ActionResultData extends Vue {
    @Prop()
    action: IBoardItemActionDto;
    actionResponseType = ActionResponseTypeDto;
    actionType = ActionTypeDto;

    get jsonData(): any {
      const result = JSON.parse(this.action.result);

      return result;
    }

    get prettyJson() {
      return JSON.stringify(this.jsonData, null, 2);
    }
  }
