
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Emit } from "vue-property-decorator";
  import { VendorDto } from "@/apiclient/apiclient_generated";

  @Component({
    components: {},
  })
  export default class AddVendor extends Vue {
    vendor: VendorDto = VendorDto.fromJS({});

    async mounted() {
      await this.$nextTick();
      await this.$nextTick();
      (this.$refs.form as any).validate();
    }

    @Emit('addvendor')
    addVendor(): VendorDto {
      return this.vendor;
    }

    @Emit('cancel')
    cancel() {
      return;
    }
  }
