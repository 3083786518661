import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '@/store/index';
import { UserClient, UserDto } from '@/apiclient/apiclient_generated';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import {Settings} from 'luxon';

@Module({ dynamic: true, namespaced: true, store, name: 'user' })
export class UserModule extends VuexModule {
  public userClient = new UserClient(ApiUrl, axios);
  user: UserDto = new UserDto();
  availableUsers: UserDto[] = [];
  users: UserDto[] = [];
  userSet = false;

  @Action
  async loadData() {
    const users = await this.userClient.getAllUsers();
    this.setUsers(users);

    const user = await this.userClient.getCurrentUser();
    this.setUser(user);
  }

  @Action
  async getAvailableUsers(params) {
    const { entityType, entityId } = params;
    const users = await this.userClient.getAvailableUsers(entityType, entityId);
    this.setAvailableUsers(users);
  }

  @Action
  async setTimezone(timezone) {
    const user = await this.userClient.setTimezone(timezone);
    this.setUser(user);
  }

  @Mutation
  setUser(user: UserDto) {
    this.user = user;
    this.userSet = true;
    Settings.defaultZoneName = this.user.timeZone;
    console.log(`You are user ${this.user.firstName} with timezone ${this.user.timeZone}`);
  }

  @Mutation
  setAvailableUsers(users: UserDto[]) {
    this.availableUsers = users;
  }

  @Mutation
  setUsers(users: UserDto[]) {
    this.users = users;
  }
}

export const UserStore = getModule(UserModule);
