
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import { SettingsStore, SettingsModule } from '@/store/settings';
  import BoardSettings from '@/components/Board/BoardSettings.vue';
  import RouteNames from '@/types/routeNames';
  import { UserModule, UserStore } from '@/store/user';

  @Component({
    components: {
      PageHeader,
      BoardSettings,
    },
  })
  export default class ApplicationSettings extends Vue {
    private settings: SettingsModule = SettingsStore;
    private users: UserModule = UserStore;
    routes = RouteNames;

    mounted() {
      const hasRightsToSeePage = this.users.user && this.users.user.isAdmin;

      if (!hasRightsToSeePage) {
        this.$router.push({ name: 'Boards' });
      }
    }
  }
