
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { LabelModule, LabelStore } from '@/store/label';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import { LabelDto } from '@/apiclient/apiclient_generated';
  import { GlobalHelper } from '@/helpers/global-helper';
  import PublicConstants from '@/types/publicConstants';
  import VSwatches from 'vue-swatches';
  import OpsBoardIcons from '@/store/labelIcons';
  import RouteNames from '@/types/routeNames';
  import LabelsTable from './LabelsTable.vue';

  @Component({ components: { VSwatches, LabelsTable } })
  export default class BoardsLabels extends Vue {
    private labelStore: LabelModule = LabelStore;
    private manuallyAddedLabels: LabelDto[] = [];
    isLoading = false;
    isDirty = false;
    swatches = PublicConstants.labelColors;

    appIcons = OpsBoardIcons;

    async mounted() {
      if (this.isBoardSpecificLabelsPage) {
        await this.labelStore.getBoardSpecificLabelsAsync(this.boardId);
      } else {
        await this.labelStore.getGeneralLabelsAsync();
      }
    }

    get activeLabels() {
      return this.isBoardSpecificLabelsPage ? this.boardActiveLabels : this.generalActiveLabels;
    }
    get archivedLabels() {
      return this.isBoardSpecificLabelsPage ? this.boardArchivedLabels : this.generalArchivedLabels;
    }
    get manuallyAddedLabels_() {
      return this.manuallyAddedLabels.length ? this.manuallyAddedLabels : null;
    }

    //active labels with manually created (items are manually created, until they get id.)
    get boardActiveLabels() {
      return [...this.manuallyAddedLabels, ...this.labelStore.boardActiveLabels];
    }
    get generalActiveLabels() {
      return [...this.manuallyAddedLabels, ...this.labelStore.generalActiveLabels];
    }

    get generalArchivedLabels() {
      return this.labelStore.generalArchivedLabels;
    }

    get boardArchivedLabels() {
      return this.labelStore.boardArchivedLabels;
    }

    get boardsLabels() {
      return [...this.manuallyAddedLabels, ...this.labelsList];
    }

    get labelsIcons() {
      return OpsBoardIcons.icons;
    }

    get isFormValid() {
      return this.isDirty ? !this.isLoading && this.boardsLabels.every((t) => t.title && t.color && t.icon) : false;
    }

    async deleteBoardsLabel(boardLabel: LabelDto) {
      if (boardLabel.isUsed) {
        return;
      }

      const boardLabel_ = boardLabel;

      const success = async () => {
        if (boardLabel_.id) {
          const result = await this.labelStore.deleteLabelAsync(boardLabel_);

          if (result && !result.isSuccess) {
            GlobalHelper.NotifyError(PublicConstants[result.errorCode]);
          }
        } else {
          const labelIndex = this.manuallyAddedLabels.findIndex((t) => t === boardLabel);
          this.manuallyAddedLabels.splice(labelIndex, 1);
        }
      };

      ConfirmModal.confirm(this, `Are you sure you want to delete this board label?`, 'Confirm delete', 'Yes, delete', 'No', success, null, 'danger');
    }

    addNewLabel() {
      this.isDirty = true;

      this.manuallyAddedLabels.unshift({
        color: this.swatches[0],
      } as LabelDto);
    }

    async saveLabels() {
      this.isLoading = true;

      const command = { boardId: this.boardId, labels: [] };

      this.boardsLabels.forEach((label, index) => {
        command.labels.push({
          id: label.id,
          boardId: this.boardId,
          title: label.title,
          category: label.category,
          icon: label.icon,
          color: label.color,
          sortOrder: index,
        } as LabelDto);
      });

      await this.labelStore.updateBoardLabelsAsync(command);

      this.isLoading = false;
      this.manuallyAddedLabels = [];

      GlobalHelper.NotifySuccess('Labels were successfully updated');
    }

    get isBoardSpecificLabelsPage() {
      return this.$route.name === RouteNames.labels.boardLabels.name;
    }

    get boardId() {
      return this.isBoardSpecificLabelsPage ? this.$route.params.id : null;
    }

    get labelsList() {
      return this.isBoardSpecificLabelsPage ? this.labelStore.boardActiveLabels : this.labelStore.generalActiveLabels;
    }

    async archiveLabelAsync(label: LabelDto) {
      const success = async () => {
        const result = await this.labelStore.archiveLabelAsync(label.id);

        if (result && !result.isSuccess) {
          GlobalHelper.NotifyError(PublicConstants[result.errorCode]);
        }
      };

      ConfirmModal.confirm(
        this,
        `After archiving, this label cannot be manually selected in tasks. Do you really want to archive this label?`,
        'Confirm Archive',
        'Yes, Archive',
        'Cancel',
        success,
        null
      );
    }

    async deleteLabelAsync(label: LabelDto) {
      if (!label.id) {
        return this.deleteManuallyCreatedLabel(label);
      }

      if (label.isUsed) {
        return;
      }

      const boardLabel_ = label;

      const success = async () => {
        if (boardLabel_.id) {
          const result = await this.labelStore.deleteLabelAsync(boardLabel_);

          if (result && !result.isSuccess) {
            GlobalHelper.NotifyError(PublicConstants[result.errorCode]);
          }
        } else {
          this.deleteManuallyCreatedLabel(label);
        }
      };

      ConfirmModal.confirm(this, `Are you sure you want to delete this board label?`, 'Confirm delete', 'Yes, delete', 'No', success, null, 'danger');
    }
    private deleteManuallyCreatedLabel(label) {
      const labelIndex = this.manuallyAddedLabels.findIndex((t) => t === label);

      this.manuallyAddedLabels.splice(labelIndex, 1);
    }

    async reactivateLabelAsync(label: LabelDto) {
      await this.labelStore.reactivateLabelAsync(label.id);
    }
  }
