
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import BoardSettings from '@/components/Board/BoardSettings.vue';
  import RouteNames from '@/types/routeNames';
  import { UserModule, UserStore } from '@/store/user';

  @Component({
    components: {
      PageHeader,
      BoardSettings,
    },
  })
  export default class UserSettings extends Vue {
    private userStore: UserModule = UserStore;
    routes = RouteNames;

    mounted() {
      if (!this.userStore.user) {
        this.$router.push({ name: 'Boards' });
      }
    }
  }
