
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { sortBy } from 'lodash';
  import { BoardModule, BoardStore } from '@/store/board';
  import { UserModule, UserStore } from '@/store/user';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import {
    IBoardItemDto,
    BoardItemStatusDto,
    BoardItemPriorityDto,
    MoveBackTypeDto,
    AttachmentDestination,
    IAttachmentDto,
    TechnicalOccurrenceViewDto,
    BoardItemShareDto,
    BoardItemUnshareDto,
    BoardItemDto,
    IBoardDto,
    BoardItemClickDto,
  } from '@/apiclient/apiclient_generated';
  import ModalWindow from '@/components/ModalWindow.vue';
  import BoardItemConnections from '@/components/BoardItem/BoardItemConnections.vue';
  import BoardItemStatus from '@/components/BoardItem/BoardItemStatus.vue';
  import BoardItemHistory from '@/components/BoardItem/BoardItemHistory.vue';
  import BoardItemPriority from '@/components/BoardItem/BoardItemPriority.vue';
  import BoardItemActions from '@/components/BoardItemActions/BoardItemActions.vue';
  import BoardItemDate from '@/components/BoardItem/BoardItemDate.vue';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import IBoardItemDates from '@/types/boardItemDates';
  import GroupedSelect from '@/components/Misc/GroupedSelect.vue';
  import ToggleSwitch from '@/components/Misc/ToggleSwitch.vue';
  import AcceptRejectView from '@/types/acceptRejectView';
  import BoardItemAcceptReject from '@/components/BoardItem/BoardItemAcceptReject.vue';
  import BoardItemSpreadsheet from '@/components/BoardItem/BoardItemSpreadsheet.vue';
  import AddChecklist from '@/components/Checklist/AddChecklist.vue';
  import AddLabels from '@/components/Labels/AddLabels.vue';
  import Checklist from '@/components/Checklist/Checklist.vue';
  import WideAttachments from '@/components/Attachments/WideAttachments.vue';
  import RouteNames from '@/types/routeNames';
  import ToggleSwitchColors from '@/types/toggleSwitchColors';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import BoardItemLabels from '@/components/Labels/BoardItemLabels.vue';
  import UploadAttachment from '@/components/Attachments/UploadAttachment.vue';
  import AttachmentView from '@/components/Attachments/AttachmentView.vue';
  import { LabelModule, LabelStore } from '@/store/label';
  import SendAsEmail from '@/components/SendAsEmail.vue';
  import BoardItemAssignedUser from '@/components/BoardItem/BoardItemAssignedUser.vue';
  import { SettingsModule, SettingsStore } from '@/store/settings';
  import { SelectListItemGroup } from '@/components/Misc/SelectListItemGroup';
  import { AttachmentsModule, AttachmentsStore } from '@/store/attachments';
  import { GlobalHelper } from '@/helpers/global-helper';
  import TaskDetailComments from '@/components/TaskDetail/TaskDetailComments.vue';

  import { reportsClient } from '@/apiclient/opsboardapi';

  import { linkify } from '@/directives/linkify-directive';
  import WysiwigEditor from '@/components/Misc/WysiwigEditor.vue';
  import BoardItemSwitcher from '../BoardItem/BoardItemSwitcher.vue';
  import SpecialOccurrenceService from '@/services/specialOccurrence.service';
  import FileService from '@/services/files.service';

  Component.registerHooks(['beforeRouteUpdate']);

  @Component({
    components: {
      WysiwigEditor,
      TaskDetailComments,
      BoardItemAssignedUser,
      SendAsEmail,
      LoadingSpinner,
      ModalWindow,
      BoardItemStatus,
      BoardItemHistory,
      BoardItemDate,
      GroupedSelect,
      BoardItemConnections,
      BoardItemPriority,
      BoardItemActions,
      BoardItemAcceptReject,
      AddChecklist,
      Checklist,
      AddLabels,
      BoardItemLabels,
      UploadAttachment,
      AttachmentView,
      BoardItemSwitcher,
      WideAttachments,
      ToggleSwitch,
      BoardItemSpreadsheet,
    },
  })
  export default class TaskDetail extends Vue {
    private labelStore: LabelModule = LabelStore;
    private userStore: UserModule = UserStore;
    private boardStore: BoardModule = BoardStore;
    private occurrenceStore: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private settingsStore: SettingsModule = SettingsStore;
    private attachmentsStore: AttachmentsModule = AttachmentsStore;
    private routes: RouteNames = RouteNames;
    private acceptRejectView = AcceptRejectView;
    private commentIsDirty = false;

    private jsonEditorOptions = {
      mode: 'code',
    };

    private updatesStore = [];
    private updateStoreKeys = {
      assignedUser: 'assignedUser',
      validFromDate: 'validFromDate',
      validToDate: 'validToDate',
      dueDate: 'dueDate',
      plannedDate: 'plannedDate',
      priority: 'priority',
      isRelevantForReport: 'isRelevantForReport',
      isConfidential: 'isConfidential',
      spreadsheetUpdate: 'spreadsheetUpdate',
    };
    private allowModalClose = false;

    private comment = '';
    editdescription = false;
    isSaving = false;
    toggleActive = false;
    showBoardItemSpreadsheet = false;

    private itemid = '';
    private loading = true;
    pdfIsLoading = false;
    private noCloseOnBackdrop = false;
    private itemStatus = BoardItemStatusDto;
    toggleSwitchColors = ToggleSwitchColors;

    boardItemsChanges = {};

    private soSearch = '';

    private metadata = {
      initialOwnerBoardId: null,
      occId: null,
    };

    private initMetadata() {
      this.metadata.initialOwnerBoardId = this.$route.params.board;
      this.metadata.occId = this.$route.params.occId;
    }

    async created() {
      this.itemid = this.getItemId();

      this.initMetadata();

      await this.occurrenceStore.getActiveAndArchivedOccurrences();

      this.$root.$on('actionDateUpdated', (event) => {
        this.setPropChanges(event.name, event.value);
      });
    }

    get actionsTemplateObj() {
      return JSON.parse(this.item.actionsJson);
    }
    set actionsTemplateObj(val) {
      this.item.actionsJson = JSON.stringify(val);
    }

    get getModalSize() {
      return 'xl';
    }

    get dynamicTemplateFilesObj() {
      if (!this.item.dynamicTemplateFilesJson) return [];

      return JSON.parse(this.item.dynamicTemplateFilesJson);
    }

    set dynamicTemplateFilesObj(val) {
      this.item.dynamicTemplateFilesJson = JSON.stringify(val);
    }

    get currentUserIsBoardAdmin() {
      return (
        this.userStore.user.isAdmin ||
        this.userStore.user.boardPermissions?.filter((_) => _.boardGuid == this.item.ownerBoardId && _.isBoardAdmin).length > 0
      );
    }

    get linkifiedDescription(): string {
      const html = linkify(this.item.description);
      return html;
    }

    get anyOccurreceIsAvailable() {
      return (
        this.occurrenceStore.activeAndArchivedOccurrences.activeSpecialOccurrences?.length ||
        this.occurrenceStore.activeAndArchivedOccurrences.archivedSpecialOccurrences?.length
      );
    }

    get occurrenceClass(): string {
      const boardItem = this.item;
      const occurrences = this.occurrenceStore.activeAndArchivedOccurrences;

      const occ =
        occurrences.activeSpecialOccurrences.find((_) => _.id == boardItem.specialOccurrenceId) ||
        occurrences.archivedSpecialOccurrences.find((_) => _.id == boardItem.specialOccurrenceId);

      if (!occ) {
        return 'badge-so';
      }

      if (occ.isCompleted) {
        return 'badge-so-completed';
      }

      if (occ.type === 'TechnicalOccurrence' && (occ as TechnicalOccurrenceViewDto).technicalOccurrenceTypeId) {
        const typeInfo = this.occurrenceStore.technicalOccurrenceTypes.find(
          (_) => _.id === (occ as TechnicalOccurrenceViewDto).technicalOccurrenceTypeId
        );

        return typeInfo?.cssClass ?? 'badge-so';
      }

      return 'badge-so';
    }

    get currentBoardName(): string {
      return this.boardStore.board.title;
    }

    private filterOccurreces(occurrences, search) {
      if (search) {
        return (occurrences || []).filter((_) => _.name.toLowerCase().indexOf(search) !== -1);
      } else {
        return occurrences;
      }
    }

    get filteredActiveOccurrences() {
      const occurrences = this.filterOccurreces(
        this.occurrenceStore.activeAndArchivedOccurrences.activeSpecialOccurrences,
        this.soSearch.toLowerCase()
      );

      return this.lodash.orderBy(occurrences, 'createdDate', 'desc');
    }

    get filteredArchivedOccurrences() {
      const occurrences = this.filterOccurreces(
        this.occurrenceStore.activeAndArchivedOccurrences.archivedSpecialOccurrences,
        this.soSearch.toLowerCase()
      );

      return this.lodash.orderBy(occurrences, 'updatedDate', 'desc');
    }

    get readOnly(): boolean {
      return this.item ? this.item.isArchived || this.item.isReadonly : false;
    }

    //gets triggered by vue router if we route from one item to another
    //Vue re-uses the already instantiated component, so we don't have a "real" navigation and
    //need to update the data manually.
    beforeRouteUpdate(to, from, next) {
      this.itemid = to.params.itemid;
      this.loadData().then(() => {
        next();
      });
    }

    get currentBoardShiftsState() {
      if (this.item.specialOccurrenceId && this.boardStore.allBoards?.length) {
        const index = this.boardStore.allBoards.findIndex((_) => _.id === this.item.initialOwnerBoardId);

        return this.boardStore.allBoards[index].enableShifts;
      }

      return this.boardStore.board.enableShifts;
    }

    async createTemplateFromBoardItem() {
      const success = async () => {
        await this.boardStore.createTemplateFromBoardItem();
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to create a template from this task?`,
        'Confirm',
        'Yes, create template',
        'No',
        success,
        null
      );
    }

    async moveBoardItemFromOccurrence(boardItemId) {
      const success = () => {
        this.occurrenceStore.unlinkBoardItem(boardItemId);
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to unlink this item from Special Occurrence ${this.item.specialOccurrenceName} ?`,
        'Confirm unlink',
        'Yes, unlink',
        'No',
        success,
        null
      );
    }

    get availableBoardCategories() {
      const settings = this.settingsStore.shiftValidationSettings;

      const availableTypes = settings.types?.filter((_) => _.isAvailable) ?? [];

      const result: SelectListItemGroup[] = [];

      this.boardStore.groupedSelect.forEach((category) => {
        const types = category.items.filter((_) => availableTypes.some((t) => t.typeId === _.id) || _.id == this.item.boardItemTypeId);

        if (types && types.length) {
          category.items = types;
          result.push(category);
        }
      });

      return result;
    }

    private getItemId() {
      return this.$route.params.boardItemOverviewId || this.$route.params.shiftBoardItemOverviewId || this.$route.params.itemid;
    }

    async loadData() {
      this.loading = true;
      if (this.itemid == 'new') {
        const isTemplate = this.$route.name === 'TemplateDetail';
        this.boardStore.initNewBoardItem(isTemplate);

        if (this.metadata.initialOwnerBoardId) {
          this.item.specialOccurrenceId = this.metadata.occId;
          this.item.ownerBoardId = this.metadata.initialOwnerBoardId;
          this.item.initialOwnerBoardId = this.metadata.initialOwnerBoardId;
        }
      } else {
        await this.boardStore.getById(this.itemid);
      }

      await this.boardStore.loadBoardCategories();
      await this.settingsStore.loadShiftValidationSettings(this.item?.ownerBoardId ?? this.$route.params.id); //params.id is the id of the board

      this.loading = false;

      await this.$nextTick();
      await this.$nextTick(); //why I need this twice???

      const title = this.$refs.title as any;
      if (title) title.resize();

      const description = this.$refs.description as any;
      if (description) description.resize();

      //focus title for new BoardItem
      if (!this.item.id && title) {
        title.$el.focus();
      }
    }

    async mounted() {
      this.$bvModal.show('taskdetail');
      await this.loadData();

      await this.labelStore.getAllLabelsAsync(this.item.ownerBoardId || this.$route.params.id);

      await this.boardStore.loadAllBoards();
      await this.boardStore.loadAvailableBoards();

      await this.occurrenceStore.getActiveAndArchivedOccurrences();

      if (this.item.id) {
        this.boardStore.processBoardItemView(this.getBoardItemClickDto(this.item));
      }

      this.showBoardItemSpreadsheet = !!this.item.spreadsheetData;
    }

    processSpreadsheetDelete() {
      this.showBoardItemSpreadsheet = false;
      this.processSpreadsheetUpdate(null);
    }

    addSpreadSheet() {
      if (!this.showBoardItemSpreadsheet) {
        this.showBoardItemSpreadsheet = true;
        this.processSpreadsheetUpdate(null);
      }
    }

    getBoardItemClickDto(boardItem: IBoardItemDto): BoardItemClickDto {
      const currentBoardId = this.boardStore.currentBoardId ?? this.$route.params.id;

      return {
        boardId: currentBoardId,
        boardItemId: boardItem.id,
      } as BoardItemClickDto;
    }

    async getBoardItemReport(includeAttachments = false) {
      this.pdfIsLoading = true;

      const result = await reportsClient.getBoardItemPdfReport(this.item.id, includeAttachments);

      FileService.downloadFile(result.data, result.fileName);

      this.pdfIsLoading = false;

      const popover: any = this.$refs.generateReportPopover;
      if (popover) {
        popover.doClose();
      }
    }

    get showAttachmentsSection() {
      return this.item.attachments?.length > 0 || this.item.linkedBoardItems?.some((_) => _.attachments.length > 0);
    }

    async toggleSubscription() {
      await this.boardStore.toggleSubscription(this.item.id);
      GlobalHelper.NotifySuccess('Subscription updated');
    }

    async toggleAutomaticStatusChangeOnShiftHandover() {
      await this.boardStore.toggleAutomaticStatusChange(this.item.id);
      GlobalHelper.NotifySuccess('Automatic status change updated');
    }

    async imagePasted(imageData: string | ArrayBuffer) {
      await this.attachmentsStore.addAttachmentFromBase64({
        destination: AttachmentDestination.BoardItem,
        id: this.item.id,
        imageData: imageData,
      });
    }

    modalClosed() {
      const location: any = {
        name: 'Board',
        params: {
          id: this.$route.params.id,
        },
      };

      switch (this.$route.name) {
        case RouteNames.specialOccurrence.task:
          location.name = RouteNames.specialOccurrence.taskGeneration;
          location.params.occId = this.$route.params.occId;
          break;

        case 'boardItemOverview':
          location.name = 'board-overview';
          break;
        case 'shiftBoardItemOverview':
          location.name = 'shifts-overview';
          break;
        case 'TemplateDetail':
          location.name = RouteNames.biTemplates;
          break;
        case RouteNames.specialOccurrence.attachmentitem:
          location.name = RouteNames.specialOccurrence.attachments;
          location.params.occId = this.$route.params.occId;
      }

      this.$router.push(location);
    }

    get allBoardsExceptCurrent() {
      const boards = this.boardStore.allBoardsExceptCurrent;

      if (this.item.specialOccurrenceId) {
        const index = boards.findIndex((_) => _.id === this.item.initialOwnerBoardId);

        if (index >= 0) {
          boards.splice(index, 1);
        }
      }

      return this.orderBoards(boards);
    }

    private get boardsToShare() {
      const boards = this.boardStore.allBoardsExceptCurrent;
      const sharedBoardIds = this.item.sharedBoards.map((_) => _.boardId);

      const filteredBoards = boards.filter((_) => sharedBoardIds.indexOf(_.id) === -1);

      return this.orderBoards(filteredBoards);
    }

    private orderBoards(boards: IBoardDto[]) {
      return this.lodash.orderBy(boards, (t) => t.title);
    }

    private get sharedBoards() {
      const boards = this.boardStore.allBoardsExceptCurrent;
      const sharedBoardIds = this.item.sharedBoards.map((_) => _.boardId);

      const filteredBoards = boards.filter((_) => sharedBoardIds.indexOf(_.id) !== -1);

      return this.orderBoards(filteredBoards);
    }

    private get isDirty() {
      const refs: any = this.$refs;
      const fields = refs.form?.fields || [];

      const keys = Object.keys(fields) || [];

      const isDirty = keys.some((key) => {
        const field = fields[key];

        return field.valid && field.changed;
      });

      return isDirty || this.updatesStore?.length;
    }

    async addNewBoardItemAndOpen() {
      this.allowModalClose = true;
      const newItem = await this.boardStore.addBoardItem(this.item);

      let routeName = this.metadata.occId ? RouteNames.specialOccurrence.task : 'BoardItemDetail';
      if (this.$route.name === 'TemplateDetail') routeName = 'TemplateDetail';

      const location = {
        name: routeName,
        params: { itemid: newItem.id },
      };

      this.$router.push(location);
    }

    async addNewBoardItemAndClose() {
      this.allowModalClose = true;
      await this.boardStore.addBoardItem(this.item);
      this.closeBoardItemDetails();
    }

    async saveChanges() {
      if (this.autoSaveTimeout) {
        clearTimeout(this.autoSaveTimeout);
      }

      if (this.item.spreadsheetData) {
        this.item.spreadsheetData = JSON.stringify(this.item.spreadsheetData);
      }

      await this.boardStore.saveBoardItem(this.item);
      const refs: any = this.$refs;
      refs.form.reset();
      this.updatesStore = [];
    }

    async saveChangesAndClose() {
      this.allowModalClose = true;
      await this.saveChanges();
      this.closeBoardItemDetails();
    }

    closeBoardItemDetails() {
      this.$root.$emit('bv::hide::modal', 'taskdetail');
    }

    saveValidChanges() {
      this.allowModalClose = true;
      this.boardStore.saveBoardItem(this.item);
      this.closeBoardItemDetails();
    }

    discardChanges() {
      this.allowModalClose = true;
      this.closeBoardItemDetails();
    }

    modalHiding(event) {
      if (this.readOnly) {
        return;
      }

      if (!this.allowModalClose) {
        event.preventDefault();
        const refs: any = this.$refs;

        refs.form.validate().then((validationResult) => {
          if (validationResult && !this.isDirty && !this.commentIsDirty) {
            this.allowModalClose = true;
            this.closeBoardItemDetails();
          } else if (validationResult && (this.isDirty || this.commentIsDirty)) {
            const modal: any = this.$refs['unsavedValidModal'];
            modal.show();
          } else if (!validationResult) {
            const modal: any = this.$refs['unsavedInValidModal'];
            modal.show();
          }
        });
      }
    }

    get item(): IBoardItemDto {
      return this.boardStore.currentBoardItem;
    }

    get orderedAttachments(): IAttachmentDto[] {
      let attachments = [];

      if (this.item.linkedBoardItems) {
        attachments = this.item.linkedBoardItems.reduce((prev, curr) => {
          curr.attachments.forEach((_: any) => {
            _.parentId = curr.id;
            _.parentTitle = curr.title;
          });

          return [...prev, ...curr.attachments];
        }, []);
      }

      if (attachments.length > 0) {
        attachments.forEach((_) => (_.isChildItem = true));
      }

      return sortBy([...this.item.attachments.filter((t) => !t.isWideBoardItemAttachment), ...attachments], 'createdDate');
    }

    async destroyed() {
      if (!this.item.boardItemTypeId && this.item.id) {
        this.boardStore.resetBoardItemType(this.item.id);
      }
    }

    async processPriorityUpdates(priority: BoardItemPriorityDto) {
      this.item.priority = priority;
      this.setPropChanges(this.updateStoreKeys.priority, priority);
    }

    processAssignedUserUpdates(userUpdates) {
      this.item.assignedToUser = userUpdates;
      this.setPropChanges(this.updateStoreKeys.assignedUser, userUpdates);
    }

    processBoardItemDateUpdates(dateUpdates: IBoardItemDates) {
      this.item.validFromDate = dateUpdates.validFromDate;
      this.item.validToDate = dateUpdates.validToDate;
      this.item.dueDate = dateUpdates.dueDate;
      this.item.plannedDate = dateUpdates.plannedDate;

      this.setPropChanges(this.updateStoreKeys.validFromDate, dateUpdates.validFromDate);
      this.setPropChanges(this.updateStoreKeys.validToDate, dateUpdates.validToDate);
      this.setPropChanges(this.updateStoreKeys.dueDate, dateUpdates.dueDate);
      this.setPropChanges(this.updateStoreKeys.plannedDate, dateUpdates.plannedDate);
    }

    processSpreadsheetUpdate(updates) {
      this.item.spreadsheetData = updates;
      this.setPropChanges(this.updateStoreKeys.spreadsheetUpdate, true);
    }

    processPriorityChange(newState) {
      this.item.priority = newState ? BoardItemPriorityDto.High : BoardItemPriorityDto.Normal;
      this.setPropChanges(this.updateStoreKeys.priority, this.item.priority);
    }

    processIsRelevantForReportChange(newState) {
      this.item.isRelevantForReport = newState;
      this.setPropChanges(this.updateStoreKeys.isRelevantForReport, newState);
    }

    processIsConfidentialChange(newState) {
      this.item.isConfidential = newState;
      this.setPropChanges(this.updateStoreKeys.isConfidential, newState);
    }

    private setPropChanges(key, value) {
      this.updatesStore.push({ key, value });
    }

    async startEditDescription() {
      if (this.readOnly) return;

      this.editdescription = true;
      await this.$nextTick();

      (this.$refs.editor as any).focus('end');
      this.noCloseOnBackdrop = true;
    }

    async leaveDescription(reason: string) {
      if (this.item.isTemplate && reason == 'blur') {
        return;
      }

      this.editdescription = false;

      setTimeout(() => {
        this.noCloseOnBackdrop = false;
      }, 200);

      await this.autoSaveDelayed();
    }

    autoSaveTimeout: any = null;

    autoSaveDelayed() {
      if (this.autoSaveTimeout) clearTimeout(this.autoSaveTimeout);

      if (!this.item.id) {
        return;
      }

      this.autoSaveTimeout = setTimeout(async () => {
        await this.saveChanges();
      }, 300);
    }

    async moveTo(board) {
      const currentBoardId = this.item.ownerBoardId;
      const newBoardId = board.id;
      const boardItemId = this.item.id;

      const success = async () => {
        await this.boardStore.moveTo({ currentBoardId, newBoardId, boardItemId });
        this.closeBoardItemDetails();
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to move this item to ${board.title} ?`,
        'Confirm move to',
        'Yes, move',
        'No',
        success,
        null
      );
    }

    async shareWith(board, isPreSharing = false) {
      const dto = new BoardItemShareDto();
      dto.boardItemId = this.item.id;
      dto.sharedBoardId = board.id;
      dto.currentBoardId = this.boardStore.currentBoardId;

      const success = async () => {
        const biUpdates: BoardItemDto = await this.boardStore.shareWith(dto);
        if (isPreSharing) {
          this.boardStore.setCurrentBoardItem(biUpdates);
          GlobalHelper.NotifySuccess('Board item was pre-shared.');
        } else {
          this.closeBoardItemDetails();
        }
      };
      const action = isPreSharing ? 'pre-share' : 'share';

      ConfirmModal.confirm(
        this,
        `Are you sure you want to ${action} this item with ${board.title} ?`,
        'Confirm sharing',
        `Yes, ${action}`,
        'No',
        success,
        null
      );
    }

    async unshare(board, isPreSharing = false) {
      const dto = new BoardItemUnshareDto();
      dto.boardItemId = this.item.id;
      dto.currentBoardId = this.boardStore.currentBoardId;
      dto.unsharedBoardId = board.id;

      const success = async () => {
        const biUpdates = await this.boardStore.unShareWith(dto);

        if (isPreSharing) {
          this.boardStore.setCurrentBoardItem(biUpdates);
          GlobalHelper.NotifySuccess('Board item was unshared.');
        } else {
          this.closeBoardItemDetails();
        }
      };

      const action = isPreSharing ? 'pre-sharing' : 'sharing';

      ConfirmModal.confirm(
        this,
        `Are you sure you want to stop ${action} this item with ${board.title} ?`,
        `Confirm stop ${action}`,
        `Yes, stop ${action}`,
        'No',
        success,
        null
      );
    }

    async leaveSharedItem() {
      const dto = new BoardItemUnshareDto();
      dto.boardItemId = this.item.id;
      dto.currentBoardId = this.boardStore.currentBoardId;

      const success = async () => {
        await this.boardStore.leaveSharing(dto);
        this.closeBoardItemDetails();
      };

      ConfirmModal.confirm(this, `Are you sure you want to leave this task?`, 'Confirm leave task', 'Yes, leave task', 'No', success, null);
    }

    async stopSharing() {
      const currentBoardId = this.boardStore.currentBoardId;
      const newBoardId = this.boardStore.currentBoardId;
      const boardItemId = this.item.id;

      const success = () => {
        this.boardStore.stopSharing({ currentBoardId, newBoardId, boardItemId });
        this.closeBoardItemDetails();
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to stop sharing this item for all other boards?`,
        `Confirm stop sharing`,
        `Yes, stop sharing`,
        'No',
        success,
        null
      );
    }

    async linkBoardItem(occurrence) {
      const boardItemId = this.item.id;
      const params = { occurrenceId: occurrence.id, boardItemId };

      const success = () => {
        this.occurrenceStore.linkBoardItem(params);
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to link this item to Special Occurrence - ${occurrence.name} ?`,
        'Confirm link',
        'Yes, link',
        'No',
        success,
        null
      );
    }

    async delegate(board) {
      const currentBoardId = this.boardStore.currentBoardId;
      const newBoardId = board.id;
      const boardItemId = this.item.id;

      const success = () => {
        this.boardStore.delegate({ currentBoardId, newBoardId, boardItemId });
        this.closeBoardItemDetails();
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to delegate this item to ${board.title} ?`,
        'Confirm delegate to',
        'Yes, delegate',
        'No',
        success,
        null
      );
    }

    async takeBackDelegatedBoardItem() {
      const params = { boardItemId: this.item.id, moveType: MoveBackTypeDto.TakeBack };

      await this.boardStore.moveBack(params);
      this.closeBoardItemDetails();
    }

    async giveBackDelegatedBoardItem() {
      const params = { boardItemId: this.item.id, moveType: MoveBackTypeDto.GiveBack };

      const success = () => {
        this.boardStore.moveBack(params);
        this.closeBoardItemDetails();
      };

      const board = this.boardStore.allBoards.find((_) => _.id === this.item.ownerBoardId);

      ConfirmModal.confirm(
        this,
        `Are you sure you want to give back this item to ${board.title} ?`,
        'Confirm give back',
        'Yes, give back',
        'No',
        success,
        null
      );
    }

    openSendAsEmail() {
      this.$bvModal.show('sendAsEmailModal');
    }

    // Drag and drop of files
    private dragCounterDropzone = 0;
    private dragOverDropzone = false;
    private draggedOver = false;
    private fileIsUploading = false;

    async dropDropzone(e) {
      if (this.fileIsUploading) return;

      e.preventDefault();
      const droppedFiles = e.dataTransfer.files;

      if (droppedFiles.length > 1) {
        alert('Please drop only one file at a time');
        this.dragCounterDropzone = 0;
        this.draggedOver = false;
        this.dragOverDropzone = false;
        return;
      }

      const file = droppedFiles[0];
      this.fileIsUploading = true;
      await this.attachmentsStore.addAttachment({
        destination: 'BoardItem',
        id: this.item.id,
        fileData: file,
      });

      this.fileIsUploading = false;
      this.dragOverDropzone = false;
      this.draggedOver = false;

      GlobalHelper.NotifySuccess('File uploaded');
    }

    dragEnterDropzone(e) {
      e.preventDefault();
      console.log(e);
      this.dragCounterDropzone++;
      this.dragOverDropzone = true;
    }

    dragLeaveDropzone(e) {
      e.preventDefault();
      this.dragCounterDropzone--;

      if (this.dragCounterDropzone == 0) this.dragOverDropzone = false;
    }

    dragEnter(e) {
      e.preventDefault();

      if (this.item.id && e.dataTransfer && e.dataTransfer.files) {
        this.draggedOver = true;
      }
    }

    dragLeave(e) {
      e.preventDefault();
      this.draggedOver = false;
    }

    getTechnicalOccurrenceRouteName() {
      const so = this.occurrenceStore.allOccurrences.find((t) => t.id === this.item.specialOccurrenceId);

      return SpecialOccurrenceService.getTechnicalOccurrenceRouteName(so);
    }
  }
