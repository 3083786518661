
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { IBoardItemDto } from '@/apiclient/apiclient_generated';
  import BoardItemAction from './BoardItemAction.vue';

  @Component({
    components: {
      BoardItemAction,
    },
  })
  export default class BoardItemActions extends Vue {
    @Prop()
    item: IBoardItemDto;

    get getActions() {
      return this.lodash.sortBy(this.item.actions, 'sortOrder');
    }
  }
