
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { DateTime } from 'luxon';
  import { IBoardItemDto } from '@/apiclient/apiclient_generated';

  @Component({})
  export default class BoardItemPlannedDate extends Vue {
    @Prop()
    item: IBoardItemDto;

    get formattedDate() {
      return "Planned: " + DateTime.fromISO(this.item.plannedDate).toFormat('dd.MM.yyyy - HH:mm');
    }
  }
