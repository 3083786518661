import { DateTime, Settings } from 'luxon';
import BISeparator from '@/types/boardItemSeparator';
import { ShiftDto, ShiftTypeDto } from '@/apiclient/apiclient_generated';
import lodash from 'lodash';

export default class BoardItemSeparators {
  static getSeparators(boardItemListId, dateFrom, dateTo, currentShift: ShiftDto | null = null, shiftTypes: ShiftTypeDto[] = []): any {
    const timeRange = this.getTimeRange(dateFrom, dateTo);
    const hours: BISeparator[] = [];

    const now = DateTime.utc();
    if (now > timeRange.from) timeRange.from = now;

    while (timeRange.from < timeRange.to) {
      const currentT = timeRange.from.toLocal().toFormat('HH:00');

      hours.push({
        id: `${currentT}_${boardItemListId}`,
        title: currentT + (Settings.defaultZoneName.toLowerCase() == 'utc' ? ' UTC' : ''), // + (parseInt(timeRange.from.toFormat('yyyyMMddHH00'))-1).toString(),
        sortOrder: parseInt(timeRange.from.toFormat('yyyyMMddHH00')) - 1,
        isFix: true,
        boardItemListId: boardItemListId,
        value: timeRange.from.set({ minute: 0, second: 0, millisecond: 0 }),
      } as BISeparator);

      timeRange.from = timeRange.from.plus({ hours: 1 });
    }

    //add separators for next shifts
    //first order the shiftTypes by SortOrder
    let orderedShiftTypes = lodash.sortBy(shiftTypes, ['sortOrder']);

    //add one more whole shift cycle to the list to have something to work with ;-)
    orderedShiftTypes.push(...orderedShiftTypes);

    //get the index of the first occurencce of the current shiftType
    const currentShiftIndex = orderedShiftTypes.map((_) => _.id).indexOf(currentShift.shiftType.id);

    //remove the current shift and the previous shifts from the array => the remaining shift types are the types we want to iterate over
    orderedShiftTypes.splice(0, currentShiftIndex + 1);

    //get only n items from the array for the next shifts
    orderedShiftTypes = orderedShiftTypes.slice(0, 3);

    //as we want to build a timeline, store the end of the last shift in each iteration
    const currentShiftEnd = DateTime.fromISO(currentShift.dateTo, { zone: 'utc' });
    let lastShiftEnd = currentShiftEnd;

    for (const shiftType of orderedShiftTypes) {
      const newShiftStartTime = DateTime.fromISO(shiftType.defaultTimeFrom + 'Z', { zone: 'utc' });
      const newShiftEndTime = DateTime.fromISO(shiftType.defaultTimeTo + 'Z', { zone: 'utc' });

      let newShiftStart = lastShiftEnd.startOf('day').plus({ hours: newShiftStartTime.hour, minutes: newShiftStartTime.minute });

      //check if we need to add another day
      if (newShiftStart < currentShiftEnd) {
        newShiftStart = newShiftStart.plus({ days: 1 });
      }

      let newShiftEnd = newShiftStart.startOf('day').plus({ hours: newShiftEndTime.hour, minutes: newShiftEndTime.minute });
      if (newShiftEnd < newShiftStart) {
        newShiftEnd = newShiftEnd.plus({ days: 1 });
      }

      hours.push({
        id: `00:${newShiftStart.toFormat('HH:00')}_${boardItemListId}`,
        title: `Next ${shiftType.name} shift (${newShiftStart.toLocal().toFormat('HH:mm')} - ${newShiftEnd.toLocal().toFormat('HH:mm')} ${(Settings.defaultZoneName.toLowerCase() == 'utc' ? ' UTC' : '')})`,
        sortOrder: parseInt(newShiftStart.toFormat('yyyyMMddHH00')) - 1,
        isFix: true,
        isNextShift: true,
        boardItemListId: boardItemListId,
        value: newShiftStart,
      } as BISeparator);

      lastShiftEnd = newShiftEnd.plus({ minutes: 0 });
    }

    // add the future divider => at the end of the last shift
    hours.push({
      id: '00:00_00:00',
      title: `Future (> ${lastShiftEnd.toLocal().toFormat('yyyy-MM-dd HH:mm')})`,
      sortOrder: parseInt(lastShiftEnd.toFormat('yyyyMMddHH00')) - 1,
      isFix: true,
      isFuture: true,
      isNextShift: true,
      boardItemListId: boardItemListId,
      value: lastShiftEnd,
    } as BISeparator);

    return hours;
  }

  private static getTimeRange(dateFrom, dateTo): { from: DateTime; to: DateTime } {
    const from = DateTime.fromISO(dateFrom, { zone: 'utc' }); //.startOf('day').plus({ hours: new Date().getHours() });
    let to;

    if (dateTo) {
      to = DateTime.fromISO(dateTo, { zone: 'utc' });
    } else {
      const today = new Date(Settings.now());
      today.setHours(23);
      to = today;
    }

    return { from, to };
  }
}
