
  import Vue from "vue";
  import Component from "vue-class-component";
  import AuthService from "@/services/auth.service";
  import { Account } from "msal";
  import LoadingSpinner from "@/components/Misc/LoadingSpinner.vue";

  @Component({
    components: { LoadingSpinner }
  })
  export default class Login extends Vue {
    private authService = new AuthService();
    private account: Account = null;
    private renewResp: any = {};

    async mounted() {
      setTimeout(async () => {
        await this.authService.login();
      }, 2000);
    }

    async login() {
      await this.authService.login();
    }

    async renew() {
      this.renewResp = await this.authService.renewToken();
    }

    async getUser() {
      this.account = await this.authService.getUser();
    }

    async logout() {
      this.authService.logout();
    }
  }
