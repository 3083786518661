
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { ConnectedUserInfo, LogClient, LogDto } from "@/apiclient/apiclient_generated";
  import axios from 'axios';
  import { WS_URL as ApiUrl } from '@/config';

  @Component({})
  export default class LogsComponent extends Vue {
    private logService: LogClient = new LogClient(ApiUrl, axios);
    private logs: LogDto[] = [];
    private connectedUsers: ConnectedUserInfo[] = [];
    private interval: any;

    async mounted() {
      await this.refresh();
      this.interval = setInterval(async () => {
        await this.refresh();
      }, 20000);
    }

    async refresh() {
      this.connectedUsers = await this.logService.getConnectedUsers();
      this.logs = await this.logService.get();
    }

    async forcePageReload(connectionId: string) {
      await this.$opstaskhub.sendForceReload(connectionId);
    }

    destroyed() {
      clearInterval(this.interval);
    }
  }
