import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';
import {
  AttachmentClient, LabelsClient,
  LookupClient, MaterialRequestClient,
  OverviewClient,
  ReportsClient,
  SettingsClient, SpecialOccurrenceClient, SystemSettingsClient,
  UserClient
} from "@/apiclient/apiclient_generated";

export const settingsClient = new SettingsClient(ApiUrl, axios);
export const userClient = new UserClient(ApiUrl, axios);
export const lookupClient = new LookupClient(ApiUrl, axios);
export const overviewClient = new OverviewClient(ApiUrl, axios);
export const attachmentClient = new AttachmentClient(ApiUrl, axios);
export const labelsClient = new LabelsClient(ApiUrl, axios);
export const materialRequestClient = new MaterialRequestClient(ApiUrl, axios);
export const systemSettingsClient = new SystemSettingsClient(ApiUrl, axios);
export const specialOccurrenceClient = new SpecialOccurrenceClient(ApiUrl, axios);
export const reportsClient = new ReportsClient(ApiUrl, axios);