
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import BoardOverview from '@/components/Overview/BoardOverview.vue';
  import { BoardModule, BoardStore } from '@/store/board';

  @Component({
    components: {
      PageHeader,
      BoardOverview,
    },
  })
  export default class BoardOverviewComponent extends Vue {
    private boardStore: BoardModule = BoardStore;

    get boardTitle() {
      return this.boardStore.board.title;
    }

    async mounted() {
      await this.boardStore.getBoard(this.$route.params.id);
    }
  }
