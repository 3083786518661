
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop, Emit } from 'vue-property-decorator';
  import { uniqueId } from 'lodash';

  @Component
  export default class CheckboxWithLabel extends Vue {
    @Prop() value!: boolean;
    @Prop() label!: string;
    @Prop() labelStyle;
    @Prop() disabled!: boolean;
    private id = uniqueId();

    @Emit('input')
    valueUpdated(value: any) {
      return value.target.checked;
    }
  }
