import formatDue from "./formatDue";
import formatDate from "./formatDate";
import formatDateTime from "./formatDateTime";
import formatTime from "./formatTime";
import formatDateWithoutHours from "./formatDateWithoutHours";
import formatLineBreaks from "@/filters/formatLineBreaks";
import formatRangeDate from './formatRangeDate';
import stripHtml from "@/filters/stripHtml";

export default {
  install(Vue) {
    Vue.filter("formatDue", formatDue);
    Vue.filter("formatDate", formatDate);
    Vue.filter("formatDateTime", formatDateTime);
    Vue.filter("formatTime", formatTime);
    Vue.filter("formatDateWithoutHours", formatDateWithoutHours);
    Vue.filter("formatLineBreaks", formatLineBreaks);
    Vue.filter("formatRangeDate", formatRangeDate);
    Vue.filter("stripHtml", stripHtml);
  },
};
