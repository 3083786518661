
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import { UserModule, UserStore } from '@/store/user';

  @Component({
    components: {
      PageHeader,
    },
  })
  export default class UnauthorizedComponent extends Vue {
    private userStore: UserModule = UserStore;

    mounted() {
      const user: any = this.userStore.user;

      if (user && user.userHasAccess) {
        this.$router.push({ name: 'Boards' });
      }
    }
  }
