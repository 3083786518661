import { ChecklistClient, ChecklistDto } from '@/apiclient/apiclient_generated';
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators';
import store from '@/store/index';
import axios from 'axios';
import { WS_URL as ApiUrl } from '@/config';

@Module({ dynamic: true, namespaced: true, store, name: 'checklist' })
export class ChecklistModule extends VuexModule {
  private checklistClient = new ChecklistClient(ApiUrl, axios);

  @Action
  async addChecklist({ title, boardItemId }) {
    const checklist = {
      boardItemId,
      title,
    } as ChecklistDto;

    await this.checklistClient.addChecklist(boardItemId, checklist);
  }

  @Action
  async addChecklistItem({ boardItemId, item }) {
    await this.checklistClient.addChecklistItem(boardItemId, item);
  }

  @Action
  async changeSortOrder({ boardItemId, checklistId, direction }) {
    await this.checklistClient.changeSortOrder(boardItemId, checklistId, direction);
  }

  @Action
  async changeItemSortOrder({ boardItemId, checklistItemId, direction }) {
    await this.checklistClient.changeItemSortOrder(boardItemId, checklistItemId, direction);
  }

  @Action
  async renameChecklist({ boardItemId, checklistId, title }) {
    await this.checklistClient.updateChecklist(boardItemId, checklistId, title);
  }

  @Action
  async updateChecklistItem({ boardItemId, item }) {
    await this.checklistClient.updateChecklistItem(boardItemId, item);
  }

  @Action
  async deleteChecklist({ checklistId, boardItemId }) {
    await this.checklistClient.deleteChecklist(boardItemId, checklistId);
  }

  @Action
  async deleteChecklistItem({ boardItemId, checklistId, checklistItemId }) {
    await this.checklistClient.deleteChecklistItem(boardItemId, checklistId, checklistItemId);
  }
}

export const ChecklistStore = getModule(ChecklistModule);
