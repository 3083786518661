
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import { CreateSpecialOccurrenceDto, SpecialOccurrenceTypeDto } from '@/apiclient/apiclient_generated';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { systemSettingsClient } from '@/apiclient/opsboardapi';

  @Component({
    components: { ModalWindow },
  })
  export default class AddSpecialOccurrence extends Vue {
    private occurrenceStore: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private isSaving = false;
    private occurrence = new CreateSpecialOccurrenceDto({
      name: '',
      type: SpecialOccurrenceTypeDto.Comat,
    });

    materialRequestIsEnabled = false;

    async mounted() {
      await this.occurrenceStore.loadPossibleOwnerBoards();
      this.materialRequestIsEnabled = await systemSettingsClient.getIsMaterialRequestEnabled();

      //set owner board to first board if we only have one
      if (this.occurrenceStore.possibleOwnerBoards.length == 1) {
        this.occurrence.ownerBoardId = this.occurrenceStore.possibleOwnerBoards[0].id;
      }
    }

    get canAdd() {
      if (this.occurrence.type == 'MaterialRequest') {
        if (!this.isSaving && this.occurrence.type && this.occurrence.ownerBoardId) return true;
      } else {
        if (!this.isSaving && this.occurrence.name && this.occurrence.type && this.occurrence.ownerBoardId) return true;
      }

      return false;
    }

    get availableTypes() {
      if (this.materialRequestIsEnabled) {
        return [
          SpecialOccurrenceTypeDto.Comat,
          SpecialOccurrenceTypeDto.TechnicalOccurrence,
          SpecialOccurrenceTypeDto.Diversion,
          SpecialOccurrenceTypeDto.MaterialRequest,
        ];
      } else {
        return [SpecialOccurrenceTypeDto.Comat, SpecialOccurrenceTypeDto.TechnicalOccurrence, SpecialOccurrenceTypeDto.Diversion];
      }
    }

    async addOccurrence() {
      this.isSaving = true;
      const newOcc = await this.occurrenceStore.create(this.occurrence);
      await this.$router.push({ name: 'special-occurrence', params: { occId: newOcc.id } });
    }

    cancel() {
      this.$bvModal.hide('newSpecialOccurrence');
    }
  }
