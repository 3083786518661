import { DirectiveOptions } from 'vue';

const maxStringLength = 500;

const simplifyHtmlToString: DirectiveOptions = {
  inserted(el, node) {
    if (node.value) {
      el.innerHTML = removeHtmlTags(node.value).substring(0, maxStringLength);
    }
  },

  update(el, node) {
    if (node.value) {
      el.innerHTML = removeHtmlTags(node.value).substring(0, maxStringLength);
    }
  },
};

const removeHtmlTags = function (stripHtml) {
  return stripHtml.replace(/<\/?[^>]+(>|$)/g, ' ');
};

export default simplifyHtmlToString;
