var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"disable-component",rawName:"v-disable-component",value:(_vm.occurrence.isCompleted || !_vm.userCanEdit),expression:"occurrence.isCompleted || !userCanEdit"}],staticClass:"so-completion"},[_c('div',[_c('div',{staticClass:"label mt-2 mb-1"},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.occurrence.name),expression:"occurrence.name"}],staticClass:"form-control",attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.occurrence.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.occurrence, "name", $event.target.value)}}})]),(_vm.soBoardSummary && _vm.soBoardSummary.length)?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"label"},[_vm._v("Task Overview")]),_vm._l((_vm.soBoardSummary),function(board){return _c('div',{key:board.boardId},[_c('div',{staticClass:"font-weight-bold bsummary-box align-content-center mb-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-5 text-nowrap d-flex align-items-center"},[_c('i',{staticClass:"far fa-check-square mr-3"}),_vm._v(" "+_vm._s(board.boardName)+" ")]),_c('div',{staticClass:"col-5"},_vm._l((board.items),function(item,index){return _c('div',{key:index,staticClass:"ds-flex justify-content-between align-content-center"},[_vm._v(" "+_vm._s(item.count)+" "),_c('div',{staticClass:"ml-3 pt-1 badge badge-pill",class:{
                  'badge-primary': item.name === _vm.boardItemStatus.Planned,
                  'badge-info': item.name === _vm.boardItemStatus.Information,
                  'badge-warning': item.name === _vm.boardItemStatus.InProgress,
                  'badge-success': item.name === _vm.boardItemStatus.Done || item.name === _vm.boardItemStatus.UnderReview,
                }},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0),_c('div',{staticClass:"col-2 d-flex align-items-center justify-content-end"},[_c('i',{staticClass:"far fa-circle board-summary",class:{
                'board-orange': !_vm.isBoardCompleted(board.items),
                'board-green': _vm.isBoardCompleted(board.items),
              }})])])])])})],2):_vm._e(),_c('div',{staticClass:"label mt-2 mb-1"},[_vm._v("Outcome")]),_c('textarea-autosize',{staticClass:"form-control",staticStyle:{"padding-right":"30px"},attrs:{"rows":"3","placeholder":"Final comment...","min-height":75,"disabled":_vm.occurrence.isCompleted},model:{value:(_vm.occurrence.outcomeComment),callback:function ($$v) {_vm.$set(_vm.occurrence, "outcomeComment", $$v)},expression:"occurrence.outcomeComment"}}),(_vm.isTechnicalOccurrence)?_c('div',[_c('div',{staticClass:"label mt-2 mb-1"},[_vm._v("Notify")]),_c('multiselect',{staticClass:"flex-grow-1 mr-4",attrs:{"placeholder":"Click to select, type to add recipient","label":"name","track-by":"name","options":_vm.allReceiversList,"multiple":true,"open-direction":"bottom","taggable":true,"tagPlaceholder":"Press enter to add the address"},on:{"tag":_vm.addRecipient},scopedSlots:_vm._u([{key:"tag",fn:function(props){return [_c('div',{staticClass:"multiselect-tag"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip:hover",arg:"hover"}],attrs:{"title":props.option.email}},[_vm._v(_vm._s(props.option.name))]),_c('i',{staticClass:"far fa-times cursor-pointer",on:{"click":function($event){return props.remove(props.option)}}})])]}}],null,false,1936812475),model:{value:(_vm.chosenRecipients),callback:function ($$v) {_vm.chosenRecipients=$$v},expression:"chosenRecipients"}})],1):_vm._e(),(!_vm.occurrence.isCompleted)?_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('checkbox-with-label',{attrs:{"label":'Send final management report'},model:{value:(_vm.sendFinalManagementReport),callback:function ($$v) {_vm.sendFinalManagementReport=$$v},expression:"sendFinalManagementReport"}}),_c('div',{staticClass:"label"},[_c('button',{staticClass:"btn btn-block btn-sm btn-success mt-2",on:{"click":function($event){return _vm.completeOccurrence()}}},[_c('i',{staticClass:"far fa-save"}),_vm._v(" Complete Occurrence ")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }