
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { BoardItemDto } from "@/apiclient/apiclient_generated";

  @Component({})
  export default class BoardItemTemplateSingle extends Vue {
    @Prop() template: BoardItemDto;

    openModal() {
      this.$bvModal.show(this.template.id);
    }
  }
