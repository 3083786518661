import { render, staticRenderFns } from "./SpecialOccurrenceAccess.vue?vue&type=template&id=1951f226&scoped=true&"
import script from "./SpecialOccurrenceAccess.vue?vue&type=script&lang=ts&"
export * from "./SpecialOccurrenceAccess.vue?vue&type=script&lang=ts&"
import style0 from "./SpecialOccurrenceAccess.vue?vue&type=style&index=0&id=1951f226&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1951f226",
  null
  
)

export default component.exports