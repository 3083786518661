
  import { UserDto } from '@/apiclient/apiclient_generated';
  import { UserModule, UserStore } from '@/store/user';
  import Vue from 'vue';
  import Component from 'vue-class-component';

  @Component({})
  export default class UserInfo extends Vue {
    private userStore: UserModule = UserStore;

    get user(): UserDto {
      return this.userStore.user;
    }
  }
