
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { boardItemTemplateClient } from '@/apiclient/opsboard-api';
  import { BoardItemTemplateAvailabilityViewDto } from '@/apiclient/apiclient_generated';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import { Prop } from 'vue-property-decorator';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { GlobalHelper } from "@/helpers/global-helper";

  @Component({ components: { ModalWindow, LoadingSpinner } })
  export default class BoardItemTemplateAvailabilityEdit extends Vue {
    @Prop() currentTemplateId: string;

    private availabilites: BoardItemTemplateAvailabilityViewDto[] = [];
    private loading = false;

    async mounted() {
      await this.loadData();
    }

    async loadData() {
      this.loading = true;
      this.availabilites = await boardItemTemplateClient.getAvailability(this.currentTemplateId);
      this.loading = false;
    }

    close() {
      this.$root.$bvModal.hide('editAvailabilityModal');
    }

    async saveAndClose() {
      await boardItemTemplateClient.storeAvailability(this.currentTemplateId, this.availabilites);
      GlobalHelper.NotifySuccess('Availability successfully stored');
      this.close();
    }
  }
