
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';
  import { AttachmentsModule, AttachmentsStore } from '@/store/attachments';
  import { AttachmentDestination } from '@/apiclient/apiclient_generated';

  @Component
  export default class UploadAttachment extends Vue {
    @Prop() destination: AttachmentDestination;
    @Prop() destinationId: string;
    @Prop() label: string;
    @Prop() isSmallButton: boolean;

    private attachmentssStore: AttachmentsModule = AttachmentsStore;

    addAttachment() {
      (this.$refs.file as HTMLInputElement).click();
    }

    private async uploadAttachment() {
      const fileData = (this.$refs.file as HTMLInputElement).files[0];

      await this.attachmentssStore.addAttachment({
        destination: this.destination,
        id: this.destinationId,
        fileData: fileData,
      });

      this.$emit('uploaded');
    }
  }
