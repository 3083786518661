
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Emit, Prop } from 'vue-property-decorator';
  import { GridColumnProps } from '@progress/kendo-vue-grid';
  import { VendorDto } from '@/apiclient/apiclient_generated';
  import { materialRequestClient } from '@/apiclient/opsboardapi';

  @Component({
    components: {},
  })
  export default class SelectVendor extends Vue {
    @Prop() selectedVendors: string[];
    @Prop() materialRequestId: string;

    vendors: VendorDto[] = [];
    addedVendors: string[] = [];

    async mounted() {
      await this.loadData();
    }

    async loadData() {
      this.vendors = await materialRequestClient.getVendorsWithDistance(this.materialRequestId);
    }

    get columns(): GridColumnProps[] {
      return [
        { field: 'selected', width: '50px', title: ' ' },
        { field: 'favorite', width: '30px', title: ' ' },
        { field: 'name', title: 'Name', filterable: false },
        { field: 'email', title: 'E-Mail', filterable: false},
        { field: 'location', title: 'Location', filterable: false},
        { field: 'distance', title: 'Distance in km', filterable: false},
      ];
    }

    @Emit('selectvendors')
    selectVendors() {
      return this.addedVendors;
    }

    @Emit('cancel')
    cancel() {
      return;
    }

    toggleSelection(guid: string) {
      if (this.addedVendors.indexOf(guid) === -1) {
        this.addedVendors.push(guid);
      } else {
        this.addedVendors = this.addedVendors.filter((_) => _ != guid);
      }
    }

    isSelected(guid: string) {
      return this.addedVendors.indexOf(guid) != -1;
    }

    onRowClick(event) {
      this.toggleSelection(event.dataItem.id);
    }
  }
