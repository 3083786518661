
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import PageHeader from '@/components/PageHeader.vue';
  import BoardsLabels from '@/components/BoardsSettings/BoardsLabels.vue';
  import RouteNames from '@/types/routeNames';
  import { UserModule, UserStore } from '@/store/user';
  import MaterialRequestService from '@/services/materialRequest.service';

  @Component({
    components: {
      PageHeader,
      BoardsLabels,
    },
  })
  export default class BoardsSettings extends Vue {
    private userStore: UserModule = UserStore;
    routes = RouteNames;

    mounted() {
      if (!this.userStore.user) {
        this.$router.push({ name: RouteNames.boards });
      }
    }

    get isGeneralLabelsIsAvailable() {
      return this.userStore.user.isAdmin || this.userStore.user.isSuperAdmin;
    }

    get isMaterialRequestEmailsAvailable() {
      return MaterialRequestService.isMaterialRequestEmailsAvailable(this.userStore.user);
    }
  }
