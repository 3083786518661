
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { ComatRoutingDto, FlightInfoDto } from "@/apiclient/apiclient_generated";
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import FlightInfo from '@/components/SpecialOccurrence/Data/Flights/FlightInfo.vue';
  import ModalWindow from '@/components/ModalWindow.vue';
  import { DateTime } from 'luxon';
  import BoardItemDatePicker from "@/components/BoardItem/BoardItemDatePicker.vue";

  @Component({
    components: {
      BoardItemDatePicker,
      FlightInfo,
      ModalWindow,
    },
  })
  export default class ComatFlightsSelector extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private filters: FlightInfoDto = new FlightInfoDto();

    mounted() {
      this.$bvModal.show('addComatFlightModal');
    }

    private dateFilter = (filterDate: DateTime, date: string) => {
      const itemDate = DateTime.fromISO(date);

      return itemDate.isValid && filterDate?.isValid
        ? itemDate.year === filterDate.year &&
            itemDate.month === filterDate.month &&
            itemDate.day === filterDate.day
        : true;
    };

    private stringFilter = (filter: string, source: string) => {
      return filter ? source?.toLowerCase().includes(filter.toLowerCase()) : true;
    };

    get filteredcomatRouting() {
      const originalRoutings: ComatRoutingDto[] = this.store.comatRoutings;

      let filterDate: DateTime;

      if (this.filters.date) {
        filterDate = DateTime.fromISO(this.filters.date);
      }

      const result = originalRoutings.filter((_) => {
        return _.flights.some(
          (_) =>
            this.dateFilter(filterDate, _.date) &&
            this.stringFilter(this.filters.flightNo, _.flightNo) &&
            this.stringFilter(this.filters.operator, _.operator) &&
            this.stringFilter(this.filters.aircraftType, _.aircraftType) &&
            this.stringFilter(this.filters.aircraftRegistration, _.aircraftRegistration) &&
            this.stringFilter(this.filters.departureAirport, _.departureAirport) &&
            this.stringFilter(this.filters.arrivalAirport, _.arrivalAirport) &&
            this.stringFilter(this.filters.flightState, _.flightState)
        );
      });

      return result;
    }

    async saveComatRoute(route: ComatRoutingDto) {
      this.$emit('saveComatRoute', route.flights);
      await this.closeModal();
    }

    async closeModal() {
      this.$emit('closeRoutesModal');
      await this.$root.$emit('bv::hide::modal', 'addComatFlight');
    }

    modalHiding() {
      this.closeModal();
    }
  }
