
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import {
    AddMaterialRequestVendorDto,
    AirportDto,
    MaterialRequestDto,
    PartOverviewViewDto,
    SelectMaterialRequestVendorDto,
    SpecialOccurrenceAccessLevel,
    VendorDto,
    VendorRequestDto,
    ManagementReportPostponeDto,
  } from '@/apiclient/apiclient_generated';
  import { Prop } from 'vue-property-decorator';
  import { GlobalHelper } from '@/helpers/global-helper';
  import TechnicalOccurrenceFlightsSelector from '@/components/SpecialOccurrence/Data/Flights/TechnicalOccurrenceFlightsSelector.vue';
  import { GridColumnProps } from '@progress/kendo-vue-grid';
  import { materialRequestClient } from '@/apiclient/opsboardapi';
  import AddVendor from '@/components/SpecialOccurrence/MaterialRequest/AddVendor.vue';
  import VendorRequestEmail from '@/components/SpecialOccurrence/MaterialRequest/VendorRequestEmail.vue';
  import VendorRequest from '@/components/SpecialOccurrence/MaterialRequest/VendorRequest.vue';
  import SelectVendor from '@/components/SpecialOccurrence/MaterialRequest/SelectVendor.vue';
  import ModalWindow from '@/components/ModalWindow.vue';
  import formatTime from '@/filters/formatTime';
  import formatDateTime from '@/filters/formatDateTime';
  import { MaterialRequestModule, MaterialRequestStore } from '@/store/materialRequest';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';

  @Component({
    components: {
      ModalWindow,
      SelectVendor,
      VendorRequest,
      VendorRequestEmail,
      AddVendor,
      TechnicalOccurrenceFlightsSelector,
      LoadingSpinner,
    },
  })
  export default class MaterialRequestData extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private mrStore: MaterialRequestModule = MaterialRequestStore;
    @Prop() id: string;

    parts: PartOverviewViewDto[] = [];
    vendorRequests: VendorRequestDto[] = [];

    reportIsSending = false;
    private defaultPostponeValue = 1;
    postponeValue = this.defaultPostponeValue;

    selectedParts: string[] = [];
    numberOfPendingVendorRequests = 0;
    sendingVendorRequests = false;

    async mounted() {
      await this.loadData();
    }

    destroyed() {
      this.mrStore.setMaterialRequest({} as MaterialRequestDto);
    }

    get materialRequest() {
      return this.mrStore.materialRequest;
    }

    async loadData() {
      await this.mrStore.getMaterialRequest(this.id);

      this.parts = await materialRequestClient.getParts(this.id);
      this.vendorRequests = await materialRequestClient.getVendorRequests(this.id);
      this.numberOfPendingVendorRequests = await materialRequestClient.getNumberOfPendingVendorRequests(this.id);
    }

    get columns(): GridColumnProps[] {
      return [
        { field: 'selected', width: '50px', title: ' ' },
        { field: 'pn', title: 'PN', filterable: false, width: 100 },
        { field: 'ipc', title: 'IPC', filterable: false, width: 100 },
        { field: 'description', title: 'Description', filterable: false, width: 170 },
        { field: 'quantityRequested', title: 'Quantity', filterable: false, width: 70 },
        { field: 'altparts', title: 'Alt Parts Checked', filterable: false, width: 100 },
        { field: 'requestStatus', title: 'Request Status', filterable: false },
        { field: 'orderStatus', title: 'Order Status', filterable: false },
        { field: 'shipmentStatus', title: 'Shipment Status', filterable: false },
        { field: 'times', title: 'Time', filterable: false },
        { field: 'action', title: ' ', filterable: false, width: 80 },
      ];
    }

    get result() {
      return this.parts;
    }

    get selectedVendors(): string[] {
      return this.vendorRequests.map((_) => _.vendor.id);
    }

    get canAddVendor(): boolean {
      return this.selectedParts.length > 0;
    }

    get aircrafts() {
      return this.store.aircrafts;
    }

    get airports() {
      return this.store.airports;
    }

    get userCanEdit(): boolean {
      return (
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin ||
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor
      );
    }

    get stationInfo(): AirportDto {
      return this.store.airports.find((_) => _.id == this.materialRequest.airport) || new AirportDto();
    }

    toggleSelection(guid: string) {
      if (this.selectedParts.indexOf(guid) === -1) {
        this.selectedParts.push(guid);
      } else {
        this.selectedParts = this.selectedParts.filter((_) => _ != guid);
      }
    }

    editPart(partId: string) {
      this.$router.push({ name: 'MaterialRequestPartEdit', params: { materialRequestId: this.materialRequest.id, partId: partId } });
    }

    addPart() {
      this.$router.push({ name: 'MaterialRequestPartEdit', params: { materialRequestId: this.materialRequest.id, partId: '0' } });
    }

    openAddVendor() {
      const modal: any = this.$refs['addVendor'];
      modal.show();
    }

    closeAddVendor() {
      const modal: any = this.$refs['addVendor'];
      modal.hide();
    }

    async addVendor(vendor: VendorDto) {
      const dto = AddMaterialRequestVendorDto.fromJS({});
      dto.name = vendor.name;
      dto.email = vendor.email;
      dto.partIds = this.selectedParts;
      dto.materialRequestId = this.materialRequest.id;
      await materialRequestClient.addVendor(dto);
      await this.loadData();
      this.selectedParts.splice(0);
      this.closeAddVendor();
      GlobalHelper.NotifySuccess('Vendor added');
    }

    openSelectVendors() {
      const modal: any = this.$refs['selectVendors'];
      modal.show();
    }

    closeSelectVendors() {
      const modal: any = this.$refs['selectVendors'];
      modal.hide();
    }

    async selectVendors(vendors: string[]) {
      const dto = SelectMaterialRequestVendorDto.fromJS({});
      dto.vendorIds = vendors;
      dto.partIds = this.selectedParts;
      dto.materialRequestId = this.materialRequest.id;
      await materialRequestClient.selectVendors(dto);
      await this.loadData();
      this.selectedParts.splice(0);
      this.closeSelectVendors();
      GlobalHelper.NotifySuccess('Vendors selected');
    }

    async saveChanges() {
      GlobalHelper.NotifySuccess('Material Request updated');
      await materialRequestClient.saveChanges(this.materialRequest);
      await this.loadData();
    }

    partToBeRemovedId = '';
    openRemovePartModal(id: string) {
      this.partToBeRemovedId = id;
      const modal: any = this.$refs['removePartModal'];
      modal.show();
    }

    cancelRemovePart() {
      const modal: any = this.$refs['removePartModal'];
      modal.hide();
      this.partToBeRemovedId = '';
    }

    async removePart() {
      if (this.partToBeRemovedId) {
        await materialRequestClient.deletePart(this.partToBeRemovedId);
        this.partToBeRemovedId = '';
        const modal: any = this.$refs['removePartModal'];
        modal.hide();

        await this.loadData();
      }
    }

    async processMaterialRequestReport() {
      try {
        const response = await materialRequestClient.processMaterialRequestReport(this.materialRequest.id);
        if (response.isSuccess) {
          GlobalHelper.NotifySuccess(response.message);
        } else {
          GlobalHelper.NotifyError(response.message);
        }
      } catch {
        GlobalHelper.NotifyError('Something went wrong');
      }
    }

    async sendAllRequests() {
      try {
        this.sendingVendorRequests = true;
        const count = await materialRequestClient.sendAllPendingVendorRequests(this.materialRequest.id);
        await this.loadData();
        GlobalHelper.NotifySuccess('E-Mail was sent to ' + count + ' contacts');
      } catch (e) {
        GlobalHelper.NotifyError('Error sending E-Mail');
      } finally {
        this.sendingVendorRequests = false;
      }
    }

    navigateTechnicalOccurrence() {
      this.$router.push({ name: 'MaterialRequestLinkIntermediate', params: { id: this.materialRequest.technicalOccurrenceGuid } });
    }

    previewEmail() {
      const modal: any = this.$refs['managementEmail'];
      modal.show();
    }

    async sendManagementStatusMail() {
      try {
        this.reportIsSending = true;
        await materialRequestClient.sendManagementStatusEmail(this.materialRequest.id);
        GlobalHelper.NotifySuccess('E-Mail was sent');
        this.closeEmailModal();
      } catch (e) {
        GlobalHelper.NotifyError('Error sending E-Mail');
      } finally {
        this.reportIsSending = false;
      }
    }

    closeEmailModal() {
      const modal: any = this.$refs['managementEmail'];
      modal.hide();
    }

    cancelReportPostpone() {
      const popover: any = this.$refs.reportPostponePopover;
      if (popover) {
        popover.doClose();
        this.postponeValue = this.defaultPostponeValue;
      }
    }

    async saveReportPostpone() {
      const command = {
        materialRequestId: this.materialRequest.id,
        postponeHours: this.postponeValue,
      } as ManagementReportPostponeDto;

      await materialRequestClient.postponeManagementReport(command);

      GlobalHelper.NotifySuccess('Next report send was successfully postponed.');

      const popover: any = this.$refs.reportPostponePopover;
      if (popover) {
        popover.doClose();
        this.postponeValue = this.defaultPostponeValue;
      }
    }

    getFormattedTimeValue(timeValue) {
      if (!timeValue) {
        return ' -- : -- ';
      }

      // return formatTime(timeValue);
      return formatDateTime(timeValue);
    }
  }
