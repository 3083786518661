
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { boardItemTemplateClient } from '@/apiclient/opsboard-api';
  import { BoardItemTemplateWithScheduleAndAvailabilityDto, SpecialOccurrenceType } from '@/apiclient/apiclient_generated';
  import BoardItemScheduleEdit from '@/components/BoardSettings/BoardItemScheduleEdit.vue';
  import BoardItemTemplateSingle from '@/components/BoardSettings/BoardItemTemplateSingle.vue';
  import TaskCard from '@/components/TaskCard.vue';
  import { BoardModule, BoardStore } from '@/store/board';
  import RouteNames from '@/types/routeNames';
  import BoardItemTemplateAvailabilityEdit from '@/components/BoardSettings/BoardItemTemplateAvailabilityEdit.vue';
  import { orderBy } from 'lodash';
  import LoadingSpinner from '@/components/Misc/LoadingSpinner.vue';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import { GlobalHelper } from '@/helpers/global-helper';
  import { LabelModule, LabelStore } from '@/store/label';

  Component.registerHooks(['beforeRouteUpdate', 'beforeRouteEnter']);

  @Component({
    components: { LoadingSpinner, BoardItemTemplateAvailabilityEdit, TaskCard, BoardItemTemplateSingle, BoardItemScheduleEdit },
  })
  export default class BoardTemplates extends Vue {
    private boardId: string;
    private boardStore: BoardModule = BoardStore;
    private labelStore: LabelModule = LabelStore;

    private templates: BoardItemTemplateWithScheduleAndAvailabilityDto[] = [];

    private currentTemplateId = '';
    private searchString = '';
    private templateType = 'All';
    private loading = true;

    created() {
      this.boardId = this.$route.params.id;
    }

    beforeRouteUpdate(to: any, from: any, next: any) {
      if (to.name == RouteNames.biTemplates) {
        this.loadData();
        next();
      } else {
        next();
      }
    }

    get templateTypeOptions(): any[] {
      return [
        { id: 'All', name: 'All Templates' },
        { id: SpecialOccurrenceType.None, name: 'Board Templates' },
        { id: SpecialOccurrenceType.TechnicalOccurrence, name: 'Technial Occurrence Templates' },
        { id: SpecialOccurrenceType.Comat, name: 'COMAT Templates' },
        { id: SpecialOccurrenceType.Diversion, name: 'Diversion Templates' },
        { id: 'Scheduled', name: 'Templates with Schedules' },
      ];
    }

    async loadData() {
      await this.boardStore.getBoard(this.boardId);
      await this.labelStore.loadSelectedBoardLabels(this.boardId);
      this.templates = await boardItemTemplateClient.getTemplates(this.boardId);
      this.loading = false;
    }

    async mounted() {
      await this.loadData();
    }

    get foundTemplates(): BoardItemTemplateWithScheduleAndAvailabilityDto[] {
      let result = this.templates;

      if (!this.searchString && this.templateType == 'All') {
        return result;
      }

      if (this.searchString) {
        result = result.filter(
          (t) =>
            t.template.title.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 ||
            (t.template.description ? t.template.description.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1 : false)
        );
      }

      if (this.templateType != 'All') {
        if (this.templateType == 'Scheduled') {
          result = result.filter((_) => _.schedules.length != 0);
        } else {
          result = result.filter((_) => _.availability.filter((av) => av.specialOccurrenceType == this.templateType).length != 0);
          result.forEach((res) => {
            //@ts-ignore
            res.sortOrder = res.availability.find((_) => _.specialOccurrenceType == this.templateType).sortOrder;
          });

          result = orderBy(result, 'sortOrder');
        }
      }

      return result;
    }

    addTemplate() {
      this.$router.push({ name: 'TemplateDetail', params: { itemid: 'new' } });
    }

    async editSchedule(id: string) {
      this.currentTemplateId = id;
      this.$bvModal.show('editTemplateModal');
    }

    async editAvailability(id: string) {
      this.currentTemplateId = id;
      this.$bvModal.show('editAvailabilityModal');
    }

    async removeTemplate(id: string) {
      try {
        await boardItemTemplateClient.removeTemplate(id);
        await this.loadData();
        GlobalHelper.NotifySuccess('Template removed');
      } catch (error) {
        GlobalHelper.NotifyError('Template could not be removed');
      }
    }

    confirmRemoveTemplate(id: string) {
      const success = () => {
        this.removeTemplate(id);
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to remove this template?`,
        'Confirm delete',
        'Yes, remove template',
        'No',
        success,
        null,
        'danger'
      );
    }

    editTemplate(id: string) {
      this.$router.push({ name: 'TemplateDetail', params: { itemid: id } });
    }
  }
