
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { SpecialOccurrenceModule, SpecialOccurrenceStore } from '@/store/specialOccurrence';
  import {
    BoardItemStatusDto,
    CompleteOccurrenceDto,
    OccurrenceUpdateRecipientDto,
    SpecialOccurrenceAccessLevel,
    SpecialOccurrenceBoardListSummaryDto,
    SpecialOccurrenceTypeDto,
    SpecialOccurrenceUpdateDto,
  } from '@/apiclient/apiclient_generated';
  import { ConfirmModal } from '@/helpers/confirm-modal';
  import CheckboxWithLabel from '@/components/Misc/CheckboxWithLabel.vue';

  @Component({
    components: {
      'checkbox-with-label': CheckboxWithLabel,
    },
  })
  export default class SpecialOccurrenceCompletion extends Vue {
    private store: SpecialOccurrenceModule = SpecialOccurrenceStore;
    private occType = SpecialOccurrenceTypeDto;
    private boardItemStatus = BoardItemStatusDto;

    sendFinalManagementReport = true;

    async mounted() {
      await this.store.getAirports();
      await this.store.getSpecialOccurrenceBoardSummary(this.$route.params.occId);
      await this.store.loadOccurrenceUpdateRecipients();

      this.setDefaultRecipients();
    }

    isBoardCompleted(boardItems: SpecialOccurrenceBoardListSummaryDto[]) {
      const isCompleted = boardItems.some(
        (_) => (_.name === this.boardItemStatus.Planned && _.count > 0) || (_.name === this.boardItemStatus.InProgress && _.count > 0)
      );

      return !isCompleted;
    }

    get occurrence() {
      return this.store.currentOccurrence;
    }

    get soBoardSummary() {
      return this.store.boardSummary;
    }

    get isTechnicalOccurrence() {
      return this.store.currentOccurrence.type == SpecialOccurrenceTypeDto.TechnicalOccurrence;
    }

    get userCanEdit() {
      return (
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Admin ||
        this.store.currentOccurrenceUserAccessLevel == SpecialOccurrenceAccessLevel.Editor
      );
    }

    private manualRecipients: OccurrenceUpdateRecipientDto[] = [];
    private chosenRecipients: OccurrenceUpdateRecipientDto[] = [];

    private setDefaultRecipients() {
      const defaults = this.allReceiversList.filter((_) => _.isDefault);

      if (defaults.length) {
        const recipients: any = defaults.map((_) => {
          return {
            id: _.id,
            name: _.name,
            email: _.email,
          };
        });

        this.chosenRecipients = recipients;
      }
    }

    get allReceiversList() {
      return this.store.updateRecipients.concat(this.manualRecipients);
    }

    addRecipient(tag) {
      const recipient = new OccurrenceUpdateRecipientDto();
      recipient.email = tag;
      recipient.name = tag;
      this.manualRecipients.push(recipient);
      this.chosenRecipients.push(recipient);
    }

    async completeOccurrence() {
      const occId = this.$route.params.occId;
      const store = this.store;
      const router = this.$router;

      const recipients = this.chosenRecipients ? this.chosenRecipients.map((_) => _.email) : [];

      const success = async () => {
        if (this.isTechnicalOccurrence) {
          const updateObj = new SpecialOccurrenceUpdateDto();
          updateObj.text = this.occurrence.outcomeComment;
          updateObj.specialOccurrenceId = this.$route.params.occId;
          updateObj.recipients = recipients;
          updateObj.isCompletion = true;

          await this.store.createSpecialOccurrenceUpdate(updateObj);
        }

        await store.complete({ id: occId, sendFinalManagementReport: this.sendFinalManagementReport } as CompleteOccurrenceDto);

        router.push({ name: 'Boards' });
      };

      ConfirmModal.confirm(
        this,
        `Are you sure you want to complete this Special Occurrence?`,
        'Confirm complete',
        'Yes, complete',
        'No',
        success,
        null
      );
    }
  }
